import React from 'react';

/*
    {   id: 1, 
        term: '',
        slug: '',
        image: '',
        image_alt: '', 
        definition: <p></p>
    },

*/

const terms = [
    {   id: 1, 
        term: '529 Plan',
        slug: '529-plan',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/626809a648f400e07be19fa6_529.jpg',
        image_alt: '529 plan', 
        definition: <p>A financial account that allows you to save and invest money for the purpose of paying for education expenses, from K-12 to college and trade schools.<br></br><br></br>
        There are two types of 529 plans, one is a tax-advantaged investment account when withdrawals are used for education expenses, and the second lets you prepay for college tuition at today's prices.<br></br><br></br>529 plans are a popular college fund for kids that help lower college costs.</p>
    },

    {   id: 2, 
        term: 'Allowance',
        slug: 'allowance',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/6338ca66cfac284b8416cc69_Allowance.png',
        image_alt: 'Allowance', 
        definition: <p>An amount of money that parents routinely give to kids for them to spend and save as they wish.<br></br><br></br>Often used to teach kids about money management, saving and budgeting.<br></br><br></br>Sometimes an allowance is tied to performing chores in order to teach kids work ethic and about earning money.</p>
    },

    {   id: 3, 
        term: 'Baby Registry',
        slug: 'baby-registry',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/6338d6a96320c8bebc7e921e_baby%20registry.png',
        image_alt: 'Baby registry', 
        definition: <p>A wish list of all the gifts, items, essentials, needs, and wants, made by a parent and shared with family & friends before a baby shower or when a baby is born.<br></br><br></br>Baby registries are the polite and appropriate way to let your family and friends know what you would prefer to receive as gifts for your baby.<br></br><br></br>There are online baby registries, in-store registries, and even financial registries for your baby's long term savings.</p>
    },

    {   id: 4, 
        term: 'Child Tax Credit',
        slug: 'child-tax-credit',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/6338cb6bd610db3f69498f34_child%20tax%20credit.png',
        image_alt: 'child tax credit', 
        definition: <p>A tax benefit for families that reduces their income tax for each child they have and has the potential to pay out a lump sum amount if the total tax owed is less than the full credit amount.<br></br><br></br>The credit provides up to $2,000 in benefits per child per year ($1,400 that is available as a payout) until the year 2025 and has been temporarily increased for the year of 2021 to $3,600 for children under the age of 6 and $3,000 for kids ages 6 to 17. <br></br><br></br>The child tax credit is available through the yearly tax return filing to parents and guardians who have a dependent child under the age of 17 and meet the minimum income and maximum income thresholds.</p>
    },

    {   id: 5, 
        term: 'Compound Interest',
        slug: 'compound-interest',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/638f9d337dd8712deba87a34_compound_interest.png',
        image_alt: 'compound interest', 
        definition: <p>Interest that is earned on both initially contributed funds and on previously earned interest.<br></br><br></br>Over long periods of time, compound interest grows aggressively fast and can make initial contributions multiply in value.<br></br><br></br>As an example, $500 invested when a child is born and invested with 7% interest per year can multiply by 3.5 times to $1,756 after 18 years or by 87 times to $43,546 after 64 years without adding a single dollar to the investment thanks to the effects of compound interest.</p>
    },

    {   id: 6, 
        term: 'Custodial Account',
        slug: 'custodial-account',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/62fc06f69a79a6d58fdabf65_custodial_account.webp',
        image_alt: 'Custodial Account', 
        definition: <p>A financial account set up by an adult for the benefit of a child and managed by the adult until the child reaches adulthood and control is transferred over to the child.<br></br><br></br>The account is legally owned by the child beneficiary.<br></br><br></br>There are different types of custodial accounts, including custodial bank accounts, custodial investment accounts, and custodial retirement accounts.</p>
    },

    {   id: 7, 
        term: 'Generational Wealth',
        slug: 'generational-wealth',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/633b168d6d2c30dbeb6c9695_generational%20wealth.png',
        image_alt: 'Generational Wealth', 
        definition: <p>Generational Wealth includes all of the assets, money, resources, and knowledge, passed down from one family generation to the next.<br></br><br></br>These can include stocks, real estate properties, bonds, businesses, valuables, and any other physical or intangible items that have monetary value.<br></br><br></br>Generational Wealth is created and transferred to the next generation during life, directly as or indirectly through education and financial literacy, or after life by inheritance.</p>
    },

    {   id: 8, 
        term: 'HSA',
        slug: 'hsa',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/6338cb5461a7c346cc7978af_HSA.png',
        image_alt: 'HSA', 
        definition: <p>A financial account you can open and deposit money into to pay for medical expenses, while reducing your income taxes.<br></br><br></br>Contributions to Health Savings Accounts are tax deductible (they lower your income tax).<br></br><br></br>Withdrawals are tax-free if used for qualified healthcare expenses.<br></br><br></br>Families with young children and high family health care expenses can use HSA accounts as a way to lower their overall yearly spend.<br></br><br></br>In order to qualify to open an HSA, you need to have a High-Deductible Health Plan, a health insurance plan with a yearly deductible of at least $1,400 for individuals and $2,800 for families in 2022.</p>
    },

    {   id: 9, 
        term: 'Life Insurance',
        slug: 'life-insurance',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/6338d3883b99ec6c2c672ae7_life%20insurance.png',
        image_alt: 'Life Insurance', 
        definition: <p>An insurance policy that provides financial security for loved ones in the event of someone's death by paying out a large amount of money to the policy's surviving benefactor.<br></br><br></br>Can be bought to cover a limited period in life (also known as Term Life Insurance) or for a person's entire life (also known as Whole Life Insurance).<br></br><br></br>Life insurance is an especially useful tool for parents to secure their children's financial well-being in the unfortunate event of an untimely death.</p>
    },

    {   id: 10, 
        term: 'Monetary Gift',
        slug: 'monetary-gift',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/630cdc24c9f872544615997d_monetary_gift.webp',
        image_alt: 'Monetary gift', 
        definition: <p>A transfer of money or a financial instrument that has liquid value from one party to another, without receiving or expecting anything in return.<br></br><br></br>Giving gifts of monetary value shows you care about the gift receiver's future, especially when gifting money to children.</p>
    },

    {   id: 11, 
        term: 'Parental Leave',
        slug: 'parental-leave',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/636aaf2b2afef8ed497a6853_parental%20leave.png',
        image_alt: 'Parental Leave', 
        definition: <p>A period of time that parents take off from work to focus on caring for a newborn or newly adopted child.<br></br><br></br>Parental leave comes in two types, paid and unpaid parental leave.<br></br><br></br>Most Americans are eligible for unpaid, job-protected parental leave.<br></br><br></br>Some employers offer paid parental leave, continuing to pay you part or all of your salary while you care for your new child.</p>
    },

    {   id: 12, 
        term: 'Piggy Bank',
        slug: 'piggy-bank',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/6338c9d91badd9e337d32edc_Piggy%20Bank.png',
        image_alt: 'Piggy bank', 
        definition: <p>A child's money container, sometimes in the shape of a cartoonish pig.<br></br><br></br>Piggy banks are used by children to store their coins and bills.<br></br><br></br>Some variations of piggy banks have Save, Spend and Give compartments and labels on them to help kids learn how to budget their spending and saving.</p>
    },


    {   id: 13, 
        term: 'Roth IRA',
        slug: 'roth-ira',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/6338d3a6c2d6b0429090acfe_roth%20ira.png',
        image_alt: 'Roth IRA', 
        definition: <p>A financial investment account that helps save for retirement with tax benefits.<br></br><br></br>With a Roth IRA, you can invest your after-tax dollars today and avoid paying capital gains taxes on your investments when you retire.<br></br><br></br>Roth IRAs are best for children and young adults, people who have a low tax rate today and expect a higher income (and higher tax rate) later in life.<br></br><br></br>In order to have an IRA account, you (or your child) need to have some form of taxable income.</p>
    },


    {   id: 14, 
        term: 'Trust',
        slug: 'trust',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/6338d615509ebc6707011431_trust.png',
        image_alt: 'Trust', 
        definition: <p>An agreement in which you can give another person (a trustee) the right to hold and manage assets and funds for the benefit of another person or cause, throughout your life and after you die.<br></br><br></br>Trusts can help give greater control by specifying rules about spending funds, which can be useful to prevent a child from spending a lump sum inheritance.<br></br><br></br>Trusts may be set up as changeable (revocable) or unchangeable (irrevocable), and do not have the power to choose caregivers for children in the event of a death (unlike Wills).<br></br><br></br>Trusts provide more privacy than Wills do.</p>
    },


    {   id: 15, 
        term: 'UTMA Account',
        slug: 'utma-account',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/62ff239c769a7280d0c57a94_UTMA_Account.webp',
        image_alt: 'UTMA Account', 
        definition: <p>An investment account set up by an adult for the benefit of a child and managed by the adult until the child reaches adulthood.<br></br><br></br>The funds and assets in the account are legally owned by the child and may benefit from the child's lower tax rate.<br></br><br></br>Especially useful to invest for a child's future with no strings attached and to teach kids how investing works.</p>
    },


    {   id: 16, 
        term: 'Will',
        slug: 'will',
        image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/6338d594e851f03ba8de948f_will.png',
        image_alt: 'Will', 
        definition: <p>A legal document that dictates how a person's belongings should be distributed and who should take care of the person's children in the event that the person dies.<br></br><br></br>Wills are especially important for people who have dependents (like children or spouses that depend on them to survive) and remove the need for courts to make important decisions like custody of children or inheritance.<br></br><br></br>Also known as the Last Will and Testament.</p>
    }
]


;

export default terms;