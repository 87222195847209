import React from 'react';
import './Rule_of_72.css';

import GG_loader from '../../general_components/GG_loader/GG_loader';
import MasterButton from '../../general_components/MasterButton/MasterButton';
import gg_icon from '../../../images/logos/gg_logo_icon.png';


class Rule_of_72 extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,

            calc_type: '',

            other_calc: '',
            user_input: '',
            result: '',

            error: null,

            yearlyInvestments: []

        };

        this.handleChange                   = this.handleChange.bind(this);
        this.handleCalculation              = this.handleCalculation.bind(this);
        this.calculateYearlyInvestments_2   = this.calculateYearlyInvestments_2.bind(this);
        this.calculateYearlyInvestments_1   = this.calculateYearlyInvestments_1.bind(this);
        this.renderBreakdown   = this.renderBreakdown.bind(this);

        this.inputRef = React.createRef();

    }

    handleChange(event) {    
        const { name, value} = event.target;
        let result;
        if (value && !isNaN(value)) {
            result = (72 / parseFloat(value)).toFixed(2);
        } 

        if(value === ''){
            this.setState({ user_input: '', result: '', yearlyInvestments: []})
        }
        
        this.setState({[name]: value, result: result}, this.handleCalculation); 
    }


    calculateYearlyInvestments_1(rate) {
        let amount = 1000;
        const yearlyInvestments = [amount];
    
        while (amount < 2000) {
            amount += amount * rate;
            yearlyInvestments.push(amount);
        }
    
        return yearlyInvestments;
    }

    calculateYearlyInvestments_2(years) {
        let amount = 1000;
        const yearlyInvestments = [amount];
    
        // Calculate the compound interest rate based on the number of years
        const interestRate = Math.pow(2, 1 / parseFloat(years)) - 1;
    
        while (amount < 2000) {
            amount += amount * interestRate;
            yearlyInvestments.push(amount);
        }
    
        return yearlyInvestments;
    }


    handleCalculation() {
        console.log(this.state.calc_type);
        let { user_input, calc_type } = this.state;
        let result;

        if (user_input && !isNaN(user_input)) {
            if (calc_type === 'Interest rate') {
                result = (72 / parseFloat(user_input)).toFixed(3);
            } else if (calc_type === 'Years') {
                result = (72 / parseFloat(user_input)).toFixed(3); // This is the calculation for years
            }

            let yearlyInvestments;
            if (calc_type === 'Interest rate') {
                yearlyInvestments = this.calculateYearlyInvestments_1(parseFloat(user_input) / 100);
            } else {
                yearlyInvestments = this.calculateYearlyInvestments_2(user_input);
            }
            this.setState({ result: result, yearlyInvestments: yearlyInvestments });
        }
    }



    // render
    renderBreakdown(){
        if(this.state.yearlyInvestments.length > 0) {

            let yearlyInvestments = this.state.yearlyInvestments;
            let midpoint = Math.ceil(this.state.yearlyInvestments.length / 2);


            return (
                <div className="grid-wrapper">
                    <div>
                        {yearlyInvestments.slice(0, midpoint).map((amount, index) => (
                                <div 
                                    key={index} 
                                >
                                    <span>Year {index }: </span>
                                    <span>${amount.toFixed(2)}</span>
                                </div>
                            ))
                        }
                    </div>

                    <div>
                    {yearlyInvestments.slice(midpoint).map((amount, index, array) => (
                            <div 
                                key={index} 
                                className={index === array.length - 1 ? 'green_text bold' : ''}
                            >
                                <span>Year {index + midpoint }: </span>
                                <span>${amount.toFixed(2)}</span>
                            </div>
                        ))
                    }

                    </div>
                </div>
            )
         } else {
            return null
         }
    }

    render () {
        
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }
        
        return (
           <div className='rule_of_72_wrapper'>
                
                <h2>Rule of 72 Calculator</h2>

                <h3>1. Select calculation method</h3>

                <div className='click_button_wrapper'>
                 
                    {/* click_button_container_single */}
                    <div className='click_button_container'>
                        
                        
                            

                        <div onClick={() => {
                            this.setState({calc_type: 'Interest rate', other_calc: 'Years'}, this.handleCalculation);
                            this.inputRef.current.focus();
                            
                            
                        }}
                            className={this.state.calc_type === 'Interest rate' ? 'click_button_selected click_button' : 'click_button'}>
                            <i className={this.state.calc_type === 'Interest rate' ? 'material-icons-outlined green_icon' : 'material-icons-outlined'}>percent</i>
                            <p className='small_text'>Start with Interest Rate</p>
                        </div>

                        <div onClick={() => {
                            this.setState({calc_type: 'Years', other_calc: 'Interest rate'}, this.handleCalculation); 
                            this.inputRef.current.focus();
                            
                            
                        }}
                            className={this.state.calc_type === 'Years' ? 'click_button_selected click_button' : 'click_button'}>
                            <i className={this.state.calc_type === 'Years' ? 'material-icons-outlined green_icon' : 'material-icons-outlined'}>calendar_month</i>
                            <p className='small_text'>Start with Years</p>
                            
                        </div>
                
                    </div>

                </div>

                <h3>2. Calculation:</h3>
                    
                <div className='flex_box margin_bottom'>
                    <p className='large_text'>72 / &nbsp;</p>
                    
                    <input 
                    type='number' 
                    id='user_input' 
                    onChange={this.handleChange}
                    name='user_input' 
                    placeholder={this.state.calc_type ? (this.state.calc_type === 'Years' ? 'Enter years' : 'Enter %') : ''}
                    value={this.state.user_input}
                    autoComplete='off'
                    maxLength={ 5 }
                    className={'small_input large_text'}
                    disabled={!this.state.calc_type}
                    ref={this.inputRef}
                    min="0"
                    pattern="^\d*\.?\d*$"

                    />

                    {this.state.calc_type ? 
                    (this.state.calc_type === 'Years' ?
                        
                        <p className='large_text'>{`(years) = ${this.state.result ? this.state.result : ''}%` }</p>
                        :
                        <p className='large_text'>{`% = ${this.state.result ? this.state.result : ''} years` }</p>
                        )
                        : 
                        <p className='large_text'>{`% =  years` }</p>

                    }

                   

                </div>
                
                {   this.state.error && 
                        <p className="gg_red">
                            {this.state.error}
                        </p>
                }
                        
                {this.state.result ? 
                    (
                        <div className='greeting_box greeting_box_green top_margin'>

                        {this.state.calc_type === 'Years' ?
                        <p>To double your investment in {`${this.state.user_input}`} years, you will need to earn an annual interest rate of <span className='green_text bold'>{`${this.state.result}%`}</span></p>
                        :
                        <p>With {`${this.state.user_input}%`} annual interest, your investment will double in <span className='green_text bold'>{`${this.state.result}`} years</span></p>
                        }
                        
                        </div>
                    ) :
                        <p>Choose a calculation type and enter a number to see a final result and explanation!</p>
                }    


                {/*this.state.yearlyInvestments.length > 0 ?

                    (<div className='investment_growth margin_top'>
                        <h4>Investment Growth Breakdown Example:</h4>
                        <p>Below is the year-by-year growth of a $1,000 investment at the specified interest rate:</p>
                        <div className='investment_growth_list'>
                        {
                            this.state.yearlyInvestments.map((amount, index) => (
                                <div 
                                key={index} 
                                className={index === this.state.yearlyInvestments.length - 1 ? 'green_text bold' : ''}
                                >
                                    <span>Year {index}: </span>
                                    <span>${amount.toFixed(2)}</span>
                                </div>
                            ))
                            }
                            </div>
                    </div>)
                : null */}

                {this.renderBreakdown()}

                <MasterButton 
                    buttonText={'Restart'} 
                    handleButtonClick={() => {this.setState({calc_type: '', other_calc: '', user_input: '', result: '', yearlyInvestments: []})}}
                    notSticky={true}
                    links={false}
                    inactive_button={!this.state.calc_type}
                />

{this.state.yearlyInvestments.length === 0 ?
<div className='mini-buffer'></div> : null}

                <div className='gifting_footer'>
                    <a href='https://www.greatestgiftapp.com' target='_blank' rel="noreferrer">
                        <img src={gg_icon} alt='gg icon'/>
                    </a>
                    <p className='tiny_text'>Greatest Gift</p>
                </div>

           </div>
        );
    }
}

export default Rule_of_72;