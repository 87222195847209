import React from 'react';
import './Gifting_step_3.css';

import {Helmet} from "react-helmet";
import MasterButton from '../../../general_components/MasterButton/MasterButton.js';

import phones from '../../../../utils/phones.js'; 

class Gifting_step_3 extends React.Component {
    constructor(props) {
        super(props);
        this.handleChangeAndSelectEmail = this.handleChangeAndSelectEmail.bind(this);
        this.handleChangeAndSelectPhone = this.handleChangeAndSelectPhone.bind(this);
        this.handlePaste                = this.handlePaste.bind(this);
    }

    handleChangeAndSelectPhone(event, id) {
        document.getElementById(id).checked = true;
        this.props.handlePhoneChange(event);
        this.props.updateChannel(id);
    }

    handleChangeAndSelectEmail(event, id) {
        document.getElementById(id).checked = true;
        this.props.handleChange(event);
        this.props.updateChannel(id);
    }

    // Handle paste event
    handlePaste(e, id){
        
        e.preventDefault(); // Prevent the default paste behavior
        
        const pastedData = e.clipboardData.getData('Text');
        const formattedNumber = phones.from_copy_to_gg(pastedData);

        // activate the phone as selected channel
        document.getElementById(id).checked = true;
        this.props.updateChannel(id);
        this.props.handlePastePhone(e.target.name, formattedNumber);
    };


    render () {
        if (this.props.currentStep !== 3) {
            return null;
        }
        
        if(this.props.receiver_number && this.props.receiver){
            return (
                    <div>
                    <Helmet>
                        <title>{`Give a Monetary Gift | Receiver Details | Greatest Gift`}</title>
                    </Helmet>
                    <h2>Receiver Details</h2>

                    <form> 
                        
                        
                        
                        
                        <p>The gift will be sent to {this.props.receiver} by text message to the number ending in:</p>
                        <p className='small_text'>(XXX) XXX-{this.props.receiver_number}</p>
                        
                        {/* add a sender email input when we are in the guest gifting flow */}
                        {this.props.guest ? (
                            <div>
                                <p className='form_label'>Sender Email:</p>
                                <input 
                                    type='text' 
                                    id='sender_email' 
                                    onChange={this.props.handleChange}
                                    name='sender_email' 
                                    placeholder='YourEmail@email.com' 
                                    value={this.props.sender_email}
                                    autoComplete='on'
                                    /> 
                                <p className='form_label'>Your email is required in order to send you a receipt</p>
                            </div>
                        ) : null}


                    </form>
                    
                    <MasterButton 
                        buttonText={this.props.buttonText} 
                        handleButtonClick={this.props.handleButtonClick}
                        notSticky={true}
                    />
                </div>
            )
        }
        
        return (
           <div>
               
                <h2>Receiver Details</h2>

                <form> 
                    <p className='form_label'>Send to:</p>
                    <input 
                        type='text' 
                        id='send_to_guardian' 
                        name='Send_to' 
                        placeholder='Alice and Bob' 
                        onChange={this.props.handleChange}
                        autoFocus
                        value={this.props.Send_to}
                         />
                    
                    {/*  text message input */  }
                    <input 
                        type='radio' 
                        id='channel_text' 
                        name='channel' 
                        value='text_message' 
                        onChange={this.props.handleChange}
                        checked={this.props.channel === 'text_message'}/> 
                    <p className='form_radio_label'>Text message:</p>

                    <input 
                        type='text' 
                        id='text_message_number_input' 
                        name='send_to_number' 
                        placeholder='555-555-5555' 
                        maxLength='12'
                        onChange={(event) => {this.handleChangeAndSelectPhone(event, 'channel_text')}} 

                        onPaste={(event) => {this.handlePaste(event, 'channel_text')}} 

                        value={this.props.send_to_number}
                        />

                    {/*  email input */  }
                    <input 
                        type='radio' 
                        id='channel_email' 
                        name='channel' 
                        value='email' 
                        onChange={this.props.handleChange}
                        checked={this.props.channel === 'email'}/> 
                    <p className='form_radio_label'>Email:</p>

                    <input 
                        type='text' 
                        id='email_input' 
                        name='send_to_email' 
                        placeholder='Example@email.com' 
                        onChange={(event) => {this.handleChangeAndSelectEmail(event, 'channel_email')}} 
                        value={this.props.send_to_email}
                        />

                    {/* add a sender email input when we are in the guest gifting flow */}
                    {this.props.guest ? (
                        <div>
                            <p className='form_label'>Sender Email:</p>
                            <input 
                                type='text' 
                                id='sender_email' 
                                onChange={this.props.handleChange}
                                name='sender_email' 
                                placeholder='YourEmail@email.com' 
                                value={this.props.sender_email}
                                autoComplete='on'
                                /> 
                            <p className='form_label'>Your email is required in order to send you a receipt</p>
                        </div>
                    ) : null}


                </form>
                
                <MasterButton 
                    buttonText={this.props.buttonText} 
                    handleButtonClick={this.props.handleButtonClick}
                    notSticky={true}
                />
           </div>
        );
    }
}

export default Gifting_step_3;
