import React from 'react';
import './Payment_methods.css';

import { PlaidLink } from 'react-plaid-link';
import {CardElement} from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';

import MasterButton from '../general_components/MasterButton/MasterButton.js';
import stripe_CC_badge from '../../images/stripe_imgs/stripe_cards_badge.png'
import GG_loader from '../general_components/GG_loader/GG_loader';
import Overlay_popup from '../general_components/Overlay_popup/Overlay_popup';
import GGApi from '../../utils/GGApi';

import stripe_logo from '../../images/stripe_imgs/stripe_black.png';
import plaid_logo from '../../images/stripe_imgs/plaid.svg';

import gg_fees from '../../utils/gg_pricing.js';

import config from '../../utils/config';

import amplitude from 'amplitude-js';
import Plaid_oauth from '../Financial_account_components/Link_destination/callback_oauth/Plaid_oauth';

amplitude.getInstance().init(config.amp_key);

let ach_send_fee    =  `${String((gg_fees.send_fees.ach_variable*100).toFixed(1))}% ${gg_fees.send_fees.ach_fixed === 0 ? '': '+'+gg_fees.send_fees.ach_fixed+'¢' }`;
let cc_send_fee     =  `${String((gg_fees.send_fees.cc_variable*100).toFixed(1))}% ${gg_fees.send_fees.cc_fixed === 0 ? '': '+'+gg_fees.send_fees.cc_fixed+'¢' }`;   


class Payment_methods extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,

            method_type: '',
            verification: '',

            account_number: '',
            routing_number: '',
            new_manual_bank: {},

            overlay_open: false,

            save_method: true,

            plaid_link_token: '',
            plaid_session: ''
        };

        this.toggle                 = this.toggle.bind(this);
        this.handleChange           = this.handleChange.bind(this);

        this.renderCC               = this.renderCC.bind(this);
        this.handleCCSubmit         = this.handleCCSubmit.bind(this);

        this.renderBank             = this.renderBank.bind(this);
        this.renderManual           = this.renderManual.bind(this);
        this.handleOverlayButton    = this.handleOverlayButton.bind(this);
        this.handleBankButton       = this.handleBankButton.bind(this);
        this.handleInstantClick     = this.handleInstantClick.bind(this);
        
        this.renderPlaidLink        = this.renderPlaidLink.bind(this);
        this.handlePlaidSuccess     = this.handlePlaidSuccess.bind(this);
        this.handlePlaidLoad        = this.handlePlaidLoad.bind(this);
        this.handlePlaidExit        = this.handlePlaidExit.bind(this);

        this.renderDisclaimer       = this.renderDisclaimer.bind(this);
        this.renderStripe           = this.renderStripe.bind(this);


    }
    
    componentDidMount(){
        if (this.props.open_payments) {
        // amplitude
        amplitude.getInstance().logEvent('add pm: start add pm');
        }

        // check if there is a plaid link in the props. If so, it is a continuation from oauth flow
        if(this.props.plaid_link_token && this.props.plaid_session) {
            this.setState({
                plaid_link_token: this.props.plaid_link_token, 
                plaid_session: this.props.plaid_session})
        }
    }

    toggle(){
        this.setState({save_method: !this.state.save_method})
    }

    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }

    // credit card methods

    renderCC(){
        if (this.state.method_type === 'CC') {
            const {stripe} = this.props;

            
            return (
                <form onSubmit={this.handleCCSubmit}>

                    <div>
                        <CardElement options={{
                            style: {
                                complete: {
                                    color: '#16dfad',
                                },
                            },
                        }}/>
                    </div>

                    <button type="submit" 
                            disabled={!stripe} 
                            style={{'width': '100%'}} 
                            className='stripe_button'>
                        Add Card
                    </button>

                </form>
            )
        } else {
            return null;
        }
    }

    handleCCSubmit(event){
        // Block native form submission.
        event.preventDefault();
    
        const {stripe, elements} = this.props;
    
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
        // Get a reference to a mounted CardElement. Elements knows how to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
        

        // create a payment method through stripe.js, and get back a pm item with payment info.
        const user = JSON.parse(localStorage.getItem('user'));
        // if registered user - add billing name. 
        // otherwise, it's a guest checkout
        if(user){
            stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: (user.first_name + ' ' + user.last_name),
                  }
    
            }).then(response => {
                if (response.error) {
                    console.log('error', response.error);
                    if(response.error.message) {alert(response.error.message)}
                  } else {
                    this.props.updateCard(response.paymentMethod, this.state.save_method, );
                  }
            }); 

        } else if(!user){
            stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
    
            }).then(response => {
                if (response.error) {
                    console.log('error', response.error);
                  } else {
                    this.props.updateCard(response.paymentMethod, this.state.save_method, );
                  }
            }); 
        }     
      };


    // bank methods

    renderBank(){
        if (this.state.method_type === 'ACH') {
            return (
                <div>

                    <h2>Bank verification</h2>

                    <div className='click_button_wrapper'>
                        
                        <div className='click_button_rec_container'>
                            <p className='tiny_text green_text'>Recommended</p>
                            
                        </div>
                        
                        <div className='click_button_container'>
                        
                            <div onClick={this.handleInstantClick}
                                className={this.state.verification === 'Instant' ? 'click_button_selected click_button' : 'click_button'} >
                                <p className='bold'>Instant Verification</p>
                                <p className='small_text'>Sign in to your bank to instantly verify your bank account</p>
                                <div className='powered_by_plaid'>
                                    <p className='tiny_text'>Powered by </p><img src={plaid_logo}/>
                                </div>
                            </div>
                        {
                            <div onClick={() => this.setState({verification: 'Manual'})}
                                className={this.state.verification === 'Manual' ? 'click_button_selected click_button' : 'click_button'}>
                                <p className='bold'>Manual Verification</p>
                                <p className='small_text'>Use your bank’s routing and account number. Verification can take up to 3 business days.</p>
                            </div>
                        }
                        </div>
                    </div>

                    {this.renderManual()}
                    {this.renderPlaidLink()}
                </div>
            )
        } else {
            return null;
        }
    }

    renderManual(){
        if(this.state.verification !== 'Manual') {
            return null;
        } else {
            return (
                <div>
                    <form>
                        <p className='form_label'>Routing Number</p> 
                        <input 
                            type={this.state.show_details? 'text' : 'password'} 
                            name='routing_number' 
                            id='routing_number' 
                            onChange={this.handleChange}
                            placeholder='123-456-789' 
                            value={this.state.routing_number}
                            autoComplete='off'
                            maxLength='9'
                            />

                        <p className='form_label'>Account Number</p> 
                        <input 
                            type={this.state.show_details? 'text' : 'password'}
                            name='account_number' 
                            id='account_number' 
                            onChange={this.handleChange}
                            placeholder='123-456-789' 
                            value={this.state.account_number}
                            autoComplete='off'
                            maxLength='30'
                            />
                    </form>
                    <div className='disclaimer_checkbox_container'>
                        {this.state.show_details ?
                            <i onClick={() => this.setState({show_details: !this.state.show_details})} className='material-icons-outlined disclaimer_checkbox'>check_box</i> :
                            <i onClick={() => this.setState({show_details: !this.state.show_details})} className='material-icons-outlined disclaimer_checkbox'>check_box_outline_blank</i>}
                        <p className='disclaimer_checkbox_text tiny_text'>Show routing and account numbers</p>
                    </div>

                    <MasterButton 
                            buttonText={'Add bank'} 
                            handleButtonClick={this.handleBankButton}
                            notSticky={true}
                        /> 
                    
                    <Overlay_popup 
                        open={this.state.overlay_open}
                        closer={false}
                        overlay_title='Next Steps'
                        body_1='To verify your account, our payments partner Stripe sends two small deposits to your account in the next 1-2 business days with a description that includes AMTS followed by the amount.'
                        body_2='In order to finalize the verification process, please go to your payments method settings and submit the amounts for approval.'
                        button_1={  {   text: 'Next',
                                        handler: this.handleOverlayButton                        
                                    }}
                    />

                </div>
                
            )
        } 
    }

    handleOverlayButton(){
        // reset the payments page
        this.setState({
            method_type: '',
            verification: '',

            account_number: '',
            routing_number: '',
            new_manual_bank: {},

            overlay_open: false,

            save_method: true
        })
        // move on to next step in the gift flow
        this.props.bankNext(this.state.new_manual_bank);
    }

    handleBankButton(){
        this.setState({loading: true});
        
        // verify the user token
        let user = JSON.parse(localStorage.getItem('user'));
        let validToken = true; 
        if (!user ) {
            this.setState({loading: false});
            return;
        } else if (user) {
            GGApi.checkToken().then(response => {
                validToken = response;
                if (validToken === false) {
                    this.setState({loading: false});
                    return;
                }
            })
        }

        if(this.state.verification === 'Manual'){
            //verify account number and routing number
            
            // validate the routing number was submitted (if needed)
            if(!this.state.routing_number || this.state.routing_number.length !== 9) {
                alert('Please submit a valid 9-digit routing number.')
                this.setState({loading: false});
                return;
            } 
            
            // validate the account number submission
            if(!this.state.account_number) {
                alert('Please submit your account number.')
                this.setState({loading: false});
                return;
            };
                    
            // check if stripe loaded already 
            const {stripe, elements} = this.props;
            if (!stripe || !elements) {
                this.setState({loading: false});
                return;
            }
            ////////////////////////////////////
            console.log('cleared validations');

            // set up the submission for the token:
                // create a bank token through stripe.js, and get back a token to be sent to the back-end.
                // get billing name from session token. 
            const user = JSON.parse(localStorage.getItem('user'));
            let user_name = user.first_name + ' ' + user.last_name;
            let {routing_number, account_number} = this.state;            

            ////
            stripe.createToken('bank_account', {
                country: 'US', 
                currency: 'usd',
                routing_number: routing_number,
                account_number: account_number,
                account_holder_name: user_name,
                account_holder_type: 'individual',
            
            // Then, call the GGApi with the received token to add bank info to gg db. 
            }).then(result => {
                if(result.error){
                    alert('Encountered an error. Please try again.')
                    // reset 
                    this.setState({
                        loading: false,
                        account_number: '',
                        routing_number: ''
                    })
                }
                if(result.token) {
                    //GGApi.create_bank(result.token.id, this.props.recipient, this.state.account_type, this.state.institution_name);
                    GGApi.bank_create(result.token.id, null, false).then((response) => {
                        if(response.success){
                            this.setState({ 
                                overlay_open: true,
                                loading: false,
                                account_number: '',
                                routing_number: '',
                                new_manual_bank: response.new_pm
                                })
                        } else {
                            this.setState({
                                loading: false,
                                account_number: '',
                                routing_number: ''
                                })
                            if(response.message === 'A bank account with this information already exists for your use. Please add a different bank account.') {
                                alert(response.message);
                            } else {
                                alert('The server encountered an error. Please try again, or try to add a different payment method.')
                            }
                        }
                    })
                } else {
                    // reset 
                    this.setState({
                        loading: false,
                        account_number: '',
                        routing_number: ''
                    })
                };
            });
        } 
    }

    // Instant verification methods (Plaid)

    handleInstantClick(){
        this.setState({verification: 'Instant'});
        if(this.state.plaid_link_token){
            return;
        } else {
            this.setState({loading: true})
            
            GGApi.get_plaid_link('checking_accounts').then(response => {
                if(response.success){
                    this.setState({plaid_link_token: response.link_token, loading: false});
                    
                // save info for OAuth:
                    
                    // save gift info
                    this.props.save_gift_for_oauth();
                    // remember plaid link token
                    localStorage.setItem("plaid_link_token", JSON.stringify(response.link_token));
                    // remember the gifting flow url
                    localStorage.setItem("gifting_url", JSON.stringify(window.location.href));
                    // remember the gifting flow url
                    localStorage.setItem("oauth_flow", JSON.stringify("pm"));

                    
                } else {
                    this.setState({loading: false});
                };
            });
        }
    }

    renderPlaidLink(){
        if(!this.state.plaid_link_token || this.state.verification !== 'Instant'){
            return null;
        } else {
            return (
                <div className='button_container' 
                    style={{ position: 'static', margin: "24px 0px" }}>
                <PlaidLink
                    token={this.state.plaid_link_token}
                    onLoad={this.handlePlaidLoad}
                    onSuccess={this.handlePlaidSuccess}
                    onExit={this.handlePlaidExit}
                    style={{    width: '100%', 
                                padding: '0.875rem 1rem', 
                                borderRadius: '5px',
                                border: 'none',
                                background: '#FFD700'}}
                    >
                    Connect a bank account with Plaid
                </PlaidLink>
                </div>
            )
        }
        
    }
    handlePlaidLoad(){
        // The Link module finished loading.
    }
    handlePlaidSuccess(public_token, metadata){
        // loading screen until apis finish their work
        this.setState({loading: true});
        
        GGApi.bank_create(null, null, true, public_token, metadata.accounts[0].id).then(response => {
            if(response.success) {
                console.log('success in exchanging tokens and creating new payment method.')
                console.log(response);
                // reset this component
                this.setState({
                    loading: false,
                    method_type: '',
                    verification: '',

                    account_number: '',
                    routing_number: '',
                    new_manual_bank: {},

                    overlay_open: false,

                    save_method: true
                });
                // push the new bank account token up to the gift flow as selected payment method!
                this.props.bankNext(response.new_pm);

                // cleanup oauth
                localStorage.removeItem("plaid_link_token");
                localStorage.removeItem("gift");
                localStorage.removeItem("gifting_url");
                localStorage.removeItem("oauth_flow");
                this.props.cleanupOauth();

            } else {
                // there was an issue, alert the user, ask to try again
                if(response.message = 'A bank account with this information already exists for your use. Please add a different bank account.'){
                    alert(response.message);
                } else {
                    alert('The server has encountered a problem. Please try again, or select a different payment method.')
                }
                this.setState({loading: false});
                console.log(response);
            }
        })
    }
    handlePlaidExit(err, metadata){
        // The user exited the Link flow.

        // cleanup oauth
        localStorage.removeItem("plaid_link_token");
        localStorage.removeItem("gift");
        localStorage.removeItem("gifting_url");
        localStorage.removeItem("oauth_flow");
        this.props.cleanupOauth();

        this.setState({
            plaid_link_token: null,
            plaid_session: null,
            verification: ''
        })  

        if (err !== null) {
            console.log(err);
            // The user encountered a Plaid API error
            // prior to exiting.
        } else {
            console.log(metadata)
            this.setState({loading: false});
        }
        // metadata contains information about the institution
        // that the user selected and the most recent
        // API request IDs.
        // Storing this information can be helpful for support.
    }

    // general methods

    renderDisclaimer(){
        if (this.state.method_type === 'CC' ){
            return (
            <div className='disclaimer_checkbox_container'>
                {this.state.save_method ?
                <i onClick={this.toggle} className='material-icons-outlined disclaimer_checkbox'>check_box</i> :
                <i onClick={this.toggle} className='material-icons-outlined disclaimer_checkbox'>check_box_outline_blank</i>}
                <p className='disclaimer_checkbox_text tiny_text'>Save payment method for future use</p>
            </div> 
            )
        } else if (this.state.method_type === 'ACH' && this.state.verification){
            return (
                <div className='disclaimer_checkbox_container'>
                <i className='material-icons-outlined disclaimer_checkbox'>info</i>
                <p className='disclaimer_checkbox_text tiny_text'>Bank accounts are automatically saved for future use</p>
            </div> 
            )
        } else {
            return null;
        }
    }
    
    renderStripe(){
        //if(this.state.method_type === 'CC') {
            return (
                <div>
                    <div className='stripe_cc_badge_wrapper'>
                        <img className='stripe_badge' src={stripe_CC_badge} />
                    </div>
                    <p className='tiny_text'>Your bank account or credit card information will be used by <a target="_blank" href='https://stripe.com/customers' className='green_text '>Stripe’s secure platform</a> (a payment platform used by Uber, Spotify, Postmates, and many more) to process payments, and Greatest Gift will never keep any of your bank account or credit card information on our servers. 
                    <br></br>Learn more about our security practices <a target="_blank" href='https://www.greatestgiftapp.com/#security' className='green_text '>here.</a></p>
                </div>
            )
        //} else {
        //    return null;
        //}
    }

    // render

    render () {
        if (!this.props.open_payments) {
            return null;
        }
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        if (this.state.plaid_link_token && this.state.plaid_session) {
            return (
                <div>
                        
                        <div className='button_container' style={{ position: 'static', margin: "24px 0px" }}>

                        <Plaid_oauth 
                            token               = {this.state.plaid_link_token}
                            onSuccess           = {this.handlePlaidSuccess}
                            onLoad              = {this.handlePlaidLoad}
                            onExit              = {this.handlePlaidExit}
                            receivedRedirectUri = {this.state.plaid_session}
                            
                        />

                    </div>
                    
                </div>                
            )

        } 
        
        return (
           <div>
                <h2>Choose payment method type</h2>

                <div className='click_button_wrapper'>
                 {this.props.guest ? 
                    <div className='click_button_rec_container'>
                        <p className='tiny_text green_text'>
                        <Link to={'/new_account?source=give-a-gift' + '&' + window.location.search.slice(1)} className='green_text' onClick={() => {this.props.remember_gift(); amplitude.getInstance().logEvent(`signup: click sign up`, {"page": 'pm'});}}>Register to unlock</Link>
                        </p>
                    </div>
                 :
                    <div className='click_button_rec_container'>
                        <p className='tiny_text green_text'>Recommended</p>
                    </div>
                }

                    {/* click_button_container_single */}
                    <div className={this.props.guest ? 'click_button_container ' : 'click_button_container'}>
                    
                    {this.props.guest ?
                        <div 
                            className={'click_button unavailable_click_button'}>
                            <i className={'material-icons-outlined'}>account_balance</i>
                            <p className='bold click_button_header'>Bank</p>
                            <p className='small_text'>Send a gift with a low {ach_send_fee} fee by using a direct bank ACH payment</p>
                        </div>
                    :
                        <div onClick={() => {this.setState({method_type: 'ACH'}); amplitude.getInstance().logEvent(`add pm: select method type`, {"method type": 'bank'});}}
                            className={this.state.method_type === 'ACH' ? 'click_button_selected click_button' : 'click_button'}>
                            <i className={this.state.method_type === 'ACH' ? 'material-icons-outlined green_icon' : 'material-icons-outlined'}>account_balance</i>
                            <p className='bold click_button_header'>Bank</p>
                            <p className='small_text'>Send a gift with a low {ach_send_fee} fee by using a direct bank ACH payment</p>
                        </div>
                    }
                        

                        <div onClick={() => {this.setState({method_type: 'CC'}); amplitude.getInstance().logEvent(`add pm: select method type`, {"method type": 'credit card'});}}
                            className={this.state.method_type === 'CC' ? 'click_button_selected click_button' : 'click_button'}>
                            <i className={this.state.method_type === 'CC' ? 'material-icons-outlined green_icon' : 'material-icons-outlined'}>credit_card</i>
                            <p className='bold click_button_header'>Credit Card</p>
                            <p className='small_text'>We charge a standard {cc_send_fee} convenience fee for credit cards. However, banks have minimal fees!</p>
                        </div>
                
                    </div>
               
                </div>
                
                {this.renderCC()}
                {this.props.guest ? null : this.renderBank()}
            

                {this.props.guest ? 
                    <div>
                        <p>Payment with banks are only available to registered Greatest Gift users. <br></br>
                        <Link to={'/new_account?source=give-a-gift' + '&' + window.location.search.slice(1)} className='green_text bold' onClick={() => {this.props.remember_gift(); amplitude.getInstance().logEvent(`signup: click sign up`, {"page": 'pm'});}}>Create an account </Link>
                        to access minimal {ach_send_fee} fees.</p>
                        <p>Or, if you already have an account, you can 
                        <Link to={'/?source=give-a-gift' + '&' + window.location.search.slice(1)} 
                            className='green_text bold' 
                            onClick={() => {
                                this.props.remember_gift(); 
                                this.props.remember_gift_link()
                                amplitude.getInstance().logEvent(`signup: click log in`, {"page": 'pm'});
                            }}
                            > Log In here.</Link></p>
                    </div>
                 : this.renderDisclaimer()}
                
                {this.renderStripe()}

                {/*<div>
                    <div className='powered_by_stripe_container'>
                        <i className='material-icons-outlined'>lock</i>
                        <p className='disclaimer_checkbox_text tiny_text'>Guaranteed <span className='bold'>{"safe & secure"}</span> transactions</p>
                        <div className='stripe_and_plaid_container'>
                            <a target='_blank' href='https://www.stripe.com'><img src={stripe_logo}/></a>
                            <a target='_blank' href='https://plaid.com/how-it-works-for-consumers/'><img src={plaid_logo}/></a>
                        </div>
                    </div>
                    <p className='tiny_text'>Your bank account or credit card information will be used by <a target="_blank" href='https://stripe.com/customers' className='green_text '>Stripe’s secure platform</a> (a payment platform used by Uber, Spotify, Postmates, and many more) to process payments, and Greatest Gift will never keep any of your bank account or credit card information on our servers. 
                    <br></br>Learn more about our security practices <a target="_blank" href='/#faq' className='green_text '>here.</a></p>
                </div>*/}
                
           </div>
        );
    }
}

export default Payment_methods;