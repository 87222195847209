import React from 'react';
import './Main_link_destination.css';

import { PlaidLink } from 'react-plaid-link';
import { Link } from 'react-router-dom';

import Tracker from '../../../layout_components/Tracker/Tracker.js';
import Link_destination_1 from '../Link_destination_1/Link_destination_1.js';
import Link_destination_2 from '../Link_destination_2/Link_destination_2.js';
import MasterButton from '../../../general_components/MasterButton/MasterButton.js';
import Overlay_popup from '../../../general_components/Overlay_popup/Overlay_popup';

import GGApi from '../../../../utils/GGApi.js';

import dates from '../../../../utils/dates.js';
import Header_for_multistep from '../../../layout_components/Header_for_multistep/Header_for_multistep';
import validations from '../../../../utils/validations';
import GG_loader from '../../../general_components/GG_loader/GG_loader';

import stripe_logo from '../../../../images/stripe_imgs/stripe_black.png';

import Plaid_oauth from '../callback_oauth/Plaid_oauth';

import config from '../../../../utils/config';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);

class Main_link_destination extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //form details
            loading: true,

            firstLink: false, 
            currentStep: 1,
            overlay_open: false,
            buttonLinks: false,
            buttonActive: false,

            //submission details
            account_type: '',
            institution_name: '',
            account_number: '',
            routing_number: '',
            
            dob: '',
            digits: '',
            //supporting states for masking purposes
            dob_support:'',

            //plaid
            fund_id: '',
            plaid_link_token: '',

            plaid_link_token_destination: '',
            plaid_destination_button: '',
            bank_destination_token_from_plaid: '',

            public_token: '',
            metadata: {},
            create_bank_from_plaid: false
        }

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDDSelection = this.handleDDSelection.bind(this);

        this.createBank = this.createBank.bind(this);

        this.openOverlay = this.openOverlay.bind(this);
        this.renderOverlayPlaid = this.renderOverlayPlaid.bind(this);
        this.handleOverlayButton = this.handleOverlayButton.bind(this);

        this.renderPlaidLink        = this.renderPlaidLink.bind(this);
        this.handlePlaidSuccess     = this.handlePlaidSuccess.bind(this);
        this.handlePlaidLoad        = this.handlePlaidLoad.bind(this);
        this.handlePlaidExit        = this.handlePlaidExit.bind(this);

        this.renderPlaidLinkButton  =this.renderPlaidLinkButton.bind(this);
        this.handlePlaidSuccessForDestination = this.handlePlaidSuccessForDestination.bind(this);
    }


    // component loading 
    componentDidMount() {
        if(!this.props.recipient) {
            this.props.history.goBack();
        }

        // check if there is a plaid link in the props. If so, it is a continuation from oauth flow
        if(this.props.plaid_link_token && this.props.plaid_session) {
            //console.log('Continue from existing oauth...')
            let account_type = JSON.parse(localStorage.getItem('account_type'));
            this.setState({
                account_type: account_type,
                plaid_link_token: this.props.plaid_link_token, 
                plaid_session: this.props.plaid_session})
        }

        //check if this is the first linked account. If so, set the state to first = true
        GGApi.check_first_link().then(first_link => {
            this.setState({firstLink: first_link, loading: false})
            // amplitude
            amplitude.getInstance().logEvent('add fund: start add fund', {'first fund': first_link});
        });
    }

    // button clicks
    handleButtonClick() {
        let step = this.state.currentStep;     

        // make sure that the button is active and there is a recipient
        if(this.state.buttonActive && this.props.recipient) {

            if (step === 1) {

                // validate the account number submission
                if(!this.state.account_number) {
                    alert('Please submit your account number.')
                } else {

                    // validate the routing number was submitted (if needed)
                    if(!this.state.routing_number && 
                        (this.state.account_type.title === 'Savings Account' 
                        || this.state.account_type.title === 'Checking Account'
                        || this.state.institution_name.routing === 'unknown')) {
                        alert('Please submit a routing number.')
                    } else {
                        // validations complete, continue with either bank creation or move to next step
                        step += 1;
                        // amplitude
                        amplitude.getInstance().logEvent(`add fund: submit fund`, {'first fund': this.state.firstLink})
                        if (this.state.firstLink) { 
                            this.setState({currentStep: step}); 

                        } else { // if not the first linked account, create the bank here
                            
                            // create the bank
                            this.createBank(); 
                            
                            // open overlay:
                            //this.openOverlay();
                        }
                            
                    }
                }
                
            }  else if (step === 2) {

                // validate the date of birth
                    // check for a full number of characters
                    if (!(this.state.dob.length === 10)) {
                        alert('Please input a valid date of birth in the following format: \nMM/DD/YYYY');
                    //check that the input is a valid date between the years 1900-2200
                    } else if (!dates.validateDate(this.state.dob)) {
                        alert('Please input a valid date of birth in the following format: \nMM/DD/YYYY');
                    } else {

                //validate the digits input
                            if(!(this.state.digits.length === 4)) { //check for length
                                alert('Please input the last 4 digits of your social security number')
                            } else if (!validations.validateFourDigits(this.state.digits)){ //check for pattern - 4 digits
                                alert('Please input the last 4 digits of your social security number')
                            } else {
                                // validations complete. Create the bank:

                                this.createBank();
                                // amplitude
                                amplitude.getInstance().logEvent(`add fund: submit verification`)
                                // open the overlay 
                                //this.openOverlay();
                            }
                    }
            }
        }
    }

    createBank(){
        const urlParams = new URLSearchParams(window.location.search);
        if(!this.state.create_bank_from_plaid){
            this.setState({loading: true})
            console.log(21);
            const user = JSON.parse(localStorage.getItem('user'));   
            let user_name = user.first_name + ' ' + user.last_name;
            
            // Change account number if needed, based on 529 or Investment direct deposit instructions
            let account_number = this.state.account_number;
            if(this.state.institution_name.account_prefix){
                account_number = this.state.institution_name.account_prefix + account_number;
            }
            if(this.state.institution_name.account_ending){
                account_number = account_number + this.state.institution_name.account_ending;
            }
            // create token with stripe
            this.props.stripe.createToken('bank_account', {
                country: 'US', 
                currency: 'usd',
                routing_number: this.state.routing_number,
                account_number: account_number,
                account_holder_name: user_name,
                account_holder_type: 'individual',
            
            // Then, call the GGApi with the received token to add bank info to gg db. 
            }).then(result => {
                
                if(result.error){
                    alert('There was an issue when trying to add this account. Please try a different account, or contact our support team for more information.')
                    this.setState({loading: false})
                    console.log(2);
                    console.log(result.error)
                
                } else if(result.token) {

                    GGApi.create_bank(result.token.id, this.props.recipient, this.state.account_type, this.state.institution_name.title, this.state.institution_name.image)
                    .then(response => { 
                        if(response.success) {
                            
                            this.setState({fund_id: response.fund_id})
                            
                            if(this.state.currentStep === 1){
                                alert('Successfully added a new destination.')
                                
                                if(urlParams.get('source')){
                                    window.location.href = 'receive/'+urlParams.get('source');
                                } else {
                                    window.location.href = 'plan'
                                }

                            } else if (this.state.currentStep === 2){
                                // update the stripe connect account with the additional identification information
                                GGApi.update_stripe_account(this.state.digits, this.state.dob).then(result => {
                                    alert('Successfully added a new destination.')
                                    
                                    if(urlParams.get('source')){
                                        window.location.href = 'receive/'+urlParams.get('source');
                                    } else if (urlParams.get('plan')){
                                        window.location.href = 'plan/'+urlParams.get('plan');
                                    } else {
                                        window.location.href = 'plan'
                                    }

                                });

                            }

                        } else {
                            alert('There was an issue when trying to add this account. Please try again, or try a different account.')
                            this.setState({loading: false})
                            console.log(3);
                        }
                    });
                };
            });
        
        } else { // create bank from plaid link
            
            this.setState({loading: true});
            console.log(22);
            let logo = 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_bank_generic_bank.png';
            
            GGApi.create_bank(null, this.props.recipient, this.state.account_type, this.state.metadata.institution.name, logo, this.state.public_token, this.state.metadata.accounts[0].id, true)
            .then(response => { 
                
                if(response.success) {
                                
                    this.setState({fund_id: response.fund_id})
                            
                    if(this.state.currentStep === 1){
                        alert('Successfully added a new destination.')
                        
                        if(urlParams.get('source')){
                            window.location.href = 'receive/'+urlParams.get('source');
                        } else {
                            window.location.href = 'plan'
                        }
                    } else if (this.state.currentStep === 2){
                        // update the stripe connect account with the additional identification information
                        GGApi.update_stripe_account(this.state.digits, this.state.dob).then(result => {
                            alert('Successfully added a new destination.')
                            
                            if(urlParams.get('source')){
                                window.location.href = 'receive/'+urlParams.get('source');
                            } else if (urlParams.get('plan')){
                                window.location.href = 'plan/'+urlParams.get('plan');
                            } else {
                                window.location.href = 'plan'
                            }
                        });

                    }

                } else {
                    alert('There was an issue when trying to add this account. Please try again, or try a different account.')
                    this.setState({loading: false})
                    console.log(4);
                }
            });
        }
        
    }

    handleBackButton() {
        let step = this.state.currentStep;

        if (step === 1) {
            const urlParams = new URLSearchParams(window.location.search);
            if(urlParams.get('source')){
                window.location.href = 'receive/'+urlParams.get('source');
            } else if (urlParams.get('plan')){
                window.location.href = 'plan/'+urlParams.get('plan');
            } else {
                window.location.href = 'plan'
            }
            //this.props.history.goBack()

        } else if (step === 2) {
            step -= 1;
            this.setState(
                {   currentStep: step,
                    buttonLinks: false
                });  

        };
    }

    // input event handlers
    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value, buttonActive: true});
    }

    handleDateChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value, dob_support: value});

        if (event.target.value.length === 2 && this.state.dob_support.length === 1) {
            let newValue = value + "/";
            this.setState({[name]: newValue});
        } else if (event.target.value.length === 5 && this.state.dob_support.length === 4) {
            let newValue = value + "/";
            this.setState({[name]: newValue});
        };  
    }

    handleDDSelection(selection, dd_name) {
        let account_type = this.state.account_type;

        // reset if someone chooses a different account type after choice
        if(dd_name === 'account_type' && !(selection.title === account_type.title)) {
            this.setState({
                [dd_name]: selection, 
                institution_name: '',
                plaid_link_token_destination: ''
            });
        
        // 
        } else {
            selection.routing ? 
            this.setState({
                [dd_name]: selection, routing_number: selection.routing
            }) :
            this.setState({
                [dd_name]: selection
            });
            if(selection.routing === 'unknown'){
                this.setState({routing_number: ''});
            } 
        }
        // if selected checking or savings - render the plaid link, and get the link token
        if(selection.title === 'Checking Account' || selection.title === 'Savings Account'){
            this.setState({loading: true})
            console.log(23);
            let link_type = selection.title; // options: 'Savings Account', Checking Account, 529 Plan, Investment Account
            link_type = selection.title === 'Checking Account' ? 'checking_accounts' : 'savings_accounts';

            GGApi.get_plaid_link(link_type).then(response => {
                if(response.success){
                    this.setState({plaid_link_token_destination: response.link_token, loading: false, plaid_destination_button: true});
                    
                    // save information for oauth:
                    localStorage.setItem("plaid_link_token", JSON.stringify(response.link_token));
                    localStorage.setItem("child_account_name", JSON.stringify(this.props.recipient));
                    localStorage.setItem("account_type", JSON.stringify(this.state.account_type));
                    localStorage.setItem("oauth_flow", JSON.stringify("destination"));
                    const urlParams = new URLSearchParams(window.location.search);
                    if(urlParams.get('source')){
                        localStorage.setItem("last_seen_gift", JSON.stringify(urlParams.get('source')));
                    } 

                    console.log(5);
                } else {
                    alert('could not load the plaid link');
                    this.setState({loading: false});
                    console.log(6);
                };
            });
        }
        /*
        // if selected us savings bonds - set the institution
        if(selection.title === 'US Savings Bonds'){
            this.setState({
                institution_name: 
                {   id: 1, 
                    title: 'TreasuryDirect',
                    image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_savings_bonds_treasurydirect.svg',
                    routing: '051736158', // TreasuryDirect
                    account_format: 'xxxxxxxxxx', // 10 digit 
                    account_length: 10,
                    link: 'https://www.treasurydirect.gov/log-in/', 
                    account_input_instructions: 'Please enter your 10 digit TreasuryDirect account number, without hyphens:'
                }, 
                routing_number: '051736158'
            });
        }
        */
        // amplitude
        amplitude.getInstance().logEvent(`add fund: select ${dd_name}`, {'selection': selection.title})
        
    }

    // Plaid link for checking and savings
    renderPlaidLinkButton(){
        if(!this.state.plaid_link_token_destination || (this.state.account_type.title === '529 Plan' || this.state.account_type.title === 'Investment Account')){
            return null;
        } else {
            return (
                <div className='button_container' style={{ position: 'static', margin: "24px 0px" }}>
                    {/*<div className='plaid_button_clicker' onClick={() => this.setState({loading: true})}>*/}
                        <PlaidLink
                            token       =   {this.state.plaid_link_token_destination}
                            onLoad      =   {this.handlePlaidLoad}
                            onSuccess   =   {this.handlePlaidSuccessForDestination}
                            onExit      =   {this.handlePlaidExit}
                            style       =   {{  width: '100%', 
                                                padding: '0.875rem 1rem', 
                                                borderRadius: '5px',
                                                border: 'none',
                                                background: '#FFD700'}}
                            >
                            Connect To Bank
                        </PlaidLink>
                   {/* </div> */}
                </div>
            )
        }
    }

    handlePlaidSuccessForDestination(public_token, metadata){
        //console.log(public_token, metadata);
        // amplitude
        amplitude.getInstance().logEvent(`add fund: submit fund`, {'first fund': this.state.firstLink})

        // cleanup oauth
        localStorage.removeItem("plaid_link_token");
        localStorage.removeItem("child_account_name");
        localStorage.removeItem("last_seen_gift");
        localStorage.removeItem("account_type");
        localStorage.removeItem("oauth_flow");
        this.props.cleanupOauth();
        

        if(!this.state.firstLink){
            // create bank and move on 
            let logo = 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_bank_generic_bank.png';
            this.setState({loading: true});
            //console.log(24);
            GGApi.create_bank(null, this.props.recipient, this.state.account_type, metadata.institution.name, logo, public_token, metadata.accounts[0].id, true)
            .then(response => { 
                //console.log(response);
                if(response.success) {
                    this.setState({fund_id: response.fund_id})                         
                    console.log('finished adding bank account');
                    // open overlay:
                    //this.openOverlay();
                    
                    // go to next page
                    alert('Successfully added destination account!');
                    const urlParams = new URLSearchParams(window.location.search);
                    if(urlParams.get('source')){
                        window.location.href = 'receive/'+urlParams.get('source');
                    } else {
                        window.location.href = 'plan'
                    } 
                } 
            });

        } else {
            console.log('This is the first added destination. Save in state and move to next step.')
            // get info from plaid. Don't create bank yet! store info in state. Move to step 2.
            this.setState({
                public_token: public_token,
                metadata: metadata,
                create_bank_from_plaid: true,
                loading: false,
                
                // eliminate the plaid link token, go to next step
                plaid_link_token_destination: '',
                currentStep: 2
            })
            //console.log(7);
        }
        
        

        
        //GGApi.create_bank_from_plaid_token(public_token, metadata.accounts[0].id, this.props.recipient, this.state.account_type, metadata.institution.name).then(result => {
            // send the new token to 
            //GGApi.create_bank(result.token.id, this.props.recipient, this.state.account_type, this.state.institution_name)


    }
    
    // link Plaid goal tracker 

    openOverlay(){
        this.setState({loading: true})
        console.log(25);
        let link_type = this.state.account_type.title; // options: 'Savings Account', Checking Account, 529 Plan, Investment Account
        link_type = this.state.account_type.title === 'Checking Account' ? 'checking_accounts' : link_type;

        GGApi.get_plaid_link(link_type).then(response => {
            if(response.success){
                this.setState({plaid_link_token: response.link_token, loading: false, overlay_open: true});
                console.log(8);
            } else {
                alert('could not load the plaid link');
                this.setState({loading: false});
                const urlParams = new URLSearchParams(window.location.search);
                if(urlParams.get('source')){
                    window.location.href = 'receive/'+urlParams.get('source');
                } else {
                    window.location.href = 'plan'
                } 
            };
            console.log(9);
        });
    }

    renderOverlayPlaid(){
        return (
            <div>
                <Overlay_popup 
                    open={this.state.overlay_open}
                    closer={false}
                    overlay_title='Goal Tracking'
                    body_1={`Connect account balance and track savings progress for ${this.props.recipient}'s savings goal?`}
                    button_1={  {   text: 'Track Savings',
                                    handler: this.handleOverlayButton                        
                                }}
                    joker={ 
                    <div style={{"margin-bottom": "16px"}}>
                        {this.renderPlaidLink()}
                        <Link to='/receive' className='secondary_dark'>Skip</Link>
                    </div>}
                />
            </div>
        )
    }

    handleOverlayButton(){
        const urlParams = new URLSearchParams(window.location.search);
            if(urlParams.get('source')){
                window.location.href = 'receive/'+urlParams.get('source');
            } else {
                window.location.href = 'plan'
            }
    }


    renderPlaidLink(){
        if(!this.state.plaid_link_token){
            return null;
        } else {
            return (
                <div className='button_container' style={{ position: 'static', margin: "24px 0px" }}>
                    <PlaidLink
                        token={this.state.plaid_link_token}
                        onLoad      =   {this.handlePlaidLoad}
                        onSuccess   =   {this.handlePlaidSuccess}
                        onExit      =   {this.handlePlaidExit}
                        style={{    width: '100%', 
                                    padding: '0.875rem 1rem', 
                                    borderRadius: '5px',
                                    border: 'none',
                                    background: '#FFD700'}}
                        >
                        Track Savings
                    </PlaidLink>
                </div>
            )
        }
        
    }
    handlePlaidLoad(){
        // The Link module finished loading.
    }
    handlePlaidSuccess(public_token, metadata){
        // loading screen until apis finish their work
        this.setState({loading: true});
        console.log(26);
        console.log(public_token, metadata);
        // call GGApi, send the link data to gg db
        
        GGApi.track_fund_balance(public_token, this.state.fund_id).then(response => {
            // reset this component
            this.setState({
                loading: false,
                openOverlay: false,
                currentStep: 1,

                //submission details
                account_type: '',
                institution_name: '',
                account_number: '',
                routing_number: '',
                
                dob: '',
                digits: '',
                //supporting states for masking purposes
                dob_support:'',

                //plaid
                plaid_link_token: ''
            })
            console.log(10);

            // go to next pages.
            alert('Added the new investment account. going to next page')
            const urlParams = new URLSearchParams(window.location.search);
            if(urlParams.get('source')){
                window.location.href = 'receive/'+urlParams.get('source');
            } else {
                window.location.href = 'plan'
            }
        });
    }
    handlePlaidExit(err, metadata){
        // The user exited the Link flow.
        
        // cleanup oauth
        localStorage.removeItem("plaid_link_token");
        localStorage.removeItem("child_account_name");
        localStorage.removeItem("last_seen_gift");
        localStorage.removeItem("account_type");
        localStorage.removeItem("oauth_flow");
        this.props.cleanupOauth();

        if (err !== null) {
            console.log(err);  
            this.setState({
                plaid_link_token: null,
                plaid_session: null, 
                account_type: ''
            })  
            // The user encountered a Plaid API error
            // prior to exiting.
        } else {
            console.log(metadata)
            //this.setState({loading: false});
            this.setState({
                plaid_link_token: null,
                plaid_session: null,
                account_type: ''
            });
            console.log(11);
        }
        // metadata contains information about the institution
        // that the user selected and the most recent
        // API request IDs.
        // Storing this information can be helpful for support.
    }

    /////////

    render () {
        if(this.state.loading) {
            return (
                <GG_loader header_text='Link Account' />
            )
        }

        if (this.state.plaid_link_token && this.state.plaid_session) {
            return (
                <div>
                    <Header_for_multistep 
                        text='Link Account'
                        handleBackButton={() => this.handleBackButton()}
                    />
        
                    <main>
                        {this.state.firstLink ? 
                        <Tracker currentStep={this.state.currentStep} numOfSteps={2} tags={['Fund', 'Verification']}/>
                        : null}

                        <div className='button_container' style={{ position: 'static', margin: "24px 0px" }}>
                        <Plaid_oauth 
                            token               = {this.state.plaid_link_token}
                            onSuccess           = {this.handlePlaidSuccessForDestination}
                            onExit              = {this.handlePlaidExit}
                            receivedRedirectUri = {this.state.plaid_session}
                        />

                    </div>
                    
                    </main>
                
                </div>                
            )

        } else {
            
            return (
            <div>
                <Header_for_multistep 
                    text='Link Account'
                    handleBackButton={() => this.handleBackButton()}
                />
    
                <main>
                    {this.state.firstLink ? 
                    <Tracker currentStep={this.state.currentStep} numOfSteps={2} tags={['Fund', 'Verification']}/>
                    : null}
                    
    
                    <Link_destination_1 currentStep={this.state.currentStep} 
                                    handleChange={this.handleChange}
                                    recipient={this.props.recipient}
                                    handleDDSelection={this.handleDDSelection}
                                    account_type={this.state.account_type}
                                    institution_name={this.state.institution_name}
                                    account_number={this.state.account_number}
                                    routing_number={this.state.routing_number}
                                    disabled={this.state.institution_name ? false : true }
                                    />
                    {this.renderPlaidLinkButton()}
                    
    
                    <Link_destination_2 currentStep={this.state.currentStep} 
                                    handleChange={this.handleChange}
                                    handleDateChange={this.handleDateChange}
                                    dob={this.state.dob}
                                    digits={this.state.digits}
                                    />
    
                    {this.renderOverlayPlaid()}
        
                    {   this.state.account_type.title === '529 Plan' || 
                        this.state.account_type.title === 'Investment Account' ||
                        this.state.account_type.title === 'US Savings Bonds' ||
                        this.state.currentStep === 2
                        ? 
                <MasterButton 
                    buttonText={(this.state.firstLink && this.state.currentStep === 1) ? 'Next' : 'Add Account'} 
                    handleButtonClick={this.handleButtonClick}
                    links={false}
                    notSticky={true}
                    inactive_button={!this.state.buttonActive}
                />
                : null }
                
                { !this.state.account_type.title ?
                null :
                <div>
                    <div className='powered_by_stripe_container'>
                        <i className='material-icons-outlined'>lock</i>
                        <p className='disclaimer_checkbox_text tiny_text'>Guaranteed <span className='bold'>{"safe & secure"}</span> transfers</p>
                        <a target='_blank' href='https://www.stripe.com'><img src={stripe_logo}/></a>
                    </div>
                    <p className='tiny_text'>Your account information will be stored on <a target="_blank" href='https://stripe.com/customers' className='green_text '>Stripe’s secure platform</a> (a payment platform used by Uber, Spotify, Postmates, and many more), and Greatest Gift will never keep any of your financial account information on our servers. 
                    <br></br>Learn more about our security practices <a target="_blank" href='https://www.greatestgiftapp.com/#security' className='green_text '>here.</a></p>
                </div>
                }
    
                </main>
            
                <div className='buffer'></div>
                <div className='buffer'></div>
                
            </div>
            );

        }
    }
}

export default Main_link_destination;