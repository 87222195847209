import React from 'react';
import './Dropdown_w_category.css';
import { Link } from 'react-router-dom';

class Dropdown_w_category extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
            //selected: ''
        };

        this.toggleOpen = this.toggleOpen.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        
        this.getHeaderClass = this.getHeaderClass.bind(this);
        this.getHeaderArrow = this.getHeaderArrow.bind(this);
        this.renderHeaderText = this.renderHeaderText.bind(this);

        this.renderHeader       =   this.renderHeader.bind(this);
        this.renderOpenDD       =   this.renderOpenDD.bind(this);
        this.renderCategories   =   this.renderCategories.bind(this)
        this.renderItems        =   this.renderItems.bind(this);
        this.renderLastOption   =   this.renderLastOption.bind(this);
        

    }

    // handle clicks
    toggleOpen(){
        this.setState({open: !this.state.open})
    }

    handleItemClick(item){
        //this.setState({selected: item});
        this.props.handleItemClick(item, this.props.dd_name);
        this.toggleOpen();
    }

    // header changes

    getHeaderClass() {
        let dd_header = 'dd-header';
        let dd_header_selected = 'dd-header-selected';
        let highlighted = 'highlight';
        let open_list_header = 'dd-header-open';
        let inactive = 'dd-header-inactive'
        let result = dd_header;
        
        if(!this.props.active) {
            result += ' ' + inactive;
        }

        if(this.props.selected) {
            result += ' ' + dd_header_selected;
        } else if (this.props.highlight){
            result += ' ' + highlighted;
        };

        if(this.state.open){
            result += ' ' + open_list_header;
        }

        return result;
    }

    getHeaderArrow() {
        if (this.state.open) {
            return 'material-icons rotate_270'
        } else {
            return 'material-icons rotate_90'
        };
    }

    renderHeaderText(){
        if(!this.props.selected) {
            return (
                <div className='ddc_header_text'>
                    <p>{this.props.header}</p>
                </div>
            )
        } else {
            return (
                <div className='ddc_header_text'>
                    <p className='ddc_header_label tiny_text'>{this.props.header}</p>
                    <p className='ddc_header_selected'>{this.props.selected.main_text} {this.props.selected.sub_text}</p>
                </div>
            )
        }
        
    }

    // render methods
    renderHeader(){
            // Display the header with just the call to action
            return (
                <div className={this.getHeaderClass()} onClick={this.props.active ? this.toggleOpen : null}>
                        
                        {this.renderHeaderText()}

                        <div className='ddc_header_arrow'>
                            <i className={this.getHeaderArrow()}>arrow_forward_ios</i>
                        </div>
                </div>
            )
    } 
    
    renderOpenDD(){
        if(this.state.open) {
            return (
                <ul className='ddc_category_list'>
                    {this.renderCategories()}
                    {this.props.lastItem ? this.renderLastOption() : null}
                </ul>
                
            );
        } else {
            return null;
        };
    }
    
    renderCategories(){
        //render li of categories, that each have a new ul list of items inside of it
        return this.props.list.map(category => {
            
            let class_for_category = 'ddc_category_item';
            class_for_category = category.disabled ? class_for_category + ' ddc_category_disabled' : class_for_category;
            class_for_category = this.props.lastItem ? class_for_category : class_for_category + ' rounded_bottom_last';

            return(
                <li className={class_for_category}
                    key={category.id}>
                    <div className='ddc_category_header'  >
                        <div className='ddc_category_header_main_text'>
                            <p className='tiny_text'>{category.category_header}</p>
                        </div>
                        <div className='ddc_category_header_sub_text'>
                            <p className={'tiny_text '+ category.color}>{category.category_subtext}</p>
                        </div>
                    </div>

                    <ul className='ddc_item_list'>
                        {this.renderItems(category.items, category.disabled)}
                    </ul>
                    
                </li>
                
            )
        });
    }

    renderItems(list, disabled){
        return list.map(item => {
            return(
                <li className='ddc_item' key={item.id}  onClick={ disabled ? null : () => {this.handleItemClick(item)}}>
                    
                    <div className='ddc_item_image_container'>
                        <img src={item.image}></img>
                        <div className='ddc_item_image_placeholder'></div>
                    </div>

                    <div className='ddc_item_right_side'>
                    <div className='ddc_item_text'>
                        <p className='small_text'>{item.main_text}<br></br>{item.sub_text}
                        </p>
                    </div>
                    
                    <div className='ddc_item_icon'> 
                        {(this.props.selected.id === item.id) ? (<i className='material-icons-outlined green_icon'>done</i>) : null}   
                    </div>
                    </div>
                </li>
            )
        });

    }


    renderLastOption(){
        if (this.props.lastItemLink) {
            return (
                <Link to={this.props.lastItemLink}>
                    <li onClick={() => {this.props.handleLastItemClick()}}
                        className='ddc_item ddc_item_last' key='0'>
                            <div className='dd-list-item-text'>
                                <p>{this.props.lastItemText}</p>
                            </div>
                            <div className='ddc_item_icon'> 
                                <i className='material-icons'>{
                                    this.props.last_item_add_button ? 
                                    'add_circle' : 
                                    'arrow_forward_ios'
                                }</i>
                            </div>
                    </li>
                </Link>
            )
        } else {
            return (
                    <li onClick={() => {this.props.handleLastItemClick()}}
                        className='ddc_item ddc_item_last' key='0'>
                            <div className='dd-list-item-text'>
                                <p>{this.props.lastItemText}</p>
                            </div>
                            <div className='ddc_item_icon'> 
                                <i className='material-icons'>{
                                    this.props.last_item_add_button ? 
                                    'add_circle' : 
                                    'arrow_forward_ios'
                                }</i>
                            </div>
                    </li>
            )
        }
        
    }

    render () {
        
        return (
        <div className='dd-wrapper' >
        
            {this.renderHeader()}
            {this.renderOpenDD()}
        
        </div>
        );
    }
}

export default Dropdown_w_category;

/*
if(!this.props.list.includes(this.state.selected)) {
    this.setState({selected: ''})
}
*/
