import React from 'react';
import './Profile_overlay.css';

import { Link } from 'react-router-dom';

class Profile_overlay extends React.Component {
    constructor(props) {
        super(props);

        this.signOut = this.signOut.bind(this);
    }
    
    signOut() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            localStorage.removeItem("user");
        };
    }

    render () {
        return (
            <div className='profile_overlay_menu' style={{display: this.props.display}}>
                
                <div className='arrow-up-outline'></div>
                <div className='arrow-up'></div>
                

                <ul className='overlay_list' onClick={this.props.toggleMenu}>
                    
                        <li className='overlay_list_item'>
                            <Link to='/settings/account'>
                                <p>Account Settings</p>
                            </Link>
                        </li>

                        <li className='overlay_list_item'>
                            <Link to='/settings/payment_methods'>
                                <p>Payment Methods</p>
                            </Link>
                        </li>

                        <li className='overlay_list_item'>
                            <Link to='/settings/notifications'>
                                <p>Notifications</p>
                            </Link>
                        </li>

                        <li className='overlay_list_item'>
                            <Link to='/greatest_gift_intro'>
                                <p>Greatest Gift Intro</p>
                            </Link>
                        </li>

                        <li className='overlay_list_item'>
                            <p className='overlay_no_link' onClick={() => window.location.href='mailto:Support@GreatestGiftApp.com'}>Help</p>
                        </li>

                        <li className='overlay_list_item'>
                            <Link to='/'>
                                <p onClick={this.signOut}>Sign out</p>
                            </Link>
                        </li>
                    
                    
                </ul>
                
            </div>
            );
    }
}

export default Profile_overlay;