import React from 'react';
import './Sent_gift.css';

class Sent_gift extends React.Component {
    render () {
        return (
            <div className='gift'>
                <div className='gift_left_side'>
                    <p className='l1'>Gift to {this.props.giftTo}</p>
                    <p className='small_text l2'>Delivery Date</p>
                </div>
                <div className='gift_right_side'>
                    <p className='money l1'>${this.props.giftAmount}</p>
                    <p className='small_text l2'>{this.props.giftDate}</p>
                </div>
            </div>
        );
    }
}

export default Sent_gift;

/*
    <div className='gift'>
        <p className='l1'>Gift to {this.props.giftTo}<span className='money'>${this.props.giftAmount}</span></p>
        <p className='small_text l2'>Delivery Date <span>{this.props.giftDate}</span></p>
    </div>
*/
