import React from 'react';
import './Secondary_emails.css';

import GGApi from '../../../utils/GGApi.js';

import GG_loader from '../../general_components/GG_loader/GG_loader.js';
import MasterButton from '../../general_components/MasterButton/MasterButton.js';
import validations from '../../../utils/validations';

function delay(time){
    return new Promise(resolve => setTimeout(resolve, time));
};

class Secondary_emails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            secondary_email: '',
            unique_email: true,
            invalid_email_format: false,
            error_message: '',
            buttonActive: true
        }

        this.handleChange = this.handleChange.bind(this);

        this.handleEnter = this.handleEnter.bind(this);
        this.requestSecondaryEmail = this.requestSecondaryEmail.bind(this);

    }


    handleChange(event) {
        // set the state
        const { name, value} = event.target;
        this.setState({[name]: value, invalid_email_format: false, error_message: ''});

        // check uniqueness of email input
        if(value.length > 4){
            GGApi.uniqueEmail(value.toLowerCase()).then(unique => {
                this.setState({unique_email: unique});
            });
        }
        if(value.length === 0 ){
            this.setState({unique_email: true})
        }

    }

    // button handlers

    handleEnter(event) {
        if(event.keyCode == 13 ) {
            event.preventDefault();
            this.requestSecondaryEmail();
        };
    }

    requestSecondaryEmail(){
        // if not unique, button inactive, or no input, break
        if(!this.state.unique_email || !this.state.buttonActive || !this.state.secondary_email){
            return;
        } 
        
        // start loading screen until done, disable button
        this.setState({loading: true, buttonActive: false})

        // validate the email in the state
       
        if (!validations.validateEmail(this.state.secondary_email)) {
            this.setState({loading: false, invalid_email_format: true, buttonActive: true})
            return;
        };

        GGApi.add_secondary_email(this.state.secondary_email.toLowerCase()).then(response => {
            if(response.message !== 'Email sent.'){
                delay(3*1000).then(() => {
                    this.setState({error_message: response.message, secondary_email: '', loading: false, buttonActive: true})
                })    
            } else {
                // add delay of 3 seconds here, to make sure no hits the button too many times
                delay(3*1000).then(() => {
                    this.setState({secondary_email: '', loading: false, buttonActive: true})
                    alert('Please check your email to verify and activate your new secondary email');
                })
            }
        });
        
    }

    // render methods
    

    render () {
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        return (
        <main>
            <div>
                

                <h2>Add a Secondary Email</h2>
                <p className='form_label'>You will be able to receive gifts sent to your primary email and each verified secondary email.</p>
                <form>
                        
                        <p className='form_label'>Email: </p>
                        <input 
                            className={ this.state.error_message || !this.state.unique_email || this.state.invalid_email_format ?  'invlid_input' : null  }
                            type='email' 
                            id='secondary_email' 
                            name='secondary_email' 
                            placeholder='example@email.com' 
                            onChange={this.handleChange} 
                            value={this.state.secondary_email}
                            autoComplete='off'
                            onKeyDown={this.handleEnter}
                            />
                        
                        {!this.state.unique_email ? 
                            <p className='small_text validation_message_error'>This email is already associated with a user. Please enter a different, unique email address.</p>
                        : null}
                        {this.state.invalid_email_format ? 
                            <p className='small_text validation_message_error'>Please enter a valid email address.</p>
                        : null}
                        {this.state.error_message ? 
                            <p className='small_text validation_message_error'>{this.state.error_message}</p>
                        : null}

                </form>


                <MasterButton   buttonText='Request secondary email'
                                handleButtonClick={this.requestSecondaryEmail}
                                notSticky={true}
                                inactive_button={!this.state.unique_email && this.state.buttonActive}
                                />

                <div className='buffer'></div>
            </div>
        </main> 
        );
    }
}

export default Secondary_emails;