import React from 'react';
import './GG_intro.css';

import Tracker from '../../../layout_components/Tracker/Tracker.js';
import MasterButton from '../../../general_components/MasterButton/MasterButton.js';
import Header_for_multistep from '../../../layout_components/Header_for_multistep/Header_for_multistep';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import GG_balloons from '../../../general_components/GG_confetti/GG_balloons.js';

import image_1 from '../../../../images/illustrations/gifts_w_confetti.png';
import image_2 from '../../../../images/illustrations/redeem_gift.png';
import image_3 from '../../../../images/illustrations/financial_goals.png';
import image_4 from '../../../../images/illustrations/discover_book.png';
import gg_logo from '../../../../images/logos/logo.png';
import GG_confetti from '../../../general_components/GG_confetti/GG_confetti.js';

class GG_intro extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //form details
            currentStep: 1,
            buttonText: 'Next',
            buttonLinks: false,
            next_page: '/home',
            welcome: '',
            from: ''
        }

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.renderIntroStep = this.renderIntroStep.bind(this);
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('source')){
            this.setState({next_page: '/'+urlParams.get('source')})
        }
        if(urlParams.get('welcome')){
            this.setState({welcome: urlParams.get('welcome'), currentStep: 0})
        }
        if(urlParams.get('from')){
            this.setState({from: urlParams.get('from'), currentStep: 0})
        }
    }

    // button clicks
    handleButtonClick() {
        window.scrollTo(0, 0);
        let step = this.state.currentStep;        
        const urlParams = new URLSearchParams(window.location.search);
        if (step === 0) {
            step += 1;
            this.setState({currentStep: step});

        } else if (step === 1 || step === 2 ) {
            step += 1;
            this.setState({currentStep: step});

        } else if (step === 3) {
            step += 1;
            this.setState({currentStep: step, buttonText: 'Get Started', buttonLinks: true});
            if(this.state.welcome || this.state.from){
                this.setState({buttonText: 'Give A Gift Now', next_page: '/give-a-gift'})
            }
            

        } else if (step === 3) {
        } 
    }

    handleBackButton() {
        window.scrollTo(0, 0);
        let step = this.state.currentStep;
        const urlParams = new URLSearchParams(window.location.search);
        if (step === 0) {
            if(urlParams.get('source')){
                window.location.href = '/'+urlParams.get('source');
            } else {
                window.location.href = '/'
            }

        } else if (step === 1) {
            if(this.state.welcome || this.state.from){
                step -= 1;
                this.setState({currentStep: step});
            } else {
                // go back to the last screen before this flow
                if(urlParams.get('source')){
                    window.location.href = '/'+urlParams.get('source');
                } else {
                    window.location.href = '/home'
                }
            }

        } else if (step === 2 || step === 3 ) {
            step -= 1;
            this.setState({currentStep: step});

        } else if (step === 4) {
            step -= 1;
            this.setState({currentStep: step, buttonText: 'Next', buttonLinks: false});
        };
    }

    renderIntroStep() {
        let image;
        let headline;
        let body_1;
        let body_2;
        let welcome = this.state.welcome ? this.state.welcome : 'there';
        let from = this.state.from ? this.state.from : 'Greatest Gift';

        if(this.state.currentStep === 0) {
            image       = gg_logo;
            headline    =  `Hi ${welcome}, Welcome to Greatest Gift`;
            body_1      = `${from} is inviting you to join a community of Great Gifters.`;
            body_2      = `Greatest Gift turns investing in a child's future into a personal gift that you can send to your loved ones.`;
        }
        if(this.state.currentStep === 1) {
            image       = image_1;
            headline    = '1. Send Cash Gifts With Purpose';
            body_1      = 'Easy to send. Fun to receive.';
            body_2      = 'Turn a money gift into a thoughtful gesture by adding a personal greeting with pictures and videos, and set a young one on the right financial path.';
    
        } else if (this.state.currentStep === 2) {
            image       = image_2;
            headline    = '2. Receive & Redeem Cash Gifts';
            body_1      = 'Skip the trip to the bank.';
            body_2      = 'Transfer gift funds from Greatest Gift directly to your favorite long-term savings or investment accounts.';
        } else if (this.state.currentStep === 3) {
            image       = image_3;
            headline    = '3. Create Gifting Pages';
            body_1      = 'Let people know what you are saving for.';
            body_2      = 'Create and share a gifting page to receive cash gifts from friends & family for your little one’s financial goals.';
        } else if (this.state.currentStep === 4) {
            image       = image_4;
            headline    = '4. Discover Ways to Save & Invest';
            body_1      = 'Looking for great ways to save?';
            body_2      = 'We help parents answer financial questions, and provide a curated collection of financial products, guides and life-hacks for creating a brighter future for your family.';
        };

        return (
            <div>
                <div className='intro_img_container'>
                    <img className='intro_img' src={image}></img>
                </div>
                <h2>{headline}</h2>
                <p>{body_1}</p>
                <p>{body_2}</p>
            </div>
        )
    }
    
    render () {
        return (
        <div>
            <Header_for_multistep 
                text='Greatest Gift'
                handleBackButton={() => this.handleBackButton()}
            />

            <Helmet>
                <title>Welcome to Greatest Gift</title>
                <meta property="og:title" content='Welcome to Greatest Gift'/>
                <meta name="description"
                    content='Join a community of Great Gifters' 
                />
                <meta property="og:description" 
                    content='Join a community of Great Gifters'  
                />
                
            </Helmet>

            {this.state.currentStep === 0 ? 
                //<GG_balloons landing={false}/>
                <GG_confetti />
            : null}

            <main>
        
                <Tracker 
                        currentStep={this.state.welcome || this.state.from ? 
                                    this.state.currentStep + 1 : 
                                    this.state.currentStep } 
                        numOfSteps={this.state.welcome || this.state.from ? 5 : 4}/>
                
                {this.renderIntroStep()}
            
                <br></br>
                <br></br>

                { (this.state.welcome || this.state.from) && this.state.currentStep === 4 ?
                    <MasterButton 
                    buttonText={'Create an Account'} 
                    links={true}
                    linkTo={'/new_account'}
                    notSticky={true}
                /> 
                : null }

                { (!this.state.welcome && !this.state.from) && this.state.currentStep === 4 ?
                    <h2>Ready to start?</h2>
                : null }

                { (!this.state.welcome && !this.state.from) && this.state.currentStep === 4 ?
                    <MasterButton 
                    buttonText={'Set up recipient & gifting page'} 
                    links={true}
                    linkTo={'/add_account'}
                    notSticky={true}
                /> 
                : null }


                <MasterButton 
                    buttonText={this.state.buttonText} 
                    handleButtonClick={this.handleButtonClick}
                    links={this.state.buttonLinks}
                    linkTo={this.state.next_page}
                    notSticky={true}
                />

                <div className='skip_button'>
                    <a href={this.state.welcome || this.state.from ? 'https://www.greatestgiftapp.com' : this.state.next_page}>
                        <p className='secondary_dark'>{this.state.currentStep === 3 && this.state.welcome || this.state.from ? 'Learn more' : 'Skip'}</p>
                    </a>
                </div>

            </main>
        
            <div className='buffer'></div>
            <div className='buffer'></div>

        </div>
        );
    }
}

export default GG_intro;