import React from 'react';
import './Account_step_3.css';

class Account_step_3 extends React.Component {

    render () {   
        if (this.props.currentStep !== 3) {
            return null;
        };
        return (
           <div>
                <h2 className='center'>Verification</h2>
        
                <form onSubmit={(event) => {
                        event.preventDefault();} 
                        }>

                    <p className='form_label'>Enter phone verification code:</p>
                    <input 
                        type='text' 
                        id='phone_verification' 
                        name='phone_verification' 
                        placeholder='123-456' 
                        maxLength='6'
                        onChange={this.props.handleChange} 
                        value={this.props.phone_verification}
                        autoFocus 
                        onKeyDown={event => {
                            if(event.key === "Enter" ){
                                event.preventDefault()
                            };
                        }}
                        autoComplete='off'/>
                </form>

               

           </div>
        );
    }
}

export default Account_step_3;
