import React from 'react';
import './Nav.css';

import { Link } from 'react-router-dom';

class Nav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSection: 'Gift'
        };

        this.renderNavOptions = this.renderNavOptions.bind(this);
        this.getIconClass = this.getIconClass.bind(this);
        this.setNavActive = this.setNavActive.bind(this);
        this.getNavClass = this.getNavClass.bind(this);

        this.navOptions = {
            Gift: {
                path: '/gifts',
                text: 'Gift',
                icon: 'pages',
                icon_class: "material-icons-outlined nav_icon"
                //selected: 'selected'
            },
            Receive: {
                path: '/receive',
                text: 'Receive',
                icon: 'sentiment_very_satisfied',
                icon_class: "material-icons-outlined nav_icon"
                //selected: ''
            },
            Plan: {
                path: '/plan',
                text: 'Plan',
                icon: 'spa',
                icon_class: "material-icons-outlined nav_icon"
                //selected: ''
            },
            Discover: {
                path: '/discover',
                text: 'Discover',
                icon: 'language',
                icon_class: "material-icons nav_icon"
                //selected: ''
            }
        }
    }

    getNavClass(navOption) {
        if (this.state.activeSection === navOption) {
            return 'selected';
        }
        return '';
    }

    getIconClass(navOption, iconClass) {

        if (this.state.activeSection === navOption) {
            return (iconClass + ' selected');
        }
        return iconClass;
    }

    setNavActive(navOption) {
        this.setState({activeSection: navOption});
    }

    componentDidMount() {
        // if someone goes directly to a section, by putting in the url, make sure the correct
        // section is activated and highlighted
        if (this.props.match.path === '/gifts') {
            this.setState({activeSection: 'Gift'});

        } else if (this.props.match.path === '/receive') {
            this.setState({activeSection: 'Receive'});
        } else if (this.props.match.path === '/receive/:id') {
            this.setState({activeSection: 'Receive'});

        } else if (this.props.match.path === '/plan') {
            this.setState({activeSection: 'Plan'});
        } else if (this.props.match.path === '/plan/:plan_id') {
            this.setState({activeSection: 'Plan'});

        } else if (this.props.match.path === '/discover') {
            this.setState({activeSection: 'Discover'});
        } else if (this.props.match.path === '/discover/*') {
            this.setState({activeSection: 'Discover'});
        };
    }

    componentDidUpdate(){
        if((this.props.match.path === '/discover' || this.props.match.path === '/discover/*') && this.state.activeSection !== 'Discover' ){
            this.setState({activeSection: 'Discover'});
        } else if ((this.props.match.path === '/receive' || this.props.match.path === '/receive/:id') && this.state.activeSection !== 'Receive'){
            this.setState({activeSection: 'Receive'});
        } else if ((this.props.match.path === '/gifts' || this.props.match.path === '/gifts/*') && this.state.activeSection !== 'Gift'){
            this.setState({activeSection: 'Plan'});
        } else if ((this.props.match.path === '/plan' || this.props.match.path === '/plan/*') && this.state.activeSection !== 'Plan'){
            this.setState({activeSection: 'Plan'});
        } 
    }

    renderNavOptions() {
        return Object.keys(this.navOptions).map(navOption => {
            let navOptionObject = this.navOptions[navOption] ;
            
            return (
                <Link className='nav_link' 
                    to={navOptionObject.path} 
                    key={navOption} 
                    onClick={this.setNavActive.bind(this, navOption)}
                >
                    <div className='nav_item'>
                        <i className={this.getIconClass(navOption, navOptionObject.icon_class)}>{navOptionObject.icon}</i>
                        <p className={this.getNavClass(navOption)}>{navOptionObject.text}</p>
                    </div>
                </Link>
            )
        });
    }


    render () {
        if (this.props.visible === false) {
            return null;
        };
        return (
        <footer>
            <nav>
                {this.renderNavOptions()}
            </nav>
        </footer>
        );
    }
}

export default Nav;