import React from 'react';
import './My_account.css';

import GGApi from '../../../utils/GGApi.js';
import { Link } from 'react-router-dom';

import GG_loader from '../../general_components/GG_loader/GG_loader.js';
import MasterButton from '../../general_components/MasterButton/MasterButton.js';
import { Helmet } from 'react-helmet';

/* Need to pull gift data from the database here, for inputs into the fields */

class My_account extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            emailConfirmed: false,

            username: '',
            email: '',
            phone_number: '',

            secondary_email_list: [],

            update_password: false,
            buttonActive: true,
            user_password: '',
            user_password_verification: '',
            show_password: false,
        }

        this.toggle_loading = this.toggle_loading.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.sendAnotherConfirmation = this.sendAnotherConfirmation.bind(this);
        this.updatePasswordButton = this.updatePasswordButton.bind(this);

        this.renderConfirmEmailButton = this.renderConfirmEmailButton.bind(this);
        this.renderSecondaryEmailList = this.renderSecondaryEmailList.bind(this);
        this.renderPasswordUpdate = this.renderPasswordUpdate.bind(this);
    }

    componentDidMount() {        
        // load the account details & email confirmed
        GGApi.account_details().then(response => {
            this.setState({
                emailConfirmed: response.email_confirmed,
                username:       response.username,
                email:          response.email,
                phone_number:   response.phone_number,
                loading: false
            })
        })
        GGApi.get_secondary_emails().then(response => {
            this.setState({
                secondary_email_list: response,
                loading: false
            })
        })
    }
    
    // toggles

    toggle_loading(){
        this.setState({loading: !this.state.loading})
    }

    toggle(){
        this.setState({show_password: !this.state.show_password});
    }

    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }

    // button handlers

    sendAnotherConfirmation(){
        GGApi.resend_email_verification();
        alert('A new email confirmation link is being sent to your email!');
    }

    updatePasswordButton(){
        // disable if button is inactive
        if(!this.state.buttonActive){
            return;
        }
        // validate the password selections
        if (this.state.user_password.length < 8) {
            alert('Please enter a password with at least 8 characters.');
            this.setState({loading: false});
        } else if (!(this.state.user_password === this.state.user_password_verification)) {
            alert ('Please make sure the passwords in both fields match.')
            this.setState({loading: false});
        } else {
            this.setState({loading: true, buttonActive: false});
            // inputs validated. Send the new password to db
            GGApi.create_new_password_from_login(this.state.user_password)
            .then(response => {
                if(response.message === 'password updated'){
                    alert('Your password was successfully changed!');
                    this.setState({update_password: false})
                } else {
                    alert('Your password reset encountered an error. Please try again, or contact our support team at Suport@GreatestGiftApp.com!');
                }
                this.setState({loading: false, buttonActive: true});
            });
        }
    }

    // render methods

    renderConfirmEmailButton(){
        if(!this.state.emailConfirmed){
            return (
                <MasterButton   buttonText='Verify Email'
                                handleButtonClick={this.sendAnotherConfirmation}
                                notSticky={true}
                                />
            )
        } else {
            return null;
        }
    }

    renderSecondaryEmailList(){
        // get the secondary email list from the state, map it, and return a <p> with the email for each item
        let i = 0 ;
        return this.state.secondary_email_list.map(item => {
            i++;
            return (
                <div className='two_column_line' key={i}>
                    <div className='left_column_text'><p>Secondary email:</p></div>
                    <div className='right_column_text'><p>{item.email}</p></div>
                </div>
            )
        })
    }

    renderPasswordUpdate(){
        if(!this.state.update_password) {
            return null;
        } else {
            return (
            <div>

                <h2>Reset Password:</h2>
                
                <form>
                    <p className='form_label'>Please enter a new password </p>
                        <input 
                            type={this.state.show_password ? 'text' : 'password' }
                            id='user_password' 
                            name='user_password'
                            onChange={this.handleChange}
                            placeholder='Ab123456!' 
                            value={this.state.user_password}
                            autoComplete='off'
                            maxLength='50'
                            />

                        <p className='form_label'>Verify your new password</p> 
                        <input 
                            type={this.state.show_password ? 'text' : 'password' }
                            id='user_password_verification' 
                            name='user_password_verification' 
                            onChange={this.handleChange}
                            placeholder='Ab123456!' 
                            value={this.state.user_password_verification}
                            autoComplete='off'
                            maxLength='50'
                            />
                </form>


                <div className='disclaimer_checkbox_container'>
                    {this.state.show_password ?
                        <i onClick={this.toggle} className='material-icons-outlined disclaimer_checkbox'>check_box</i> :
                        <i onClick={this.toggle} className='material-icons-outlined disclaimer_checkbox'>check_box_outline_blank</i>}
                    <p className='disclaimer_checkbox_text tiny_text'>Show password</p>
                </div>


                <MasterButton   buttonText='Reset password'
                                handleButtonClick={this.updatePasswordButton}
                                notSticky={true}
                                inactive_button={!this.state.buttonActive}
                                />
            </div>
            )
        }
    }

    render () {
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        return (
        <main>
            
            <Helmet>
                <title>{`Account Settings | Greatest Gift`}</title>
                <meta name="description"
                    content='Greatest Gift account settings page. Update your personal info, see secondary emails, and more.' 
                />
            </Helmet>

            <h2>Account Details</h2>
            
            <div className='two_column_text_container'>
               
                <div className='two_column_line'>
                    <div className='left_column_text'><p>Username:</p></div>
                    <div className='right_column_text'><p>{this.state.username}</p></div>
                </div>
    
                
                <div className='two_column_line'>
                    <div className='left_column_text'><p>User phone number:</p></div>
                    <div className='right_column_text'><p>{this.state.phone_number}</p></div>
                </div>

                <div className='two_column_line'>
                    <div className='left_column_text'><p>User email:</p></div>
                    <div className='right_column_text'><p>{this.state.email}</p></div>
                </div>
                
                {this.renderConfirmEmailButton()}

                {this.renderSecondaryEmailList()}
            </div>


                

                    

            <p>Add and verify secondary email accounts <Link className='green_text bold' to='/settings/secondary_emails'>here</Link> in order to receive gifts sent to a different email.</p>

            <MasterButton   buttonText='Change password'
                            handleButtonClick={() => this.setState({update_password: true})}
                            notSticky={true}
                            />

            {this.renderPasswordUpdate()}

            <div className='buffer'></div>
    
        </main> 
        );
    }
}

export default My_account;