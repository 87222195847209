import React from 'react';
import { Link } from 'react-router-dom';
import './Articles_list_item.css';

class Articles_list_item extends React.Component {
    render () {
        return (
            <a href={this.props.link} target='_blank'>
            <div className='article'>
                <div className='image_container'>
                    <img src={this.props.picture_url}/>
                </div>
                <div className='text_and_headline_container'>
                    <div className='article_headline'><p className='bold'>{this.props.headline}</p></div>
                    <div className='article_text'><p className='small_text'>{this.props.preview}</p></div>    
                </div>
            </div>
            </a>
            
        );
    }
}

export default Articles_list_item;

/*


*/