import React from 'react';
import './Multi_factor_auth.css';

import GGApi from '../../../../utils/GGApi.js';

import GG_loader from '../../../general_components/GG_loader/GG_loader.js';
import MasterButton from '../../../general_components/MasterButton/MasterButton.js';
import validations from '../../../../utils/validations';

import config from '../../../../utils/config';
import amplitude from 'amplitude-js';
import { Helmet } from 'react-helmet';
amplitude.getInstance().init(config.amp_key);

function delay(time){
    return new Promise(resolve => setTimeout(resolve, time));
};

class Multi_factor_auth extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            button1Active: true,
            button2Active: true,

            phone_ending: '1234',
            masked_email: 'Ab*****@gm*****',
            
            verification_method: 'verification_sms',
            show_verification_input: false,
            verification_code: '',
            remember_me: false,
        }

        this.send_verification_code = this.send_verification_code.bind(this);    
        this.verify_and_login = this.verify_and_login.bind(this);        
        this.handleChange = this.handleChange.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
    }

    componentDidMount(){
        // check for a 2fa token
        const data_for_2fa = JSON.parse(localStorage.getItem('data_for_2fa'));
        if(data_for_2fa){
            // check that the token is valid and not expired
            GGApi.check2FAToken().then(valid => {
                if(!valid){
                    window.location.href = '/';

                } else { // if token is valid, set the blocked phone number and email
                    const data_for_2fa = JSON.parse(localStorage.getItem('data_for_2fa'));
                    this.setState(
                        {   loading: false,
                            phone_ending: data_for_2fa.phone,
                            masked_email: data_for_2fa.email ? data_for_2fa.email : null
                        }
                    )
                }
            })
        } else {
            window.location.href = '/';
        }
        
    }

    send_verification_code(){
        // verify button is active
        if(!this.state.button1Active){
            return;
        }
        // verify a radio button is selected.
        if(!this.state.verification_method){
            return;
        } else {
            this.setState({loading: true, button1Active: false});
            GGApi.send_otp_for_2fa(this.state.verification_method)
            .then(response => {
                if(response.message === 'One time verification sent.'){
                    // amplitude
                    amplitude.getInstance().logEvent('login: request 2fa otp');
                    this.setState({loading: false, show_verification_input: true, button2Active: true})
                    delay(3*1000).then(() => {
                        this.setState({button1Active: true})
                    });
                } else {
                    alert('We\'re sorry, but there was an issue when attempting to send the code. Please try again');
                    window.location.href = '/';
                    localStorage.removeItem("data_for_2fa");
                }
            })
        }
    }

    verify_and_login(shortcut_code){
        let code = this.state.verification_code;
        // make sure the button is active
        if(!this.state.button2Active){
            return;
        }
        if(shortcut_code){
            code = shortcut_code;
        };
        // validate the verification code input
        if(!validations.validateSixDigits(code)){
            alert('Please enter a valid 6 digit code.')
            return;
        }
        // ggapi - send otp for verification
        this.setState({loading: true, button2Active: false});
        GGApi.check_otp_for_2fa(code, this.state.remember_me)
        .then(response => {
            if(response.accessToken){
                // if successful - redirect, like the login page 
                if(this.props.gift_link){ // if there's a gift link - go back to that gift
                    window.location.href = ('/give-a-gift'+this.props.gift_link);    
                } else {
                    window.location.href = '/home'
                }
            } else {
                // if not successful - error message!
                delay(3*1000).then(() => {
                    alert('We\'re sorry, but there was an issue when attempting to verify the code. Please try again');
                    this.setState({loading: false, button2Active: true});
                });
            }
        })
        
        
    }

    handleChange(event) {
        const { name, value} = event.target;

        if(name === 'verification_code' && value.length === 6){
            this.verify_and_login(value);
        }

        this.setState({[name]: value});
    }

    handleEnter(event) {
        if(event.keyCode == 13 ) {
            event.preventDefault();
            // activity:
            this.verify_and_login();
        };
    }

    render () {
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        return (
        <main>

                <Helmet>
                    <title>{`Authentication | Greatest Gift`}</title>
                </Helmet>

            <h2>Multi-Factor Authentication</h2>

            <p>We're sorry, we don't recognize this device or location. We use multi-factor authentication to verify your login attempt.</p> 


            {this.state.show_verification_input ? 
            
            <div>
                <h2>Verify</h2>
                <form>

                    <p className='form_label'>Enter verification code:</p>
                    <input 
                        type='text' 
                        id='verification_code' 
                        name='verification_code' 
                        placeholder='123456' 
                        maxLength='6'
                        onChange={this.handleChange} 
                        value={this.state.verification_code}
                        onKeyDown={event => {
                            if(event.keyCode === 13 ){
                                event.preventDefault()
                            };
                        }}
                        autoComplete='off'/>

                </form>
                
                <div className='disclaimer_checkbox_container'>
                    {this.state.remember_me ?
                        <i onClick={() => this.setState({remember_me: !this.state.remember_me})} className='material-icons-outlined disclaimer_checkbox'>check_box</i> :
                        <i onClick={() => this.setState({remember_me: !this.state.remember_me})} className='material-icons-outlined disclaimer_checkbox'>check_box_outline_blank</i>}
                    <p className='disclaimer_checkbox_text tiny_text'>Remember this device</p>
                </div>

                <MasterButton   buttonText={'Verify & Login'}
                                handleButtonClick={this.verify_and_login}
                                notSticky={true}
                                inactive_button={!this.state.button2Active}
                                />

                                <p onClick={this.send_verification_code} className='secondary_dark align_center pointer cursor'>Resend Code</p>
            </div>

        :  
<div>
            <form>
                <p className='form_label bold'>Choose a verification method: </p>

                <input 
                    type='radio' 
                    id='verification_sms' 
                    name='verification_method' 
                    value='verification_sms' 
                    onChange={this.handleChange}
                    checked={this.state.verification_method === 'verification_sms'}/> 
                <p className='form_radio_label'>Send SMS to phone: {'XXX-XXXX-'+this.state.phone_ending}</p>
                
                <br></br>

                { this.state.masked_email ? 
                <input 
                type='radio' 
                id='verification_email' 
                name='verification_method' 
                value='verification_email' 
                onChange={this.handleChange}
                checked={this.state.verification_method === 'verification_email'}/> : null }
                {this.state.masked_email ?  <p className='form_radio_label'>Send email to: {this.state.masked_email}</p> : null }

            </form>

            <MasterButton   buttonText={this.state.show_verification_input ? 'Resend Code' : 'Send Verification Code'}
            handleButtonClick={this.send_verification_code}
            notSticky={true}
            inactive_button={!this.state.button1Active}
            />
</div>
        }
            

        </main> 
        );
    }
}

export default Multi_factor_auth;