import React from 'react';
import './Add_step_4.css';

import MasterButton from '../../../general_components/MasterButton/MasterButton.js';

import config from '../../../../utils/config';
import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);


class Add_step_4 extends React.Component {
    render () {
        if (this.props.currentStep !== 4) {
            return null;
        }
        
        
        return (
           <div>
                <h2 className='center'>You're all set!</h2>    
                
                <p>1. {this.props.account_name}'s gifting page is now live! Share it with friends and family so they can start sending you great gifts!</p>
                <MasterButton
                    buttonText={`See ${this.props.account_name}'s Gifting Page`}
                    
                    //// need to get the url for the gifting page! pull it from the props when submitting api in previous page
                    handleButtonClick={() => {amplitude.getInstance().logEvent('new recipient: go to gifting page'); window.open(this.props.gifting_page_url, '_blank').focus()}}
                    links={false}
                    linkTo={this.props.gifting_page_url}

                    notSticky={true}
                    icon={'launch'}
                    />

                <div className="margin_bottom_40"></div>

                <p>2. Start saving for {this.props.account_name} by linking a financial account and redeeming gifts to that account</p>
                <MasterButton
                    buttonText='Link a financial account'
                    handleButtonClick={() => {this.props.updateActiveRecipient(this.props.account_name); amplitude.getInstance().logEvent('new recipient: go to add fund'); }}
                    links={true}
                    linkTo={this.props.source ? '/link_destination?source='+this.props.source : '/link_destination'}
                    notSticky={true}
                    />
                
                <div className="margin_bottom_40"></div>

           </div>
        );
    }
}

export default Add_step_4;
