import React from 'react';
import {Link} from 'react-router-dom';
import './Apply_gift.css';
import MasterButton from '../../general_components/MasterButton/MasterButton.js';
import Overlay_popup from '../../general_components/Overlay_popup/Overlay_popup.js';
import Dropdown from '../../general_components/Dropdown/Dropdown';
import GGApi from '../../../utils/GGApi';
import Dropdown_w_category from '../../general_components/Dropdown_w_category/Dropdown_w_category';

import gg_fees from '../../../utils/gg_pricing.js';
import User_guide from '../../general_components/User_guide/User_guide';

import config from '../../../utils/config';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);

class Apply_gift extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
            selected_fund_active: false,
            buttonActive: false,

            linked_accounts: [],
            
            // selections:
            recipient: '',
            selected_fund: '',

            // user guides:
            guide_active: false,
            open_overlay: false,
            guide_1: false,
            guide_2: false,
            guide_3: false,
            highlight_fields: false,

            finish_overlay_open: false
        };

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleDDSelection = this.handleDDSelection.bind(this);
        this.renderFeeSummary = this.renderFeeSummary.bind(this);
        this.calculateFees = this.calculateFees.bind(this);
        this.overlayButton1Handler = this.overlayButton1Handler.bind(this);
        this.renderRecommendation = this.renderRecommendation.bind(this);
    }

    componentDidMount(){
        //if(this.props.new_receiver){
            this.setState({
                guide_active: true,
                guide_1: true,
                open_overlay: true
            })
        //}
    }

    handleButtonClick() {
        if(this.props.redeem_status === 'failed') {
            return;
        }
        if(this.state.buttonActive) {
            if(!this.state.recipient || !this.state.selected_fund){
                alert('Please select a recipient, and a financial destination to transfer the gift funds to.');
                this.setState({highlight_fields: true});
                return;
            }
            this.props.toggle_loading();
            let redemption_details = {  gift_id: this.props.gift_id, 
                                        child_account_id: this.state.recipient.id, 
                                        fund_id: this.state.selected_fund.id}; // this is new. Need to continue this...
            GGApi.redeem_gift(redemption_details).then(response => {
                // stop the loading circle
                this.props.toggle_loading();
                if(response.success){
                    // open a successful popup message
                    this.props.open_finish();
                    // add a successful redemption message here!
                    this.setState({finish_overlay_open: true})
                    //window.location.href = '/receive'; 
                    //alert('Your gift is scheduled for redemption!');
                    // amplitude
                    amplitude.getInstance().logEvent('receive: redeem gift');
                    
                } else {
                    this.props.showError();
                    alert('We\'re sorry, but there was an issue when trying to redeem this gift. Please contact our support team for additional details.')
                }
                
                
            });
        }
        else {
            alert('Please select a recipient, and a financial destination to transfer the gift funds to.');
            this.setState({highlight_fields: true});
        }
    }

    handleDDSelection(item, dd_name) {
        if(dd_name === 'recipient') {
            // reset the 'selected_fund' dd when the recipient changes:
            if(this.state.recipient.id !== item.id){
                this.setState(
                    {   selected_fund: '', 
                        buttonActive: false, 
                        guide_1: false, 
                        guide_2: true
                    });
            };

            // grab the list of funds for the recipient from the gg db
            GGApi.get_recipient_funds(item.id).then(list => {
                this.setState(
                    {   recipient: item, 
                        selected_fund_active: true,
                        linked_accounts: list
                    });    
            });
            // amplitude
            amplitude.getInstance().logEvent('receive: select recipient');

        } else if (dd_name === 'selected_fund') {
            this.setState(
                {   selected_fund: item, 
                    buttonActive: true,
                    guide_2: false,
                    guide_3: true
                });
            // amplitude
            amplitude.getInstance().logEvent('receive: select fund');
        }
        
    }

    renderFeeSummary() {
        let fees = this.state.selected_fund ? '$5' : 'Select transfer destination to see fees';
        if(this.state.recipient && this.state.selected_fund){
            return (
                <div className='two_column_text_container'>
                    <div className='two_column_line'>
                        <div className='left_column_text'><p>Total fees to redeem:</p></div>
                        <div className='right_column_text'><p className={this.state.selected_fund.fund_type === 'Checking Account' ? 'gg_red' : ''}>{this.calculateFees()}</p></div>
                    </div>
                    {this.state.selected_fund.fund_type === 'Checking Account' ? <p className='gg_red'>Transfer to a 529 plan, investment account or savings account in order to reduce the redeeming fees. For recommended institutions and accounts, check out the discover section.</p> : null}
                </div>
                
            )
        }
    }

    calculateFees(){
        let fee;
        let amount = Number(this.props.Gift_amount);
        let centAmount = amount * 100;
        if(this.state.selected_fund.fund_type === 'Checking Account') {
            fee = ( centAmount * gg_fees.redeem_fees.checking_variable ) + gg_fees.redeem_fees.checking_fixed;
        } else if (this.state.selected_fund.fund_type === 'Savings Account' 
                || this.state.selected_fund.fund_type === 'Investment Account' 
                || this.state.selected_fund.fund_type === '529 Plan' 
                //|| this.state.selected_fund.fund_type === 'US Savings Bonds' 
                ) {
            fee = ( centAmount * gg_fees.redeem_fees.other_variable ) + gg_fees.redeem_fees.other_fixed;
        } else {
            fee = 0;
        };
        fee = Math.floor(fee);
        fee = fee / 100 ;
        return '$' + String(fee);
    }

    overlayButton1Handler(){
        this.setState({open_overlay: false});
        this.props.show_confetti()
        if(!this.state.recipient){
            this.setState({guide_1: true})
        }
    }

    renderRecommendation(){
        if(!this.state.recipient){
            return null;
        } else {
            // if we have a recipient, give advice!

            // if only checking, or nothing at all, start with a savings acccount
            if( this.state.linked_accounts.findIndex(category => category.category_header === '529 Plans') < 0 &&
                this.state.linked_accounts.findIndex(category => category.category_header === 'Investment Accounts') < 0 &&
                this.state.linked_accounts.findIndex(category => category.category_header === 'Savings Accounts') < 0 
            ){
                return (
                    <Link to='/discover/kidssavings' onClick={() => amplitude.getInstance().logEvent('receive: click recommendation', {'category': 'savings'})}>
                    <div className='recommendation_container'>
                        <p className='bold'>Did you know?</p>
                        <p>Setting up a saving account for your kid is a great way to set aside funds for your child's future. </p>
                        <p className='green_text bold'>Discover savings accounts here.</p>
                    </div>
                    </Link>
                )
            } else if ( // if savings, but no investment or 529 - randomize recommendation
                this.state.linked_accounts.findIndex(category => category.category_header === '529 Plans') < 0 &&
                this.state.linked_accounts.findIndex(category => category.category_header === 'Investment Accounts') < 0 &&
                this.state.linked_accounts.findIndex(category => category.category_header === 'Savings Accounts') >= 0 
            ){
                let random = Math.floor(Math.random() * 2);
                if (random === 0 ){
                    return (
                        <Link to='/discover/529plans' onClick={() => amplitude.getInstance().logEvent('receive: click recommendation', {'category': '529'})}>
                        <div className='recommendation_container'>
                            <p className='bold'>Did you know?</p>
                            <p>529 plans allow you to save and invest for your child's educational future. </p>
                            <p className='green_text bold'>Discover our favorite 529 plans.</p>    
                        </div>
                        </Link>
                    )
                } else {
                    return (
                        <Link to='/discover/investments' onClick={() => amplitude.getInstance().logEvent('receive: click recommendation', {'category': 'investment'})}>
                        <div className='recommendation_container'>
                            <p className='bold'>Did you know?</p>
                            <p>Setting up an investment account for your child can help reach your savings goals faster.</p>
                            <p className='green_text bold'>Discover easy to use investment tools.</p>
                        </div>
                        </Link>
                    )
                }
                
            } else if ( // if investment, but no 529
                this.state.linked_accounts.findIndex(category => category.category_header === '529 Plans') < 0 &&
                this.state.linked_accounts.findIndex(category => category.category_header === 'Investment Accounts') >= 0 
            ) {
                return (
                    <Link to='/discover/529plans' onClick={() => amplitude.getInstance().logEvent('receive: click recommendation', {'category': '529'})}>
                    <div className='recommendation_container'>
                        <p className='bold'>Did you know?</p>
                        <p>529 plans provide tax benefits on investments.</p>
                        <p className='green_text bold'>Discover more here.</p>
                    </div>
                    </Link>
                )
            } else if ( // 529 or investment, but no savings
                (this.state.linked_accounts.findIndex(category => category.category_header === '529 Plans') >= 0 ||
                this.state.linked_accounts.findIndex(category => category.category_header === 'Investment Accounts') >= 0) &&
                this.state.linked_accounts.findIndex(category => category.category_header === 'Savings Accounts') < 0 
            ) {
                return (
                    <Link to='/discover/kidssavings' onClick={() => amplitude.getInstance().logEvent('receive: click recommendation', {'category': 'savings'})}>
                    <div className='recommendation_container'>
                        <p className='bold'>Did you know?</p>
                        <p>Savings accounts make a great rainy day fund, and can be used to teach kids about banking and finance.</p>
                        <p className='green_text bold'>Discover savings accounts here.</p>
                    </div>
                    </Link>
                )
            } else if ( // 529 plan & savings, but no investment - suggest investment
                this.state.linked_accounts.findIndex(category => category.category_header === '529 Plans') >= 0 &&
                this.state.linked_accounts.findIndex(category => category.category_header === 'Investment Accounts') < 0 &&
                this.state.linked_accounts.findIndex(category => category.category_header === 'Savings Accounts') >= 0 
            ){
                return (
                    <Link to='/discover/investments' onClick={() => amplitude.getInstance().logEvent('receive: click recommendation', {'category': 'investment'})}>
                    <div className='recommendation_container'>
                        <p className='bold'>Did you know?</p>
                        <p>Investment accounts can provide more control over your investment, fit for more experienced investors.</p>
                        <p className='green_text bold'>Discover our favorite investment accounts.</p>
                    </div>
                    </Link>
                )
            }            
        };
        

    }

    render () {
        if (this.props.received_gift_status === 'new') {
            return (
            <div>

                <div className='apply_gift_headline'>
                    <h2>Apply Gift</h2>
                    
                    <i onClick={() => this.setState({open_overlay: true, guide_active: true})} className='material-icons-outlined inline_icon'>help</i>
                </div>

                <Overlay_popup 
                    open            = {this.state.open_overlay}
                    closer          = {true}
                    handleClose     = {() => {
                        this.setState({open_overlay: false, guide_1: true})
                        this.props.show_confetti()
                    }
                    }
                    overlay_title   = 'Quick Tips'
                    wide            = {false}
            
                    body_1          = 'On this page, you can redeem a gift and transfer the gift funds to an external financial account where you can start earning interest, and put that money to work for you!'
                    
                    button_1        = {{text: 'Start',
                                        links: false,
                                        handler: this.overlayButton1Handler
                                        }}     
                />

                <Dropdown dd_name='recipient'

                    header='Choose a recipient:' 
                    list={this.props.recipients}

                    handleItemClick={this.handleDDSelection}

                    selected={this.state.recipient}
                    
                    highlight={this.state.highlight_fields}
                    
                    lastItem={true}
                    lastItemLink={'/add_account?source='+this.props.gift_id}
                    lastItemText='Add a recipient account'
                    active={true}/>


                <User_guide 
                    show_guide={this.state.guide_active && this.state.guide_1}
                    headline='Quick Tips (1/3)'
                    handleClose={() => this.setState({guide_1: false})}
                    text_1={`Select the recipient the gift is for. This could be your child, yourself, or a different beneficiary.`}
                    text_2={`If you haven’t already, create a new recipient account now.`}
                />
                
                {this.renderRecommendation()}

                <Dropdown_w_category dd_name='selected_fund'

                    header='Transfer gift to:' 
                    list={this.state.linked_accounts}

                    handleItemClick={this.handleDDSelection}
                    handleLastItemClick={() => this.props.updateActiveRecipient(this.state.recipient.title)}

                    selected={this.state.selected_fund}
                    
                    lastItem={true}
                    lastItemLink={'/link_destination?source='+this.props.gift_id}
                    lastItemText='Link another account'
                    active={this.state.selected_fund_active}/>
                
                <User_guide 
                    show_guide={this.state.guide_active && this.state.guide_2}
                    headline='Quick Tips (2/3)'
                    handleClose={() => this.setState({guide_2: false})}
                    text_1={`Transfer the gift funds into one of ${this.state.recipient.title}’s financial accounts.`}
                    text_2={`If you haven’t already, link a financial account for ${this.state.recipient.title} now.`}
                />
                {this.renderFeeSummary()}

                <MasterButton 
                    buttonText={'Redeem Gift'}
                    notSticky={true}
                    handleButtonClick={this.handleButtonClick}
                    inactive_button={this.props.redeem_status==='failed' ? true : !this.state.buttonActive}
                    //links={this.state.buttonActive}
                    links={false}
                    linkTo={'/receive'}/>

                <User_guide 
                    show_guide={this.state.guide_active && this.state.guide_3}
                    headline='Quick Tips (3/3)'
                    handleClose={() => this.setState({guide_3: false})}
                    text_1={`Click the redeem button, and watch as your funds transfer to your favorite account.`}
                />


                <Overlay_popup 
                    open            = {this.state.finish_overlay_open}
                    overlay_title   = 'Your gift is being redeemed!'
                    wide            = {true}
            
                    body_1          = {
                        this.props.thank_you_sent ?
                        `Your gift is scheduled to be redeemed. The gift funds will appear in the selected financial account in a few days.` 
                        :
                        `Your gift is scheduled to be redeemed. The gift funds will appear in the selected financial account in a few days. 
                        \nWould you like to send a thank you note?` 
                    }
                    button_1        = {{text: this.props.thank_you_sent ? 'finish' : 'create thank you note',
                                        links: true,
                                        linkTo: this.props.thank_you_sent ? '/receive' : `/receive/${this.props.gift_id}/thank_you`,
                                        }}    
                    joker           = {<div>
                        {this.props.thank_you_sent ? null :
                        <p className='small_text'><Link to='/receive' className='green_text'>Finish</Link></p>
                         }
                        
                    </div>}   
                />

            </div>
            );
        } else {
            return null;
        }
    }
}

export default Apply_gift;