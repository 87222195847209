import React from 'react';
import './Secondary_header.css';

class Secondary_header extends React.Component {
    constructor(props){
        super(props);

    }
    
    getClass(item) {
        if(item === this.props.active_header) {
            return 'selector_item cursor';
        } else {
            return 'selector_item unselected cursor';
        }
    }

    render () {
        return (
            <div className='top_selector_container'>
                <div className='top_secondary_container'>
                    <div 
                        className={this.getClass('offers')}
                        onClick={this.props.handleOffersClick}
                    >
                        <h2>Offers</h2>
                    </div>
                    <div 
                        className={this.getClass('articles')}
                        onClick={this.props.handleArticlesClick}
                    >
                        <h2>Articles</h2>
                    </div>
                </div>
            </div> 
        );
    }
}

export default Secondary_header;