import React from 'react';
import GGApi from '../../../../utils/GGApi';

import './Media_selector.css';

import camera from '../../../../images/icons/camera.png';
import video from '../../../../images/icons/video.png';

class Media_selector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null
        };

        this.fileUploadHandler = this.fileUploadHandler.bind(this);
        this.handlePicClick = this.handlePicClick.bind(this);
        this.handleVidClick = this.handleVidClick.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    fileUploadHandler(){
        let file = this.state.file;
        console.log(file);
        GGApi.upload_media(file, 'greetings-media').
        then(res => {
            console.log(res);
        })
    }

    handlePicClick(){
        this.fileInput.click();
    }
    
    handleVidClick(){
        if(this.props.file){
            return;
        } else {
            this.videoInput.click();
        }
    }

    handleRemove(){
        this.props.handleRemoveMedia();
        this.fileInput.value = null;
        this.videoInput.value = null;
    }

    render () {
        return (
        <div>
            <p className='form_label'>{this.props.label}</p>
            
            <div className='media_upload_wrapper'
                style={this.props.file ? {border: 'none', cursor: 'auto'} : null }>
                
                <div onClick={this.handlePicClick} 
                    className={this.props.file && (this.props.file.type === 'image/jpeg' || this.props.file.type === 'image/jpg' || this.props.file.type === 'image/png') ? 'media_uploader_sub_wrapper upload_picture_button_selected' : 'media_uploader_sub_wrapper'}>
                    <img 
                        src={camera}
                        className={'upload_picture_button'}
                        />
                </div>

                <div onClick={this.handleVidClick}
                    className={this.props.file && (this.props.file.type === 'video/quicktime' || this.props.file.type === 'video/mp4' || this.props.file.type === '.mov' || this.props.file.type === '.qt') ? 'media_uploader_sub_wrapper upload_picture_button_selected' : 'media_uploader_sub_wrapper'}>
                    <img 
                        src={video}
                        className={'upload_picture_button'}
                        />
                </div>

            </div>

            {this.props.file ? 
            
            <div className='media_upload_preview'>
                {this.props.file && (this.props.file.type === 'image/jpeg' ||this.props.file.type === 'image/jpg' || this.props.file.type === 'image/png') ?
                <img src={URL.createObjectURL(this.props.file)} className='uploaded_media_preview'/>
                :
                <video className='uploaded_media_preview' controls>
                    <source src={URL.createObjectURL(this.props.file)}/>
                </video>
                }
                <div className='media_upload_cancel'
                    >
                    <i className="material-icons" onClick={this.handleRemove}>close</i>
                </div>
            </div>
            
            :null}
            
            <input type="file" 
                onChange={this.props.handlePicChange} 
                accept='image/png, image/jpeg, image/jpg'
                style={{display: 'none'}}
                ref={fileInput => this.fileInput = fileInput}
                //capture='camera'
                />


            <input type="file" 
                onChange={this.props.handlePicChange} 
                accept='video/*'
                //accept='video/quicktime, video/mp4, .mov, .qt'
                style={{display: 'none'}}
                ref={videoInput => this.videoInput = videoInput}
                //capture='camera'
                />

            {/*<button onClick={this.fileUploadHandler}>Upload media</button>*/}
        </div>
        );
    }
}

export default Media_selector;