import React from 'react';
import './Gifting_thank_you.css';

import MasterButton from '../../../general_components/MasterButton/MasterButton.js';
import GG_confetti from '../../../general_components/GG_confetti/GG_confetti.js';

import srcd_image from '../../../../images/illustrations/gift.png';

class Gifting_thank_you extends React.Component {
    render () {
        if (this.props.sent !== true) {
            return null;
        }
        
        return (
           <div>
               <div className='blur_all'></div>
               <div className='overlay thank_you_popup'>

                    <div className='overlay_icon_and_headline_container'>
                        <i  className='material-icons' 
                            onClick={this.props.handleOverlayBack} >
                            {this.props.guest ? 'arrow_back_ios' : 'close' }
                        </i>  
                        
                        <h2> Thank you!</h2>
                        <div className='overlay_icon_and_headline_container_placeholder'></div>
                    </div>

                    <div className='overlay_text_container'>
                        <p>On behalf of {this.props.param1}, thank you for investing in {this.props.param2}’s future.</p>
                        <p>You will receive a gift confirmation shortly via email. Sit back and watch their gift grow, and lead them to a better future of education and financial security.</p>
                    </div>

                    <img className='overlay_img' src={srcd_image} />

                    <div className='overlay_buttons_container'>
                        <MasterButton 
                            buttonText='Send another gift' 
                            handleButtonClick={this.props.sendAnotherGift}
                            notSticky={true}
                        />

                        { 
                            // if guest flow, and create an account button
                            this.props.guest ? 
                            <MasterButton 
                                buttonText='Sign Up'
                                notSticky={true}
                                links={true}
                                linkTo='/new_account'
                            /> :  // if user flow back to gifting page button
                            <MasterButton 
                                buttonText='Finish'
                                notSticky={true}
                                links={true}
                                linkTo='/gifts'
                            />
                        }
                    </div>

               </div>

              
                
           </div>
        );
    }
}

export default Gifting_thank_you;