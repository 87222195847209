import React from 'react';

import { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';



export default function Plaid_oauth(props) {
    const config =  {
      token: props.token,
      onSuccess: props.onSuccess,
      onEvent: props.onEvent,
      onExit: props.onExit,
      receivedRedirectUri: props.receivedRedirectUri
    };
    
    const { open, ready } = usePlaidLink(config);

    // open Link immediately when ready
    useEffect(() => {
      if (ready) {
        open();
      }
    }, [ready, open]);

    // token must be the same token used for the first initialization of Link

    return <></>;
}