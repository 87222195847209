import React from 'react';
import './Dropdown_input.css';
import { Link } from 'react-router-dom';

/*
        dd_name='delivery_timezone'

        header={'Select timezone'}
        
        list={
            [
                {id: 1, title: "Eastern Time", tz: "America/New_York" },
                { id: 2, title: "Central Time", tz: "America/Chicago" },
                { id: 3, title: "Mountain Time", tz: "America/Denver" },
                { id: 4, title: "Pacific Time", tz: "America/Los_Angeles" }
            ]
            }

        handleItemClick={this.handleTimezoneChange}

        selected={this.state.timezone}
        
        highlight={false}
        
        lastItem={false}
        active={true}
*/

class Dropdown_input extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
           // selected: ''
        };

        this.toggleOpen = this.toggleOpen.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.renderDDList = this.renderDDList.bind(this);
        this.renderLastOption = this.renderLastOption.bind(this);
        this.renderOpenDD = this.renderOpenDD.bind(this);
        this.getHeaderClass = this.getHeaderClass.bind(this);
        this.getHeaderArrow = this.getHeaderArrow.bind(this);
        this.getHeaderText = this.getHeaderText.bind(this);

    }

    toggleOpen(){
        this.setState({open: !this.state.open})
    }

    handleItemClick(item){
        //this.setState({selected: item});
        this.props.handleItemClick(item, this.props.dd_name);
        this.toggleOpen();
    }


    getHeaderText() {
        if(!this.props.selected) {
            return (this.props.header);
        } else {
            return (this.props.selected.title);
        }

    }

    getHeaderClass() {
        let dd_header = 'dd-header';
        let dd_header_selected = 'dd-header-selected green_text';
        let highlighted = 'highlight';
        let open_list_header = 'dd-header-open';
        let inactive = 'dd-header-inactive'
        let result = dd_header;
        
        if(!this.props.active) {
            result += ' ' + inactive;
        }

        if(this.props.selected) {
            result += ' ' + dd_header_selected;
        } else if (this.props.highlight){
            result += ' ' + highlighted;
        };

        if(this.state.open){
            result += ' ' + open_list_header;
        }

        return result;
    }

    getHeaderArrow() {
        if (this.state.open) {
            return 'material-icons rotate_270'
        } else {
            return 'material-icons rotate_90'
        };
    }

    // RENDER METHODS

    renderOpenDD(){
        if(this.state.open) {
            return (
                <div className='dd-list-wrapper-absolute'>
                    <ul className=' dd-list-absolute'>
                        {this.renderDDList()}
                        {this.props.lastItem ? this.renderLastOption() : null}
                    </ul>
                </div>
                
            );
        } else {
            return null;
        };
    }

    renderLastOption(){
        return (
            <Link to={this.props.lastItemLink}>
                <li className='dd-list-item dd-list-item-last' key='0'>
                        <div className='dd-list-item-text'>
                            <p>{this.props.lastItemText}</p>
                        </div>
                        <div className='dd-list-item-img'> 
                            <i className='material-icons'>arrow_forward_ios</i>
                        </div>
                </li>
            </Link>
        )
    }

    renderDDList(){
        return this.props.list.map(item => {
            if(this.props.includesImgs) {
                return (
                    <li className='dd-list-item' key={item.id}  onClick={() => {this.handleItemClick(item)}}>
                            <div className='dd-list-item-image_container'>
                                <img src={item.image}></img>
                            </div>
                            <div className='dd-list-item-text_with_img'>
                                <p>{item.title}</p>
                            </div>
                            {/*
                            <div className='dd-list-item-secondary-text_with_img'>
                                <p className={'tiny_text '+ item.color}>{item.secondary}</p>
                            </div>
                            */}
                            <div className='dd-list-item-img_with_img'> 
                                {this.props.selected.title === item.title ? (<i className='material-icons-outlined green_icon'>done</i>) : null}   
                            </div>
                    </li>
                )
            } else {
                return (
                    <li className='dd-list-item' key={item.id}  onClick={() => {this.handleItemClick(item)}}>
                            <div className='dd-list-item-text'>
                                <p>{item.title}</p>
                            </div>
                            <div className='dd-list-item-img'> 
                                {this.props.selected.title === item.title ? (<i className='material-icons-outlined green_icon'>done</i>) : null}   
                            </div>
                    </li>
                )
            }
        });
    }

    render () {
        
        return (
        <div className='dd-wrapper input-dd-wrapper' >
        
            <div className={this.getHeaderClass()} onClick={this.props.active ? this.toggleOpen : null}>
                    <div className='dd-header-text'>
                        <p>{this.getHeaderText()}</p>
                    </div>
                    <div className='dd-header-arrow'>
                        <i className={this.getHeaderArrow()}>arrow_forward_ios</i>
                    </div>
            </div>

            {this.renderOpenDD()}
        
        </div>
        );
    }
}

export default Dropdown_input;

/*
if(!this.props.list.includes(this.state.selected)) {
    this.setState({selected: ''})
}
*/