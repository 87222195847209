import React from 'react';
import './Main_discover.css';
import { Link } from 'react-router-dom';

import Offers_main from '../../Offers/Offers_main/Offers_main.js';
import Articles_list from '../../Articles/Articles_list/Articles_list.js';

import logo from '../../../../images/logos/logo.png';


import config from '../../../../utils/config';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);

class Main_discover extends React.Component {
    componentDidMount(){
        // amplitude
        amplitude.getInstance().logEvent('discover: view main page')
    }

    render () {
        return (
            <main className='main_discover'>
                <Offers_main 
                    active_header={this.props.active_header}
                />
                <Articles_list 
                    active_header={this.props.active_header}
                />

                {/*<Link to='/' className='home_bottom_logo'>*/}
                <a href='https://www.greatestgiftapp.com' className='home_bottom_logo'>
                    <div className='home_bottom_logo'>
                        <img src={logo}/>
                    </div>
                </a>
                {/*</Link>*/}
                <div className='buffer'></div>
            </main>
        );
    }
}

export default Main_discover;