import React from 'react';
import './Detailed_plan_bottom.css';

import {Link} from 'react-router-dom';

import Financial_account from '../../Financial_account_components/Financial_account/Financial_account.js';

import Goal_graphics from '../Goal_graphics/Goal_graphics.js';
import Goal_details from '../Goal_details/Goal_details.js';

import calculator from '../../../utils/calculator.js';
import money from '../../../utils/money';

class Detailed_plan_bottom extends React.Component {
    render () {
        return (
            <div className='plan_bottom  goals'>

                <div className='headline  bold'><h2>{this.props.name}'s Savings</h2></div>

                <Goal_details 
                saved_to_date={this.props.saved_to_date}
                redeemed_gifts={this.props.redeemed_gifts}
                target_savings={this.props.target_savings}
                target_date={this.props.target_date}
                potential_profit={this.props.potential_profit}
                linked_acount={this.props.linked_acount}
                plan_id={this.props.plan_id}
                />
                
                
               {/*
                <Goal_graphics 
                saved_to_date={this.props.saved_to_date}
                target_savings={this.props.target_savings}
                target_date={this.props.target_date}
                potential_profit={this.props.potential_profit}
                />
                */}

                    <div className='goal_takeaway_container'>
                    <div className='goal_takeaway'>
                {/*
                        <p>You can reach your target goal on the target date by saving and investing <span className='money'>${money.addCommas(calculator.monthlyAddition(this.props.target_savings, this.props.potential_profit, (calculator.getYearFromDate(this.props.target_date)-calculator.todaysYear()), 0.07))}</span> every month</p>
                        */}
                        <Link to='/discover'><p className='bold green_text'>Discover great ways to save here</p></Link>
                    </div>
                </div>
                
                {/* Need to add the linked goals here */}

                {/*
                <div className='edit_goal green_text bold'><h2>Edit Goal</h2></div>
                */}

            </div>
        );
    }
}

export default Detailed_plan_bottom;