import React from 'react';
import './Plan_bottom.css';

import {Link} from 'react-router-dom';

import money from '../../../utils/money.js';


import config from '../../../utils/config';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);


class Plan_bottom extends React.Component {
    render () {
        return (
            <div className='plan_bottom'>

                {/* total saved row */}
                <div className='plan_bottom_buttons_row'>
                
                    <Link to={`/plan/${this.props.plan_id}`} className='plan_button left_button'>
                        <p className='large_text'>Total Saved: ${money.addCommas(this.props.saved_to_date)}</p>
                    </Link>
                    
                    {   this.props.page_active ? <div></div>
                        :
                        <div 
                        className='plan_button right_button'
                        onClick={() => this.props.share_button(this.props.plan_id, "link")}>

                            <p className='small_text share_button'>Share</p>
                            <i className='material-icons-outlined'>launch</i>  
                        </div>
                    }

                </div>

                {/* create / edit gifting page  */}
                <Link className='plan_bottom_buttons_row' to={`/plan/${this.props.plan_id}/gifting_page`}>
                   
                    <div  className='plan_button left_button'>
                        {   !this.props.page_active ?
                            <p className='large_text green_text'>Set Up Gifting Page</p>
                            :
                            <p className='large_text'>Edit Gifting Page</p>
                        }
                    </div>
                        
                    <div className='plan_button right_button'>
                        <i className={!this.props.page_active ? 'material-icons-outlined green_icon' : 'material-icons-outlined'}>arrow_forward_ios</i>  
                    </div>
                </Link>

                
                {/* share gifting page  */}

                {
                    this.props.page_active ?
                    <div className='plan_bottom_buttons_row' 
                        onClick={() => {this.props.share_button(this.props.plan_id, "gifting page"); amplitude.getInstance().logEvent('gifting page: click share button', {"page": window.location.pathname})}}>
                    
                        <div  className='plan_button left_button'>
                            <p className='large_text'>Share Gifting Page</p>
                        </div>
                            
                        <div className='plan_button right_button'>
                            <i className='material-icons-outlined green_icon'>link</i>  
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

export default Plan_bottom;