import React from 'react';
import './Received_gift.css';

import {Link} from 'react-router-dom';

import config from '../../../utils/config';

import amplitude from 'amplitude-js';
import dates from '../../../utils/dates';
amplitude.getInstance().init(config.amp_key);

/*
    gift status can be:
    new
    Redeemed
    failed

*/

class Received_gift extends React.Component {
    constructor(props) {
        super(props);

        this.displayRedeemText = this.displayRedeemText.bind(this);
    }
    
    displayRedeemText(){
        if(this.props.gift_status === 'Redeemed') {
            return ({display: 'none'});
        } ;
    }

    render () {
        return (
            <Link to={`/receive/${this.props.gift_id}`} onClick={() => amplitude.getInstance().logEvent('receive: click gift', {'gift status': this.props.gift_status})}>
                <div className='gift'>
                    <div className='gift_left_side'>
                        <p className='tiny_text gray'>{dates.jsDate(this.props.delivery_date)}</p>
                        <p className='l1'>Gift for: {this.props.giftTo}</p>
                        <p className='l2'>From: {this.props.gift_from}</p>
                        <p className='small_text italics'>"{this.props.giftGreeting}"</p>
                    </div>
                    <div className='gift_right_side'>

                        <p className='tiny_text gray'>&nbsp;</p>

                        <p className='money l1'>{this.props.giftAmount}</p>
                        
                        {
                        this.props.gift_status === 'failed' ?
                        <p style={this.displayRedeemText()} className='small_text gg_red bold l2'>!</p>
                        :
                        this.props.gift_status === 'Redeemed' && !this.props.thank_you_sent ?
                        <Link to={`/receive/${this.props.gift_id}/thank_you`}>
                            <p className='small_text green_text bold l2'>Send Thanks</p>
                        </Link>
                        :
                        <p style={this.displayRedeemText()} className='small_text green_text bold l2'>Redeem</p>
                        }
                        
                    </div>
                </div>
            </Link>
        );
    }
}

export default Received_gift;


/*
    <Link to={`/receive/${this.props.gift_id}`}>
        <div className='gift'>
            <p className='l1'>Gift for {this.props.giftTo}<span className='money'>{this.props.giftAmount}</span></p>
            {this.props.gift_status === 'failed' ?
            <p className='small_text l2'>{this.props.giftGreeting}<span style={this.displayRedeemText()} className='gg_red bold'>!</span></p> :
            <p className='small_text l2'>{this.props.giftGreeting}<span style={this.displayRedeemText()} className='green_text bold'>Redeem</span></p>
            }
        </div>
    </Link>
*/