import React from 'react';
import './Login_page.css';

import ReCAPTCHA from "react-google-recaptcha";
import { Link, withRouter } from 'react-router-dom';

import {Helmet} from "react-helmet";
import GGApi from '../../../utils/GGApi.js';

import signup_page_picture from '../../../images/illustrations/gifts_w_confetti.png';
import gg_logo from '../../../images/logos/logo.png';

import config from '../../../utils/config.js';
import GG_loader from '../../general_components/GG_loader/GG_loader';
import Overlay_popup from '../../general_components/Overlay_popup/Overlay_popup';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);

function delayRandom(maxSecs){
    let time = Math.floor(Math.random()*maxSecs) * 1000;
    
    console.log(time);
    return new Promise(resolve => setTimeout(resolve, time));
};

const recaptchaRef = React.createRef();

class Login_page extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            loading: false,

            // login inputs
            disabled: false,
            username: '',
            user_first_name: '',
            username_input: '',
            password_input: '',
            captcha_count: 0,
            captcha_token: '',

            // failed email confirmation messages
            open_overlay: false,
            email_confirmation_message: ''
        }

        this.handleEnter = this.handleEnter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        
        this.renderCaptcha = this.renderCaptcha.bind(this);
        this.handleRecaptcha = this.handleRecaptcha.bind(this);
        this.resetCaptcha = this.resetCaptcha.bind(this);
    }
    
    componentDidMount() {
        this.props.removeNavBar();
        this.props.removeHeader();

        // messages about email verification processes
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('email') && urlParams.get('email') === 'claimed'){
            this.setState({open_overlay: true, email_confirmation_message: 'This email is already associated with a different user. Please try a different email.'})
        } else if(urlParams.get('email') && urlParams.get('email') === 'unconfirmed'){
            this.setState({open_overlay: true, email_confirmation_message: 'There was an issue when trying to validate the email. Please try again, or contact our support team.'})
        };
    }

    componentWillUnmount() {
        this.props.restoreNavBar();
        this.props.restoreHeader();
    }

    // Handle log in methods
    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }

    handleEnter(event) {
        if(event.keyCode == 13 ) {
            this.handleSignIn();
            event.preventDefault();
        };
    }

    handleSignIn() {
        amplitude.getInstance().logEvent('login: click log in');
        if(this.state.disabled) {return;};
        // doesn't work if captcha is required, and not present:
        if(this.state.captcha_count >= 2 && !this.state.captcha_token){
            alert('Please fill out the captcha requirement.'); 
            return;
        };
        // doesn't work if the username or password inputs are empty
        if (!this.state.username_input) {return;};
        if (!this.state.password_input) {return;};
        
        //disable the button for duration of authentication request
        // start spinner until answer
        this.setState({disabled: true, loading: true});

        //api call to get the logged in state
        let login_attempt;
        if (this.state.captcha){
            login_attempt = {   username: this.state.username_input.toLowerCase(), 
                                password: this.state.password_input, 
                                captcha_token: this.state.captcha_token
                            };
        } else {
            login_attempt = {   username: this.state.username_input.toLowerCase(), 
                                password: this.state.password_input
                            };
        }
        
        delayRandom(3).
        then(() => {
            GGApi.log_in(login_attempt).then(result => {

                if(result && result.token_2fa){
                    amplitude.getInstance().logEvent('login: go to 2fa');
                    localStorage.setItem("data_for_2fa", JSON.stringify(result));

                    this.props.history.push('/login/verify');
                }
                if(result && result.accessToken){
                    localStorage.setItem("user", JSON.stringify(result));
                    amplitude.getInstance().logEvent('login: successful log in');
                    amplitude.getInstance().setUserId(result.userId);
                };

                // reset the captcha for every attempt
                if(this.state.captcha_token){
                    this.resetCaptcha(recaptchaRef);
                }

                if(result && result.accessToken) {
                    if(this.props.gift_link){
                        this.props.history.push('/give-a-gift'+this.props.gift_link);    
                    } else {
                        this.props.history.push('/home');
                    }

                // if failed an attempt:
                } else if (result.captcha && !(result.msg === 'error logging in')){
                    let new_captcha_count = this.state.captcha_count + 1;
                    this.setState({ 
                        loading: false,
                        username_input: '',
                        password_input: '',
                        disabled: false,
                        captcha_count: new_captcha_count,
                        captcha_token: ''
                    });
                    alert(result.msg);

                } else if (result.msg && !(result.msg === 'error logging in')) { // other err ? 
                    this.setState({ 
                        loading: false,
                        username_input: '',
                        password_input: '',
                        disabled: false,
                        captcha_token: ''
                    });
                    alert(result.msg);
                } else {

                };
            });
        })
        
    }


    // captcha

    renderCaptcha(){
        if(this.state.captcha_count < 2){
            return null;
        } else {
            return (
                <div className='recaptcha_container'>
                    <ReCAPTCHA
                    sitekey={config.recaptcha_siteKey}
                    onChange={this.handleRecaptcha}
                    ref={recaptchaRef}
                        />
                </div>
            )
        }
    }

    handleRecaptcha(value){
        this.setState({captcha_token: value})
    }

    resetCaptcha(ref){
        ref.current.reset();
    }
    
    // render 

    render () {
        return (
        
        <div className='light_green_background'>

            <Helmet>
                <title>Greatest Gift | Login, create an account, and send monetary gifts</title>
                <meta property="og:title" content='Greatest Gift | Login, create an account, and send monetary gifts'/>
                <link rel="icon" href="/static/media/logo_var_2.3a7e8fdf.png" />
                <link rel="canonical" href="https://app.greatestgiftapp.com/"/>
                <meta name="description"
                    content='Sign up and sign in to Greatest Gift, send and receive monetary gifts for children`s long term savings.' 
                />
                <meta property="og:description" 
                    content='Sign up and sign in to Greatest Gift, send and receive monetary gifts for children`s long term savings.'
                />
            </Helmet>

            <div className='login_illustration_container'>
                <div>
                    {/*<Link to='/'>*/}
                    <a href='https://www.greatestgiftapp.com'>
                    <img className='login_logo' src={gg_logo} />
                    {/*</Link>*/}
                    </a>
                </div>
                
                <div>
                <img className='login_illustration' src={signup_page_picture} />
                </div>
                
                
            </div>
        {/* <h1 style={{fontSize: '2rem', margin: '1rem auto'}}>Greatest Gift</h1> */}
            
            <div className='login_container'>  
                <form>
                    <div className='login_username_input login_input'>
                        <p className='tiny_text'>Username</p> 
                        <input 
                            type='text' 
                            id='username_input' 
                            onChange={this.handleChange}
                            name='username_input'  
                            value={this.state.username_input}
                            className='input_for_login'
                            />
                    </div>

                    <div className='login_password_input login_input'>
                        <p className='tiny_text'>Password</p> 
                        <input 
                            type='password' 
                            id='password_input' 
                            onChange={this.handleChange}
                            name='password_input'  
                            value={this.state.password_input}
                            className='input_for_login'
                            onKeyDown={this.handleEnter}
                            />
                    </div>
                </form>
                
                {this.renderCaptcha()}

                <div className='forgot_login_info'>
                    <Link to='/settings/recover' onClick={() => amplitude.getInstance().logEvent('login: click forgot password')}>
                    <p className='tiny_text secondary_dark'>Forgot username or password?</p>
                    </Link>
                </div>
                
                {   this.state.loading ?
                    <GG_loader mini_loader={true}/>
                :   <button className='signIn' 
                            onClick={this.handleSignIn} 
                            disabled={this.state.disabled}>
                        Log In
                    </button>
                }
                
                
                <div className='or'><p className='tiny_text'>- or -</p></div>
                <Link to='/new_account' onClick={() => amplitude.getInstance().logEvent('signup: click sign up', {"page": 'login'})}>
                    <button className='signIn'>Sign Up</button>
                </Link>
                
                {/*  bottom three buttons  */}
                <div className='additional_sign_in_buttons'>
                    <Link to={'/give-a-gift'} className='additional_sign_in_button' onClick={() => amplitude.getInstance().logEvent('gifting: click give a gift', {"page": "login"})}>
                        <p>Give a gift now!</p>
                    </Link>
                    <Link to={'/legal'} className='additional_sign_in_button border_left_for_button'>
                        <p>Terms</p>
                    </Link>
                    <Link to={'/legal'} className='additional_sign_in_button border_left_for_button'>
                        <p>Privacy</p>
                    </Link>
                </div>

            </div>
            
            <Overlay_popup 
                open            = {this.state.open_overlay}
                closer          = {true}
                handleClose     = {() => this.setState({open_overlay: false})}
                overlay_title   = 'Email Confirmation'
                wide            = {false}
        
                body_1          = {this.state.email_confirmation_message}
            />
        </div>
        
        
        );
    }
}

export default withRouter(Login_page);