/*
    {   id: 1, 
        title: '',
        image: '',
        account_format: '',
        link: ''
    }

    links take users to the institution's website to retreive their account number
*/

import logo_chase from '../../../../images/financial_institution_logos/logo_bank_chase.png';

const banks = [
    {   id: 1, 
        title: 'Chase',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_bank_chase.png',
        account_pattern: '', 
        link: 'https://www.chase.com/personal/checking/routing-numbers'
    }
]


;

export default banks;

