import React from 'react';
import './Accordion.css';
import { Link } from 'react-router-dom';
import Accordion_item from './Accordion_item';

/*
    takes a list with couples - header, and content
    return a clickable accordian with the headers and content filled in
*/

class Accordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.renderAccordionItems = this.renderAccordionItems.bind(this);
    }

    renderAccordionItems() {
        let i = 0;
        return this.props.list.map(item => {
            i++;
            return <Accordion_item 
                        header={item.header}
                        content={item.content}
                        id={item.id}
                        key={i}
                    />
            });
    }

    render () {
        
        return (
        <div className='accordion_list_container'>
            <div className='accordion_list'>
                {this.renderAccordionItems()}
            </div>
        </div>
        );
    }
}

export default Accordion;
