import { format } from "date-fns";

const dates = {

    // turn a javascript date string into a sql date string
    // input is '12/25/2020' 
    // expected output is '2020-12-25'
    sqlizeDate(date) {
        let day = date.slice(3,5);
        let month = date.slice(0,2);
        let year = date.slice(6);
        let newDate = year+'-'+month+'-'+day;
        return newDate;
    },

    // turn a mysql date string into a javascript date string
    // input is '2020-12-25' 
    // expected output is '12/25/2020'
    jsDate(date) {
        let day = date.slice(8,10);
        let month = date.slice(5,7);
        let year = date.slice(0,4);
        let newDate = month+'/'+day+'/'+year;
        return newDate;
    },

    // validate the text input date
    // input is a string: '12/25/2020' 
    // expected output is true if it is a valid date between the years 1900-2200, and false otherwise
    validateDate(date){
        let datePattern = /^\d{1,2}\/\d{1,2}\/\d{4}$/ ;
        let parts = date.split("/");
        let day = parseInt(parts[1], 10);
        let month = parseInt(parts[0], 10);
        let year = parseInt(parts[2], 10);

        if(!(datePattern.test(date))) {
            return false;
        };
                
        // Check the ranges of month and year
        if(year < 1900 || year > 2200 || month === 0 || month > 12) {
            return false;
        };

        let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
        // Adjust for leap years
        if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }
            
        // Check the range of the day
        if (!(day > 0 && day <= monthLength[month - 1])) {
            return false;
        };

        // if no issues found, return true
        return true;
    },

    // check that a valid date is at most one year from today
    // input is a string: '12/25/2020' 
    // expected output is true if it is a valid date, and false otherwise
    checkOneYear(date){
        
        // get yesterday's date and one year from today
        
        //there is a problem when using the next line internationally. Fixed with different solution  
        //let [month, day, year]    = ( new Date() ).toLocaleDateString().split("/");

        // different solution to get date from today
        let d = new Date();
        let month = String(d.getMonth()+1);
        let day = String(d.getDate());
        let year = String(d.getFullYear());


        if (month.length === 1) {
            month = '0'+month;
        };
        /* methods for yesterday (old)
        
        let lastDay = String(Number(day)-1)
        if (lastDay.length === 1) {
            lastDay = '0'+lastDay;
        };
        // yesterday's year was year-1 if today is Jan first
        if(day === '1' && month === '01'){
            year = String(d.getFullYear() - 1);
        }
        let yesterday = year+'-'+month+'-'+lastDay;
        */
        if (day.length === 1) {
            day = '0'+day;
        }
        
        let today = year+'-'+month+'-'+day;
        year = String(Number(year)+1)
        let yearPlusOneYear = year+'-'+month+'-'+day;

        
        //transform the input into a format that we can compare lexicographically
        let sqlzedDate = dates.sqlizeDate(date);
        
        // check that input date is in range 
        // if (sqlzedDate >= yesterday && sqlzedDate <= yearPlusOneYear) {  // Yesterday
        if (sqlzedDate >= today && sqlzedDate <= yearPlusOneYear) {  // today
            return true;
        } else {
            return false;
        };
    },

    // check that a valid date is a future date
    // input is a string: '12/25/2020' 
    // expected output is true the date is in the future, otherwise false
    checkFutureDate(date){
        
        // get yesterday's date
        // let [month, day, year]    = ( new Date() ).toLocaleDateString().split("/")
        

        // different solution to get date from yesterday
        let d = new Date();
        let month = String(d.getMonth()+1);
        let day = String(d.getDate());
        let year = String(d.getFullYear());

        if (month.length === 1) {
            month = '0'+month;
        };
        let lastDay = String(Number(day)-1)
        if (lastDay.length === 1) {
            lastDay = '0'+lastDay;
        };
        // yesterday's year was year-1 if today is Jan first
        if(day === '1' && month === '01'){
            year = String(d.getFullYear() - 1);
        }

        let yesterday = year+'-'+month+'-'+lastDay;
        
        //transform the input into a format that we can compare lexicographically
        let sqlzedDate = dates.sqlizeDate(date);
        
        // check that input date is after yesterday 
        if (sqlzedDate >= yesterday) {
            return true;
        } else {
            return false;
        };
    },
    
    // turn military time to AM - PM time with no seconds
    // input is '19:59:59' 
    // expected output is '07:59 PM'
    deMilitarizeTime(time) {
        let hour = time.slice(0,2);
        let numberHour = Number(hour);
        let minutes = time.slice(3,5);
        let ampm = '';

        if (hour === '00') {
            hour = '12';
            ampm = 'AM';
        };
        if (numberHour > 21) {
            numberHour = numberHour - 12;
            hour = String(numberHour);
            ampm = 'PM';
        } else if (numberHour > 12 && numberHour < 22) {
            numberHour = numberHour - 12;
            hour = '0'+String(numberHour);
            ampm = 'PM';
        } else if (numberHour === 12) {
            ampm = 'PM';
        } else {
            ampm = 'AM';
        };

        let usTime = hour+':'+minutes+' '+ampm;
        return usTime;
    },

    // turn  AM - PM with no seconds time to  military time (rounded seconds to zero)
    // input is '07:59 PM'
    // expected output is '19:59:00' 
    militarizeTime(time){
        let hour = time.slice(0,2);
        let numberHour = Number(hour);
        let minutes = time.slice(3,5);
        let ampm = time.slice(6,8);

        if (hour === '12' && ampm === 'AM') {
            hour = '00';
        } else if (hour === '12' && ampm === 'PM') {
            hour = '12';
        } else if (ampm === 'PM'){
            numberHour = numberHour + 12;
            hour = String(numberHour);
        }


        let military_time = hour + ':' + minutes + ':00'
        return military_time;
    },

    // get the year as a NUMBER from a js string 
    // input is a string: '12/25/2020' 
    // expected output 2020
    getYearFromDate(date){
        let year = Number(date.slice(6));
        return year;
    },

    // get the month as a NUMBER from a js string 
    // input is a string: '12/25/2020' 
    // expected output 12
    getMonthFromDate(date){
        let month = Number(date.slice(0,2));
        return month;
    },

    // get today's year as a number
    todaysYear() {
        let d = new Date();
        let year = d.getFullYear();
        return year;
    },

    today() {
        let today = new Date();
        // daylight savings adjustments!
        if(today.getUTCHours() < 5 ){
            today.setDate(today.getUTCDate() - 1) 
        }

        // adjust for month 0 (January)
        let month = (1 + today.getMonth()).toString();
        // add the 0 to the month string for single digit months
        month = month.length > 1 ? month : '0' + month;
        
        let day = today.getDate().toString();
        // add the 0 to the month string for single digit dates
        day = day.length > 1 ? day : '0' + day;

        let year = today.getFullYear();
        
        let response = `${month}/${day}/${year}`;
        return response;
    },

    tomorrow() {
        let today = new Date();
        if(today.getUTCHours() < 5 ){
            today.setDate(today.getUTCDate() - 1) 
        }
        let tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1);

        let month = (1 + tomorrow.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        
        let day = tomorrow.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let year = tomorrow.getFullYear();
        
        let response = `${month}/${day}/${year}`;
        return response;
    },

    date_plus_day(date){
        let new_date = new Date(date);
        new_date.setDate(new_date.getDate() + 1);
        new_date = format(new_date, "MM/dd/yyyy");
        return new_date;
    },

    nextWeek() {
        let today = new Date();
        let nextWeek = new Date(today)
        nextWeek.setDate(nextWeek.getDate() + 7);

        let month = (1 + nextWeek.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        
        let day = nextWeek.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let year = nextWeek.getFullYear();
        
        let response = `${month}/${day}/${year}`;
        return response;
    },


    nextYear() {
        let today = new Date();
        let nextYear = new Date(today)
        nextYear.setDate(nextYear.getDate() + 365);

        let month = (1 + nextYear.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        
        let day = nextYear.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let year = nextYear.getFullYear();
        
        let response = `${month}/${day}/${year}`;
        return response;
    },


    next5Years() {
        let today = new Date();
        let next5Years = new Date(today)
        next5Years.setDate(next5Years.getDate() + (365*5) + 1 );

        let month = (1 + next5Years.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        
        let day = next5Years.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let year = next5Years.getFullYear();
        
        let response = `${month}/${day}/${year}`;
        return response;
    },

    // get day of week from javascript format date  
    // input in MM/DD/YYYY
    // output in day of week
    dayOfWeek(date){
        let x = new Date(date);
        let dayNumber = x.getDay();

        switch (dayNumber) {
            case 0:
                return 'Sun'
            case 1:
                return 'Mon'
            case 2:
                return 'Tue'
            case 3:
                return 'Wed'
            case 4:
                return 'Thu'
            case 5:
                return 'Fri'
            case 6:
                return 'Sat'
        }
    },

    // get current military time
    current_time(){
        let date = new Date();
        let hours = date.getUTCHours() - 4;
        if ( hours < 0) {
            hours = hours + 24;
        }
        hours = String(hours);
        if (hours.length === 1) {
            hours = '0'+hours;
        }
        
        let minutes = date.getUTCMinutes();
        minutes = String(minutes);
        if(minutes.length === 1) {
            minutes = '0'+minutes;
        }

        let seconds = date.getUTCSeconds();
        seconds = String(seconds);
        if(seconds.length === 1) {
            seconds = '0'+seconds;
        }

        let time = hours + ':' + minutes + ':' + seconds;
        return time;
    }

};

export default dates;
