import React from 'react';
import './Forgot_password_reset.css';

import GGApi from '../../../utils/GGApi.js';

import GG_loader from '../../general_components/GG_loader/GG_loader.js';
import MasterButton from '../../general_components/MasterButton/MasterButton.js';


function delay(time){
    return new Promise(resolve => setTimeout(resolve, time));
};

class Forgot_password_reset extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            buttonActive: false,

            user_password: '',
            user_password_verification: '',

            show_password: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount(){
        // check the token to verify it is valid, and the person can access this page to change the password  
        let token = this.props.match.params.t;      
        let validToken = false;
        GGApi.checkPwdResetToken(token).then(response => {
            validToken = response;
            // if token is not valid, block this page (redirect to different page)
            if (validToken === false) {
                window.location.href = '/';
            } else if (validToken === true) {
                // token is valid. Allow access, enable button
                console.log('token validated. Allowed to reset password.');
                this.setState({loading: false, buttonActive: true});
            };
        });
        
    }

    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }

    handleButtonClick(){
        // disable if button is inactive
        if(!this.state.buttonActive){
            return;
        }
        // validate the password selections
        if (this.state.user_password.length < 8) {
            alert('Please enter a password with at least 8 characters.');
            this.setState({loading: false});
        } else if (!(this.state.user_password === this.state.user_password_verification)) {
            alert ('Please make sure the passwords in both fields match.')
            this.setState({loading: false});
        } else {
            this.setState({loading: true});
            // inputs validated. Send the new password to db
            let token = this.props.match.params.t;   
            GGApi.create_new_password(token, this.state.user_password)
            .then(response => {
                if(response.message === 'password updated'){
                    alert('Your password was successfully changed!');
                    this.setState({loading: false});
                    window.location.href = '/';
                } else {
                    alert('Your password reset encountered an error. Please try again!');
                    this.setState({loading: false});
                }
            });
        }
    }

    toggle(){
        this.setState({show_password: !this.state.show_password});
    }

    render () {
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        return (
        <main>

            <h2>Reset Password:</h2>
            
            <form>
                <p className='form_label'>Please enter a new password </p>
                    <input 
                        type={this.state.show_password ? 'text' : 'password' }
                        id='user_password' 
                        name='user_password'
                        onChange={this.handleChange}
                        placeholder='Ab123456!' 
                        value={this.state.user_password}
                        autoComplete='off'
                        maxLength='50'
                        />

                    <p className='form_label'>Verify your new password</p> 
                    <input 
                        type={this.state.show_password ? 'text' : 'password' }
                        id='user_password_verification' 
                        name='user_password_verification' 
                        onChange={this.handleChange}
                        placeholder='Ab123456!' 
                        value={this.state.user_password_verification}
                        autoComplete='off'
                        maxLength='50'
                        />
            </form>


            <div className='disclaimer_checkbox_container'>
                {this.state.show_password ?
                    <i onClick={this.toggle} className='material-icons-outlined disclaimer_checkbox'>check_box</i> :
                    <i onClick={this.toggle} className='material-icons-outlined disclaimer_checkbox'>check_box_outline_blank</i>}
                <p className='disclaimer_checkbox_text tiny_text'>Show password</p>
            </div>


            <MasterButton   buttonText='Reset password'
                            handleButtonClick={this.handleButtonClick}
                            notSticky={true}
                            inactive_button={!this.state.buttonActive}
                            />
        </main> 
        );
    }
}

export default Forgot_password_reset;