import React from 'react';
import './Financial_account.css';

import {Link} from 'react-router-dom';

class Financial_account extends React.Component {
    constructor(props) {
        super(props);
    }
    
   handleClick(account_id) {
    this.props.handleAccountClick(account_id);
   }

   colorSelectedGreen(){
       if (this.props.account_id === this.props.selected_account) {
           let styleObject = {backgroundColor: '#B5F1DB'};
           return (styleObject);
       };
   }

    render () {
        if(this.props.links === true) {
            return (
                <div className={this.props.container === 'narrow' ?  'narrow_linked_account_container' : 'regular_linked_account_container'}>
                    
                    {/* individual account components */}

                        <Link to={this.props.linksTo} className='financial_account'>

                            <div className='image_container'>
                                <i className='material-icons-outlined'>account_balance</i>
                            </div>
                            <div className='financial_text_container'>
                                <p>Chase Bank</p>
                                <p>xxxx-5555</p>
                            </div>
                            <div className='account_fees'><p className='small_text'>No fee</p></div>
                            <div className='check_mark image_container'>
                                <i className='material-icons'>arrow_forward_ios</i>
                            </div>
                        </Link>    
                </div>
                
        )} else {
            return (
                <div className={this.props.container === 'narrow' ?  'narrow_linked_account_container' : 'regular_linked_account_container'}>
                    
                    {/* individual account components */}
                    
                        <div className='financial_account' 
                            onClick={this.handleClick.bind(this, this.props.account_id)}
                            style={this.colorSelectedGreen()}>
                                
                            <div className='image_container'>
                                <i className='material-icons-outlined'>account_balance</i>
                            </div>
                            <div className='financial_text_container'>
                                <p>Chase Bank</p>
                                <p>xxxx-5555</p>
                            </div>
                            <div className='account_fees'><p className='small_text'>No fee</p></div>
                            <div className='check_mark image_container'>
                                {this.props.account_id === this.props.selected_account ? <i className='material-icons green_icon'>done</i> : null}
                            </div>
                        </div>    
                </div>
                
        )
        };
        
    }
}

export default Financial_account;