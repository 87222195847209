import React from 'react';
import './Redemption_details.css';
import GGApi from '../../../utils/GGApi.js';

class Redemption_details extends React.Component { 
    render () {
        if (this.props.received_gift_status === 'new') {
            return null;
        }
        return (
        <div>
            <h2>Redemption Details</h2>

            <div className='two_column_text_container'>

                <div className='two_column_line'>
                    <div className='left_column_text'><p>Applied for:</p></div>
                    <div className='right_column_text'><p>{this.props.gift_redeemed_for_child_account}</p></div>
                </div>

                <div className='two_column_line'>
                    <div className='left_column_text'><p>Applied to:</p></div>
                    <div className='right_column_text'><p>{this.props.redeemed_at_financial_account}</p></div>
                </div>

                <div className='two_column_line'>
                    <div className='left_column_text'><p>Redeemed on:</p></div>
                    <div className='right_column_text'><p>{this.props.redemption_date}</p></div>
                </div>

                <div className='two_column_line'>
                    <div className='left_column_text'><p>Redeemed status:</p></div>
                    <div className='right_column_text'><p style={{textTransform: 'capitalize'}}>{this.props.redeem_status}</p></div>
                </div>

            </div>

        </div>
        );
    }
}

export default Redemption_details;
