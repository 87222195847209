import React from 'react';
import './Account_step_4.css';

class Account_step_4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_password: false
        }

        this.toggle = this.toggle.bind(this);
        //this.handleChangeAndCheckUnique = this.handleChangeAndCheckUnique.bind(this);
    }
/*
    handleChangeAndCheckUnique(event, type) {
        this.props.handleChange(event);
        this.props.checkUnique(event, type);
    }
*/
    
    toggle(){
        this.setState({show_password: !this.state.show_password});
    }

    render () {
        if (this.props.currentStep !== 4) {
            return null;
        }
        
        
        return (
           <div>
                <h2 className='center'>Choose username and password</h2>

                <form>
                    <p className='form_label'>Username</p>
                    <input 
                        type='text' 
                        id='username' 
                        onChange={this.props.handleChange} 
                        onBlur={(event) => {this.props.checkUnique(event, 'username')}}
                        name='username' 
                        placeholder='userName' 
                        value={this.props.username}
                        autoFocus 
                        autoComplete='off'
                        maxLength='50'
                        />

                    <p className='form_label'>Password</p> 
                    <input 
                        type={this.state.show_password ? 'text' : 'password' }
                        id='user_password' 
                        onChange={this.props.handleChange}
                        name='user_password' 
                        placeholder='Ab123456!' 
                        value={this.props.user_password}
                        autoComplete='off'
                        maxLength='50'
                        />

                    <p className='form_label'>Verify Password</p> 
                    <input 
                        type={this.state.show_password ? 'text' : 'password' }
                        id='user_password_verification' 
                        onChange={this.props.handleChange}
                        name='user_password_verification' 
                        placeholder='Ab123456!' 
                        value={this.props.user_password_verification}
                        autoComplete='off'
                        maxLength='50'
                        />
                </form>

                <div className='disclaimer_checkbox_container'>
                    {this.state.show_password ?
                        <i onClick={this.toggle} className='material-icons-outlined disclaimer_checkbox'>check_box</i> :
                        <i onClick={this.toggle} className='material-icons-outlined disclaimer_checkbox'>check_box_outline_blank</i>}
                    <p className='disclaimer_checkbox_text tiny_text'>Show password</p>
                </div>
                
                
           </div>
        );
    }
}

export default Account_step_4;
