import React from 'react';
import './Link_destination_2.css';

class Link_destination_2 extends React.Component {
    render () {   
        if (this.props.currentStep !== 2) {
            return null;
        };
        return (
           <div>
                <h2 className='center'>Additional Identification Information</h2>

                <form>
                    <p className='form_label'>Your Date of Birth</p> 
                    <input 
                        type='text' 
                        name='dob' 
                        id='dob' 
                        onChange={this.props.handleDateChange}
                        value={this.props.dob}
                        maxLength='10'
                        minLength='10'
                        placeholder='MM / DD / YYYY'
                        autoComplete='off'
                         />

                    <p className='form_label'>Last 4 Digits of Social Security Number</p> 
                    <p className='form_label inline_label'>XXX-XX-</p>
                    <input 
                        type='password' 
                        name='digits' 
                        id='digits' 
                        onChange={this.props.handleChange}
                        placeholder='XXXX' 
                        value={this.props.digits}
                        autoComplete='off'
                        maxLength='4'
                        disabled={this.props.disabled}
                        className='inline_input'
                        />
                </form>

                <p className='small_text'>In order to verify account ownership, Greatest Gift partners with Stripe and is required to provide some additional identification details.
                    <br></br>This information will be stored on Stripe’s secure platform, and Greatest Gift will never store, share, or use this information.  
                </p>

           </div>
        );
    }
}

export default Link_destination_2;