import React from 'react';
import './Offers_page.css';
import {Helmet} from "react-helmet";

import Offers_list_container from './Offers_list_container/Offers_list_container.js';

import GGApi from '../../../../utils/GGApi.js'

// temporary until DB is connected via api
import plans from './Offers/Offer_529Plans.js';
import savings from './Offers/Offer_savings.js';
import investments from './Offers/Offer_investment.js';

import Overlay_popup from '../../../general_components/Overlay_popup/Overlay_popup';
import Home_Page_Footer from '../../../Landing_page/Home_Page_Footer';


import config from '../../../../utils/config';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);

class Offers_page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tokenIsValid: true,
            guest: '',
            open_overlay: false,

            '529plans': [],
            'kidssavings': [],
            'investments': [],
            kidstech: []
            /*
            {
                id: ,
                affiliate_company: ,
                affiliate_logo: ,
                offer_summary: ,
                offer_link:
            }
            */

        }
    }

    // check - user or guest flow. makes it possible for guests to see discover section
    // should probably have an api call here, to pull offers from the database and push them to the offers list
    componentDidMount() {
        //amplitude
        amplitude.getInstance().logEvent('discover: view offers', {'category': this.props.category});

        
        this.setState({
            '529plans': plans,
            'kidssavings': savings, 
           'investments': investments 
        })

        let user = JSON.parse(localStorage.getItem('user'));
        let validToken = '';
        // if there's a token, check that it's valid
        if (user) {
            GGApi.checkToken().then(response => {
                validToken = response;
                // if token is not valid, log out
                if (validToken === false) {
                    this.setState({tokenIsValid: false})

                } else if (validToken === true) {
                    // token is valid. set flow as signed in user
                    console.log('user validated. Discover as a user:');
                    this.setState({guest: false})
                };
            });
        
            // if there is no token, continue as a guest
        } else if (!user) {
            console.log('Discover as guest');
            this.setState({guest: true});
            this.props.removeNavBar();
        };
    }

    componentWillUnmount() {
        this.props.restoreNavBar();
    }
    
    render_page_intro(){
        if(this.props.category === 'kidssavings') {
            return (
                <div className='offer_page_intro'>
                    <h2>Discover great savings accounts for your kids.</h2>
                    <p>Setting up a saving account for your kid is a great way to set aside funds for your child's future, and avoid confusing the funds with your own. </p>
                    <p>You can use these savings accounts for:</p>
                    <ul>
                        <li>A digital piggy bank for short and medium term goals</li>
                        <li>Building a rainy day fund</li>
                        <li>Teaching young kids about banking and finance</li>
                    </ul>
                </div>
            )
        } else if (this.props.category === '529plans'){
            return (
                <div className='offer_page_intro'>
                    <h2>Discover how to invest in your child's future, tax free</h2>
                    <p>A 529 plans allow you to save and <span className='bold'>invest for K-12 tuition and higher education in a tax free way,</span> in a similar way to retirement investment accounts.</p>
                    <h2>Factors to consider when choosing a 529 plan:</h2>
                    <ul>
                        <li>State tax benefits and incentives (look at your state's plans first!)</li>
                        <li>The percentage fees of the total invested assets</li>
                        <li>Other plan fees, like an annual fee or opening fee</li>
                        <li>Investment options available with the plan</li>
                    </ul>
                    <p>Choosing a plan from so many options can be intimidating, but any plan is better than no plan. Starting to save early can make a big difference, and opening an account can take as little as 10 minutes.</p>
                    <p>Here are a few of our favorites that have an easy online enrollment option:</p>
                </div>
            )
        } else if (this.props.category === 'investments') {
            return (
                <div className='offer_page_intro'>
                    <h2>Discover how to invest for your child's future, your way</h2>
                    <p>A brokerage account allows you to buy and sell a variety of investments, such as stocks, bonds, and mutual funds, and save for any use, no strings attached (unlike a 529 plan).</p>
                    <p>You can even open a custodial brokerage account, an account that is owned by your child but remains in your control until the child becomes an adult. The account's earnings will be taxed on the child's tax rate, which is usually lower than yours.</p>
                    
                    <h2>Factors to consider when choosing an online brokerage account as a parent:</h2>
                    <ul>
                        <li>Account opening and investment minimums</li>
                        <li>Fees - opening fees, monthly and annual fees, and trade commisions</li>
                        
                        <li>The selection of investment options available at the brokerage (should match your investment strategy and knowledge)</li>
                        <li>Educational and training tools offered for new investors</li>
                        <li>Fractional shares trading (allows trading in your budget) </li>
                        <li>Customer service</li>
                    </ul>
                    <p>For first time investors, consider buying an ETF with your new brokerage account. An ETF is like a basket of multiple stocks and bonds, not just one, and can be bought and sold just like a single stock.</p>
                    <p className='green_text bold'>The first step is to open an account, and can take as little as 10 minutes online.</p>
                    <p>Here are a few of our favorites that have an easy online enrollment option:</p>
                </div>
            )
        }
    }

    render () {
        return (
        <div>

            <Helmet>
                <title>Discover {this.props.category === 'investments' ? 'Investment Accounts': this.props.category === '529plans' ? '529 Plans' : "Savings Accounts" } | Greatest Gift</title>
            </Helmet>

            <main >
                {this.render_page_intro()}

                <div className='offers_secondary_header'>
                    <div className='offers_secondary_header_container'>
                        <div className='offers_top_picks'>
                            <h2>Top Picks</h2>
                        </div>
                        <div className='advertiser_disclosure'>
                            <p  className='small_text' 
                                onClick={() => {this.setState({open_overlay: true})}}>Advertiser Disclosure</p>
                        </div>
                    </div>
                </div> 


                <Offers_list_container 
                    offers={this.state[this.props.category]}
                />
                
                <Overlay_popup 
                    open            = {this.state.open_overlay}
                    closer          = {true}
                    handleClose     = {() => this.setState({open_overlay: false})}
                    overlay_title   = 'Advertiser Disclosure'
                    wide            = {false}
                    body_1          = {`Offers are provided to you as a convenience. 
                    Greatest Gift does not endorse, warrant or guarantee the products or services available through the Offers (or any other third-party products or services advertised on or linked from our site), whether or not sponsored.
                    Greatest Gift is not an agent or broker or otherwise responsible for the activities or policies of those websites.
                    Greatest Gift may receive compensation from third parties which may impact the placement and availability of the Offers.
                    If you elect to use or purchase services from third parties, you are subject to their terms and conditions and privacy policy.`}
                />

                <div className='buffer'></div>
            
            </main> 

            {this.state.guest ? 
             <Home_Page_Footer /> 
            : null}
        </div>
        );
    }
}

export default Offers_page;