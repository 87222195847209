import React from 'react';
import './Account_step_2.css';

class Account_step_2 extends React.Component {
    constructor(props) {
        super(props);

        this.handleChangeAndCheckUnique = this.handleChangeAndCheckUnique.bind(this);
    }


    handleChangeAndCheckUnique(event, type) {
        if (type === 'email') {
            this.props.handleChange(event);
        } else if (type = 'phone') {
            this.props.handlePhoneChange(event);
        };
        this.props.checkUnique(event, type);
    }

    render () {   
        if (this.props.currentStep !== 2) {
            return null;
        };
        return (
           <div>
                <h2 className='center'>Enter your contact information</h2>
        
                <form>
                    
                    <p className='form_label'>Email </p>
                    <input 
                        type='email' 
                        id='user_email' 
                        name='user_email' 
                        placeholder='Example@email.com' 
                        onChange={this.props.handleChange} 
                        value={this.props.user_email}
                        autoFocus 
                        autoComplete='off'
                        onBlur={(event) => {this.props.checkUnique(event, 'email')}}/>

                    <p className='form_label'>US phone number</p>
                    <input 
                        type='text' 
                        id='user_phone_number' 
                        name='user_phone_number' 
                        placeholder='555-555-5555' 
                        maxLength='12'
                        onChange={(event) => {this.handleChangeAndCheckUnique(event, 'phone')}}  
                        value={this.props.user_phone_number}
                        autoComplete='off'/>
                    

                </form>

               

           </div>
        );
    }
}

export default Account_step_2;
