import React from 'react';
import './Gifting_step_1.css';

import {Helmet} from "react-helmet";
import MasterButton from '../../../general_components/MasterButton/MasterButton.js';
import Pic_selector from '../../../general_components/Pic_selector/Pic_selector';
import Media_selector from '../../../general_components/Pic_selector/Media/Media_selector';
import Greeting_box from '../../../Landing_page/Greeting_box/Greeting_box';

class Gifting_step_1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            occasion: ''
        }

        this.updateOccasion = this.updateOccasion.bind(this);
    }

    updateOccasion(event){
        this.setState({occasion: event.target.value})
    }

    render () {
        if (this.props.currentStep !== 1) {
            return null;
        }
        
        return (
           <div>
               
                <h2>Let's Start with a Greeting <span className='tiny_text'>(optional)</span></h2>
                
                <form>

                    <p className='form_label'>Gift for:</p>
                    { !this.props.guest ? 
                    <input 
                        type='text' 
                        id='gift_for_child_name' 
                        onChange={this.props.handleChange}
                        name='Gift_for' 
                        placeholder='Child name' 
                        value={this.props.Gift_for}
                        autoFocus
                        maxLength='50'
                        autoComplete='on'
                        /> 
                        :
                        
                    <input 
                    type='text' 
                    id='gift_for_child_name' 
                    onChange={this.props.handleChange}
                    name='Gift_for' 
                    placeholder='Child name' 
                    value={this.props.Gift_for}
                    maxLength='50'
                    autoComplete='on'
                    />}
                    

                        <div>
                            <p className='form_label'>Gift from:</p>
                            <input 
                                type='text' 
                                id='gift_from' 
                                onChange={this.props.handleChange}
                                name='from' 
                                placeholder='Your name' 
                                value={this.props.from}
                                maxLength='50'
                                autoComplete='on'
                                /> 
                        </div>

                    {/*
                    <p className='form_label'>Greeting:</p>
                    <textarea 
                        id='greeting_input' 
                        
                        onChange={this.props.handleChange}
                        name='greeting' 
                        placeholder='Example: We’re so excited to celebrate with you, and hope this helps you achieve all of your dreams!'
                        value={this.props.greeting}
                        maxLength='150'
                        autoComplete='off'
                        />
                       */}

                        <p className='form_label'>What is the occasion? (We’ll offer some greetings!) </p> 

                            <select id='occasions' 
                                    name='occasion' 
                                    className='dropdown_input dropdown_with_arrow' 
                                    onChange={this.props.handleChange} 
                                    required
                                    
                                    placeholder='Birthday / just because / other...' >
                                <option disabled selected hidden value=''>Birthday / just because / other... </option>
                                <option value='Just because'>       Just because</option>
                                <option value='Baby shower'>        Baby shower</option>
                                <option value='New baby'>           New baby</option>
                                <option value='Holidays'>           Holidays</option>
                                <option value='Birthday'>           Birthday</option>
                                <option value='School Graduation'>  School Graduation</option>
                                <option value='Other'>              Other</option>
                            </select>

                        <Greeting_box   occasion={this.props.occasion}
                                        label={'Greeting'}
                                        handleChange={this.props.handleChange}
                                        greeting={this.props.greeting}
                                        select_greeting={(new_value) => this.props.select_greeting(new_value)}/>
                </form>

                <Media_selector 
                    label={'Add a picture or video (max 8MB):'}
                    handlePicChange={this.props.handlePicChange}
                    handleRemoveMedia={this.props.handleRemoveMedia}
                    file={typeof this.props.file === 'string' ? null : this.props.file}/>

                <MasterButton 
                    buttonText={this.props.buttonText} 
                    handleButtonClick={this.props.handleButtonClick}
                    notSticky={true}
                />
           </div>
        );
    }
}

export default Gifting_step_1;

/*
<Pic_selector 
                    label={'Add a picture:'}
                    handlePicChange={this.props.handlePicChange}
                    file={this.props.file}/>
*/