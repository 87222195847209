import React from 'react';
import './Overlay_popup.css';

import MasterButton from '../MasterButton/MasterButton.js';

/*
    Props:
        open            = {this.state.open_overlay}
        closer          = {true}
        handleClose     = {() => this.setState({open_overlay: false})}
        overlay_title   = 'string'
        wide            = {true}

        body_1          = 'string'
        body_2          = 'string'
        form            = {}
        image           = {}
        button_1        = {{text: 'Button',
                            links: true,
                            linkTo: '/',
                            handler: this.overlayButton1Handler()
                            }}    
        button_2        = {}    
        joker           = {}

*/

class Overlay_popup extends React.Component {
    render () {
        if (this.props.open !== true) {
            return null;
        }
        
        return (
           <div>
               <div className='popup_blur_all'></div>
               <div className={this.props.wide ?  'overlay wide_overlay' : 'overlay'}>

                    <div className={this.props.closer ? 'overlay_icon_and_headline_container' : 'overlay_headline_container'}>
                        {this.props.closer ? <i  className='material-icons' onClick={this.props.handleClose} >close</i> : null }
                        <h2>{this.props.overlay_title}</h2>
                        {this.props.closer ? <div className='overlay_icon_and_headline_container_placeholder'></div> : null }
                    </div>

                    <div className='overlay_text_container'>
                        <p>{this.props.body_1}</p>
                        <p>{this.props.body_2}</p>
                        
                    </div>

                    <div>
                        {this.props.form}
                    </div>

                    {this.props.image ? <div className='overlay_popup_img_container'><img className='overlay_popup_img' src={this.props.image} /></div> : null}
                    
                    {this.props.button_1 || this.props.button_2 ? 
                    <div className='overlay_buttons_container_flex'>
                        {this.props.button_1 ? 
                        <MasterButton 
                            buttonText={this.props.button_1.text}
                            handleButtonClick={this.props.button_1.handler}
                            notSticky={true}
                            links={this.props.button_1.links}
                            linkTo={this.props.button_1.linkTo}
                        /> 
                        : null}

                        {this.props.button_2 ? 
                        <MasterButton 
                            buttonText={this.props.button_2.text}
                            handleButtonClick={this.props.button_2.handler}
                            notSticky={true}
                            links={this.props.button_2.links}
                            linkTo={this.props.button_2.linkTo}
                        /> 
                        : null}
                    </div>
                    : null }

                    <div>
                        {this.props.joker}
                    </div>

               </div>

              
                
           </div>
        );
    }
}

export default Overlay_popup;