import React from 'react';
import './Plan_top.css';

import {Link} from 'react-router-dom';

class Plan_top extends React.Component {
    constructor(props) {
        super(props);

        this.getStyle = this.getStyle.bind(this);
    }
    
    getStyle(){
        let style = {};
        if (this.props.banner_pic){
            style = {
                'backgroundImage': `url(${this.props.banner_pic})`,
                "backgroundPosition": "center",
                "backgroundSize": "100% auto"
            }
        } else {
            style = null;
        }
        return style;
    }
    
    render () {
        if (this.props.links) {
            return (
            <Link to={`/plan/${this.props.plan_id}`}>
                <div className='kid_picture_container' style={this.getStyle()}>
                    <p className={this.props.banner_pic ? 'kid_name shadow' : 'kid_name'}>{this.props.name}</p>
                </div>
            </Link>
            )
        } else {
            return (
                <div className='kid_picture_container' style={this.getStyle()}>
                    <p className={this.props.banner_pic ? 'kid_name shadow' : 'kid_name'}>{this.props.name}</p>
                    <p className='align_right'>
                        <i className='material-icons align_right plan_menu_button'
                        onClick={this.props.handleMoreClick}
                        >more_vert</i>  
                    </p>
                </div>
            )
        }
    }
}

export default Plan_top;