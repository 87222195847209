import React from 'react';
import './Received_gift_list.css';

import Received_gift from '../Received_gift/Received_gift.js';

class Received_gift_list extends React.Component {
    constructor(props) {
        super(props);

        this.renderGiftList = this.renderGiftList.bind(this);
    }

    renderGiftList(){
        let gift_status = '';
        let gift_amount = 0;
        return this.props.gifts.map(gift => {
            if(gift.redeem_status === 'failed'){
                gift_status = 'failed';
            } else if(gift.redemption_date) {
                gift_status = 'Redeemed';
            } else {
                gift_status = 'new';
            };
            gift_amount = gift.gift_amount - (1 - gift.accepted_fees) * gift.gift_send_fee;

            return <Received_gift 
                        key={gift.gift_id}
                        delivery_date={gift.delivery_date}
                        giftTo={gift.gift_for} 
                        gift_from={gift.gift_from} 
                        giftAmount={'$'+gift_amount} 
                        giftGreeting={gift.greeting}
                        gift_status={gift_status}
                        gift_id={gift.gift_id}
                        thank_you_sent={gift.sent_on}
                        /> ;
            });
    }
    
    render () {
        return (
            <div className='list'>
                {this.renderGiftList()}
            </div>
        );
    }
}

export default Received_gift_list;