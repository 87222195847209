
const money = {

    // take a javascript number, and return a string with commas for thousands and millions
    // input is '1' or '1234' or '1234567'
    // expected output is '1' or '1,234' or '1,234,567'
    addCommas(amount) {
        let number = String(amount);
        // Split the number into integer and fractional parts
        let [integerPart, fractionalPart] = number.split('.');

        // Add commas to the integer part
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Combine the integer part with the fractional part, if any
        if (fractionalPart !== undefined) {
            return `${integerPart}.${fractionalPart}`;
        } else {
            return integerPart;
        }
    },

    removeCommas(str){
        let new_str = str.split(',').join('');
        return new_str;
    },

    // takes a number without $ sign
    // return true if number has commas in right spaces
    checkForCommas(str){
        if(str.length < 4 && str.includes(',') ){
            return false;
        } else if (str.length === 4 || str.length === 8 ){ //1000 or 1000,000 or 1,000000
            return false
        } else if (str.length === 5 && str[1] !== ','){ //1,000 1 l=5
            return false
        } else if (str.length === 6 && str[2] !== ','){//10,000 2 l=6
            return false
        } else if (str.length === 7 && str[3] !== ','){//100,000 3 l=7
            return false
        } else if (str.length === 9 && str[1] !== ',' && str[5] !== ','){//1,000,000 1 5 l=9 
            return false
        } else if (str.length === 9 && str[2] !== ',' && str[6] !== ','){//10,000,000 2 6 l=10
            return false
        } else if (str.length === 9 && str[3] !== ',' && str[7] !== ','){//100,000,000 3 7 l=11
            return false
        } else {
            return true;
        }
    }
};

export default money;