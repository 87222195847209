import React from 'react';
import './About.css';

import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import Home_Page_Header from '../Home_Page_Header';
import Home_Page_Footer from '../Home_Page_Footer';

import founder_img from '../../../images/founder.png';

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    

    render () {

        return (
            <div className='landing_body'>
                <Helmet>
                    <title>About Greatest Gift</title>
                    <meta name="description"
                        content={"Greatest Gift turns investing in a child's future into a personal gift that you can send to your loved ones."}
                    />
                    <meta property="og:description" 
                        content={"Greatest Gift turns investing in a child's future into a personal gift that you can send to your loved ones."} 
                    />
                    <meta property="og:title" content={'About Greatest Gift'}/>
                </Helmet>
                <Home_Page_Header />
                
                <div className='landing_main'>
                    <div className='background_confetti_image'></div>
                    <div className='section_1 about'>
                        <div className='single_column_content'>
                            <div className='landing_page_tagline'>
                                <h2>About</h2>
                                <h3>Greatest Gift turns investing in a child's future into a personal gift that you can send to your loved ones.</h3>
                                <h3>On Average, parents only start saving when their child is seven years old, and four out of ten parents don’t save for their child at all. Some parents don’t know how to start saving for their child, some are overwhelmed with information and options, and some just haven’t gotten to it yet.</h3>
                                <h3>Greatest Gift encourages parents to start saving early and use the right tools at the right time. After parents receive a gift through Greatest Gift, we help them discover great ways to save and invest for children and answer their family finance questions.</h3>
                                
                                <div className='about_founder_container'>

                                    <div className='about_founder_img_container'>
                                        <img src={founder_img}/>
                                        <h3 className='tiny_text'>Ben Arbov<br></br>
                                        Founder</h3>
                                    </div>
                                    
                                    <div className='about_founder_text_container'>
                                        <h3>Greatest Gift was founded in 2020 by Ben Arbov, an Israeli American entrepreneur with a background in Economics, Psychology and Management Consulting. Ben loves sending gifts to his nephews and nieces, with the intention of creating a brighter future for them.</h3>
                                        <h3>Let’s give great gifts together.</h3>
                                    </div>

                                </div>

                            </div>
                        </div>
                        

                        <div className='section_button_container about_button'>
                            <Link to='/give-a-gift'>
                                <button className='landing_page_button'>
                                    Give a gift now
                                </button>
                            </Link>
                        </div>

                        

                    </div>

                </div>
            
                <Home_Page_Footer />

            </div>
        );
    }
}

export default About;