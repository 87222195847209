import React from 'react';
import './Tracker.css';

class Tracker extends React.Component {
    constructor(props) {
        super(props);

        this.renderTracker = this.renderTracker.bind(this);
    }

    renderTracker(){
        let i = 0;
        const stepsArray = new Array(this.props.numOfSteps).fill(0);
        return stepsArray.map(step => {
            i++;
            return (<div className={(this.props.currentStep >= i ) ? 'circle_big' : 'circle_small'} key={i}></div>);
            });
    }

    renderTags(){
        if(this.props.tags){
            let j = 0;
            let flex_basis = (1 / this.props.numOfSteps * 100 ) + '%';
            return this.props.tags.map(tag => {
                j++;
                // changed the flex basis here to static, there were bugs with a 2 step tracker
                return (
                    <p key={j} style={{'flexBasis': "25%"}} className={(this.props.currentStep === j ) ? 'tiny_text active_tag tag' : 'tiny_text tag'}>{tag}</p>
                )
            })
        } else {
            return null;
        }
        
    }

    render () {
        return (
            <div className='tracker_container'>
                
                <div className={this.props.numOfSteps === 2 ? 'circles_line circles_line_for_2': 'circles_line'}>
                    {this.renderTracker()}
                </div>
                {this.props.tags ? 
                <div className={this.props.numOfSteps === 2 ? 'tags_container tags_container_for_2' :'tags_container' }> 
                    {this.renderTags()}
                </div>
                : null}
            </div>

        );
    }
}

export default Tracker;