import React from 'react';
import './Dictionary_term.css';

import config from '../../../utils/config';

import terms from './Terms';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);

class Dictionary_term extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            
            daily_index: 0,

            term: '',
            image: '',
            definition: '',
            url: ''
        }
    }

    componentDidMount() {
        // get dictionary term of the day 
        console.log(terms);
        // get day of year - 
        var now = new Date();
        var start = new Date(now.getFullYear(), 0, 0);
        var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
        var oneDay = 1000 * 60 * 60 * 24;
        var day = Math.floor(diff / oneDay);
        
        // get term of the day
        let index = (day / terms.length - Math.floor(day / terms.length)) * terms.length;
        console.log('daily index: ' + index);
        this.setState({daily_index: index})
    } 

    //
    render () {
        return (
            <div className={'card dictionary_term_container'}>

                <p className='small_text gray'>Term of the day</p>

                <p className='xl_text no_margin bold'>{terms[this.state.daily_index].term}</p>

                <img src={terms[this.state.daily_index].image} alt={terms[this.state.daily_index].image_alt}/>

                <p>{terms[this.state.daily_index].definition}</p>

                <p className='bold align_center'>
                    <a className='green_text' target='_blank' 
                    onClick={() => amplitude.getInstance().logEvent(`discover: click term of the day`)}
                        href={`https://www.greatestgiftapp.com/dictionary/${terms[this.state.daily_index].slug}`}
                    >Learn More</a>
                </p>

            </div>
        );
    }
}

export default Dictionary_term;