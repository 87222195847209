import article1 from '../../../images/articles_images/Savings_ilustration.jpg';
import gift_image from '../../../images/illustrations/redeem_gift.png';

let articles = [
    {   id: 1,
        article_name: 'How to Ask for Money as Gifts for a Baby Shower',
        
        url: 'https://www.greatestgiftapp.com/blog/how-to-ask-for-money-as-gifts-for-a-baby-shower',

        preview: `Money gifts have become more widely accepted, and with the right gifting tools, they can even be personal and fun. Let your guests know how...`,
        preview_image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/62f164b2232303113666d5ec_Money%20Gifts.webp',
        
        cover_image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/62f164b2232303113666d5ec_Money%20Gifts.webp'
    },
    {   id: 2,
        article_name: 'Investing for Kids: Setting Kids Up for a Bright Future',
        
        url: 'https://www.greatestgiftapp.com/blog/investing-for-kids',

        preview: 'Investing for the kids in your life can set them up for a bright financial future. Kickstart a young one’s future goals with 529 plans, UTMA Accounts...',
        preview_image: "https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/63157b47c9476c6d3016ba69_investing%20for%20kids.png",
        
        cover_image: "https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/63157b47c9476c6d3016ba69_investing%20for%20kids.png",
    },
    {   id: 3,
        article_name: 'How to Financially Prepare for a Baby: 20 Practical Tips',
        
        url: 'https://www.greatestgiftapp.com/blog/how-to-financially-prepare-for-a-baby',

        preview: 'Creating a stable financial foundation will help you handle the road ahead. Organize your family finances...',
        preview_image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/636298ad5989dc399a1cb265_how%20to%20financially%20prepare%20for%20a%20baby.png',
        
        cover_image: 'https://uploads-ssl.webflow.com/60cca304af56de10cf67096c/636298ad5989dc399a1cb265_how%20to%20financially%20prepare%20for%20a%20baby.png',
    }

];

export default articles;

/*
  {   article_name: 'How to Start Saving for Your Child’s Future – An Actionable Guide',
        id: 1,
        
        publish_date: '04/27/2021',
        author: 'Ben Arbov',
        author_bio: 'Ben Arbov is the founder of Greatest Gift.',
        
        preview: 'Learning the best ways to save for your kids is the first step in creating a better future for them...',
        preview_image: gift_image,
        
        cover_image: gift_image,
        body: [
        ],

        next_article: 'Introducing Greatest Gift Inc: Gifts for Kids\' Long Term Savings'
    },
    {   article_name: 'How to Ask for Money as Gifts for a Baby Shower',
        id: 2,
        
        publish_date: '03/07/2021',
        author: 'Dana Arbov',
        author_bio: 'Dana Arbov is a writer at Greatest Gift, a mother of three, and an expert Gifter.',
        
        preview: 'Most parents-to-be prefer money for their new baby instead of traditional gifts. Here are some facts and tips ...',
        preview_image: "https://gg-app-images.s3.us-east-2.amazonaws.com/articles/greatest_gift_money_gifts.png",
        
        cover_image: "https://gg-app-images.s3.us-east-2.amazonaws.com/articles/greatest_gift_money_gifts.png",
        body: [
        ],

        next_article: 'Introducing Greatest Gift Inc: Gifts for Kids\' Long Term Savings'
    },
    {   article_name: '18 Great Ways to Start Saving for Your Child (Before Your Child Turns 18)',
        id: 3,
        
        publish_date: '04/30/2021',
        author: 'Ben Arbov',
        author_bio: 'Ben Arbov is the founder of Greatest Gift.',
        
        preview: 'We did some research and found the best ways to save for your child...',
        preview_image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/articles/greatest_gift_dollar_signs.png',
        
        cover_image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/articles/greatest_gift_dollar_signs.png',
        body: [
        ],

        next_article: 'Introducing Greatest Gift Inc: Gifts for Kids\' Long Term Savings'
    },

*/