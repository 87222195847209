import React from 'react';
import './Header_for_multistep.css';

class Header_for_multistep extends React.Component {
    render () {
        return (
            <header>
                <div className='header_container'>
                    <i  className='material-icons' 
                            onClick={this.props.handleBackButton} >arrow_back_ios</i>  
                    <h1>{this.props.text}</h1>
                    <div className='background_for_icon'>
                        <i className='material-icons-outlined person_icon'>person</i>  
                    </div>
                </div>
            </header>
            );
    }
}

export default Header_for_multistep;