import React from 'react';
import './GG_loader.css';

import Header_for_multistep from '../../layout_components/Header_for_multistep/Header_for_multistep.js';

class GG_loader extends React.Component {
    render () {
        if(this.props.mini_loader){
            return (
                <div className='loader mini_loader'>Loading...</div>
            )
        }
        return (
            <div>
                {this.props.header_text ? 
                <Header_for_multistep text={this.props.header_text}/>
                : null
                }
                <main>
                    <div className='loader'>Loading...</div>
                </main>
            </div>

            );
    }
}

export default GG_loader;