import React from 'react';
import canvas_confetti from 'canvas-confetti';

function delay(time){
    return new Promise(resolve => setTimeout(resolve, time));
};

class GG_confetti extends React.Component {
    componentDidMount() {
        delay(0.5*1000).then(() => {
            this.launchConfetti();
        });
    }
    
    launchConfetti() {
        const colors = ['#16DFAD', '#FFD700', '#5481C2', '#F04758'];
        const confettiSource = window.innerWidth < 600 ? 
            { x: window.innerWidth * 0.5, y: window.innerHeight * 0.2} :
            { x: window.innerWidth * 0.5, y: window.innerHeight * 0.2};

        const particleCount = window.innerWidth < 600 ? 80 : 120;
        const gravity = 1.4;
        const initialVelocityY = window.innerWidth < 600 ? 15 : 20;
        const tweenDuration = window.innerWidth < 600 ? 300 : 250;

        canvas_confetti({
            particleCount: particleCount,
            startVelocity: initialVelocityY,
            origin: {
                x: confettiSource.x / window.innerWidth,
                y: confettiSource.y / window.innerHeight
            },
            colors,
            shapes: ['square'],
            angle: 90,
            spread: 360,
            startVelocity: initialVelocityY,
            gravity: gravity,
            ticks: tweenDuration,
            colors: colors,
            scalar: 1.5, // Adjust the size of the shapes

        });

        canvas_confetti({
            particleCount: particleCount / 12,
            ticks: tweenDuration,
            origin: {
                x: confettiSource.x / window.innerWidth,
                y: confettiSource.y / window.innerHeight
            },
            colors,
            shapes: ['square'],
            angle: 90,
            spread: 360,
            startVelocity: initialVelocityY /4 ,
            gravity: gravity,
            colors: colors,
            scalar: 1.5, // Adjust the size of the shapes

        });


        canvas_confetti({
            particleCount: particleCount / 2,
            ticks: tweenDuration,
            origin: {
                x: confettiSource.x / window.innerWidth,
                y: confettiSource.y / window.innerHeight
            },
            colors,
            shapes: ['square'],
            angle: 90,
            spread: 360,
            startVelocity: initialVelocityY * 2 ,
            gravity: gravity,
            colors: colors,
            scalar: 1.5, // Adjust the size of the shapes

        });
    }

    render() {
        return null;
    }

}


export default GG_confetti;
