import React from 'react';
import './Ask_for_gift.css';

import Pic_selector from '../../general_components/Pic_selector/Pic_selector.js';
import MasterButton from '../../general_components/MasterButton/MasterButton';

import gifts from '../../../images/illustrations/personalized_cash_gift_app.png';
import Overlay_popup from '../../general_components/Overlay_popup/Overlay_popup';
import GGApi from '../../../utils/GGApi';
import validations from '../../../utils/validations';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../../utils/config.js';
import GG_loader from '../../general_components/GG_loader/GG_loader';

let share_request_for_gift_recaptcha = React.createRef();

class Ask_for_gift extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open_overlay: false,
            file: null,
            asker_name: '',
            asker_email: '',
            child: '',
            recipient_name: '',
            recipient_email: '',
            captcha_token: '',
            button_loading: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handlePicChange = this.handlePicChange.bind(this);
        this.link_button = this.link_button.bind(this);
        this.share_button_handler = this.share_button_handler.bind(this);
        this.handleRecaptcha = this.handleRecaptcha.bind(this);
        this.resetCaptcha = this.resetCaptcha.bind(this);
    }

    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }

    handlePicChange(event) {
        let file = event.target.files[0];
        
        // try to convert here to jpeg?
        if(file.size > 5242880) {
            alert('Please select a photo with a max size of 5MBs.');
            return;
        }
        this.setState(
            {file: event.target.files[0]}
        );
    }

    link_button(){
        navigator.clipboard.writeText(`https://app.GreatestGiftApp.com/give-a-gift?Gift_for=${encodeURI(this.state.child)}&Send_to=${encodeURI(this.state.asker_name)}&send_to_email=${encodeURI(this.state.asker_email)}&channel=email`)
            .then(() => alert('The link was successfully copied!'), () => alert('There was an issue when attempting to copy the link.'))
    }

    share_button_handler(){
        // verify the field inputs:
        let {recipient_name, asker_name, asker_email, child, recipient_email} = this.state;
        if(!recipient_name || !asker_name || !child || !asker_email || !recipient_email){
            alert('Some of the information is missing. Please fill all the required fields.')
            return;
        };
        if(!validations.validateEmail(recipient_email)) {
            alert('Please enter a valid recipient email address.');
            return;
        };
        if(!validations.validateEmail(asker_email)) {
            alert('Please enter your email address.');
            return;
        };
        if(!this.state.captcha_token){
            alert('Please fill out the captcha requirement.'); 
            return;
        };
        if(this.state.captcha_token){
            this.resetCaptcha(share_request_for_gift_recaptcha);
        }
        this.setState({button_loading: true})
        GGApi.upload_media(this.state.file, 'greetings-media').then(response => {
            // handle media upload error
            let media;
            if(response.errors){
                console.log(response.errors[0].detail);
                alert(response.errors[0].detail + ' \nEmail will be shared without the attached picture.')
            } else if(response.mediaUrl){
                media = response.mediaUrl;
            };

            GGApi.share_request_for_gifts(this.state.captcha_token, recipient_name, asker_name, child, asker_email, recipient_email, media)
            .then(response => {
                this.setState({recipient_name: '', recipient_email: '', captcha_token: '', button_loading: false})
                alert('An email has been sent on your behalf! To share with another recipient, simply add a new recipient name and email.')
            }).catch(err => {
                alert('There was an error when trying to send this email. Please try again.')
                this.setState({loading: false});
            })
        })

        
    }

    handleRecaptcha(value){
        this.setState({captcha_token: value})
    }

    resetCaptcha(ref){
        ref.current.reset();
    }

    render () {

        return (
        <div>

            { this.props.alt_button ? 
            <div className='ask_for_gift_alt_button' onClick={() => this.setState({open_overlay: true})}>
                {this.props.alt_button }
            </div>
            :
            <MasterButton 
                    buttonText={this.props.text ? this.props.text : 'Click here to share!'} 
                    handleButtonClick={() => this.setState({open_overlay: true})}
                    notSticky={true}
            />
            }

            <Overlay_popup 
                open            = {this.state.open_overlay}
                closer          = {true}
                handleClose     = {() => this.setState({open_overlay: false})}
                overlay_title   = {'Share With Friends & Family'}
                wide            = {true}
                body_1          = {'Add some details here, and we let your friends and family know that you are saving for your baby\'s future.' }
                joker           = { 
                    <div>
                        <div className='two_column_flex_content article_two_column'>
                                                
                            <div className='left_side'>
            
                                <form className='ask_for_gift_form'>
                                    
                                    <p className='form_label'>1. Your name:</p>
                                    <input 
                                        type='text' 
                                        id='asker_name' 
                                        onChange={this.handleChange}
                                        name='asker_name' 
                                        placeholder='Jane Diaz' 
                                        value={this.state.asker_name}
                                        maxLength='50'
                                        autoComplete='on'
                                        /> 
            
                                    <p className='form_label'>2. Your Email:</p>
                                    <input 
                                        type='text' 
                                        id='asker_email' 
                                        onChange={this.handleChange}
                                        name='asker_email' 
                                        placeholder='YourEmail@email.com' 
                                        value={this.state.asker_email}
                                        autoComplete='on'
                                        /> 
                                    
                                    <p className='form_label'>3. Who is the gift for?</p>
                                    <input 
                                        type='text' 
                                        id='child' 
                                        onChange={this.handleChange}
                                        name='child' 
                                        placeholder='Baby Jones' 
                                        value={this.state.child}
                                        maxLength='50'
                                        autoComplete='on'
                                        />
            
            
                                    <p className='form_label'>4. Recipient's name:</p>
                                    <input 
                                        type='text' 
                                        id='recipient_name' 
                                        onChange={this.handleChange}
                                        name='recipient_name' 
                                        placeholder='Joe Jonson' 
                                        value={this.state.recipient_name}
                                        maxLength='50'
                                        autoComplete='on'
                                        /> 
            
                                    <p className='form_label'>5. Recipient's Email:</p>
                                    <input 
                                        type='text' 
                                        id='recipient_email' 
                                        onChange={this.handleChange}
                                        name='recipient_email' 
                                        placeholder='RecipientEmail@email.com' 
                                        value={this.state.recipient_email}
                                        autoComplete='on'
                                        /> 
                                    
                                    <Pic_selector 
                                        label={'6. Add a picture (optional):'}
                                        handlePicChange={this.handlePicChange}
                                        file={this.state.file}/>
            
                                </form>
                                    
                            </div>
                            
                            <div className='right_side'>

                                <div className='share_preview'>
                                    <h4>Email Preview</h4>
                                    <p>Dear {this.state.recipient_name ? this.state.recipient_name : '_____'},<br></br>
                                    {this.state.asker_name ? this.state.asker_name : '_____'} is saving for {this.state.child ? this.state.child : '_____'}'s future.<br></br>
                                        Send a gift now, and be part of {this.state.child ? this.state.child : '_____'}'s journey to success!<br></br>
                                        <br></br>
                                        Happy Gifting,<br></br>
                                        Your friends at Greatest Gift.</p>
                                    {/*<MasterButton 
                                        buttonText={this.state.child ? 'Send a gift to '+ this.state.child :'Send a gift to '+ '_____'} 
                                        notSticky={true}
                                    />*/}
                                    <img 
                                        src={this.state.file ? URL.createObjectURL(this.state.file) : gifts}
                                        className={'uploaded_picture_preview'}/>
                                </div>

                            </div>
                            
                            <div className='ask_share_buttons'>
                                {this.state.button_loading ? 
                                    <GG_loader mini_loader={true} />
                                : <MasterButton 
                                    buttonText={'Share Now'} 
                                    handleButtonClick={this.share_button_handler}
                                    notSticky={true}
                                    />
                                }
                                
                    
                                <div className='recaptcha_container'>
                                    <ReCAPTCHA
                                    sitekey={config.recaptcha_siteKey}
                                    onChange={this.handleRecaptcha}
                                    ref={share_request_for_gift_recaptcha}
                                        />
                                </div>

                                <p>- or -</p>
                                <MasterButton 
                                    buttonText={<span className='button_with_icon'>Copy Shareable Link<i className='material-icons-outlined'>link</i> </span>} 
                                    handleButtonClick={this.link_button}
                                    notSticky={true}
                                />
                            </div>

                        </div>

                    </div>}

            />
        </div>


        );
    }
}

export default Ask_for_gift;