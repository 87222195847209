import React from 'react';
const investments = [


    {
        id: 304,
        affiliate_company: 'Betterment',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_brokerage_betterment.png',
        offer_summary: `Extremely easy to use, with no minimum to open an account. 
        Automatically managed smart investments based on your goals combined with a great website and app make this a great investment tool for new investors who want a hands-off investment experience, with a technologically pleasing platform.`,
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>No minimum to open managed investment accounts</li>
                <li>Annual advisory fee of 0.25% of total investment assets</li>
                <li>Great user experience on website and mobile app</li>
                <li>Automatic features like portfolio rebalancing, dividend reinvestment, and auto-adjust</li>
                <li>Socially responsible investing options</li>
            </ul>
        </div>,
        offer_link: 'https://betterment.evyy.net/rnWJdR'
    },

    {
        id: 301,
        affiliate_company: 'Fidelity',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_brokerage_fidelity.png',
        offer_summary: 'Managed investment with no fees for the hands-off investor. Virtual assistant, no minimums, low cost mutual funds, and fractional share trading for the hands-on investor. These make Fidelity a great choice for new investors that are looking to dip their toes in investing.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>A virtual assistant offers step by step help for new investors</li>
                <li>Individual and custodial accounts: no minimum to start, $0 commission on stock and ETF trades</li>
                <li>Managed portfolios: no minimum to start, and no advisory fees for accounts with balance under $10,000</li>
                <li>Low to no cost mutual funds with no minimums to invest</li>
                <li>Allows buying fractions of pricey shares for as little as $1</li>
            </ul>
        </div>,
        offer_link: 'https://www.fidelity.com/open-account/custodial-account'
    },
    /*
    {
        id: 301,
        affiliate_company: 'E*Trade',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_brokerage_etrade.png',
        offer_summary: 'Founded over 35 years ago, E*Trade is a well established online broker. E*Trade offers a variety of investment options and education tools fit for investors of all levels, and lets you start trading with as little as you want.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>Individual and custodial accounts: no minimum to start, $0 commission on stock and ETF trades</li>
                <li>Managed portfolios: minimum of $500 to start, and advisory fees of 0.30% of total assets per year</li>
                <li>Offers guides and articles for different knowledge levels</li>
                <li>A full range of investment options, for all investor levels</li>
            </ul>
        </div>,
        offer_link: 'https://us.etrade.com/planning'
    },*/

    
    {
        id: 303,
        affiliate_company: 'Ally Invest',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_kidssavings_ally_bank.png',
        offer_summary: 'This account has no minimum to start, making it a great starter account for parents looking to start investing for their child, and the managed portfolio is a great way to start earning on your investments if you prefer a hands-off approach.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>Individual and custodial accounts: no minimum to start, $0 commission on stock and ETF trades</li>
                <li>Mutual fund trading for a low $9.95</li>
                <li>Managed portfolios: low minimum of $100 to start, and advisory fees of 0.30% of total assets per year</li>
                <li>A full range of investment options, for all investor levels</li>
                <li>Earn interest on uninvested cash when you open and link an Ally savings account</li>
                <li>Get a signup bonus when you fund a new account with at least $10,000</li>
            </ul>
        </div>,
        offer_link: 'https://www.ally.com/invest/'
    },

   


    {
        id: 305,
        affiliate_company: 'Public',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_brokerage_public.png',
        offer_summary: 'Public aims to remove the barriers to enter the stock market by making it a social experience where investors can share ideas and follow experienced investors. No minimums and fractional share trading allow users to enter the stock market with any budget.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>Social features allow you to follow other traders and chat about investment ideas</li>
                <li>No minimum to start, $0 commission on stock and ETF trades</li>
                <li>No account fees</li>
                <li>Allows buying fractions of pricey shares for as little as $5</li>
                <li>Requests tips for trades in place of participating in Payment for Order Flow</li>
                <li>No day-trading policy</li>
                <li>User friendly app (but no website)</li>
            </ul>
        </div>,
        offer_link: 'https://track.flexlinkspro.com/g.ashx?foid=156074.10608.698556&trid=1241967.212434&foc=16&fot=9999&fos=5'
    },

    {
        id: 306,
        affiliate_company: 'ZACKS Trade',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_brokerage_zackstrade.png',
        offer_summary: 'Expert support makes this best suited for investors looking to take their game to the next level. Investors can use live support from seasoned brokers at no extra charges, and get some advice or information about high risk and complex trades.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>Brokerage and custodial accounts: minimum of $2,500 to open an account</li>
                <li>No extra charges for broker assisted trades means you can leverage professional brokers' experience</li>
                <li>Trade fees as low as $0.01 per share or ETF, with $1 minimum per trade</li>
                <li>Investment options include Stocks, ETFs, Mutual Funds, Bonds, and Options</li>
            </ul>
        </div>,
        offer_link: 'https://www.zackstrade.com/'
    },

]

export default investments;