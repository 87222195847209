import React from 'react';
import './Page_received_gift.css';
import Apply_gift from '../Apply_gift/Apply_gift';
import Redemption_details from '../Redemption_details/Redemption_details';

import GGApi from '../../../utils/GGApi.js';

import dates from '../../../utils/dates.js';
import GG_confetti from '../../general_components/GG_confetti/GG_confetti';
import GG_loader from '../../general_components/GG_loader/GG_loader';
import Overlay_popup from '../../general_components/Overlay_popup/Overlay_popup';
import { Link } from 'react-router-dom';

import config from '../../../utils/config';

import amplitude from 'amplitude-js';
import { Helmet } from 'react-helmet';
amplitude.getInstance().init(config.amp_key);

/* Need to pull gift data from the database here, for inputs into the fields */

class Page_received_gift extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            show_confetti: false,

            redeem_status: '',
            access: '',
            gift_status: '',
            from: '',
            recipients: [],
            applied_for: '',
            applied_to: '',
            applied_on: '',
            thank_you_sent: false,
            finish_open: false,
            gift: {}
            /* gift schema: 
                    account_id: null
                    delivery_date: "2020-09-09"
                    delivery_time: "19:00:00"
                    fund_id: null
                    gg_processing_fee: null
                    gift_amount: "$100"
                    gift_for: "Me"
                    gift_id: 3
                    greeting: "Hi Me"
                    id: 3
                    payment_method_id: "PAYMENT METHOD"
                    receiver_id: 1
                    redeem_id: 3
                    redemption_date: null
                    redemption_time: null
                    send_date: "2020-09-15"
                    send_time: "19:49:11"
                    sender_id: 1
                    sent_to_email: ""
                    sent_to_number: "929-255-9286"
                    gift_send_fee: 
                    accepted_fees: 
                    
                    thank_you_note:
                    thank_you_media:
                    sent_on:

            */
        }

        this.showError = this.showError.bind(this);
        this.render_gift_media = this.render_gift_media.bind(this);

    }

    componentDidMount() {        
        // load the received gift's details
        GGApi.received_gift(this.props.match.params.id).then(result => {
            // block entrance when necessary
            if(result.access === 'No-access') {
                this.setState({access: result.access, loading: false})
                return;
            };
            
            let requestedGift = result.requestedGift;
            let from = result.from;
            this.setState({
                gift: requestedGift,
                from: from,
                redeem_status: requestedGift.redeem_status

            });
            
            // change the date back to JS readable format
            if (requestedGift.delivery_date) {
                requestedGift.delivery_date = dates.jsDate(requestedGift.delivery_date);
            };
            
            // check if gift was redeemed:
            if(result.requestedGift.redemption_date) {
                // get gift's redemption details (if gift is redeemed)
                GGApi.redeemed_gift(this.props.match.params.id).then(result => {
                    if (result.applied_on) {
                        this.setState({
                            gift_status: 'Redeemed',
                            
                            applied_for: result.applied_for,
                            applied_to: result.applied_to,
                            applied_on: dates.jsDate(result.applied_on),
                            
                            loading: false
                        });
                    } else {
                        this.setState({
                            gift_status: 'Redeemed',
                            loading: false
                        });
                    };
                    // amplitude
                    amplitude.getInstance().logEvent('receive: see gift', {'gift status': "Redeemed"})
                });

            } else { // no redemption date means this is a new gift, and we need the recipient list
                // get recipients list for the Apply To section
                GGApi.get_recipient_accounts().then(result => {
                    if(result.success) {
                        let recipients = result.recipients.map(recipient => {
                            return  ({id: recipient.id, title: recipient.account_name});
                        })
                        this.setState({
                            recipients: recipients,
                            gift_status: 'new',
                            loading: false
                        });
                    } else {
                        // no recipients!
                        this.setState({
                            gift_status: 'new',
                            loading: false
                        });
                    }; 
                    // amplitude
                    amplitude.getInstance().logEvent('receive: see gift', {'gift status': 'new'})
                });

            };
            
            // check if thank you was sent
            if(result.requestedGift.sent_on) {
                this.setState({thank_you_sent: true})
            };
            

        }); 
    }


    showError(){
        this.setState({redeem_status: 'failed'});
    }

    render_gift_media(){
        if(!this.state.gift.mediaUrl){
            return null;
        } else {
            let split = this.state.gift.mediaUrl.split(".");
            let extension = this.state.gift.mediaUrl.split(".")[split.length - 1];
            if (extension === 'jpeg' || 
                extension === 'jpg' || 
                extension === 'png' ){
                    return (
                        <img 
                        src={this.state.gift.mediaUrl}
                        className='received_picture_greeting' />
                    )
            } else if ( 
                extension === 'quicktime' ||
                extension === 'mp4' ||
                extension === 'mov' ||
                extension === 'qt'){
                    return (
                        <video controls className='received_picture_greeting'>
                            <source src={this.state.gift.mediaUrl}/>
                        </video>
                    )
            }
        }
    }

    render () {
        if (this.state.access === 'No-access') {
            return (
                <main>
                    <h2>We're sorry, but the page you are trying to locate is unavailable.</h2>
                </main> 
            )
        };

        if(this.state.loading) {
            return (
                <GG_loader/>
            )
        }

        return (
    <div>
        {this.state.gift_status === 'new' && this.state.show_confetti && !this.state.finish_open ? <GG_confetti/> : null}
        <main>

            <Helmet>
                <title>{`Received Gift Details | Greatest Gift`}</title>
                <meta name="description"
                    content='Your received gift information. Redeem your gift and send thank you notes.' 
                />
            </Helmet>

            {this.state.redeem_status === 'failed' ? 
            <p className='gg_red'>We're sorry, but there was an issue when trying to redeem this gift. Please contact our support team for additional details.</p> 
            : null}

            {!this.state.gift_status === 'new' || this.state.finish_open ? null :
            <Apply_gift 
                received_gift_status={this.state.gift_status}
                recipients={this.state.recipients}
                updateActiveRecipient={this.props.updateActiveRecipient}
                gift_id={this.state.gift.gift_id} 
                toggle_loading={() => this.setState({loading: !this.state.loading})}
                open_finish={() => this.setState({finish_open: true})}
                showError={this.showError}
                redeem_status={this.state.redeem_status}
                new_receiver={this.props.new_receiver}
                Gift_amount={this.state.gift.gift_amount - (1 - this.state.gift.accepted_fees) * this.state.gift.gift_send_fee}
                show_confetti={() => this.setState({show_confetti: true})}
                thank_you_sent={this.state.gift.sent_on}
                />
            }
            <Overlay_popup 
                    open            = {this.state.finish_open}
                    overlay_title   = 'Your gift is being redeemed!'
                    wide            = {true}
            
                    body_1          = {
                        this.state.gift.sent_on ?
                        `Your gift is scheduled to be redeemed.` 
                        :
                        `Your gift is scheduled to be redeemed. The gift funds will appear in the selected financial account in a few days.` 
                    }
                    body_2          = {
                        this.state.gift.sent_on ?
                        `The gift funds will appear in the selected financial account in a few days.`
                        :
                        `Would you like to send a thank you note?` 
                    }

                    button_1        = {{text: this.state.gift.sent_on ? 'finish' : 'create thank you note',
                                        links: true,
                                        linkTo: this.state.gift.sent_on ? '/receive' : `/receive/${this.state.gift.gift_id}/thank_you`,
                                        }}    
                    joker           = {<div>
                        {this.state.gift.sent_on ? null :
                        <p className='small_text'><Link to='/receive' className='green_text'>Finish</Link></p>
                         }
                        
                    </div>}   
                />

            <h2>Details {'&'} Greeting</h2>
            
            <div className='two_column_text_container'>
               
                <div className='two_column_line'>
                    <div className='left_column_text'><p>Gift for:</p></div>
                    <div className='right_column_text'><p>{this.state.gift.gift_for}</p></div>
                </div>
    
                <div className='two_column_line'>
                    <div className='left_column_text'><p>From:</p></div>
                    <div className='right_column_text'><p>{this.state.from}</p></div>
                </div>
    
                <div className='two_column_line'>
                    <div className='left_column_text'><p>Date:</p></div>
                    <div className='right_column_text'><p>{this.state.gift.delivery_date}</p></div>
                </div>
                
                <div className='two_column_line'>
                    <div className='left_column_text'><p>Time:</p></div>
                    <div className='right_column_text'><p>{dates.deMilitarizeTime(this.state.gift.delivery_time)}</p></div>
                </div>
    
                <div className='two_column_line'>
                    <div className='left_column_text'><p>Gift amount:</p></div>
                    <div className='right_column_text'><p>${this.state.gift.gift_amount - (1 - this.state.gift.accepted_fees) * this.state.gift.gift_send_fee}</p></div>
                </div>
    
            </div>
            <div className='separator'></div>
            <p className='header_for_greeting'>Here's what {this.state.from} wrote:</p>
            <div className='greeting_box'>
                <p>"{this.state.gift.greeting}"</p>
            </div>

            {this.render_gift_media()}
            
            {/* THANK YOU */}
            <Link id='thank_you_link' className='dd-list-item' 
                    to={`/receive/${this.props.match.params.id}/thank_you`}>
            
                <div className='dd-list-item-text'>
                    <p>{this.state.thank_you_sent ?  'Thank You Note Details' : 'Send a Thank You Note' }</p>
                </div>
                <div className='dd-list-item-img'> 
                    <i className='material-icons'>arrow_forward_ios</i>
                </div>

            </Link>
            {/* REDEMPTION DETAILS*/}

            <Redemption_details 
                received_gift_status={this.state.gift_status}
                gift_redeemed_for_child_account={this.state.applied_for}
                gift_id={this.state.gift.gift_id} 
                redemption_date={this.state.applied_on}
                redeemed_at_financial_account={this.state.applied_to}
                redeem_status={this.state.redeem_status} />

            

    
            <div className='buffer'></div>
    
        </main> 
        </div>
        );
    }
}

export default Page_received_gift;