import React from 'react';
import './Detailed_plan_page.css';
import { Link } from 'react-router-dom';

import Plan_top from '../Plan_top/Plan_top.js';
import Detailed_plan_bottom from '../Detailed_plan_bottom/Detailed_plan_bottom.js';
import List_card_w_header from '../../general_components/Cards/List_card_w_header';
import Overlay_popup from '../../general_components/Overlay_popup/Overlay_popup';

import GGApi from '../../../utils/GGApi.js';
import calculator from '../../../utils/calculator.js';
import dates from '../../../utils/dates.js';

import gifting_page_img from '../../../images/illustrations/personalized_cash_gift_app.png';
import accounts_icon from '../../../images/icons/529_plan_100.png';
import GG_loader from '../../general_components/GG_loader/GG_loader.js';
import List_w_Category from '../../general_components/List_w_Category/List_w_Category.js';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';


class Detailed_plan_page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            access: '',
            open_overlay: false,
            open_plan_menu: false,
            loading: true,
            plan: {
                target_date: '08/01/2040',
                fund_amount: 0,
                page_active: 0
            },
            /*
                $user_id: '2', 
                $account_name: 'Neve', 
                $birthday: '06/07/2004'

                $account_id: '2', 
                $goal_name: 'Savings', 
                $target_savings: '$30,000', 
                $target_date: '08/01/2041', 
                $fund_id: '2'

                $account_id: '2', 
                $fund_type: '529 Plan', 
                $fund_institution: 'CA 529', 
                $fund_number_or_api_id: '555-111-1234', 
                $fund_amount: '$25,000'            
            */
            linked_accounts: [],

            update_name: '', 
            update_name_unique: true,
            update_birthday: ''

        }

        this.share_button = this.share_button.bind(this);
        this.link_button = this.link_button.bind(this);
        this.copy_to_clipboard = this.copy_to_clipboard.bind(this);
        this.updateRecipientHandler = this.updateRecipientHandler.bind(this);
        this.checkUnique = this.checkUnique.bind(this);
        
    }

    componentDidMount() {        
        GGApi.get_plan_by_id(this.props.match.params.plan_id).then(response => {
            if (response.fetched_plan) {
                //response.fetched_plan.target_date = dates.jsDate(response.fetched_plan.target_date);
                response.fetched_plan.birthday = dates.jsDate(response.fetched_plan.birthday);

                // set up linked accounts for adding
                this.props.updateActiveRecipient(response.fetched_plan.account_name);
                this.setState({plan: response.fetched_plan});

                // grab the list of funds for the recipient from the gg db
                GGApi.get_recipient_funds(this.props.match.params.plan_id).then(list => {
                    this.setState(
                        {   
                            linked_accounts: list,
                            loading: false
                        });    
                });

            };
            if(response.access === 'No-access') {
                this.setState({access: response.access})
            };
        });

        // get linked accounts?
        
    }

    share_button(){
        
        if (navigator.canShare){
            let child = this.state.plan.account_name;

            let url = this.state.plan.wf_item_id ? 'https://www.greatestgiftapp.com/gifts/' + this.state.plan.slug 
            : 'https://app.greatestgiftapp.com/registry/' + this.state.plan.slug;
            let text = this.state.plan.welcome_message;
            let title = `Invest in ${child}'s future`;

            navigator.share({url: url, text: text, title: title})
            .then(() => {
                alert('Successfully shared!')
            })
            .catch(err => {
                alert('There was an error when trying to share. Please try again.')
            })
        } else {
            console.log('navigator.share not available')
            this.setState({open_overlay: true});
        }
    }

    copy_to_clipboard(){
        /* clipboard successfully set */
        alert('The link was successfully copied!');
        this.setState({open_overlay: false});
    }

    link_button(){
        navigator.clipboard.writeText(this.state.plan.wf_item_id ? `https://www.greatestgiftapp.com/gifts/${this.state.plan.slug}` 
            : `https://app.greatestgiftapp.com/registry/${this.state.plan.slug}`)
            .then(this.copy_to_clipboard, function() {
                /* clipboard write failed */
                alert('There was an issue when attempting to copy the link.');
            })

    }

    checkUnique(e) {
        GGApi.unique_child_account(e.target.value.toLowerCase()).then(unique => {
            if(!unique) {
                alert('You already have a recipient with this name. Please Choose a different name for this recipient account.');
            };
            this.setState({update_name_unique: unique});
        });
    }

    updateRecipientHandler(){
        
        let formatted_date = this.state.update_birthday ? format(this.state.update_birthday, 'MM/dd/yyyy') : '';

        if(!this.state.update_birthday && !this.state.update_name) {
            return;
        }

        this.setState({loading: true});
        // validations
        if (this.state.update_birthday) {
            // check for a full number of characters
            if (!(formatted_date.length === 10)) {
                alert('Please input a valid birthday in the following format: \nMM/DD/YYYY');
                this.setState({loading: false});
                return;
                //check that the input is a valid date between the years 1900-2200
            } else if (!dates.validateDate(formatted_date)) {
                alert('Please input a valid birthday in the following format: \nMM/DD/YYYY');
                this.setState({loading: false});
                return;
            }
            formatted_date = dates.sqlizeDate(formatted_date);
        }
        if (this.state.update_name) {
            if (!this.state.update_name_unique) {
                alert('You already have a recipient with this name. Please Choose a different name for this recipient account.');
                this.setState({loading: false});
                return;
            }
        } 

        

        // api call - update the recipient account with the validated inputs
        GGApi.update_recipient_account(this.state.plan.account_name, this.state.update_name, formatted_date).then(result => {
            if(result.success){
                this.setState({
                    open_plan_menu: false, 
                    
                    update_name: '', 
                    update_name_unique: true,
                    update_birthday: '' });
                    window.location.reload();
            } else {
                alert('An error occurred. Please try again or reach out to our team for more information.');
                this.setState({loading: false});
            }
        })
    }

    render () {
        if (this.state.loading === true) {
            return (
                <main>
                    <GG_loader/>
                </main> 
            )
        };

        if (this.state.access === 'No-access') {
            return (
                <main>
                    <h2>We're sorry, but the page you are trying to locate is unavailable.</h2>
                </main> 
            )
        };

        return (
            <div>
                <main>
                <div className='kid_account margin_top_24'>
                    <Plan_top 
                        name={this.state.plan.account_name} 
                        account_banner_picture={this.state.plan.banner_picture}
                        banner_pic={this.state.plan.media_url}
                        menu={true}
                        handleMoreClick={() => {this.setState({open_plan_menu: true})}}
                    />
                    <Detailed_plan_bottom 
                        name={this.state.plan.account_name} 
                        saved_to_date={this.state.plan.total_redeemed ? this.state.plan.total_redeemed : '0'}
                        redeemed_gifts={this.state.plan.redeemed_gifts ? this.state.plan.redeemed_gifts : '0'}
                        //target_savings={this.state.plan.target_savings}
                        //target_date={this.state.plan.target_date}
                        //potential_profit={calculator.compound(this.state.plan.total_redeemed ? this.state.plan.total_redeemed : '0', (calculator.getYearFromDate(this.state.plan.target_date)-calculator.todaysYear()), 0.07) }
                        linked_acount={this.state.plan.fund_api_id}
                        plan_id={this.state.plan.id}
                    />

                {/* if there is no gifting page - show create page. Else - show other options */}
                    {
                        this.state.plan.page_active 
                        ? 
                        <List_card_w_header 
                            header={
                                {
                                link: '',
                                text: 'Gifting Page',
                                icon: ''
                                }
                            }
                            list = {[
                               
                                {
                                links: true,
                                link: `/plan/${this.props.match.params.plan_id}/gifting_page`,
                                text: 'Edit Gifting Page',
                                icon: 'arrow_forward_ios',
                                img: '',},
                                

                                {
                                links: true, 
                                link: this.state.plan.wf_item_id ? 'https://www.greatestgiftapp.com/gifts/' + this.state.plan.slug 
                                : 'https://app.greatestgiftapp.com/registry/' + this.state.plan.slug ,
                                text: 'Preview Gifting Page',
                                icon: 'launch',
                                img: '',},

                                { link: '',
                                text: 'Share Gifting Page',
                                icon: 'link',
                                img: '',
                                clickHandler: this.share_button
                                }
                            ]}
                            /> 
                        :
                        <div className='card_with_image_container'>
                            <img src={gifting_page_img}/>
                            <div className='card_with_image_text'>
                                <p>Let people know you’re saving for a brighter future!</p>
                                <Link to={`/plan/${this.props.match.params.plan_id}/gifting_page`} >
                                <p className='bold green_text'>Create a Gifting Page for {this.state.plan.account_name}</p>
                                </Link>
                            </div>
                        </div>   
                    }
 
                    {/* LINKED ACCOUNTS - if there are no linked accounts - show link account*/}
                    
                    {this.state.linked_accounts && this.state.linked_accounts.length > 0 ? 
                        <div className="card">
                            <div className='List_card_w_header_header'>
                                <p className='bold'>{this.state.plan.account_name}'s Linked Financial Accounts</p>

                            </div>
                            
                            <List_w_Category 
                                list={this.state.linked_accounts}
                                dd_style={true}
                            />

                            <Link className='List_card_w_header_item' to={`/link_destination?plan=${this.state.plan.id}`} >
                                <div className='List_card_w_header_item_left'>
                                    <p className='small_text'>Link a new account</p>
                                </div>
                                <i className='material-icons small_text'>arrow_forward_ios</i>  
                            </Link>
                        </div>
                        
                        :
                        
                        <Link to={`/link_destination?plan=${this.state.plan.id}`} >
                            <div className='card_with_image_container'>
                                <img src={accounts_icon}/>
                                <div className='card_with_image_text'>
                                    <p className='bold green_text'>Link a financial account to transfer {this.state.plan.account_name}'s gifts into.</p>
                                </div>
                            </div>   
                        </Link>
                    }

                </div>

                <div className='buffer'></div>
                <div className='buffer'></div>

            </main>

            <Overlay_popup 
                open={this.state.open_overlay}

                closer={true}
                handleClose={() => this.setState({open_overlay: false})}
                overlay_title='Share Gifting Page'
                
                body_1={`Let your friends and family know you are saving for ${this.state.plan.account_name}'s future.`}
                joker={
                    <div className='overlay_share_button_container'>
                        <a className='overlay_share_button' href={`mailto:?body=Hey%2C%20we%27re%20saving%20for%20${this.state.plan.account_name}%27s%20future!%20Here%27s%20a%20link%20for%20you%20to%20invest%20in%20${this.state.plan.account_name}%20future%20through%20Greatest%20Gift.%0A%0Ahttps%3A%2F%2Fwww.GreatestGiftApp.com%2Fgifts%2F${this.state.plan.slug}`}>
                            <i className='material-icons-outlined'>email</i>  
                            <span className='tiny_text'>Send email</span>
                        </a>
                        
                        <a className='overlay_share_button' href={`sms:?&body=Hey%2C%20we%27re%20saving%20for%20${this.state.plan.account_name}%27s%20future!%20Here%27s%20a%20link%20for%20you%20to%20invest%20in%20${this.state.plan.account_name}%20future%20through%20Greatest%20Gift.%0A%0Ahttps%3A%2F%2Fwww.GreatestGiftApp.com%2Fgifts%2F${this.state.plan.slug}`}>
                            <i className='material-icons-outlined'>textsms</i>  
                            <span className='tiny_text'>Send text</span>
                        </a>

                        <div className='overlay_share_button' 
                            onClick={this.link_button}>
                            <i className='material-icons-outlined'>link</i>  
                            <span className='tiny_text'>Copy link</span>
                        </div>

                    </div>
                }/>

            <Overlay_popup 
                open={this.state.open_plan_menu}
                closer={true}
                handleClose={() => this.setState({open_plan_menu: false, update_name: '', update_birthday: ''})}
                overlay_title='Update Recipient'
                form={<form>

                    <p className='form_label'>Update Name:</p>
                    <input 
                                type='text' 
                                id='update_name' 
                                onChange={(e) => {this.setState({update_name: e.target.value})}}
                                onBlur={(e) => {this.checkUnique(e, 'update_name')}}
                                name='update_name' 
                                placeholder='New name' 
                                value={this.state.update_name}
                                autoFocus
                                autoComplete='off'
                                />
                    <p className='form_label'>Update Birthday:</p>
                   

                        <DatePicker 
                        selected={this.state.update_birthday} 
                        onChange={(date) => {this.setState({update_birthday: date});}
                        } 

                        showIcon
                        toggleCalendarOnIconClick

                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={25}

                        placeholderText="MM / DD / YYYY"
                    />
                </form>}
                joker={<div>
                    <div className='buffer'></div>
                    <div className='buffer'></div>
                    <div className='buffer'></div>
                    <div className='buffer'></div>
                </div>}
                button_1={{text: 'Save', handler: this.updateRecipientHandler}}
            />

            </div>
        );
    } 
}

export default Detailed_plan_page;