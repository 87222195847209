import React from 'react';
import './Discover_header_and_main.css';

import {Helmet} from "react-helmet";
import Secondary_header from './Secondary_header/Secondary_header.js';
import Main_discover from './Main_discover/Main_discover.js';

import GGApi from '../../../utils/GGApi.js'
import Home_Page_Footer from '../../Landing_page/Home_Page_Footer';

class Discover_header_and_main extends React.Component {
    constructor(props){
        super(props);
        
        this.state={
            tokenIsValid: true,
            guest: '',
            discover_secondary_header: 'offers'
        }
    
        /*
        this.handleOffersClick = this.handleOffersClick.bind(this);
        this.handleArticlesClick = this.handleArticlesClick.bind(this);
        */
    }   
    
    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        let validToken = '';
        // if there's a token, check that it's valid
        if (user) {
            GGApi.checkToken().then(response => {
                validToken = response;
                // if token is not valid, log out
                if (validToken === false) {
                    this.setState({tokenIsValid: false})

                } else if (validToken === true) {
                    // token is valid. set flow as signed in user
                    console.log('user validated. Discover as a user:');
                    this.setState({guest: false})
                };
            });
        
            // if there is no token, continue as a guest
        } else if (!user) {
            console.log('Discover as guest');
            this.setState({guest: true});
            this.props.removeNavBar();
        };
        

    }

    componentWillUnmount() {
        this.props.restoreNavBar();
    }

/*
    handleOffersClick() {
        this.setState({discover_secondary_header: 'offers'});
    }
  
    handleArticlesClick(){
        this.setState({discover_secondary_header: 'articles'});
    }
*/
    
    render () {
        return (
        <div>
            <Helmet>
                <title>Discover Great Ways to Save and Invest for Children | Greatest Gift</title>
                <meta property="og:title" content={`Discover Great Ways to Save and Invest for Children | Greatest Gift`}/>
                <link rel="icon" href="/static/media/logo_var_2.3a7e8fdf.png" />
                <meta name="description"
                    content='Discover great ways to save and invest for children and find answers to family finance questions. Discover our curated collection of financial products, guides and life-hacks.' 
                />
                <meta property="og:description" 
                    content='Discover great ways to save and invest for children and find answers to family finance questions. Discover our curated collection of financial products, guides and life-hacks.' 
                />
            </Helmet>

            {/*
            <Secondary_header 
                active_header={this.state.discover_secondary_header}
                handleOffersClick={() => this.setState({discover_secondary_header: 'offers'})}
                handleArticlesClick={() => this.setState({discover_secondary_header: 'articles'})} 
            />
            */}
            <Main_discover 
                active_header={this.state.discover_secondary_header}
            />

            {this.state.guest ? 
            <Home_Page_Footer />
            : null}

        </div>
        );
    }
}

export default Discover_header_and_main;