import React from 'react';
import './Greeting_box.css';


class Greeting_box extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            selected_occasion: '',
            options: {
                "Birthday":             [   `Happy Birthday! We are so excited to be on this journey with you called life!`, 
                                            'Happy Birthday to a very special person! Wishing for us to create many great memories together.',
                                            "Happy 1st birthday! On this big day we wish you joy and happiness."
                                        ],
                "Just because":         [   `We feel so happy to have you in our lives, and wanted to remind you with a little token of gratitude!`, 
                                            'Sending you a great gift for your Greatest Gift!', 
                                            'Just thinking of you and sending you lots of love, gifts, and great vibes!',
                                            `Friendship is a preceious thing and I truly cherish ours. Thank you for being a great friend!`
                                        ],
                "New baby":             [   `Welcome to the world little one! We wish you moments filled with love and laughter. May you always be happy and blessed.`, 
                                            `Congratulations! Hello sleepless nights, dirty diapers, first smile and first steps. Time to buckle up for the journey ahead!`,
                                            `The greatest gifts come in small packages! Enjoy this one, and watch your newborn grow to be beautiful, smart, brave and healthy.`,
                                            `Welcome to the world little one and congratulations on this milestone! Wishing you a lifetime of happiness.`
                                        ],
                "Baby shower":          [   `Can't wait to meet your little bundle of joy! Wishing you a smooth and joyous journey to parenthood`,
                                            `Showering you with love and best wishes as you prepare for this amazing adventure called parenthood!`,
                                            `Here's to the adventure of a lifetime! Wishing you all the best as you get ready to meet your baby. Happy baby shower!`,
                                            `Sending special wishes for a wonderful baby shower and a healthy, happy baby. Can't wait to celebrate with you!`,
                                            `Showering you with all the best as you await the arrival of your baby. May your home be filled with love and laughter.`,

                                        ],
                "Holidays":             [   
                                            `To the most wonderful kid, wishing you little joys and big smiles this holiday season. Enjoy every magical moment!`,                        
                                            `The holidays are about spending time with your loved ones and we are so grateful to be spending them with you.`,
                                            `Sending you all the sparkle and joy of the holiday season. May your days be merry and bright!`,

                                            `To our special little star, may your holidays be as bright and joyful as you are. Enjoy every moment!`,
                                            `Even though we can't celebrate together this year, doesn't mean we can't send presents. Hope this one will create a bright future for you.`, 

                                        ],
                "School Graduation":    [   `We wanted to wish you all the best on your graduation. Believe in yourself, chase your dreams, and enjoy this new journey in life. So proud of you!`,
                                            `Happy Graduation! Enjoy your day, chase your dreams, stay happy and healthy. You're the best!`,
                                            `Congratulations to you! So proud of you and your incredible accomplishments. `
                                        ],
                "Other":                [   'Our little star, you shine so bright. You are special in every way and we love you so much! Congratulations on your <event>!', 
                                            'So happy to have you in our lives!',
                                            `Happy sweet sixteen! Be happy, stay healthy, and enjoy all the good stuff life has to offer.`
                                        ],
            }
        }

        this.nextGreeting       = this.nextGreeting.bind(this);
        this.previousGreeting   = this.previousGreeting.bind(this);
    }

    componentDidMount(){
        this.setState({selected_occasion: this.props.occasion});
    }

    componentDidUpdate(){
        if(this.state.selected_occasion === this.props.occasion){
            return;
        } else {
            let new_value = this.state.options[this.props.occasion][0];
            document.getElementById('greeting_input').value = new_value;
            this.props.select_greeting(new_value);
            this.setState({selected_occasion: this.props.occasion, index: 0})
        }
    }

    nextGreeting(){
        let i;
        if(this.state.index === this.state.options[this.props.occasion].length - 1) {
            i = 0;
        } else {
            i = this.state.index + 1;
        };
        
        let new_value = this.state.options[this.props.occasion][i];
        document.getElementById('greeting_input').value = new_value;
        this.props.select_greeting(new_value);
        this.setState({index: i});
    }

    previousGreeting(){
        let i;
        if(this.state.index === 0) {
            i = this.state.options[this.props.occasion].length - 1;
        } else {
            i = this.state.index - 1;
        };

        let new_value = this.state.options[this.props.occasion][i];
        document.getElementById('greeting_input').value = new_value;
        this.props.select_greeting(new_value);
        this.setState({index: i});
    }

    render () {

        return (
                <div>
                    
                    <p className='form_label'>{this.props.label}</p>
                    
                    <div className='interactive_greeting_master_container'>
                        
                        <div className='interactive_greeting_container'>

                            {this.props.occasion ? <div><i className={'material-icons-outlined back_arrow'} onClick={this.previousGreeting}>arrow_back_ios</i></div> : null}
                            
                            <textarea 
                                id='greeting_input' 
                                className={this.props.occasion ? 'interactive_greeting_text_area' : ''}
                                onChange={this.props.handleChange}
                                name='greeting' 
                                placeholder='Example: We’re so excited to celebrate with you, and hope this helps you achieve all of your dreams!'
                                value={this.props.greeting}
                                maxLength='250'
                                autoComplete='off'
                                />

                            {this.props.occasion ? <div><i className={'material-icons-outlined forward_arrow'} onClick={this.nextGreeting}>arrow_forward_ios</i></div> : null}
            
                        </div>

                        <div className='interactive_greeting_container_tracker'>
                            {this.props.occasion ? `${this.state.index + 1}/${this.state.options[this.props.occasion].length}`: null}
                        </div>
                    </div>

                    <div><p className='tiny_text greeting_char_count'>{this.props.greeting.length}/250</p></div>

                </div>

                                    
        );
    }
}

export default Greeting_box;