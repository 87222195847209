import React from 'react';
import './Offer.css';

import MasterButton from '../../../../general_components/MasterButton/MasterButton.js';


import config from '../../../../../utils/config';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);


class Offer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false
        }
    }
    
    render () {
        return (
        <div className='offer' id={this.props.affiliate_company}>
            <div className='offer_inner_margin'>
                <div className='logo_and_name_container'>
                    
                        <a className='affiliate_logo_container' href={this.props.offer_link} target='_blank'>
                            <img src={this.props.affiliate_logo} className='affiliate_logo_img'/>
                        </a>
                    
                    <h2>{this.props.affiliate_company}</h2>
                </div>

                <div className='description_headline'><p>Why we like it:</p></div>
                
                <div className='description'><p>{this.props.offer_summary}</p></div>
                
                {this.state.expand ? 
                this.props.expanded_info
                : null}

                {
                    this.props.expanded_info ? 

                    <p className='secondary_dark small_text cursor' 
                        onClick={() => {this.setState({expand: !this.state.expand}); amplitude.getInstance().logEvent('discover: expand offer', {'offer': this.props.affiliate_company});}} > 
                        {this.state.expand ? 'Less' : 'More Info' }
                    </p> 

                    : null
                }
                
                

                
                <a href={this.props.offer_link} target='_blank'>
                    <MasterButton 
                        handleButtonClick={() => amplitude.getInstance().logEvent('discover: click offer', {'offer': this.props.affiliate_company})}
                        buttonText='Learn More'
                        links={false}
                        linkTo={this.props.offer_link}
                        notSticky={true}
                    />
                </a>

            </div>
        </div>
        );
    }
}

export default Offer;
