import React from 'react';
import './Plan.css';

import {Link} from 'react-router-dom';

import Plan_top from '../Plan_top/Plan_top.js';
import Plan_bottom from '../Plan_bottom/Plan_bottom.js';
import { Helmet } from 'react-helmet';

class Plan extends React.Component {
    render () {
        return (
            
                <div className='kid_account margin_top_24'>
                    <Plan_top links={true} banner_pic={this.props.banner_pic} name={this.props.name} plan_id={this.props.plan_id}/>
                    <Plan_bottom 
                        saved_to_date={this.props.saved_to_date} 
                        redeemed_gifts={this.props.redeemed_gifts}
                        plan_id={this.props.plan_id}
                        share_button={this.props.share_button}
                        target_savings={this.props.target_savings}
                        page_active={this.props.page_active}
                        slug={this.props.slug}
                        />
                </div>
        );
    }
}

export default Plan;