const dev_config = {
    stripe: { // (TEST)
        public_key: 'pk_test_51I4EliEcsxHBi6WeyrxOLCBxlELKSjnpZnXrWbICIIIEJPzNa3VdmPMP6i68TOyiznPFGWtkBT9WXbYDJEYxfxOg00vqdR1NyG'
    },
    recaptcha_siteKey: '6LduNEcaAAAAAGZvDsxvHzten7WR_Q--Xo3Q93qf', // local host key

    amp_key: 'a8638024fade96beb61d3924c2debe04',

    gg_api_baseurl: 'http://localhost:5001/api'               // local dev env
    //gg_api_baseurl: 'http://localhost:5000/api'               // local dev env
    //gg_api_baseurl: 'https://greatestgiftapi.ngrok.io/api'    // ngrok tunnel to local server
}

const dev_config_live_stripe = {
    stripe: { // (LIVE)
        public_key: 'pk_live_51I4EliEcsxHBi6WeekMq6wqchzWFTrN0Ajd0nYpLHQ3JtWvofRioqDUUGuVeLa8D8flNZ6LiX00Sd9InqqpJfEI700Yzdtkugx'
    },
    recaptcha_siteKey: '6LduNEcaAAAAAGZvDsxvHzten7WR_Q--Xo3Q93qf', // local host key

    amp_key: 'a8638024fade96beb61d3924c2debe04',

    gg_api_baseurl: 'http://localhost:5001/api'               // local dev env
    //gg_api_baseurl: 'http://localhost:5000/api'               // local dev env
    //gg_api_baseurl: 'https://greatestgiftapi.ngrok.io/api'    // ngrok tunnel to local server
}

const staging_config = { // deployed to s3, talking to deployed eb
    stripe: {
        public_key: 'pk_test_51I4EliEcsxHBi6WeyrxOLCBxlELKSjnpZnXrWbICIIIEJPzNa3VdmPMP6i68TOyiznPFGWtkBT9WXbYDJEYxfxOg00vqdR1NyG'
    },
    recaptcha_siteKey: '6LcaL1EbAAAAAAq_BLuJ_lSxUQ_83NDSqYkhIF17', // https://app.GreatestGiftApp.com key
    //recaptcha_siteKey: '6LcYMkcaAAAAAGXCzRkmz6RYQK88PvmRKMyqRQlN', // https://GreatestGiftApp.com key

    amp_key: 'a8638024fade96beb61d3924c2debe04',

    gg_api_baseurl: 'https://ggapi.greatestgiftapp.com/api'     // eb
}

const production_config = { // live stripe
    stripe: {
        public_key: 'pk_live_51I4EliEcsxHBi6WeekMq6wqchzWFTrN0Ajd0nYpLHQ3JtWvofRioqDUUGuVeLa8D8flNZ6LiX00Sd9InqqpJfEI700Yzdtkugx'
    },
    recaptcha_siteKey: '6LcaL1EbAAAAAAq_BLuJ_lSxUQ_83NDSqYkhIF17', // https://app.GreatestGiftApp.com key
    //recaptcha_siteKey: '6LcYMkcaAAAAAGXCzRkmz6RYQK88PvmRKMyqRQlN', // https://GreatestGiftApp.com key

    amp_key: 'a41f7ddc7c61a2c57216847b424f5571',

    gg_api_baseurl: 'https://ggapi.greatestgiftapp.com/api'     // eb
}

let config = production_config;

module.exports = config;