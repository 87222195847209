import React from 'react';
import './Link_destination_1.css';

import Dropdown from '../../../general_components/Dropdown/Dropdown.js';

import plans from '../Institutions/529Plans.js';
import banks from '../Institutions/Banks';
import brokerages from '../Institutions/Brokerages';

import gg_fees from '../../../../utils/gg_pricing.js';

import config from '../../../../utils/config';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);

let checking_redeem_fee    =  `${String(gg_fees.redeem_fees.checking_variable   *100)}% ${gg_fees.redeem_fees.checking_fixed    === 0 ? '': '+'+gg_fees.redeem_fees.checking_fixed+'¢' }`;
let other_redeem_fee       =  `${String(gg_fees.redeem_fees.other_variable      *100)}% ${gg_fees.redeem_fees.other_fixed       === 0 ? '': '+'+gg_fees.redeem_fees.other_fixed+'¢' }`;

class Link_destination_1 extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            buttonActive: false,
            highlight_fields: false,
            show_password: false
        };

        //this.handleButtonClick = this.handleButtonClick.bind(this);
        this.renderLink = this.renderLink.bind(this);
        this.renderNonBank = this.renderNonBank.bind(this);
    }

    renderLink() {
        if(this.props.institution_name) {
            if(this.props.institution_name.link) {
                return (
                    <div>
                <p id='link_to_account_details'><a href={this.props.institution_name.link} 
                        target='_blank'
                        className='bold green_text'>Click here</a> to find your account information.</p>
                </div>)

            } else {return null;}
        } else {return null;}
    }

    renderNonBank(){
        if(this.props.account_type.title !== '529 Plan' && this.props.account_type.title !== 'Investment Account'){
        // && this.props.account_type.title !== 'US Savings Bonds'){
            return null;
        }
        let institution_list = [];
        if(this.props.account_type.title === '529 Plan') {
            institution_list = plans;
        } else if (this.props.account_type.title === 'Investment Account') {
            institution_list = brokerages;
        } else if (this.props.account_type.title === 'Savings Account') {
            institution_list = banks;
        } else if (this.props.account_type.title === 'Checking Account') {
            institution_list = banks;
        }

        return (
            <div>
                { /*  this.props.account_type.title === 'US Savings Bonds' ? null :
                */}

                <Dropdown
                    dd_name='institution_name'
                    header='Institution Name' 
                    selected={this.props.institution_name}
                    list={institution_list}
                    handleItemClick={this.props.handleDDSelection}
                    highlight={this.state.highlight_fields}
                    active={this.props.account_type ? true : false}
                    includesImgs={true}/>

                {this.renderLink()}

                <form>
                    <p className='form_label'>Account Number</p> 
                    
                    {this.props.institution_name ? 
                        (   this.props.institution_name.account_input_instructions ? 
                            <p className='form_label tiny_text'>{this.props.institution_name.account_input_instructions}</p> 
                            : null
                        ) 
                    : null }

                    <input 
                        type={this.state.show_password ? 'text' : 'password' }
                        name='account_number' 
                        id='account_number' 
                        onChange={this.props.handleChange}
                        placeholder={this.props.institution_name.account_length === 8 ? '1234-5678' : this.props.institution_name.account_length === 10 ? '123-456-7890' : '123-456-789'}
                        value={this.props.account_number}
                        autoComplete='off'
                        maxLength='30'
                        disabled={this.props.disabled}
                        />

                    { this.props.account_type.title === 'Savings Account' 
                        || this.props.account_type.title === 'Checking Account' 
                        || this.props.institution_name.routing === 'unknown'
                        ?
                        <p className='form_label'>Routing Number</p> 
                    :null }

                    { this.props.account_type.title === 'Savings Account' 
                        || this.props.account_type.title === 'Checking Account' 
                        || this.props.institution_name.routing === 'unknown' ?
                        <input 
                            type={this.state.show_password ? 'text' : 'password' }
                            name='routing_number' 
                            id='routing_number' 
                            onChange={this.props.handleChange}
                            placeholder='123-456-789' 
                            value={this.props.routing_number}
                            autoComplete='off'
                            maxLength='9'
                            disabled={this.props.disabled}
                            />
                    :null }

                </form>

                <div className='disclaimer_checkbox_container'>
                    {this.state.show_password ?
                        <i onClick={() => this.setState({show_password: !this.state.show_password})} className='material-icons-outlined disclaimer_checkbox'>check_box</i> :
                        <i onClick={() => this.setState({show_password: !this.state.show_password})} className='material-icons-outlined disclaimer_checkbox'>check_box_outline_blank</i>}
                    <p className='disclaimer_checkbox_text tiny_text'>Show fields</p>
                </div>
                
               </div>
        )
    }

    render () {
        if (this.props.currentStep !== 1) {
            return null;
        };

        return (
           <div>
                <h2 className='center'>Add a destination for {this.props.recipient}'s gifts</h2>
                <Dropdown
                    dd_name='account_type'
                    header='Account Type' 
                    selected={this.props.account_type}
                    list={[ {id: 1, title: '529 Plan',              secondary: gg_fees.redeem_fees.other_fixed === 0 && gg_fees.redeem_fees.other_variable === 0 ? 'No Fees' : other_redeem_fee + ' To Redeem'}, 
                            {id: 2, title: 'Investment Account',    secondary: gg_fees.redeem_fees.other_fixed === 0 && gg_fees.redeem_fees.other_variable === 0 ? 'No Fees' : other_redeem_fee + ' To Redeem'}, 
                            //{id: 3, title: 'US Savings Bonds',         secondary: gg_fees.redeem_fees.other_fixed === 0 && gg_fees.redeem_fees.other_variable === 0 ? 'No Fees' : other_redeem_fee + ' To Redeem'}, 
                            {id: 3, title: 'Savings Account',       secondary: gg_fees.redeem_fees.other_fixed === 0 && gg_fees.redeem_fees.other_variable === 0 ? 'No Fees' : other_redeem_fee + ' To Redeem'}, 
                            {id: 4, title: 'Checking Account',      secondary: checking_redeem_fee + ' To Redeem', color: 'gg_red'}]}
                    handleItemClick={this.props.handleDDSelection}
                    highlight={this.state.highlight_fields}
                    active={true}/>
                
                {this.renderNonBank()}

                <p> 
                    <a className='green_text bold' onClick={() => amplitude.getInstance().logEvent('discover: nav to discover', {'source': 'add fund'})}
                        href='https://app.greatestgiftapp.com/discover' 
                        target='_blank'>Discover 
                    </a> our favorite savings accounts, 529 plans, and investment accounts for more great ways to save and invest for your child's future. 
                </p>
                {/*
                <p className='small_text'>We charge a standard {checking_redeem_fee} convenience fee when gifts are redeemed at a bank checking account.
                    <br></br>{gg_fees.redeem_fees.other_fixed === 0 && gg_fees.redeem_fees.other_variable === 0 ? 'Avoid' : 'Lower'} fees by redeeming gifts into any long term savings accounts, like
                     <a className='secondary_dark bold' href='https://app.greatestgiftapp.com/discover/529plans' target='_blank'> 529 plans</a>, investment accounts, or bank savings accounts.
                </p>
                */}
                 <p>Don't see your preferred financial destination? <a className='green_text' href='mailto:Support@GreatestGiftApp.com?subject=Please%20add%20my%20financial%20institution'>Email us</a> and we'll do our best to add it ASAP!</p>
                {this.props.account_type.title === 'Checking Account' ?
                <p className='small_text gg_red'>We charge a standard {checking_redeem_fee} convenience fee when gifts are redeemed at a bank checking account.</p>
                :null}

                {/* this.props.account_type.title === 'US Savings Bonds' ?
                <p className=''>See a full guide on 
                <a className='green_text bold' href='https://www.greatestgiftapp.com/blog/savings-bonds-for-kids' 
                    target='_blank'> Savings Bonds for Kids</a> to learn more.
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </p>
                :null */}
           </div>
        );
    }
}

export default Link_destination_1;