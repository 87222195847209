import React from 'react';
import './Home.css';

import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import GGApi from '../../utils/GGApi.js';
import config from '../../utils/config';

// import react components
import GG_loader from '../general_components/GG_loader/GG_loader';
import Home_header from '../layout_components/Home_header/Home_header';
import Overlay_popup from '../general_components/Overlay_popup/Overlay_popup';
import Offer from '../discover_components/Offers/Offers_page/Offer/Offer';
import Plan from '../plan_components/Plan/Plan';
import GG_confetti from '../general_components/GG_confetti/GG_confetti';

// import the discover offers 
import plans from '../discover_components/Offers/Offers_page/Offers/Offer_529Plans.js';
import savings from '../discover_components/Offers/Offers_page/Offers/Offer_savings.js';
import investments from '../discover_components/Offers/Offers_page/Offers/Offer_investment.js';

// import images
import send_gifts from '../../images/illustrations/personalized_gift.svg';
import open_gift from '../../images/illustrations/gift_open_money_w_b.svg';

import expecting_parents from '../../images/illustrations/expecting_parents.png';
import new_parents from '../../images/illustrations/new_parents.png';
import young_kids from '../../images/illustrations/young_kids.png';
import teens from '../../images/illustrations/teens.png';
import young_adults from '../../images/illustrations/young_adults.png';

import plan_and_goal from '../../images/illustrations/goals_and_plans.svg';
import multiple_gifts from '../../images/illustrations/multiple_gifts.svg';

import trust_and_will from '../../images/financial_institution_logos/Trust&Will_stacked.png';
import share_ask from '../../images/illustrations/gift_sent.png';
import Sent_gift from '../pages/Main_gift_page/Sent_gift/Sent_gift';
import Carousel from '../general_components/Carousel/Carousel';
import logo from '../../images/logos/logo.png';
import facebook from '../../images/icons/facebook.svg';
import instagram from '../../images/icons/instagram.svg';
import dates from '../../utils/dates';
import Ask_for_gift from '../Landing_page/Ask_for_gift/Ask_for_gift';

// amplitude
import amplitude from 'amplitude-js';
import Dictionary_term from '../discover_components/Dictionary_term/Dictionary_term';
import Offer_card from '../general_components/Cards/Offer_card.js';
amplitude.getInstance().init(config.amp_key);

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // loading status for different items on page
            loading_1: true,
            loading_2: true,
            loading_3: true,
            loading_4: true,

            // sent gifts
            last_2_gifts: [],
            sent_in_last_3_months: null,

            // plans
            plans_carousel: [],
            plans: [],
            child: '',
            open_overlay_1: false,
            open_overlay_4: false,

            // discover 
            open_overlay_2: false,

            '529plans': [],
            'kidssavings': [],
            'investments': [],
            active: '',
            discover_slides: [],

            // email confirmation messages
            open_overlay_3: false,
            email_confirmation_message: ''
        };

        this.share_button = this.share_button.bind(this);    
        this.copy_to_clipboard = this.copy_to_clipboard.bind(this);
        this.link_button = this.link_button.bind(this);    
        this.link_button_2 = this.link_button_2.bind(this);    
    }
    
    
    componentDidMount() {
        // get sent gifts. 
        GGApi.sent_gifts().then(gifts => {
            // get last 2 gifts
            this.setState({
                last_2_gifts: gifts.slice(0,2)
            })
            // get number of gifts from last 3 months
            let i = 0;
            let three_months_ago = new Date();
            three_months_ago.setMonth(three_months_ago.getMonth() -3);
            gifts.forEach(gift => {
                let gift_send_date = new Date(gift.send_date);
                if(gift_send_date >= three_months_ago){
                    i++;
                }
            });
            this.setState({sent_in_last_3_months: i, loading_1: false});
        });

        // get unredeemed gifts.
        GGApi.received_gifts().then(jsonResponse => {
            let i = 0;
            jsonResponse.receivedGifts.forEach(gift => {
                if(gift.redeem_status === 'failed'){
                    return;
                } else if(gift.redemption_date) {
                    return;
                } else {
                    i++;
                };
            });
            this.setState({unredeemed_gifts: i, loading_2: false})
        })
        
        // get the user's plans
        GGApi.get_plans().then(plans => {
            let jsx_plans = plans.map(plan => {
                console.log(plan);
                return <Plan
                            key={plan.id}
                            name={plan.account_name}
                            saved_to_date={plan.total_redeemed ? plan.total_redeemed : '0'}
                            banner_pic={plan.media_url}
                            plan_id={plan.id}
                            target_savings={plan.target_savings}
                            share_button={this.share_button}
                            page_active={plan.page_active}
                            slug={plan.slug}
                            /> ;
            });
            let list = [
                <Link to='add_account' className='home_item_with_image'>
                    <img src={multiple_gifts}/>
                    <div className='home_item_with_image_text'>
                        <p>Add a recipient and create a gifting page</p>
                        <p className='small_text green_text'>Add a Recipient Account</p>
                    </div>
                </Link>
            ];
            
            list = jsx_plans.concat(list);
            this.setState({plans_carousel: list, plans: plans, loading_3: false})
        })

        // get the offers
        this.setState({
            '529plans': plans,
            'kidssavings': savings, 
           'investments': investments 
        })
        let random = Math.floor(Math.random()*3);
        if(random === 0){
            let jsx_slides = plans.map(offer => {
                return <Offer 
                            affiliate_company={offer.affiliate_company}
                            affiliate_logo= {offer.affiliate_logo}
                            offer_summary={offer.offer_summary}
                            offer_link={offer.offer_link}
                            expanded_info={offer.expanded_info}
                            key={offer.id}
                            /> ;
                });
            this.setState({active: '529plans', discover_slides: jsx_slides, loading_4: false})
        } else if (random === 1) {
            let jsx_slides = investments.map(offer => {
                return <Offer 
                            affiliate_company={offer.affiliate_company}
                            affiliate_logo= {offer.affiliate_logo}
                            offer_summary={offer.offer_summary}
                            offer_link={offer.offer_link}
                            expanded_info={offer.expanded_info}
                            key={offer.id}
                            /> ;
                });
            this.setState({active: 'investments', discover_slides: jsx_slides, loading_4: false})
        } else {
            let jsx_slides = savings.map(offer => {
                return <Offer 
                            affiliate_company={offer.affiliate_company}
                            affiliate_logo= {offer.affiliate_logo}
                            offer_summary={offer.offer_summary}
                            offer_link={offer.offer_link}
                            expanded_info={offer.expanded_info}
                            key={offer.id}
                            /> ;
                });
            this.setState({active: 'kidssavings', discover_slides: jsx_slides, loading_4: false})
        } 

        // messages about email verification processes
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('email') && urlParams.get('email') === 'confirmed'){
            this.setState({open_overlay_3: true, email_confirmation_message: 'You successfully verified your email!'})
        }
    } 
    //

    share_button(plan_id, link_to){
        
        let index = this.state.plans.findIndex(plan_list_item => plan_list_item.id === plan_id ? true : false)
        console.log(index);
        if (navigator.canShare){
            
            let user = JSON.parse(localStorage.getItem('user'));
            let child = this.state.plans[index].account_name;
            let url = ''
            let text = ''
            let title = `Invest in ${child}'s future`;


            if(link_to === "link"){
                url = `https://app.GreatestGiftApp.com/give-a-gift?Gift_for=${encodeURI(child)}&Send_to=${encodeURI(user.first_name)}&send_to_email=${user.email}&channel=email`;
                text = `Hey, we're saving for ${child} future! Here's a link for you to invest in ${child} future through Greatest Gift.`;
            
            } else if (link_to === 'gifting page') {
                url = this.state.plan[index].wf_item_id ? 'https://www.greatestgiftapp.com/gifts/' + this.state.plan[index].slug 
                : 'https://app.greatestgiftapp.com/registry/' + this.state.plan[index].slug;
                text = this.state.plans[index].welcome_message;
            }

            navigator.share({url: url, text: text, title: title})
            .then(() => {
                amplitude.getInstance().logEvent('gifting page: select share channel', {'channel': 'native' })
                alert('Successfully shared!')
            })
            .catch(err => {
                alert('There was an error when trying to share. Please try again.')
            })
        } else {
            console.log('navigator.share not available')
            console.log(encodeURI(this.state.plans[index].account_name))
            if(link_to === "link"){
                this.setState({open_overlay_1: true, overlay_index: index, child: this.state.plans[index].account_name});
            } else if (link_to === 'gifting page') {
                this.setState({open_overlay_4: true, overlay_index: index, child: this.state.plans[index].account_name, slug: this.state.plans[index].slug, wf_item_id: this.state.plans[index].wf_item_id});
            }
        }
    }

    copy_to_clipboard(){
        /* clipboard successfully set */
        alert('The link was successfully copied!');
        this.setState({open_overlay_1: false, open_overlay_4: false});
    }

    link_button(){
        let user = JSON.parse(localStorage.getItem('user'));
        navigator.clipboard.writeText(`https://app.GreatestGiftApp.com/give-a-gift?Gift_for=${encodeURI(this.state.child)}&Send_to=${encodeURI(user.first_name)}&send_to_email=${encodeURI(user.email)}&channel=email`)
            .then(this.copy_to_clipboard, function() {
                /* clipboard write failed */
                alert('There was an issue when attempting to copy the link.');
            })
    }


    link_button_2(){
        // amplitude
        amplitude.getInstance().logEvent('gifting page: select share channel', {'channel': 'copy link' });
        navigator.clipboard.writeText(`${this.state.wf_item_id ? 'https://www.greatestgiftapp.com/gifts/' : 'https://app.greatestgiftapp.com/registry/' }${this.state.slug}`)
            .then(this.copy_to_clipboard, function() {
                /* clipboard write failed */
                alert('There was an issue when attempting to copy the link.');
            })

    }

    //
    render () {
        if(this.state.loading_1 || this.state.loading_2 || this.state.loading_3 || this.state.loading_4) {
            return (
                <GG_loader />
            )
        }
        let user = JSON.parse(localStorage.getItem('user'));

        return (
            <div className='home_background'>
                <Helmet>
                    <title>{`Greatest Gift | Home - Send, Receive, Plan, Discover`}</title>
                    <meta property="og:title" content={`Greatest Gift | Home - Send, Receive, Plan, Discover`}/>
                    <link rel="icon" href="/static/media/gg_icon.3a7e8fdf.png" />
                    <meta name="description"
                        content='Send and receive monetary gifts for children`s long term savings. Plan, set goals and track savings. Discover great ways to save & invest.' 
                    />
                    <meta property="og:description" 
                        content='Send and receive monetary gifts for children`s long term savings. Plan, set goals and track savings. Discover great ways to save & invest.' 
                    />
                    <script>{`gtag('event', 'conversion', {'send_to': 'AW-409975111/ho2ZCKCD7voYEMfyvsMB'});`}</script>
                </Helmet>
                <GG_confetti />
                <Home_header toggleMenu={this.props.toggleMenu}/>
                <div className='home_header_buffer'></div>

                <div className='home_banner'>
                    <h2>We're Happy to See You {user.first_name}!</h2>
                </div>
                
                <main className='home_main'>

                    <div className='send_and_receive_container'>
                        
                        <Link className='send_and_receive_button' to='/give-a-gift' onClick={() => amplitude.getInstance().logEvent('gifting: click give a gift', {"page": "dashboard"})}>
                            <img src={send_gifts}/>
                            <div>
                                <p className='bold'>Send Gifts</p>
                                <p className='small_text'>You sent {this.state.sent_in_last_3_months} gifts in the last 3 months</p>
                            </div>
                        </Link>


                        <Link className='send_and_receive_button send_and_receive_button_right' to='/receive'>
                            <img src={open_gift}/>
                            <div>
                                <p className='bold'>Redeem Gifts</p>
                                {   this.state.unredeemed_gifts === 0 ?
                                    <p className='small_text'>See all your received gifts here!</p>:
                                    <p className='small_text'>You have <span className='secondary_dark'>{this.state.unredeemed_gifts === 1 ? '1 gift' : this.state.unredeemed_gifts + ' gifts' }</span> waiting to be redeemed</p>
                                }
                            </div>
                        </Link>
                    </div>


                    <div className='home_plans_carousel'>
                        <Carousel 
                            main_width_factor={window.innerWidth > 600 ? 0.5 : 0.9}
                            slides={this.state.plans_carousel}/>
                    </div>

                    <Offer_card 
                        imageSrc = {trust_and_will}
                        promoCode = "EXCLUSIVE10"
                        offers = {[
                            {
                                url: 'https://trustandwill.sjv.io/c/2698189/959162/11883',
                                text1: 'Make a Will online in minutes.',
                                text2: 'Make sure your children and loved ones are taken care of.'
                            },
                            {
                                url: 'https://trustandwill.sjv.io/c/2698189/959162/11883',
                                text1: 'Make a Will online in minutes.',
                                text2: 'Name a guardian for your kids, organize your assets.'
                            },
                            {
                                url: 'https://trustandwill.sjv.io/c/2698189/959164/11883',
                                text1: 'Make a Will online in minutes.',
                                text2: 'Not sure where to start? Take the quiz and determine which estate plan is best for you.'
                            }
                        ]}
                    />

                    <div className='home_item home_discover_articles'>
                        <Link className='home_discover_header' to='/discover'>
                            <p className='bold'>Discover Great Ways to Save & Invest</p>
                            <i className='material-icons small_text'>arrow_forward_ios</i>  
                        </Link>
                        
                        <a className='home_discover_articles_article' href='https://www.greatestgiftapp.com/financial-guides/expecting-parents' target='_blank'>
                            <div className='home_discover_articles_article_left'>
                                <img src={expecting_parents}/>
                                <p className='small_text'>Expecting Parents</p>
                            </div>
                            <i className='material-icons small_text'>arrow_forward_ios</i>  
                        </a>

                        <a className='home_discover_articles_article' href='https://www.greatestgiftapp.com/financial-guides/new-parents' target='_blank'>
                            <div className='home_discover_articles_article_left'>
                                <img src={new_parents}/>
                                <p className='small_text'>New Parents</p>
                            </div>
                            <i className='material-icons small_text'>arrow_forward_ios</i>  
                        </a>

                        <a className='home_discover_articles_article' href='https://www.greatestgiftapp.com/financial-guides/young-kids' target='_blank'>
                            <div className='home_discover_articles_article_left'>
                                <img src={young_kids}/>
                                <p className='small_text'>Young Kids</p>
                            </div>
                            <i className='material-icons small_text'>arrow_forward_ios</i>  
                        </a>

                        <a className='home_discover_articles_article' href='https://www.greatestgiftapp.com/financial-guides/teenagers'>
                            <div className='home_discover_articles_article_left'>
                                <img src={teens}/>
                                <p className='small_text'>Teens</p>
                            </div>
                            <i className='material-icons small_text'>arrow_forward_ios</i>  
                        </a>

                        <a className='home_discover_articles_article' href='/discover'>
                            <div className='home_discover_articles_article_left'>
                                <img src={young_adults}/>
                                <p className='small_text'>Young Adults</p>
                            </div>
                            <i className='material-icons small_text'>arrow_forward_ios</i>  
                        </a>
                    </div>

                    <Dictionary_term />

                    <div className='home_discover_offer'>
                        <div className='home_item_header_container'>

                            <div className='home_item_header'>
                                <p className='bold'>Top Picks for You</p>
                                <p className='advertiser_disclosure small_text'
                                onClick={() => {this.setState({open_overlay_2: true})}}>Advertiser Disclosure</p>
                            </div>

                            <div className='home_item_header_text'>
                                {   this.state.active === 'kidssavings' ?
                                    <p>Setting up a saving account for your kid is a great way to set aside funds for your child's future.</p>
                                    :
                                    (this.state.active === '529plans' ?
                                        <p>529 plans allow you to save and invest for your child's educational future. </p>
                                        :
                                        <p>Setting up an investment account for your child can help reach your savings goals faster.</p>
                                    )}
                            </div>
                        </div>

                        <Carousel 
                        main_width_factor={window.innerWidth > 600 ? 0.5 : 0.9}
                        slides={this.state.discover_slides}
                        />
                    </div>


                    {this.state.last_2_gifts.length > 0 ?
                    <div className='home_recent_gifts'>

                        <div className='home_item_header_container'>

                            <div className='home_item_header'>
                                <p className='bold'>Recent Gifts</p>
                                <Link to='/gifts'><p className='advertiser_disclosure small_text green_text bold'>View All</p></Link>
                            </div>
                        </div>

                        <div className='home_recent_gifts_container'>
                            <div className='home_recent_gifts_gift' style={this.state.last_2_gifts.length === 2 ? null : {'flex-basis': '100%'}}>
                                <Sent_gift 
                                    giftTo={this.state.last_2_gifts[0].gift_for} 
                                    giftAmount={this.state.last_2_gifts[0].gift_amount - (1- this.state.last_2_gifts[0].accepted_fees) * this.state.last_2_gifts[0].gift_send_fee} 
                                    
                                    giftDate={dates.jsDate(this.state.last_2_gifts[0].delivery_date)} 
                                    key={this.state.last_2_gifts[0].id}
                                />
                                <Link to={this.state.last_2_gifts[0].sent_to_email ?
                                    `/give-a-gift?Gift_for=${encodeURI(this.state.last_2_gifts[0].gift_for)}&send_to_email=${encodeURI(this.state.last_2_gifts[0].sent_to_email)}&channel=email`
                                    :
                                    `/give-a-gift?Gift_for=${encodeURI(this.state.last_2_gifts[0].gift_for)}&send_to_number=${encodeURI(this.state.last_2_gifts[0].sent_to_number)}&channel=text_message`
                                }>
                                    <p className='green_text home_recent_gifts_gift_cta'>Send Another Gift</p>
                                </Link>
                            </div>
                            { this.state.last_2_gifts.length === 2 ?
                            <div className='home_recent_gifts_gift'>
                                <Sent_gift 
                                    giftTo={this.state.last_2_gifts[1].gift_for} 
                                    giftAmount={this.state.last_2_gifts[1].gift_amount - (1- this.state.last_2_gifts[1].accepted_fees) * this.state.last_2_gifts[1].gift_send_fee} 
                                    giftDate={dates.jsDate(this.state.last_2_gifts[1].delivery_date)} 
                                    key={this.state.last_2_gifts[1].id}
                                />
                                <Link to={this.state.last_2_gifts[1].sent_to_email ?
                                    `/give-a-gift?Gift_for=${encodeURI(this.state.last_2_gifts[1].gift_for)}&send_to_email=${encodeURI(this.state.last_2_gifts[1].sent_to_email)}&channel=email`
                                    :
                                    `/give-a-gift?Gift_for=${encodeURI(this.state.last_2_gifts[1].gift_for)}&send_to_number=${encodeURI(this.state.last_2_gifts[1].sent_to_number)}&channel=text_message`
                                }>
                                <p className='green_text home_recent_gifts_gift_cta'>Send Another Gift</p>
                                </Link>
                            </div>
                            : null }
                        </div>
                    </div>
                    : null }

                    <Ask_for_gift 
                        alt_button={
                            <div className='home_item_with_image'>
                                <img src={share_ask}/>
                                <div className='home_item_with_image_text'>
                                    <p>Let people know you’re saving for a brighter future!</p>
                                    <p className='small_text green_text'>Share with Friends & Family</p>
                                </div>
                            </div>   
                        }
                    />


                    <div className='home_item home_additional_items_container'>
                        <a href='https://www.greatestgiftapp.com/#faq' className='home_additional_items_item' target='_blank'>
                            <p className='bold'>FAQ</p>
                            <i className='material-icons small_text'>arrow_forward_ios</i>  
                        </a>
                        <Link to='/legal' className='home_additional_items_item'>
                            <p className='bold'>Terms of Use</p>
                            <i className='material-icons small_text'>arrow_forward_ios</i>  
                        </Link>
                        <a href='https://www.greatestgiftapp.com/about' className='home_additional_items_item'>
                        {/*<Link to='/about' className='home_additional_items_item'>*/}
                            <p className='bold'>About Greatest Gift</p>
                            <i className='material-icons small_text'>arrow_forward_ios</i>  
                        {/*</Link>*/}
                        </a>
                        <Link to='/greatest_gift_intro' className='home_additional_items_item'>
                            <p className='bold'>Tutorial</p>
                            <i className='material-icons small_text'>arrow_forward_ios</i>  
                        </Link>
                    </div>


                    <div className='home_social_container'>
                        <div className='home_social_icons_container'>
                            <a href='https://facebook.com/greatestgiftapp' target='_blank'>
                                <img src={facebook}/>
                            </a>
                            <a href='https://instagram.com/greatestgiftapp' target='_blank'>
                                <img src={instagram}/>
                            </a>
                        </div>
                        <p>Follow our social media for more tips and fun surprises!</p>
                    </div>

                    <Overlay_popup 
                        open={this.state.open_overlay_1}
                        closer={true}
                        handleClose={() => this.setState({open_overlay_1: false})}
                        overlay_title='Share'
                        
                        body_1={`Let your friends and family know you are saving for ${this.state.child}'s future and send them a link so they can start sending ${this.state.child} gifts.`}
                        joker={
                            <div className='overlay_share_button_container'>
                                <a className='overlay_share_button' href={`mailto:?body=Hey%2C%20we%27re%20saving%20for%20${this.state.child}%27s%20future!%20Here%27s%20a%20link%20for%20you%20to%20invest%20in%20${this.state.child}%20future%20through%20Greatest%20Gift.%0A%0Ahttps%3A%2F%2Fapp.GreatestGiftApp.com%2Fgive-a-gift%3FGift_for%3D${this.state.child.split(' ').join('%2520')}%26Send_to%3D${user.first_name.split(' ').join('%2520')}%26send_to_email%3D${encodeURI(user.email)}%26channel%3Demail`}>
                                    <i className='material-icons-outlined'>email</i>  
                                    <span className='tiny_text'>Send email</span>
                                </a>
                                
                                <a className='overlay_share_button' href={`sms:?&body=Hey%2C%20we%27re%20saving%20for%20${this.state.child}%27s%20future!%20Here%27s%20a%20link%20for%20you%20to%20invest%20in%20${this.state.child}%20future%20through%20Greatest%20Gift.%0A%0Ahttps%3A%2F%2Fapp.GreatestGiftApp.com%2Fgive-a-gift%3FGift_for%3D${this.state.child.split(' ').join('%2520')}%26Send_to%3D${user.first_name.split(' ').join('%2520')}%26send_to_email%3D${encodeURI(user.email)}%26channel%3Demail`}>
                                    <i className='material-icons-outlined'>textsms</i>  
                                    <span className='tiny_text'>Send text</span>
                                </a>

                                <div className='overlay_share_button' 
                                    onClick={this.link_button}>
                                    <i className='material-icons-outlined'>link</i>  
                                    <span className='tiny_text'>Copy link</span>
                                </div>

                            </div>
                    }/>

                    <Overlay_popup 
                        open={this.state.open_overlay_4}

                        closer={true}
                        handleClose={() => this.setState({open_overlay_4: false})}
                        overlay_title='Share Gifting Page'
                        
                        body_1={`Let your friends and family know you are saving for ${this.state.child}'s future.`}
                        joker={
                            <div className='overlay_share_button_container'>
                                <a className='overlay_share_button' onClick={() => amplitude.getInstance().logEvent('gifting page: select share channel', {'channel': 'email' })}
                                    href={`mailto:?body=Hey%2C%20we%27re%20saving%20for%20${this.state.child}%27s%20future!%20Here%27s%20a%20link%20for%20you%20to%20invest%20in%20${this.state.child}%20future%20through%20Greatest%20Gift.%0A%0Ahttps%3A%2F%2Fwww.GreatestGiftApp.com%2Fgifts%2F${this.state.slug}`}>
                                    <i className='material-icons-outlined'>email</i>  
                                    <span className='tiny_text'>Send email</span>
                                </a>
                                
                                <a className='overlay_share_button' onClick={() => amplitude.getInstance().logEvent('gifting page: select share channel', {'channel': 'textsms' })}
                                    href={`sms:?&body=Hey%2C%20we%27re%20saving%20for%20${this.state.child}%27s%20future!%20Here%27s%20a%20link%20for%20you%20to%20invest%20in%20${this.state.child}%20future%20through%20Greatest%20Gift.%0A%0Ahttps%3A%2F%2Fwww.GreatestGiftApp.com%2Fgifts%2F${this.state.slug}`}>
                                    <i className='material-icons-outlined'>textsms</i>  
                                    <span className='tiny_text'>Send text</span>
                                </a>

                                <div className='overlay_share_button' 
                                    onClick={this.link_button_2}>
                                    <i className='material-icons-outlined'>link</i>  
                                    <span className='tiny_text'>Copy link</span>
                                </div>

                            </div>
                        }
                    />

                    <Overlay_popup 
                        open            = {this.state.open_overlay_2}
                        closer          = {true}
                        handleClose     = {() => this.setState({open_overlay_2: false})}
                        overlay_title   = 'Advertiser Disclosure'
                        wide            = {true}
                        body_1          = {`Offers are provided to you as a convenience. 
                        Greatest Gift does not endorse, warrant or guarantee the products or services available through the Offers (or any other third-party products or services advertised on or linked from our site), whether or not sponsored.
                        Greatest Gift is not an agent or broker or otherwise responsible for the activities or policies of those websites.
                        Greatest Gift may receive compensation from third parties which may impact the placement and availability of the Offers.
                        If you elect to use or purchase services from third parties, you are subject to their terms and conditions and privacy policy.`}
                    />

                    <Overlay_popup 
                        open            = {this.state.open_overlay_3}
                        closer          = {true}
                        handleClose     = {() => this.setState({open_overlay_3: false})}
                        overlay_title   = 'Email Confirmation'
                        wide            = {false}
                
                        body_1          = {this.state.email_confirmation_message}
                    />

                </main> 

                {/*<Link to='/' className='home_bottom_logo'>*/}
                <a href='https://www.greatestgiftapp.com' className='home_bottom_logo'>
                    <div className='home_bottom_logo'>
                        <img src={logo}/>
                    </div>
                {/*</Link>*/}
                </a>
                
            </div>
        );
    }
}

export default Home;