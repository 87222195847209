import React from 'react';
import './User_guide.css';

 /*
    <User_guide 
        show_guide={true}
        headline='string'
        handleClose={() => this.setState({})}
        text_1='string'
        text_2='string'
    />
 */

class User_guide extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
        };
    }
    render () {
        if (this.props.show_guide) {
            return (
                <div className='primary_guide_container'>
                    
                    <div className='guide_arrow_container'>
                        <div className='arrow_up_outline'></div>
                        <div className='arrow_up'></div>
                    </div>

                    <div className='secondary_guide_container'>
                    
                        <div className={'overlay_icon_and_headline_container'}>
                            <i  className='material-icons' onClick={this.props.handleClose} >close</i>
                            <h2>{this.props.headline}</h2>
                            <div className='overlay_icon_and_headline_container_placeholder'></div>
                        </div>

                        <div className='overlay_text_container'>
                            <p>{this.props.text_1}</p>
                            
                            {this.props.text_2 ? 
                                <p>{this.props.text_2}</p>
                            : null}
                            
                        </div>

                    </div>

                </div>
            );
        } else {
            return null;
        }
    }
}

export default User_guide;