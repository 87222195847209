import React from 'react';
import './Offers_list_container.css';
import Offer from '../Offer/Offer.js';

class Offers_list_container extends React.Component {


    renderOffersList(){
        return this.props.offers.map(offer => {
            return <Offer 
                        affiliate_company={offer.affiliate_company}
                        affiliate_logo= {offer.affiliate_logo}
                        offer_summary={offer.offer_summary}
                        offer_link={offer.offer_link}
                        expanded_info={offer.expanded_info}
                        key={offer.id}
                        /> ;
            });
    }

    render () {
        return (
        <div className='offers_container'>
            {this.renderOffersList()}
        </div>
        );
    }
}

export default Offers_list_container;