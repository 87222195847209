import React from 'react';
//import Confetti from 'react-confetti';
import canvas_confetti from 'canvas-confetti';

// Constants
let CANVASBALLOON = {
    KAPPA: (4 * (Math.sqrt(2) - 1))/3,
    WIDTH_FACTOR: 0.0333,
    HEIGHT_FACTOR: 0.4,
    TIE_WIDTH_FACTOR: 0.12,
    TIE_HEIGHT_FACTOR: 0.10,
    TIE_CURVE_FACTOR: 0.13,
    GRADIENT_FACTOR: 0.3,
    GRADIENT_CIRCLE_RADIUS: 3
};

const balloonPath = `M0,-${CANVASBALLOON.HEIGHT_FACTOR / 2}
    C${CANVASBALLOON.WIDTH_FACTOR / 2},-${CANVASBALLOON.HEIGHT_FACTOR / 2} ${CANVASBALLOON.WIDTH_FACTOR / 2},${CANVASBALLOON.HEIGHT_FACTOR / 2} 0,${CANVASBALLOON.HEIGHT_FACTOR / 2}
    C-${CANVASBALLOON.WIDTH_FACTOR / 2},${CANVASBALLOON.HEIGHT_FACTOR / 2} -${CANVASBALLOON.WIDTH_FACTOR / 2},-${CANVASBALLOON.HEIGHT_FACTOR / 2} 0,-${CANVASBALLOON.HEIGHT_FACTOR / 2}
    M-${CANVASBALLOON.TIE_WIDTH_FACTOR / 2},${CANVASBALLOON.HEIGHT_FACTOR / 2}
    L${CANVASBALLOON.TIE_WIDTH_FACTOR / 2},${CANVASBALLOON.HEIGHT_FACTOR / 2 + CANVASBALLOON.TIE_HEIGHT_FACTOR}
    L0,${CANVASBALLOON.HEIGHT_FACTOR / 2 + CANVASBALLOON.TIE_CURVE_FACTOR}
    Z`;

const balloonPath2 = ' M10 10 C 10 -10, 40 -10, 40 10 C 40 30, 10 30, 10 10 M25 30 L 20 50 L 30 50 L 25 30 Z';


const balloonShape = canvas_confetti.shapeFromPath({ path: balloonPath2 });


class GG_balloons extends React.Component {
    componentDidMount() {
        this.launchConfetti();
    }
    
    launchConfetti() {
        const colors = ['#16DFAD', '#FFD700', '#5481C2', '#F04758'];
        const confettiSource = window.innerWidth < 600 ? 
            { x: window.innerWidth * 0.5, y: window.innerHeight * 1} :
            { x: window.innerWidth * 0.5, y: window.innerHeight * 1};

        const particleCount = window.innerWidth < 600 ? 80 : 100;
        const gravity = -0.8;
        const initialVelocityY = window.innerWidth < 600 ? 15 : 20;
        const tweenDuration = window.innerWidth < 600 ? 300 : 250;

        canvas_confetti({
            particleCount: particleCount,
            startVelocity: initialVelocityY,
            origin: {
                x: confettiSource.x / window.innerWidth,
                y: confettiSource.y / window.innerHeight
            },
            colors,
            shapes: [balloonShape],
            
            angle: 90,
            spread: 360,
            startVelocity: initialVelocityY,
            gravity: gravity,
            ticks: tweenDuration * 2,
            colors: colors,
            scalar: 15, // Adjust the size of the shapes

        });

    }

    render() {
        return null;
    }

}


export default GG_balloons;

/*

class Old_GG_balloons extends React.Component {
    render () {
        return (
            <Confetti 
                colors={['#16DFAD', '#FFD700', '#5481C2', '#F04758']}
                confettiSource={ 
                    {   x: window.innerWidth    * 0.375, 
                        y: window.innerHeight   * (this.props.landing ? 1.8 : 1) , 
                        w: window.innerWidth    * 0.25, 
                        h: window.innerHeight   * 0.0 
                    } 
                }

                height={window.innerHeight * (this.props.landing ? 1.8 : 1)}
                width={window.innerWidth < 600 ? window.innerWidth * 1 : window.innerWidth * 1}
                numberOfPieces={window.innerWidth < 600 ? 125 : 200}
                gravity={-0.11}
                initialVelocityX={window.innerWidth < 600 ? 7 : 14}
                initialVelocityY={window.innerWidth < 600 ? 14 : 10}
                recycle={false}
                tweenDuration={10000}


                drawShape={ctx => {
                    ctx.rotate(Math.PI);
                    let centerX = 1;
                    let centerY = 1;
                    let radius = 20;
                    
                    var handleLength = CANVASBALLOON.KAPPA * radius;
                    
                    var widthDiff = (radius * CANVASBALLOON.WIDTH_FACTOR);
                    var heightDiff = (radius * CANVASBALLOON.HEIGHT_FACTOR);
                    
                    var balloonBottomY = (centerY + radius + heightDiff);

                    ctx.beginPath();
                    // Top Left Curve
                    
                    var topLeftCurveStartX = centerX - radius;
                    var topLeftCurveStartY = centerY;
                    
                    var topLeftCurveEndX = centerX;
                    var topLeftCurveEndY = centerY - radius;
                    
                    ctx.moveTo(topLeftCurveStartX, topLeftCurveStartY);
                    ctx.bezierCurveTo(topLeftCurveStartX, topLeftCurveStartY - handleLength - widthDiff,
                                            topLeftCurveEndX - handleLength, topLeftCurveEndY,
                                            topLeftCurveEndX, topLeftCurveEndY);
                                            
                    // Top Right Curve
                    
                    var topRightCurveStartX = centerX;
                    var topRightCurveStartY = centerY - radius;
                    
                    var topRightCurveEndX = centerX + radius;
                    var topRightCurveEndY = centerY;
                    
                    ctx.bezierCurveTo(topRightCurveStartX + handleLength + widthDiff, topRightCurveStartY,
                                            topRightCurveEndX, topRightCurveEndY - handleLength,
                                            topRightCurveEndX, topRightCurveEndY);
                                                        
                    // Bottom Right Curve
                    
                    var bottomRightCurveStartX = centerX + radius;
                    var bottomRightCurveStartY = centerY;
                    
                    var bottomRightCurveEndX = centerX;
                    var bottomRightCurveEndY = balloonBottomY;
                    
                    ctx.bezierCurveTo(bottomRightCurveStartX, bottomRightCurveStartY + handleLength,
                                            bottomRightCurveEndX + handleLength, bottomRightCurveEndY,
                                            bottomRightCurveEndX, bottomRightCurveEndY);							
                    
                    // Bottom Left Curve
                    
                    var bottomLeftCurveStartX = centerX;
                    var bottomLeftCurveStartY = balloonBottomY;
                    
                    var bottomLeftCurveEndX = centerX - radius;
                    var bottomLeftCurveEndY = centerY;
                    
                    ctx.bezierCurveTo(bottomLeftCurveStartX - handleLength, bottomLeftCurveStartY,
                                            bottomLeftCurveEndX, bottomLeftCurveEndY + handleLength,
                                            bottomLeftCurveEndX, bottomLeftCurveEndY);


                    
                    ctx.fill();
                    
                    // End balloon path
                    
                    // Create balloon tie
                    
                    
                    var halfTieWidth = (radius * CANVASBALLOON.TIE_WIDTH_FACTOR)/2;
                    var tieHeight = (radius * CANVASBALLOON.TIE_HEIGHT_FACTOR);
                    var tieCurveHeight = (radius * CANVASBALLOON.TIE_CURVE_FACTOR);
                    
                    ctx.beginPath();
                    ctx.moveTo(centerX - 1, balloonBottomY);
                    ctx.lineTo(centerX - halfTieWidth, balloonBottomY + tieHeight);
                    ctx.quadraticCurveTo(centerX, balloonBottomY + tieCurveHeight,
                                                centerX + halfTieWidth, balloonBottomY + tieHeight);
                    ctx.lineTo(centerX + 1, balloonBottomY);

                    ctx.fill();

                    // add strings to the balloons
                    ctx.beginPath();
                    ctx.moveTo(centerX, balloonBottomY +tieHeight);
                    ctx.quadraticCurveTo(centerX + 5, balloonBottomY + tieHeight+20,
                        centerX, balloonBottomY + tieHeight+40);
                        ctx.strokeStyle = 'gray';
                    ctx.stroke();                    

                }}
            />
            );
        
    }
}


confettiSource={ window.innerWidth < 600 ? 
                    {   x: window.innerWidth *0, // original was 0.1 
                        y: window.innerHeight *0.05 , 
                        w: window.innerWidth *0.7, 
                        h: window.innerHeight *0.3 
                    } :
                    {   x: window.innerWidth *0.075, 
                        y: window.innerHeight *0.05 , 
                        w: window.innerWidth *0.35, 
                        h: window.innerHeight *0.3 
                    } 
                }

                window.innerWidth < 600 ? 
                    {   x: window.innerWidth    * 0.0,   
                        y: window.innerHeight   * 1 , 
                        w: window.innerWidth    * 1, 
                        h: window.innerHeight   * 0.0
                    } :



                    ctx.beginPath()
                    for(let i = 0; i < 5; i++) {
                    const angle = 0.35 * i
                    const x = (0.2 + (1.5 * angle)) * Math.cos(angle)
                    const y = (0.2 + (1.5 * angle)) * Math.sin(angle)
                    ctx.lineTo(x, y)
                    }
                    ctx.stroke()
                    ctx.closePath()

*/

                    // original way to color the balloons
                    /*

                    // Create balloon gradient
                    
                    var gradientOffset = (radius/3);
                    
                    var balloonGradient =
                        ctx.createRadialGradient(centerX + gradientOffset, centerY - gradientOffset,
                                                        CANVASBALLOON.GRADIENT_CIRCLE_RADIUS,
                                                        centerX, centerY, radius + heightDiff);
                    balloonGradient.addColorStop(0, colors[Math.floor(Math.random()*4)]);
                    // balloonGradient.addColorStop(0.7, this.darkColor.rgbString());
                    
                    ctx.fillStyle = balloonGradient;
                    */