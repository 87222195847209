import React from 'react';
import './Goal_graphics.css';

class Goal_graphics extends React.Component {
    constructor(props) {
        super(props);

        this.barOnePercent = this.barOnePercent.bind(this);
        this.barTwoPercent = this.barTwoPercent.bind(this);
    }

    barOnePercent() {
        let d = this.props.saved_to_date;
        let n = this.props.target_savings;
        let percent = d/n;
        let rounded = percent.toFixed(2);
        let finalPresentation = rounded * 100;
        return (String(finalPresentation)+'%');
    }

    barTwoPercent() {
        let d = this.props.potential_profit - this.props.saved_to_date;
        let n = this.props.target_savings;
        let percent = d/n;
        let rounded = percent.toFixed(2);
        let finalPresentation = rounded * 100;
        return (String(finalPresentation)+'%');
    }

    getOutlineWidth() {
        let d = this.props.target_savings - this.props.potential_profit;
        let n = this.props.target_savings;
        let percent = d/n;
        let rounded = percent.toFixed(2);
        let finalPresentation = rounded * 100;
        return (String(finalPresentation)+'%');
    }

    render () {
        return (
            <div className='goal_graphics_container'>
                
                <div className='bar_chart'>
                    <div className='bar_one' style={{width: this.barOnePercent()}}><p className='small_text'></p></div>
                    <div className='bar_two' style={{width: this.barTwoPercent()}}><p className='small_text'></p></div>
                    <div className='bar_outline' style={{width: this.getOutlineWidth()}}></div>
                </div>
        
                
                <div className='legend_container'>
                    
                    <div className='legend_bar_one'>
                        <div className='color_for_legend'></div>
                        <div className='text_for_legend'><p>Total Saved {this.barOnePercent()}</p></div>
                    </div>
                    
                    <div className='legend_bar_two'>
                        <div className='color_for_legend'></div>
                        <div className='text_for_legend'><p>Potential Growth Profit {this.barTwoPercent()}</p></div>
                    </div>
                
                </div>
            
            </div>
        );
    }
}

export default Goal_graphics;

/*
    saved_to_date={this.props.saved_to_date}
    target_savings={this.props.target_savings}
    target_date={this.props.target_date}
    potential_profit={this.props.potential_profit}
*/