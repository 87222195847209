import React, { useState, useEffect } from 'react';
import './Cards.css';

/*
    props:
    {
       imageSrc, 
       promoCode,
       offers = [
        {url: '', text_1: '', text_2: ''}
       ] 
    }

*/


class Offer_card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOffer: {
                url: '',
                text1: '',
                text2: ''
            }
        };

        this.randomizeOffer = this.randomizeOffer.bind(this);    

    }
    componentDidMount() {
        this.randomizeOffer();
    }

    randomizeOffer() {
        let { offers } = this.props;
        let randomIndex = Math.floor(Math.random() * offers.length);
        this.setState({
            selectedOffer: offers[randomIndex]
        });
    }

    handleOnClick = () => {
        let { promoCode } = this.props;
        if (promoCode) {
            navigator.clipboard.writeText(promoCode)
            .then(() => {
                alert('Promo code copied successfully!');
            })
            .catch(err => {
                alert('Failed to copy promo code');
            });
        }
    };

    render() {
        let { imageSrc } = this.props;
        let { url, text1, text2 } = this.state.selectedOffer;
        return (
            <a className='offer_card_w_img' 
                href={url}
                target="_blank" 
                rel="noopener noreferrer"
                onClick={this.handleOnClick}>
                <img src={imageSrc}/>
                <div className='offer_card_w_img_txt'>
                    <p>{text1}</p>
                    <p>{text2}</p>
                    {this.props.promoCode && <p className='small_text green_text'>Get 10% off with code {this.props.promoCode}</p>}
                </div>
            </a>
        );
    }
    
}

export default Offer_card;