import React from 'react';
import './Main_account_creation.css';

import Tracker from '../../../layout_components/Tracker/Tracker';
import Account_step_1 from '../Account_step_1/Account_step_1.js';
import Account_step_2 from '../Account_step_2/Account_step_2.js';
import Account_step_3 from '../Account_step_3/Account_step_3.js';
import Account_step_4 from '../Account_step_4/Account_step_4.js';
import Account_step_5 from '../Account_step_5/Account_step_5.js';
import MasterButton from '../../../general_components/MasterButton/MasterButton.js';

import GGApi from '../../../../utils/GGApi.js';
import validations from '../../../../utils/validations.js';
import Header_for_multistep from '../../../layout_components/Header_for_multistep/Header_for_multistep';
import Overlay_popup from '../../../general_components/Overlay_popup/Overlay_popup.js';

import cover_picture from '../../../../images/illustrations/personalized_cash_gift_app.png';
import { Link } from 'react-router-dom';

import config from '../../../../utils/config.js'
import amplitude from 'amplitude-js';
import { Helmet } from 'react-helmet';
amplitude.getInstance().init(config.amp_key);

class Main_account_creation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //form details
            currentStep: 1,
            buttonText: 'Next',
            buttonLinks: false,
            open_overlay: false,

            //submission details
            first_name: '',
            last_name: '',
            user_email: '',
            user_phone_number: '',
            phone_verification: '',
            username: '',
            user_password: '',
            user_password_verification: '',

            //supporting states for masking purposes
            phone_support:'',

            // uniqueness of inputs
            email_unique: '',
            phone_unique: '',
            username_unique: '',

            // navigation
            next_page: '/greatest_gift_intro',
            next_page_params: ''
        }

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.checkUnique = this.checkUnique.bind(this)
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.overlayButton1Handler = this.overlayButton1Handler.bind(this);
    }

    handleButtonClick() {
        // scroll to top of page
        window.scrollTo(0, 0);

        let step = this.state.currentStep;        
        if (step === 1) {
            if(!this.state.first_name || !this.state.last_name) {
                alert('Please submit a valid first and last name, up to 50 characters.')
            } else {
            // amplitude
            amplitude.getInstance().logEvent(`signup: submit name`, {'step': step});
            //
            step += 1;
            this.setState({currentStep: step});
            }
        } else if (step === 2) {
            // validate the email
            if (!validations.validateEmail(this.state.user_email)) {
                alert('Please enter a valid email address');
                
                // check that the email is not used already
            } else if (!this.state.email_unique) {
                alert('This email is already associated with a different user. Please enter a different, unique email address.');
                
            // validate the phone number
            } else if(!validations.validatePhone(this.state.user_phone_number)) {
                alert('Please enter a valid 10-digit US phone number in the following format:\nXXX-XXX-XXXX');
            
                // check that the phone is not used already
            } else if (!this.state.phone_unique) {
                alert('This phone number is already associated with a different user. Please enter a different, unique phone number.');
                
            } else {
                GGApi.verify_phone_number(this.state.user_phone_number);
                // amplitude
                amplitude.getInstance().logEvent(`signup: submit contact info`, {'step': step});
                //
                step += 1;
                this.setState(
                    {
                    currentStep: step, 
                    buttonText: 'Verify Number', 
                    });
            };

        } else if (step === 3) {
            // check the otp
            GGApi.verify_check_number(this.state.user_phone_number, this.state.phone_verification)
            .then( response => {
                if (!(response.status === 200)) {
                    alert('Verification code incorrect. Please try again');
                } else {
                // amplitude
                amplitude.getInstance().logEvent(`signup: verify contact info`, {'step': step});
                //
                step += 1;
                this.setState(
                    {
                    currentStep: step, 
                    buttonText: 'Create Account', 
                    });
                };
            });
        } else if (step === 4) {
            if(this.state.open_overlay){
                return;
            }
            if (!this.state.username_unique) {
                alert('This username is unavailable. Please try a different username.');
            } else if (this.state.user_password.length < 8) {
                alert('Please enter a password with at least 8 characters.');
            } else if (!(this.state.user_password === this.state.user_password_verification)) {
                alert ('Please make sure the passwords in both fields match.')
            } else {
                
                // use the gg api here to send the new user info to the database
                let newUser = {
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    user_email: this.state.user_email.toLowerCase(),
                    user_phone_number: this.state.user_phone_number,
                    username: this.state.username.toLowerCase(),
                    user_password: this.state.user_password
                }
                // need to add a Captcha here!
                // the following creates a GG user, and also a stripe account and customer. 
                GGApi.create_user(newUser).then(response => {
                    GGApi.create_stripe_account();
                    GGApi.create_stripe_customer();
                    // amplitude
                    amplitude.getInstance().logEvent(`signup: finish signup`, {'step': step});
                    //
                }); // if there is an error, alert the user, and start from the beginning of the flow?
                
                /*
                // move on to next step in the form
                step += 1;
                this.setState(
                    {
                    currentStep: step, 
                    buttonText: 'CONTINUE TO MAIN PAGE',
                    buttonLinks: true
                    });
                    */    
                
                //// open the overlay
                this.setState({
                    open_overlay: true
                });
                ////
            }
            
        } else if (step === 5) {
            /* reset the state for the next flow */
            this.setState(
                {
                currentStep: 1,
                buttonText: 'Next'
                });

        } 
    }


    handleBackButton() {
        let step = this.state.currentStep;
        if(this.state.open_overlay){
            return;
        }
        // amplitude
        amplitude.getInstance().logEvent('signup: click back', {'step': step});
        if (step === 1) {
            this.props.history.goBack()

        } else if (step === 2) {
            step -= 1;
            this.setState({currentStep: step});

        } else if (step === 3) {
            step -= 1;
            this.setState(
                {   currentStep: step,
                    buttonText: 'Next'
                });
            
        } else if (step === 4) {
            step -= 1;
            this.setState(
                {   currentStep: step,
                    buttonText: 'Verify Number'
                });
        } else if (step === 5) {
            this.props.history.goBack()
        }

    }

    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }

    handleChangeEmail(event) {
        let { name, value} = event.target;
        value = value.toLowerCase();
        this.setState({[name]: value});
    }
    
    checkUnique(event, type) {
        if (type === 'email') {
            GGApi.uniqueEmail(event.target.value.toLowerCase()).then(unique => {
                if(!unique) {
                    alert('This email is already associated with a different user. Please enter a different, unique email address.');
                };
                this.setState({email_unique: unique});
            });
        } else if (type === 'phone') {
            if(event.target.value.length === 12) {
                GGApi.uniquePhone(event.target.value).then(unique => {
                    if(!unique) {
                        alert('This phone number is already associated with a different user. Please enter a different, unique phone number.');
                    }
                    this.setState({phone_unique: unique});
                });
            }
            
        } else if (type === 'username') {
            GGApi.uniqueUser(event.target.value).then(unique => {
                this.setState({username_unique: unique});
            });
        };
    }

    handlePhoneChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value, phone_support: value});

        if (event.target.value.length === 3 && this.state.phone_support.length === 2) {
            let newValue = value + "-";
            this.setState({[name]: newValue});
        } else if (event.target.value.length === 7 && this.state.phone_support.length === 6) {
            let newValue = value + "-";
            this.setState({[name]: newValue});
        };
        
    } 

    componentDidMount() {
        this.props.removeNavBar();
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('source')){
            this.setState(
                {   next_page: '/'+urlParams.get('source'),
                    next_page_params: '?' + window.location.search.slice(20)
            })

        }
        // amplitude
        amplitude.getInstance().logEvent(`signup: start signup`);
        //
    }

    componentWillUnmount() {
        this.props.restoreNavBar();
    }
    
    overlayButton1Handler(){
        console.log('overlay button click.')
    }

    render () {
        return (
       <div>
        <Header_for_multistep 
            text='Create An Account'
            handleBackButton={() => this.handleBackButton()}
        />
       <main>
    <Helmet>
                <title>Sign Up | Greatest Gift</title>
                <meta property="og:title" content='Sign Up | Greatest Gift'/>
                <link rel="icon" href="/static/media/logo_var_2.3a7e8fdf.png" />
                <link rel="canonical" href="https://app.greatestgiftapp.com/new_account"/>
                <meta name="description"
                    content='Sign up for your Greatest Gift account. Send and receive monetary gifts for children`s long term savings.' 
                />
                <meta property="og:description" 
                    content='Sign up for your Greatest Gift account. Send and receive monetary gifts for children`s long term savings.' 
                />
    </Helmet>

            <Tracker    numOfSteps={4} 
                        currentStep={this.state.currentStep}
                        tags={['Name', 'Contact Info', 'Verify', 'Username & Password']}
                        />

            <Account_step_1 currentStep={this.state.currentStep} 
                            handleButtonClick={this.handleButtonClick} 
                            handleChange={this.handleChange}
                            buttonText={this.state.buttonText}
                            first_name={this.state.first_name}
                            last_name={this.state.last_name}
                            />

            <Account_step_2 currentStep={this.state.currentStep} 
                            handleButtonClick={this.handleButtonClick} 
                            handleChange={this.handleChange}
                            buttonText={this.state.buttonText}

                            handleChangeEmail={this.handleChangeEmail}
                            checkUnique={this.checkUnique}
                            handlePhoneChange={this.handlePhoneChange}
                            user_email={this.state.user_email}
                            user_phone_number={this.state.user_phone_number}
                            />

            <Account_step_3 currentStep={this.state.currentStep} 
                            handleButtonClick={this.handleButtonClick} 
                            handleChange={this.handleChange}
                            buttonText={this.state.buttonText}

                            phone_verification={this.state.phone_verification}
                            />

            <Account_step_4 currentStep={this.state.currentStep} 
                            handleButtonClick={this.handleButtonClick} 
                            handleChange={this.handleChange}
                            checkUnique={this.checkUnique}
                            buttonText={this.state.buttonText}
                            username={this.state.username}
                            user_password={this.state.user_password}
                            user_password_verification={this.state.user_password_verification}
                            />

            {this.state.currentStep === 4 ? 
                <div className='terms_of_service_container'>
                    <p className='tiny_text'>By registering your account, you agree to our 
                        <a className='secondary_dark'
                            href='https://app.GreatestGiftApp.com/legal'
                            target='_blank'> Services Agreement
                        </a> and the   
                        <a className='secondary_dark' 
                            href='https://stripe.com/connect-account/legal/full'
                            target='_blank'> Stripe Connected Account Agreement.
                        </a>
                    </p>
                </div> 
                : null
            }

            <Overlay_popup 
                open            = {this.state.open_overlay}
                closer          = {false}
                overlay_title   = 'Congratulations!'
                wide            = {false}
        
                body_1          = "You've joined a community of Great Gifters!"
                
                image           = {cover_picture}
                button_1        = {{    text: this.state.next_page === '/give-a-gift' ? 'Back to gift' : 'next',
                                        links: true,
                                        linkTo: this.state.next_page + this.state.next_page_params,
                                        handler: this.overlayButton1Handler
                                    }}    
                joker           = {
                                    <div>
                                        <Link to='/home'>
                                            <p className='secondary_dark'>Skip</p>
                                        </Link>
                                    </div>
                                    }
            />

            <Account_step_5 currentStep={this.state.currentStep} 
                            handleButtonClick={this.handleButtonClick} 
                            buttonText={this.state.buttonText}
                            />

        
        </main>
        
           <div className='buffer'></div>

        <MasterButton 
            buttonText={this.state.buttonText} 
            handleButtonClick={this.handleButtonClick}
            links={this.state.buttonLinks}
            linkTo='/home'
        />
        </div>
        );
    }
}

export default Main_account_creation;