
const validations = {
    // validate the text input phone number
    // input is a string: '012-345-6789' 
    // expected output is true if it is a valid phone number, and false otherwise
    validatePhone(phone){
        const phonePattern = /^\(?[0-9]{3}\)?[-][0-9]{3}[-][0-9]{4}$/;

        if(!(phonePattern.test(phone))) {
            return false;
        };

        // if no issues found, return true
        return true;
    },

    // validate the text input ss number
    // input is a string: 'XXXX' 
    // expected output is true if it is a valid 4 digit number, and false otherwise
    validateFourDigits(digits){
        const digitsPattern = /^\d{4}$/;

        if(!(digitsPattern.test(digits))) {
            return false;
        };

        // if no issues found, return true
        return true;
    },

    // validate a 6 digit number (for otp)
    // input is a string: 'ABCDEF' 
    // expected output is true if it is a valid 4 digit number, and false otherwise
    validateSixDigits(digits){
        const digitsPattern = /^\d{6}$/;

        if(!(digitsPattern.test(digits))) {
            return false;
        };

        // if no issues found, return true
        return true;
    },

    // validate the text input email
    // input is a string: 'example@email.com' 
    // expected output is true if it is a valid email, and false otherwise
    // valid email is determined as 'string + @ + string + . + string'
    validateEmail(email){
        const emailPattern = /\S+@\S+\.\S+/;

        if(!(emailPattern.test(email))) {
            return false;
        };

        // if no issues found, return true
        return true;
    },
    

};

export default validations;
