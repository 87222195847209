import React from 'react';
import './Account_step_1.css';

class Account_step_1 extends React.Component {
    render () {
        if (this.props.currentStep !== 1) {
            return null;
        }
        
        return (
           <div>
                <h2 className='center'>Let's start with some details...</h2>
                <h2 className='center'>What is your legal name?</h2>
                
                <form>
                    <p className='form_label'>First name </p>
                    <input 
                        type='text' 
                        id='user_first_name' 
                        onChange={this.props.handleChange}
                        name='first_name' 
                        placeholder='Jane' 
                        value={this.props.first_name}
                        autoFocus
                        maxLength='50'
                        autoComplete='off'
                        
                        />
                    {/*<p className='validation_message_error tiny_text' display={'none'}>Please enter a valid first name, up to 50 characters</p>*/}
                    
                    <p className='form_label'>Last name </p>
                    
                    <input 
                        type='text' 
                        id='user_last_name' 
                        onChange={this.props.handleChange}
                        name='last_name' 
                        placeholder='Diaz' 
                        value={this.props.last_name}
                        maxLength='50'
                        autoComplete='off'
                        
                        />
                    
                    
                </form>

                
           </div>
        );
    }
}

export default Account_step_1;