const phones = {

    // turn a formatted phone number into a US phone number formatted the way we want it
    // input is phone number in ANY format
    // output is 111-111-1111
    
    from_copy_to_gg(number) {
        
        //console.log(number);

        const cleanedNumber = number.replace(/[^+\d]/g, '');
        
        //console.log("cleanded number:", cleanedNumber)
        let localNumber = cleanedNumber;

        if (cleanedNumber.startsWith('+') && cleanedNumber.length !== 10) {
            // Extract the country code and local number
            const match = cleanedNumber.match(/\+(\d{1,3})(\d{10})$/);

            if (match) {
                const countryCode = match[1];
                localNumber = match[2];
                //console.log(1)
        
                // Check if the country code is US (1)
                if (countryCode !== '1') {
                    alert('Please enter a valid US-based phone number.');
                    //console.log('Please enter a valid US-based phone number.');
                    //console.log(2)
                    return ''; // Return an empty string to reset the input
                }
            } else {
                alert('Please enter a valid US-based phone number.');
                //console.log('Please enter a valid US-based phone number.');
                //console.log(3)
                return ''; // Return an empty string to reset the input
            }
        } else {
            // If no country code is provided, assume the number is local and clean it
            localNumber = cleanedNumber.replace(/\D/g, '');
        }
    
        // Format the local number as 123-456-7890
        if (localNumber.length <= 3) {
            return localNumber;
        } else if (localNumber.length <= 6) {
            return `${localNumber.slice(0, 3)}-${localNumber.slice(3)}`;
        } else {
            return `${localNumber.slice(0, 3)}-${localNumber.slice(3, 6)}-${localNumber.slice(6, 10)}`;
        }
    }
};

export default phones;




/*

HAPPY PATHS

console.log("Without Country Code:", "1234567890", phones.from_copy_to_gg("1234567890"))

console.log("With US Country Code:", "+11234567890", phones.from_copy_to_gg("+11234567890"))

console.log("Formatted US Number:", "123-456-7890", phones.from_copy_to_gg("123-456-7890"))

console.log("US Number with Parentheses:", "(123) 456-7890", phones.from_copy_to_gg("(123) 456-7890"))

console.log("Incomplete US Number:", "123456", phones.from_copy_to_gg("123456"));

console.log("Mixed Characters:", "+1(123)-456-7890", phones.from_copy_to_gg("+1(123)-456-7890"));

console.log("Extra Characters:", "123-456-7890#", phones.from_copy_to_gg("123-456-7890#"));

console.log("US Number with Country Code in Parentheses:", "+1(123)4567890", phones.from_copy_to_gg("+1(123)4567890"));


1234567890
+11234567890
123-456-7890
(123) 456-7890

123456
+1(123)-456-7890
123-456-7890#5510
+1(123)4567890


UNHAPPY PATHS


console.log("UK Number with Country Code:", "+441234567890", phones.from_copy_to_gg("+441234567890"));

console.log("Canada Number with Country Code:", "+14161234567", phones.from_copy_to_gg("+14161234567"));

console.log("Germany Number with Country Code:", "+4915212345678", phones.from_copy_to_gg("+4915212345678"));

console.log("Australia Number with Country Code:", "+611234567890", phones.from_copy_to_gg("+611234567890"));

console.log("UK Number with Country Code in Parentheses:", "+44(123)4567890", phones.from_copy_to_gg("+44(123)4567890"));


 +441234567890
 +14161234567
 +4915212345678
 +611234567890
 +44(123)4567890

*/