import React from 'react';
import './Goals.css';

import Goal from './Goal.js';

import College from '../../../../images/goals/college_fund.png'
import Future from '../../../../images/goals/future_savings.png'
import Puppy from '../../../../images/goals/puppy_fund.png'
import House from '../../../../images/goals/home.png'
import First from '../../../../images/goals/car_fund.png'
import Emergency from '../../../../images/goals/emergency_fund.png'
import Financial from '../../../../images/goals/dollar_signs.png'
import Disney from '../../../../images/goals/disney_trip.png'
import Crypto from '../../../../images/goals/goal_crypto_fund.png'
import Diaper from '../../../../images/goals/diaper_fund.png'
import Adulting from '../../../../images/goals/adulting_fund.png'
import Wedding from '../../../../images/goals/goal_wedding_fund.png'
import Vacation from '../../../../images/goals/vacation_fund.png'
import Adventure from '../../../../images/goals/adventure_fund.png'




class Goals_list extends React.Component {
    constructor(props) {
        super(props);

        this.renderGoalsList = this.renderGoalsList.bind(this);
    }

    renderGoalsList(){
      let options = [
        { goal_name:'College Fund',
          goal_img: College},
        { goal_name:'Future Savings',
          goal_img: Future},
        { goal_name:'Diaper Fund',
          goal_img: Diaper},
        { goal_name:'Puppy Fund',
          goal_img: Puppy},
        { goal_name:'House Fund',
          goal_img: House},
        { goal_name:'First Car Fund',
          goal_img: First},
        { goal_name:'Adulting Fund',
          goal_img: Adulting},
        { goal_name:'Emergency Fund',
          goal_img: Emergency},
        { goal_name:'Financial Independence',
          goal_img: Financial},
        { goal_name:'Disney Trip Fund',
          goal_img: Disney},
        { goal_name:'Crypto Fund',
          goal_img: Crypto},
        { goal_name:'Wedding Fund',
          goal_img: Wedding},
        { goal_name:'Vacation Fund',
          goal_img: Vacation},
        { goal_name:'Adventure Fund',
            goal_img: Adventure},
        ];
        let selected = false;
        let i = 0;

        options = this.props.goals_list ? this.props.goals_list : options;

        return options.map(goal => {
            if(this.props.goals.includes(goal.goal_name.toLocaleLowerCase())){
              selected = true;
            } else {
              selected = false;
            }
            i++;
            return (
                <Goal
                key={i}
                goal_name={goal.goal_name}
                goal_img={goal.goal_img}
                selected={selected}
                toggle_goal={this.props.toggle_goal}
                />
            )
        })  
    }
    
    render () {
        return (
            <div className={`goals_list_container ${this.props.goals_per_line ? 'goals_per_line_'+this.props.goals_per_line : '' }`}>
                {this.renderGoalsList()}
            </div>
        );
    }
}

export default Goals_list;