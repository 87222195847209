import React from 'react';
import './Gifting_page_setup.css';

import {Helmet} from "react-helmet";

import MasterButton from '../../general_components/MasterButton/MasterButton.js';
import Selector_field from '../../general_components/Selector_field/Selector_field';
import GG_loader from '../../general_components/GG_loader/GG_loader';
import GGApi from '../../../utils/GGApi';
import Overlay_popup from '../../general_components/Overlay_popup/Overlay_popup';

import toggle_on from '../../../images/icons/toggle_on.png';
import toggle_off from '../../../images/icons/toggle_off.png';
import Pic_selector from '../../general_components/Pic_selector/Pic_selector';
import { Link } from 'react-router-dom';

import config from '../../../utils/config';
import amplitude from 'amplitude-js';
import Goals_list from './Goals/Goals_list.js';
import Image_cropper from '../../general_components/Pic_selector/Image_cropper.js';
import MUI_Switch from '../../general_components/material_ui/mui_Switch.js';
import Dropdown_input from '../../general_components/Dropdown_input/Dropdown_input.js';
amplitude.getInstance().init(config.amp_key);


class Gifting_page_setup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        // Mechanics:
            loading: false,
            access: '',
            open_overlay: false,
            button_active: true,
            open_cropper: false,
            show_activity: true,
            display_other_registries: false,
            other_registries: [],
            registry_provider: '',
            registry_link: '',

        // From db:
            user_name: 'user',
            receiver_name: 'receiver',
            gift_page_url: 'https://www.greatestgiftapp.com/gifts/',
        
        // Inputs:
            page_active: true,
            gifting_page_welcome_message: '',
            media_file: '',
            local_file_url: '',
            goals: [],

            original_media_file: ''
        }

        this.handleButtonClick  = this.handleButtonClick.bind(this);

        this.handleChange       = this.handleChange.bind(this);
        this.handlePicChange    = this.handlePicChange.bind(this);
        this.toggle_goal        = this.toggle_goal.bind(this);
        
        this.handle_add_registry    = this.handle_add_registry.bind(this);
        this.handle_remove_registry = this.handle_remove_registry.bind(this);
        
        this.render_media       = this.render_media.bind(this);
    }


    componentDidMount() {   
        // get details from back end
       
        GGApi.get_plan_by_id(this.props.match.params.plan_id).then(response => {
            if (response.fetched_plan) {
                
                // amplitude
                amplitude.getInstance().logEvent('gifting page: view setup page', {'active_page': response.fetched_plan.page_active})

                let user_name = response.fetched_plan.first_name + ' ' + response.fetched_plan.last_name;
                let receiver_name = response.fetched_plan.account_name
                let page_active = response.fetched_plan.page_active;
                let gifting_page_welcome_message = response.fetched_plan.welcome_message;
                let media_file = response.fetched_plan.media_url;
                let original_media_file = response.fetched_plan.media_url;
                let webflow = response.fetched_plan.wf_item_id;
                let gift_page_url = webflow ? 
                'https://www.greatestgiftapp.com/gifts/' + response.fetched_plan.slug : 
                'https://app.greatestgiftapp.com/registry/' + response.fetched_plan.slug;

                let show_activity = response.fetched_plan.show_activity;
                let display_other_registries = response.fetched_plan.display_other_registries;
                let other_registries = response.other_registries;
                
                // clean up goals:
                let goals = response.fetched_plan.goals;
                goals = goals.split('; ');
                goals.pop();                

                // set the gifting page items from the response in the state:
                this.setState({
                    loading: false, 
                    
                    user_name: user_name,
                    receiver_name: receiver_name,
                    gift_page_url: gift_page_url,
                    
                    page_active: page_active,
                    gifting_page_welcome_message: gifting_page_welcome_message,
                    media_file: media_file,
                    original_media_file: original_media_file,
                    goals: goals,

                    webflow: webflow,

                    display_other_registries: display_other_registries,
                    show_activity: show_activity,
                    other_registries: other_registries

                });
            };
            if(response.access === 'No-access') {
                this.setState({loading: false, access: response.access})
            };
        });

    }

    // Button handler:

    handleButtonClick(){
        if(!this.state.button_active){
            return;
        }

        // start gg loader
        this.setState({loading: true, button_active: false})
        
        // if new media was selected - upload media
        // otherwise - don't change it
        if(this.state.media_file && this.state.media_file !== this.state.original_media_file){
            GGApi.upload_media(this.state.media_file, 'gifting-page-media').then(response => {
                // handle media upload error
                let media;
                if(response.errors){
                    alert(response.errors[0].detail + ' \nThere was an issue when attempting to upload the image. Please try again.')
                } else if(response.mediaUrl){
                    media = response.mediaUrl;
                };

                // update gifting page - 
                    // active y/n
                    // welcome message
                    // media
                    // goals
                
                // temporary - set page active as true. doesn't allow the option to toggle the page active
                //let page_active = this.state.page_active
                let page_active = true; 

                GGApi.update_gifting_page(this.props.match.params.plan_id, page_active,  this.state.gifting_page_welcome_message, media, 
                    this.state.goals, 
                    this.state.show_activity, 
                    this.state.display_other_registries,
                    this.state.other_registries
                )
                .then(response => {
                    if(!response.success){
                        this.setState({loading: false, button_active: true})
                        alert('There was an issue when trying to update the gifting page. Please try again, or contact our support team for further assistance.')
                    } else if (response.success){
                        // get the slug from the back-end
                        // end gg loader
                        // open a success pop up? 
                        this.setState({loading: false, open_overlay: true, button_active: false})
                        // amplitude
                        amplitude.getInstance().logEvent('gifting page: confirm gifting page', {'active_page': this.state.page_active})
                    }
                })
            });

        } else { 
            // temporary - set page active as true. doesn't allow the option to toggle the page active
            //let page_active = this.state.page_active
            let page_active = true; 
            GGApi.update_gifting_page(this.props.match.params.plan_id, page_active,  this.state.gifting_page_welcome_message, this.state.media_file, this.state.goals, 
                this.state.show_activity, 
                this.state.display_other_registries,
                this.state.other_registries)
            .then(response => {
                if(!response.success){
                    this.setState({loading: false, button_active: true})
                    alert('There was an issue when trying update the gifting page. Please try again, or contact our support team for further assistance.')
                } else if (response.success){
                    // get the slug from the back-end
                    // end gg loader
                    // open a success pop up? 
                    this.setState({loading: false, open_overlay: true, button_active: false})
                    // amplitude
                    amplitude.getInstance().logEvent('gifting page: confirm gifting page', {'active_page': this.state.page_active})
                }
            })
        }
        
    }

    
    // Event handlers:

    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }

    handlePicChange(event) {
        let file = event.target.files[0];
        // try to convert here to jpeg?
        if(!file){
            return;
        }
        if(file.size > 4194304) {
            alert('Please select a photo with a max size of 4MBs.');
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            this.setState({media_file: event.target.files[0],  local_file_url: reader.result, open_cropper: true });
        };
        reader.readAsDataURL(file);  // This converts the file to a base64 string

        this.setState(
            {media_file: event.target.files[0], open_cropper: true}
        );
    }

    toggle_goal(goal){
        let goals = this.state.goals;
        let new_goals;
        let lower_case_goal = goal.toLocaleLowerCase();
        if(goals.includes(goal.toLocaleLowerCase())){
            let index = goals.findIndex( item => 
                item === lower_case_goal
            );
            new_goals = goals.slice(0, index).concat(goals.slice(index + 1));
            this.setState({goals: new_goals})
        } else {
            goals.push(goal.toLocaleLowerCase());
            this.setState({goals: goals})
        }
    }

    handle_add_registry(){
        if(this.state.registry_provider && this.state.registry_link){
            
            let new_registry = {
                registry_provider: this.state.registry_provider.title, 
                registry_link: this.state.registry_link
            };

            let list_of_registries = this.state.other_registries;

            // Check if the registry already exists
            let registry_exists = list_of_registries.some(registry =>
                registry.registry_provider === new_registry.registry_provider &&
                registry.registry_link === new_registry.registry_link
            );

            if (registry_exists) {
                alert('This registry already exists in your list.');
            } else {
                list_of_registries.push(new_registry);
    
                this.setState({
                    other_registries: list_of_registries, 
                    registry_provider: '', 
                    registry_link: ''
                })

            }
        }
    }

    handle_remove_registry(registry_provider, registry_link) {
        console.log('click', registry_provider, registry_link)
        let list_of_registries = this.state.other_registries;
    
        // Filter out the registry that matches the provider and link
        let updated_registries = list_of_registries.filter(registry =>
            !(registry.registry_provider === registry_provider && registry.registry_link === registry_link)
        );
        console.log(updated_registries);
    
        // Update the state with the new list of registries
        this.setState({ other_registries: updated_registries });
    }

    // Render methods:

    render_media(){
        if(!this.state.media_file){
            return null;
        } else {
            return (
                <div>
                <p className='form_label'>1. Banner Picture:</p>
                <img 
                src={this.state.media_file}
                className='gifting_page_picture' />
                <p className='cursor align_center green_text'>Change picture</p>
                </div>
            )
        }
    }
 
    render () {
        
        if(this.state.loading) {
            return (
                <GG_loader/>
            )
        }

        if (this.state.access === 'No-access') {
            return (
                <main>
                    <h2>We're sorry, but the page you are trying to locate is unavailable.</h2>
                </main> 
            )
        } else {
            return (
            <div className='home_background'>
                <main>

                    <Helmet>
                        <title>{`Gifting Page Set up | Greatest Gift`}</title>
                        <meta name="description"
                        content='Set up a gifting page to receive monetary gifts from family and friends.' 
                    />
                    </Helmet>

                    {/* activate page (currently not relevant - webflow doesn't allow to unpublish individual items)
                    <div className='toggle_switch_container'>
                        <p>Gifting Page Active</p> 
                        <img
                            className='toggle_switch_icon' 
                            src={this.state.page_active ? toggle_on : toggle_off}
                            onClick={() => this.setState({page_active: !this.state.page_active})}
                        />
                    </div>
                    */}

                    {/* picture */}
                    {
                        
                        
                        <Pic_selector 
                        label={'1. Add a banner picture:'}
                        handlePicChange={this.handlePicChange}
                        file={this.state.media_file}
                        uploaded_file={this.state.original_media_file === this.state.media_file}
                        custom_height={"200-420"}/>
                    }
                    
                    <Overlay_popup 
                        open            = {this.state.open_cropper}
                        closer          = {true}
                        handleClose     = {() => this.setState({open_cropper: false})}

                        overlay_title   = 'Edit your picture'
                        wide            = {true}
                        joker           = {
                        
                        <div>
                        <Image_cropper 
                        imageSrc={this.state.local_file_url ? this.state.local_file_url : this.state.original_media_file}
                        onCropComplete={(croppedImage) => {
                                console.log('crop complete', croppedImage)
                                this.setState({media_file: croppedImage, open_cropper: false})
                            }}
                        />
                        </div>}
                    />

                    
                    {/* welcome message */}
                    <form style={{ 'margin-top': `16px`}}>

                    <Selector_field 
                        options             = {
                                            [
                                            
                                            // saving & investing for future
                                            `${this.state.user_name} is saving for ${this.state.receiver_name}’s future! Send a gift with Greatest Gift, and invest in ${this.state.receiver_name}’s future today.\nHappy Gifting,\nYour friends at Greatest Gift.`, 
                                            
                                            // diaper fund
                                            `We’re so excited to welcome our little one soon!\n\nInstead of traditional gifts, we’re asking for contributions to our diaper fund to help us keep our baby clean and comfortable.\n\nYour support will help us cover diaper expenses and also start a savings fund for baby’s future needs.\n\nThank you for your love and support!`,
                                            `Guess what? We’re having a baby!\n\nInstead of showering us with clothes and toys, help us stock up on diapers by contributing to our diaper fund.\n\nLet’s keep those diaper changes flowing smoothly!`,
                    
                                            // future adventures
                                            `We're so excited to celebrate with you!\n\nIf you're thinking of a gift, we’ve set up a registry on Greatest Gift for ${this.state.receiver_name}’s future adventures`,
                                            
                                            // thinking ahead
                                            `As we celebrate ${this.state.receiver_name}’s big day, we're also thinking ahead!\n\nWe've set up a this registry to help build ${this.state.receiver_name}’s future`,
                    
                                            // long term savings
                                            `Thank you for visiting ${this.state.receiver_name}'s gifting page.\n\nThrough this page, you can give cash gifts for ${this.state.receiver_name}'s long term savings.`,
                                            
                                            // diaper and future
                                            `Our hearts are full as we prepare for our new baby!\n\nWe’re setting up a diaper fund and would appreciate your support.\n\nContributions will go towards both diaper expenses and a savings account for our little one’s future. Thank you for being part of our journey!`,
                                            
                                            // goal
                                            `I am saving for [goal], and a cash gift from you would be incredibly helpful. Any amount would mean the world!`,
                                            
                                            // graduation
                                            `Join in on my 'Graduation to Greatness' fund - your cash gift will help launch my exciting next chapter!`,
                                            `As I prepare for college, any cash gifts will go directly towards textbooks and other essential supplies.\n\nYour support would make a big difference!`
                    
                                            ]}

                                            

                        label               = "2. Gifting Page Welcome Message:"
                        field_name          = "gifting_page_welcome_message"
                        field_placeholder   = {`Example: ${this.state.user_name} is saving for ${this.state.receiver_name}’s future! Send a gift with Greatest Gift, and invest in ${this.state.receiver_name}’s future today.\nHappy Gifting,\nYour friends at Greatest Gift.`}
                        field_value         = {this.state.gifting_page_welcome_message}
                        max_length          = {350}
                        randomize           = {false}
                    
                        handleChange        = {this.handleChange}
                    
                        select_item         = {(selected_item) => this.setState({gifting_page_welcome_message: selected_item})}

                    />

                    </form>

                    {/* goals */}

                    <p className='form_label'>3. Select {this.state.receiver_name}'s Financial Goals:</p>

                    <Goals_list
                        goals={this.state.goals}
                        toggle_goal={(goal) => this.toggle_goal(goal)}
                    />


                    {this.state.webflow ? null :
                    <div>

                    <p className='form_label'>4. Other Linked Registries:</p>

                    <div className="notification-item-headline-and-button">
                        <p className='bold'>Display Other Registry Links</p>
                        <MUI_Switch checked={this.state.display_other_registries}  changeHandler={() => this.setState({display_other_registries: !this.state.display_other_registries})} />
                    </div>

                    {this.state.other_registries.length > 0 ? 
                    <div>
                        {this.state.other_registries.map((registry, i) => {
                           return(
                            <div key={i} className='registries_links_container'>
                                <p>
                                    {registry.registry_provider} registry: <a className='green_text' href={registry.registry_link} target='_blank'>{registry.registry_link}</a>
                                </p>
                                <p className='gg_red align_right cursor tiny_text'
                                onClick={() => this.handle_remove_registry(registry.registry_provider, registry.registry_link)}
                                > 
                                    Delete
                                </p>
                            </div>
                           )
                        })}
                    </div>
                    : 
                    <p>You don't have any other linked registries.</p>
                    }

                    <div className='margin_bottom_40'>
                        
                        <p className='bold'>Add Other Registry Links:</p>

                        <form>

                            <p className='form_label'>Registry Provider:</p> 
                            <Dropdown_input 
                                dd_name='registry_provider'

                                header={'Choose provider'}
                                
                                list={
                                    [
                                        {id: 1, title: "Amazon"},
                                        { id: 2, title: "Other" }
                                    ]
                                    }

                                handleItemClick={item => this.setState({registry_provider: item})}

                                selected={this.state.registry_provider}
                                
                                highlight={false}
                                
                                lastItem={false}
                                active={true}
                            />

                            <p className='form_label'>Your Registry Link:</p> 
                            <input 
                                type='text' 
                                id='registry_link' 
                                onChange={this.handleChange}
                                name='registry_link' 
                                placeholder='Registry URL link' 
                                value={this.state.registry_link}
                                maxLength='500'
                                autoComplete='off'
                                /> 
                        </form>

                        <p className='green_text bold align_center cursor'
                        onClick={this.handle_add_registry}
                        >
                            Add Registry Link
                        </p>

                    </div>
                    

                    <p className='form_label'>5. Additional Settings:</p>

                    <div className="notification-item">
                        <div className="notification-item-headline-and-button">
                            <p className='bold'>Show Milestones & Gifting Activity</p>
                            <MUI_Switch checked={this.state.show_activity}  changeHandler={() => this.setState({show_activity: !this.state.show_activity})} />
                        </div>
                        <p className='no_margin'>Show recent and upcoming birthdays, upcoming school milestones, and gifts {} received recently. Showing the Milestones and Gifting Activity usually increases the likelihood your family and friends will send gifts with Greatest Gift.</p>
                    </div>
                    
                    </div>
                    }

                    {/* confirm button */}
                    <MasterButton 
                        buttonText={'Save Edits'} 
                        inactive_button={!this.state.button_active}
                        handleButtonClick={this.handleButtonClick}
                        notSticky={true}
                    />
                    
                    {/* cancel button */}
                    <Link to={`/plan/${this.props.match.params.plan_id}`}> 
                        <p className='cursor align_center small_text secondary_dark' >Cancel Edits</p>
                    </Link>

                    <div className='buffer'></div>
                    <div className='buffer'></div>


                    {/* success message, and share notice */}

                    <Overlay_popup 
                    open            = {this.state.open_overlay}
                    closer          = {false}
                    
                    overlay_title   = 'Gifting Page Updated!'
                    wide            = {true}
            
                    body_1          = 'Your gifting page has been updated, and is ready to be shared. Check it out and share the link with friends and family!'
                    body_2          = {this.state.gift_page_url}
                    button_1        = {{text: 'Finish',
                                        links: true,
                                        linkTo: `/plan/${this.props.match.params.plan_id}`,
                                        }}    
                />

                </main>
            </div>
            )
        }
    }
}

export default Gifting_page_setup;