import React from 'react';

import { Link } from 'react-router-dom';

// import images:
import logo from '../../images/logos/logo.png';

class Home_Page_Header extends React.Component {
    render () {

        return (
                <header className='landing_header'>
                    <div className='landing_header_container'>
                        
                        <div className='logo_container'>
                            {/*<Link to='/'>
                                <img src={logo} className='logo'/>    
                            </Link>*/}
                            <a href='https://www.greatestgiftapp.com'>
                                <img src={logo} className='logo'/>    
                            </a>
                        </div>

                        <div className='sign_in_container'>
                            <Link to='/home'>
                                <button className='landing_page_button_sign_in'>
                                    Sign In
                                </button>
                            </Link>
                        </div>

                    </div>
                </header>
                
        );
    }
}

export default Home_Page_Header;