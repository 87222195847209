import React from 'react';
import './Thank_you.css';

import {Helmet} from "react-helmet";
import MasterButton from '../../general_components/MasterButton/MasterButton.js';
import Media_selector from '../../general_components/Pic_selector/Media/Media_selector';
import Selector_field from '../../general_components/Selector_field/Selector_field';
import GG_loader from '../../general_components/GG_loader/GG_loader';
import GGApi from '../../../utils/GGApi';
import Overlay_popup from '../../general_components/Overlay_popup/Overlay_popup';
import dates from '../../../utils/dates';

import config from '../../../utils/config';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);

class Thank_you extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            access: '',
            open_overlay: false,
            button_active: true,

            thank_you: {
                sent_on: '',
                thank_you_note: '',
                thank_you_media: ''
            },
            thank_you_note: '',
            thank_you_file: null,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handlePicChange = this.handlePicChange.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
    }


    componentDidMount() {   
        // check that the gift id matches the receiver user (also gets received gift details)
        GGApi.received_gift(this.props.match.params.id).then(result => {
            if(result.access === 'No-access') {
                this.setState({access: result.access, loading: false})
            } else {
                let thank_you = {};
                thank_you.sent_on = result.requestedGift.sent_on;
                thank_you.thank_you_note = result.requestedGift.thank_you_note;
                thank_you.thank_you_media = result.requestedGift.thank_you_media;
                this.setState({thank_you: thank_you, loading: false})
                
                // amplitude
                amplitude.getInstance().logEvent('thank you: see thank you page', {"sent": thank_you.thank_you_note ? 'yes' : 'no'})
            };

        })
    }

    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }


    handlePicChange(event) {
        let file = event.target.files[0];
        // try to convert here to jpeg?
        if(!file){
            return;
        }
        if(file.size > 8388608) {
            alert('Please select a photo or video with a max size of 8MBs.');
            return;
        }
        this.setState(
            {thank_you_file: event.target.files[0]}
        );
    }

    handleButtonClick(){
        if(!this.state.button_active){
            return;
        }
        // validate that there is at least a note
        if(!this.state.thank_you_note) {
            alert('Please enter a thank you note.')
            return;
        }

        // start gg loader
        this.setState({loading: true, button_active: false})
        
        // upload the thank you media
        GGApi.upload_media(this.state.thank_you_file, 'thank-you-media').then(response => {
            // handle media upload error
            let media;
            if(response.errors){
                console.log(response.errors[0].detail);
                alert(response.errors[0].detail + ' \nThank you will be sent without the attached media.')
            } else if(response.mediaUrl){
                media = response.mediaUrl;
            };

            // send media url and thank you note to the thank you route
            GGApi.send_thank_you(this.state.thank_you_note, media, this.props.match.params.id).then(response =>{
                if(!response.success){
                    this.setState({loading: false, button_active: true})
                    alert('There was an issue when trying to send this thank you note. Please try again, or contact our support team.')
                } else if (response.success){
                    // end gg loader
                    // open a pop up? 
                    this.setState({loading: false, open_overlay: true, button_active: false})
                    
                    // amplitude
                    amplitude.getInstance().logEvent('thank you: send thank you');
                }
            });
        });
        
        
        
    }


    render_gift_media(){
        if(!this.state.thank_you.thank_you_media){
            return null;
        } else {
            let split = this.state.thank_you.thank_you_media.split(".");
            let extension = this.state.thank_you.thank_you_media.split(".")[split.length - 1];
            if (extension === 'jpeg' || 
                extension === 'jpg' || 
                extension === 'png' ){
                    return (
                        <img 
                        src={this.state.thank_you.thank_you_media}
                        className='received_picture_greeting' />
                    )
            } else if ( 
                extension === 'quicktime' ||
                extension === 'mp4' ||
                extension === 'mov' ||
                extension === 'qt'){
                    return (
                        <video controls className='received_picture_greeting'>
                            <source src={this.state.thank_you.thank_you_media}/>
                        </video>
                    )
            }
        }
    }

    render () {
        

        if(this.state.loading) {
            return (
                <GG_loader/>
            )
        }

        if (this.state.access === 'No-access') {
            return (
                <main>
                    <h2>We're sorry, but the page you are trying to locate is unavailable.</h2>
                </main> 
            )
        } else if (this.state.thank_you.thank_you_note) {
            return (
                <main>

                    <h2>Thank You Note</h2>
                    
                    <div className='two_column_text_container'>
                    
                        <div className='two_column_line'>
                            <div className='left_column_text'><p>Sent on:</p></div>
                            <div className='right_column_text'><p>{dates.jsDate(this.state.thank_you.sent_on)}</p></div>
                        </div>
                    </div>
                    
                    <p className='header_for_greeting'>Note:</p>
                    <div className='greeting_box greeting_box_green'>
                        <p>"{this.state.thank_you.thank_you_note}"</p>
                    </div>

                    {this.render_gift_media()}


                </main>
            )
        }
        
        return (
            <main>
            <Helmet>
                <title>{`Thank You Note | Greatest Gift`}</title>
                <meta name="description"
                        content='Send a thank you note for your gifts.' 
                    />
            </Helmet>
               
                <h2>Thank You Note</h2>
                
                <form>

                    <Selector_field 
                        options             = {['Thank you for the great gift!', 
                                                'Thank you so much for your thoughtful gift and for helping me reach my financial goals!',
                                                'Thank you for helping me reach for the stars and achieve my goals!',
                                                'Thank you, your love, support and generosity mean the world to us.',
                                                'Thank you for the gift you gave me - the greatest gift! :) I can\'t wait to create many more memories together!',
                                                'Thank you for your gift! It\'s such a blessing to have you in our lives and we hope we will continue to inspire and motivate each other to reach our goals!',
                                                'Thank you so much!', 
                                            ]}

                        label               = "Note:"
                        field_name          = "thank_you_note"
                        field_placeholder   = "Example: Thank you for your generosity!"
                        field_value         = {this.state.thank_you_note}
                        max_length          = {250}
                        randomize           = {true}
                    
                        handleChange        = {this.handleChange}
                    
                        select_item         = {(selected_item) => this.setState({thank_you_note: selected_item})}

                    />

                </form>

                <Media_selector 
                    label={'Add a picture or video (optional):'}
                    handlePicChange={this.handlePicChange}
                    handleRemoveMedia={()=> this.setState({thank_you_file: null})}
                    file={this.state.thank_you_file}/>

                <MasterButton 
                    buttonText={'Send Thank You'} 
                    inactive_button={!this.state.button_active || !this.state.thank_you_note}
                    handleButtonClick={this.handleButtonClick}
                    notSticky={true}
                />

                <Overlay_popup 
                    open            = {this.state.open_overlay}
                    closer          = {false}
                    
                    overlay_title   = 'Thank you note sent!'
                    wide            = {true}
            
                    body_1          = 'Your thank you note has been sent.'
                    
                    button_1        = {{text: 'Finish',
                                        links: true,
                                        linkTo: `/receive/${this.props.match.params.id}`,
                                        }}    
                />

           </main>
        );
    }
}

export default Thank_you;