import React from 'react';
import './Main_gift.css';

import {Helmet} from "react-helmet";

import Sent_gift_list from '../Sent_gift_list/Sent_gift_list.js';
import MasterButton from '../../../general_components/MasterButton/MasterButton.js';

import GGApi from '../../../../utils/GGApi.js';
import gift_illustration from '../../../../images/illustrations/gift.png';
//import gift_illustration from '../../../images/dancing_gift2.gif';
import GG_loader from '../../../general_components/GG_loader/GG_loader';


class Main_gift extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            sentGiftsList: []
        };
    }
    
    
    componentDidMount() {
        GGApi.sent_gifts().then(gifts => {
            this.setState({sentGiftsList: gifts, loading: false});
        });
        
    } 

    render () {
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        return (
            <div>
                <Helmet>
                    <title>{`Send Gifts | Greatest Gift`}</title>
               </Helmet>
                <main>
                    <h2>Your sent gifts</h2>
                    {this.state.sentGiftsList.length !== 0 ? null : <p>Send your first gift now by clicking the button below!</p>}
                    <Sent_gift_list gifts={this.state.sentGiftsList} />
                    
                    <div className='main_gift_img_container'>
                        <img className='main_gift_illustration' src={gift_illustration} />
                    </div>

                    <div className='buffer'></div>
                </main> 

                <MasterButton 
                handleButtonClick={this.handleButtonClick}
                buttonText='Give a new Gift'
                links={true}
                linkTo='/give-a-gift'
                />
                <div className='blurred_background_1'></div>
                
            </div>
        );
    }
}

export default Main_gift;