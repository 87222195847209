import React from 'react';
import './Open_gift.css';

import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import GGApi from '../../../utils/GGApi.js';

//import open_gift_illustration from '../../../images/illustrations/gift_open.png';
import open_gift_illustration from '../../../images/illustrations/money_gifts.svg';
import GG_loader from '../../general_components/GG_loader/GG_loader';
import logo from '../../../images/logos/logo.png';
import logo_var_2 from '../../../images/logos/gg_logo_icon no_margin.svg';
import MasterButton from '../../general_components/MasterButton/MasterButton';
import amplitude from 'amplitude-js';
import config from '../../../utils/config';
import Accordion from '../../general_components/Accordion/Accordion';
import GG_balloons from '../../general_components/GG_confetti/GG_balloons';
import stripe_logo from '../../../images/stripe_imgs/stripe_black.png';
import plaid_logo from '../../../images/stripe_imgs/plaid.svg';
import GG_confetti from '../../general_components/GG_confetti/GG_confetti.js';
amplitude.getInstance().init(config.amp_key);


class Open_gift extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            gift: {
                gift_for: '',
                from: '',

            }
        }
        
        this.render_gift_media = this.render_gift_media.bind(this);
    }
      
    componentDidMount() {
        
        // get the gift details
        
        GGApi.open_new_gift(this.props.match.params.gid).then(result => {
            if(result.access === 'No-access'){
                window.location.href = 'https://www.GreatestGiftApp.com';
            } else {
                let requestedGift = result.requestedGift;
                let from = result.from;
                this.setState({
                    gift: requestedGift,
                    from: from,
                    loading: false
                });
            }
        }); 
    }

    render_gift_media(){
        if(!this.state.gift.mediaUrl){
            return null;
        } else {
            let split = this.state.gift.mediaUrl.split(".");
            let extension = this.state.gift.mediaUrl.split(".")[split.length - 1];
            if (extension === 'jpeg' || 
                extension === 'jpg' || 
                extension === 'png' ){
                    return (
                        <img 
                        src={this.state.gift.mediaUrl}
                        className='open_received_picture_greeting' />
                    )
            } else if ( 
                extension === 'quicktime' ||
                extension === 'mp4' ||
                extension === 'mov' ||
                extension === 'qt'){
                    return (
                        <video controls className='open_received_picture_greeting'>
                            <source src={this.state.gift.mediaUrl}/>
                        </video>
                    )
            }
        }
    }

    render () {
        
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        return (
            <div className='open_body'>
                <Helmet>
                    <title>{`Open your gift for ${this.state.gift.gift_for} | Greatest Gift`}</title>
                </Helmet>

                
                <GG_confetti />

                <header className='open_header'>

                    <div className='open_header_container'>
                        
                        <div>
                            {/*<Link to='/'>*/}
                            <a href='https://www.greatestgiftapp.com'>
                            <img src={logo} className='open_header_logo'/>
                            {/*</Link>*/}
                            </a>
                        </div>

                        
                        
                        <MasterButton 
                            buttonText={'Sign up'} 
                            onClick={() => {amplitude.getInstance().logEvent('open_gift: click sign up')}}
                            notSticky={true}
                            links={true}
                            linkTo={'/new_account'}
                        />
                    </div>
                </header>

                <div className='open_main'>

                    <div className='gift_card_container'>
                        <h2>{`You received a gift for ${this.state.gift.gift_for}’s future!`}</h2>

                        <div className='gift_card'>
                            
                            <div className='gift_card_left'>
                                <div className='gift_card_left_text_box'>
                                <p>{this.state.gift.greeting}</p>
                                <br></br>
                                <p>Gift amount: ${this.state.gift.gift_amount}</p>
                                <p>- {this.state.from}</p>

                                </div>
                                
                                <div className='gift_card_button_wrapper'>
                                    <MasterButton 
                                        buttonText={'Sign up & Redeem Gift'} 
                                        onClick={() => {amplitude.getInstance().logEvent('open_gift: click sign up')}}
                                        notSticky={true}
                                        links={true}
                                        linkTo={'/new_account'}
                                    />
                                    <p className='small_text align_center'><Link to='/' className='green_text' onClick={() => {amplitude.getInstance().logEvent('open_gift: click log in')}}>Log in</Link> if you already have an account</p>
                                </div>
                            </div>
                            
                            <div className='gift_card_right'>
                                {this.state.gift.mediaUrl ? this.render_gift_media() :
                                <img className='gift_card_img' src={open_gift_illustration} />
                                }
                                
                            </div>

                        </div>

                       
                    </div>

                    
                    <section className='open_gift_explainer_section'>

                        <div id='faq' className='section_7 landing_main_section faq_section'>
                            <div className='section_header'>
                                <h2>Frequently Asked Questions (FAQ)</h2>
                            </div> 
                            
                            <Accordion list={[
                               
                                {
                                    header: 'What is Greatest Gift?',
                                    content: 
                                        <div>
                                            <p>Greatest Gift is the financial gifting platform for children’s savings. 
                                                <br></br>
                                                <br></br>It turns investing in a child’s future into a gift that you can send to your loved ones, and continues to show parents how to save & invest for their kids!</p>
                                        </div>
                                },
                                {
                                    header: 'How do I redeem the gift I received?',
                                    content: 
                                        <div>
                                            <p>Transfer cash gift funds from Greatest Gift directly to your favorite 529 plans, savings accounts, investment accounts and even checking accounts:</p>
                                            <ol>
                                                <li><Link to='/new_account' className='green_text' onClick={() => {amplitude.getInstance().logEvent('open_gift: click sign up')}}>Sign up</Link> for a free GG account</li>
                                                <li>Add a recipient on your account</li>
                                                <li>Add a destination financial account</li>
                                                <li>Redeem and transfer the gift funds</li>
                                            </ol>
                                            <p>Don't forget to say thank you!</p>
                                        </div>
                                },
                                {
                                    header: 'I don’t have a saving or investment account for my child. What can I do?',
                                    content: 
                                        <div>
                                            <p> We belive financial gifts should be put to work ASAP.
                                                <br></br>
                                                <br></br>If you don't have an investment account for your child, you can find our resources on financial parenting and all of our recommended financial products for parents and kids.</p>
                                        </div>
                                }, 
                                {
                                    header: 'How can I say thank you?',
                                    content: 
                                    <div>
                                        <p>Saying thank you is easy with Greatest Gift!
                                            <br></br>
                                            <br></br>
                                            <Link to='/new_account' className='green_text' onClick={() => {amplitude.getInstance().logEvent('open_gift: click log in')}}>Log in</Link> to your account and send a thank you from your received gift page. You can even add a picture or video of the happy gift receiver!
                                            </p>
                                    </div> 
                                },
                                {
                                    header: 'Is Greatest Gift safe & secure to use?',
                                    content: 
                                    <div>
                                        <p>Your peace of mind is our top priority.</p>
                                        <p>We uses bank-level security safeguards to make sure your personal data and gifts are secure and partner with companies like Stripe and Plaid to safeguard your private information.</p>
                                        <p>For more information about our security, check out our <a href='www.GreatestGiftApp.com/#security' target='_blank' className='green_text'>security section</a></p>
                                        <div className='open_gift_faq_imgs'>
                                            <img src={stripe_logo}/>
                                            <img src={plaid_logo}/>
                                        </div>
                                    </div> 
                                },
                                
                                {
                                    header: 'What makes gifts from Greatest Gift fun and meaningful?',
                                    content: 
                                    <div>
                                        <p>Our gifts are digitally gift wrapped, and always put a smile on people's faces.</p>
                                        <p>Greetings and pictures add a personal touch to a money gift. We make it easy to save for the future, and our gifts encourage recipients to start saving and investing. <br></br>Recipients benefit from our curated collection of financial products, guides and life-hacks for creating a bright future for their children.</p>
                                        <p>Send a gift now and see for yourself!</p>
                                    </div> 
                                },
                            ]}/> 

                        </div>


                        <div className='section_button_container'>
                            <Link to='/new_account' onClick={() => {amplitude.getInstance().logEvent('open_gift: click sign up')}}>
                                <button className='landing_page_button'>
                                    Sign Up
                                </button>
                            </Link>
                        </div>


                        <div className='section_header'><h2>{'Join a Community of Great Gifters'}</h2></div>
                        <div className='open_carousel_wrapper'>
                            
                            <div className='review_container'>
                                <div className='review'>
                                    <h3 className='align_center'>Thoughtful + Impactful Gifting</h3>
                                    <p>Convenient, practical, and ideal for a busy parent with a lot of little friends to gift to throughout the year! ... Nothing but positive feedback as well from our friends who have received through Greatest Gift. Looking forward to continued gifting!</p>
                                </div>
                                <p className='align_center'>Judy</p>
                            </div>


                            <div className='review_container'>
                                <div className='review'>
                                    <h3 className='align_center'>Gifting Pages Are Great</h3>
                                    <p>Our son just turned two. We created his gifting page with Greatest Gift and shared it on the birthday evite. The results were amazing! We received 12 gifts that will be going to his college fund and savings.<br></br>Love this platform.</p>
                                </div>
                                <p className='align_center'>Daniel</p>
                            </div>


                            <div className='review_container'>
                                <div className='review'>
                                    <h3 className='align_center'>{'Easy & Meaningful Gifting Experience'}</h3>
                                    <p>I love how easy it is to give a meaningful gift to the kids and tots in my life!It means a lot to me that I can contribute to their future, and Greatest Gift makes the experience seamless and fun.</p>
                                </div>
                                <p className='align_center'>Alana</p>
                            </div>

                           
                        </div>


                        <h3 className='align_center'>As Featured In</h3> 
                        <div className="featured_wrapper">
                            
                            <a href="https://www.parents.com/parenting/money/teach-kids-exchange-rates/" target="_blank" className="featured_logo_link">
                                <img src="https://uploads-ssl.webflow.com/60cca303af56dec963670957/6196c37cde2f8602d54e6126_parents.com%20logo.png" loading="lazy" alt="parents.com logo" className="featured_logo" />
                            </a>

                            <a href="https://finance.yahoo.com/news/much-kids-could-save-investing-204619531.html" target="_blank" className="featured_logo_link">
                                <img src="https://uploads-ssl.webflow.com/60cca303af56dec963670957/6151eebcaa7eec2084f26f10_Logo_yahoofinance_color.png" loading="lazy" alt="yahoo finance logo " className="featured_logo"/>
                            </a>
                            
                            <a href="https://www.gobankingrates.com/credit/credit-score/parents-best-way-to-build-your-gen-z-kids-credit/" target="_blank" className="featured_logo_link">
                                <img src="https://uploads-ssl.webflow.com/60cca303af56dec963670957/6151ecee24c6884ffd4fb487_Logo_gobankingrates.svg" loading="lazy" alt="go banking rates logo" className="featured_logo"/>
                            </a>

                        </div>


                        <div id='receive_gifts' className='section_3 landing_main_section'>

                            <div className='section_header'><h2>{`Invest in ${this.state.gift.gift_for}’s Future with Great Gifts`}</h2></div>

                            <div className='two_column_flex_content two_column_screenshot two_column_reverse'>
                                <div className='left_side'>
                                    <div className='two_column_screenshot_textbox'>
                                        <h3>Receivers' Choice</h3>
                                        <p>Gift receivers get to choose how to use the money, and are encouraged to save in 529 plans and long term savings accounts.</p>
                                    </div>
                                </div>

                                <div className='right_side'>
                                    <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/landing_page/benefits_receivers_1.svg'}/>
                                </div>
                            </div>

                            <div className='two_column_flex_content two_column_screenshot'>
                                <div className='left_side'>
                                    <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/landing_page/benefits_receivers_2.svg'}/>
                                </div>

                                <div className='right_side'>
                                    <div className='two_column_screenshot_textbox'>
                                        <h3>Easy to Redeem</h3>
                                        <p>Skip the trip to the bank. Transfer cash gift funds from Greatest Gift directly to your favorite long-term savings or investment accounts.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='two_column_flex_content two_column_screenshot two_column_reverse'>
                                <div className='left_side'>
                                    <div className='two_column_screenshot_textbox'>
                                        <h3>Discover Ways to Save & Invest</h3>
                                        <p>We help parents answer financial questions, and provide a curated collection of financial products, guides and life-hacks for creating a brighter future for their family.</p>
                                    </div> 
                                </div>

                                <div className='right_side'>
                                    <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/landing_page/benefits_receivers_3.svg'}/>
                                </div>
                            </div>

                            <div className='two_column_flex_content two_column_screenshot'>
                                <div className='left_side'>
                                    <img src={'https://uploads-ssl.webflow.com/60cca303af56dec963670957/619e98bc6c4587dedba5aa4e_Benefits%20-%20Receivers%20-%204%20(1).svg'}/>
                                </div>

                                <div className='right_side'>
                                    <div className='two_column_screenshot_textbox'>
                                        <h3>Create Gifting Pages</h3>
                                        <p>People enjoy investing in children's futures, but they don't always know the best way to do it.
                                            <br></br>
                                            <br></br>
                                            Share your children's financial goals with your family and friends and introduce them to an easy and exciting way of investing in their future.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='section_button_container'>
                                <Link to='/new_account'>
                                    <button className='landing_page_button'>
                                        Sign Up
                                    </button>
                                </Link>
                            </div>
                            <p className='small_text align_center'><Link to='/' className='green_text' onClick={() => {amplitude.getInstance().logEvent('open_gift: click log in')}}>Log in</Link> if you already have an account</p>

                        </div>


                    </section>

                </div>

                <div className='open_footer_wrapper'>

                        <div className='open_footer'>
                            <a href='https://www.greatestgiftapp.com' target='_blank'>
                                <img src={logo_var_2}/>
                            </a>
                            <p className='tiny_text'>Greatest Gift Inc.</p>
                            <p className='small_text'>|</p>
                            <a href='/legal?active=privacy' target='_blank' className='tiny_text'>Privacy</a>
                            <a href='/legal' target='_blank' className='tiny_text'>Terms</a>
                            <a href='https://www.greatestgiftapp.com/#faq' target='_blank' className='tiny_text'>FAQ</a>
                        </div>
                </div>

            </div>
        );
    }
}

export default Open_gift;