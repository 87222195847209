/*
    {   id: 1, 
        title: '',
        image: '',
        account_format: ''
    }
*/

import logo_fidelity from '../../../../images/financial_institution_logos/logo_brokerage_fidelity.png';

const brokerages = [
    {   id: 1, 
        title: 'Fidelity',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_brokerage_fidelity.png',
        routing: '101205681',
        account_pattern: '', // different for different account types
        link: 'https://www.fidelity.com/cash-management/deposit-money/determine-routing-and-account-number'
    },
    {   id: 2, 
        title: 'Charles Schwab',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_charlesschwab.svg',
        routing: '071000013',
        account_pattern: '593853800-xxxx-xxxx', // 9 digit prefix and then 8 digit account number
        account_length: 8,
        account_prefix: '593853800',
        link: 'https://client.schwab.com/clientapps/accounts/summary/',
        account_input_instructions: 'Please enter your 8 digit account number:'
    },
    {   id: 3, 
        title: 'E*Trade',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_brokerage_etrade.png',
        routing: '056073573',
        account_pattern: '', // 8 digit brokerage account number
        account_length: 8,
        link: 'https://us.etrade.com/etx/hw/v2/accountshome'
    },
    {   id: 4, 
        title: 'Vanguard - Brokerage and UTMA',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_brokerage_vanguard.svg',
        routing: '021409169',
        account_pattern: '', // 17 digit direct deposit ID number
        account_length: 17,
        link: 'https://personal1.vanguard.com/mnt-profile-account-settings/paas/banking',
        account_input_instructions: 'Please set up Direct Deposit on your Vanguard account, then provide your 17 digit Direct Deposit ID.'

    }
]


;

export default brokerages;

/*

    

*/