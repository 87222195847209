let fee_structure_1 = { // lowest - free ach, low CC, free to redeem except checkings
   send_fees: {
       cc_variable:         0.03,   // 3%
       cc_fixed:            0.00,   // 0
       ach_variable:        0,      // 0
       ach_fixed:           0       // 0
   },

   redeem_fees: {
       checking_variable:   0.05,   // 5%
       checking_fixed:      0,      // 0
       other_variable:      0,      // 0
       other_fixed:         0,      // 0
   }
};

let fee_structure_2 = { // low ach, free to redeem except Checkings
    send_fees: {
        cc_variable:         0.035,  // 3.5%
        cc_fixed:            0.00,   // 0
        ach_variable:        0.01,   // 1%
        ach_fixed:           0       // 0
    },
 
    redeem_fees: {
        checking_variable:   0.05,   // 5%
        checking_fixed:      0,      // 0
        other_variable:      0,      // 0
        other_fixed:         0,      // 0
    }
 };

let fee_structure_3 = { // low ach, low redeem fees, high for checkings
    send_fees: {
        cc_variable:         0.035,  // 3.5%
        cc_fixed:            0.00,   // 0
        ach_variable:        0.01,   // 1%
        ach_fixed:           0       // 0
    },
 
    redeem_fees: {
        checking_variable:   0.05,   // 5%
        checking_fixed:      0,      // 0
        other_variable:      0.005,  // 0.5%
        other_fixed:         25,     // 25 cents
    }
 };


module.exports = fee_structure_3;
