import React from 'react';
import './Offers_main.css';

import Category from './Category/Category.js';

import image_529 from '../../../../images/offer_category/investing.jpg';

class Offers_main extends React.Component {
    render () {
        if(this.props.active_header === 'articles') {
            //return null;
        } else {
            return (    
                <div>
                    <h2 className='main_offers_headline'>Discover great tools to save and invest</h2>
                    
                    <div className='categories_container'>
                        <Category 
                            category='529 Plans - Education Investment Accounts'
                            linksTo='/discover/529plans'
                            image={'529'}
                        />

                        <Category 
                            category='Kids Savings Accounts'
                            linksTo='/discover/kidssavings'
                            image={'savings'}
                        />
                        <Category 
                            category='Investment Accounts'
                            linksTo='/discover/investments'
                            image={'investing'}
                        />
                        {/*
                        <Category 
                            category='Financial Tech For Kids'
                            linksTo='/discover/kidstech'
                        />
                        */}
                    </div>
                </div>
            );
        }
    }
}

export default Offers_main;