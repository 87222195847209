import React from 'react';
import './Account_step_5.css';


class Account_step_5 extends React.Component {
    render () {
        if (this.props.currentStep !== 5) {
            return null;
        }
        
        return (
            <div>
                <h2 className='center'>Congratulations! You’ve joined a community of  Great Gifters!</h2>
               { /*
                <div className='account_creation_buttons_container'>
                    
                    <div className='account_creation_button'>
                        <img />
                        <p className='small_text'>Give your first gift</p>
                    </div>

                    <div className='account_creation_button'>
                        <img />
                        <p className='small_text'>Redeem any received gifts</p>
                    </div>

                    <div className='account_creation_button'>
                        <img />
                        <p className='small_text'>Discover great ways to save and invest for your children</p>
                    </div>
                    
                </div>
    */}
           </div>
        );
    }
}

export default Account_step_5;