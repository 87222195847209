import React from 'react';
import './Carousel.css';

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            index: 1,
            transition: 0.5,
            translate: 0,

            arrow_forward: false,
            arrow_back: false,

            drag_start_x: 0,
            drag_start_y: 0,
            timestamp: 0,
            dragging: false
        }

        this.getWidth = this.getWidth.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
        this.prevSlide = this.prevSlide.bind(this);
        this.event = this.event.bind(this);
        this.mouse_down = this.mouse_down.bind(this);
        this.mouse_up = this.mouse_up.bind(this);

        this.mouseMove=this.mouseMove.bind(this);
        this.handleDrag = this.handleDrag.bind(this);

        this.touch_down = this.touch_down.bind(this);
        this.touch_up = this.touch_up.bind(this);
    }

    componentDidMount(){
        if (this.props.slides.length !== 1){
            this.setState({
                arrow_forward: true,
            })
        }

    }

    nextSlide(){
        if (this.state.index === this.props.slides.length) {
            return;
        } else if (this.state.index === 1 && this.props.slides.length === 2) {
            this.setState({
                index: this.state.index +1,
                translate: this.state.translate - this.getWidth()*0.6 - 18,
                arrow_back: true,
                arrow_forward: false
            })
        } else if (this.state.index === 1 ){
            this.setState({
                index: this.state.index +1,
                translate: this.state.translate - this.getWidth()*0.7 - 16,
                arrow_back: true
            })
        } else if (this.state.index === this.props.slides.length -1) {
            this.setState({
                index: this.state.index +1,
                translate: this.state.translate - this.getWidth()*0.7 - 19,
                arrow_forward: false
            })
        } else {
            this.setState({
                index: this.state.index +1,
                translate: this.state.translate - this.getWidth()*0.8 - 16
            })
        }
    }

    prevSlide(){
        if(this.state.index === 1){
            return;
        } else if (this.state.index === 2 && this.props.slides.length === 2) {
            this.setState({
                index: this.state.index -1,
                translate: this.state.translate + this.getWidth()*0.6 + 18,
                arrow_back: false,
                arrow_forward: true
            })
        } else if (this.state.index === 2 ){
            this.setState({
                index: this.state.index -1,
                translate: this.state.translate + this.getWidth()*0.7 +16,
                arrow_back: false
            })
        } else if (this.state.index === this.props.slides.length) {
            this.setState({
                index: this.state.index -1,
                translate: this.state.translate + this.getWidth()*0.7 + 19,
                arrow_forward: true
            })
        } else {
            this.setState({
                index: this.state.index -1,
                translate: this.state.translate + this.getWidth()*0.8 +16
            })
        }
    }

    getWidth(){
        return window.innerWidth * this.props.main_width_factor;
    }


    // additional navigating the carousel methods
    event(event) {
        //console.log(event);
        console.log('moved on x:', event.clientX - this.state.drag_start_x)
        console.log('time:', event.timestamp - this.state.timestamp)
    }

    mouse_down(event){
        this.setState({
            drag_start_x: event.clientX,
            drag_start_y: event.clientY,
            dragging: true})
    }

    mouse_up(event){
        this.setState({
            dragging: false
        })
        // if y moved more than 50 either way - false positive, do nothing
        if(event.clientY - this.state.drag_start_y >= 50){  
            return;
        }
        // if x moved 50 to the right (positive)
        if(event.clientX - this.state.drag_start_x >= 50){
            this.prevSlide();
        } else if (event.clientX - this.state.drag_start_x <= -50){
            this.nextSlide();
        }
    }

    mouseMove(event){
        if(this.state.dragging){
            let start = this.state.drag_start_x;
            let current_x = event.clientX;
            
            let delta = current_x - start;
            console.log(start, current_x, delta)
            this.setState({
                translate: this.state.translate - (delta/delta)
            })
        } else {
            return;
        }
    }

    touch_down(event){
        this.setState({
            drag_start_x: event.touches[0].clientX,
            drag_start_y: event.touches[0].clientY,
            dragging: true})
    }

    touch_up(event){
        this.setState({
            dragging: false
        })
        // if y moved more than 50 either way - false positive, do nothing
        if(event.changedTouches[0].clientY - this.state.drag_start_y >= 50){  
            return;
        }
        // if x moved 50 to the right (positive)
        if(event.changedTouches[0].clientX - this.state.drag_start_x >= 50){
            this.prevSlide();
        } else if (event.changedTouches[0].clientX - this.state.drag_start_x <= -50){
            this.nextSlide();
        }
        
    }

    handleDrag(event){
        //console.log(event);
    }

    render () {

        return (
            <div className='carousel_master_container'>

{/*                <div className='carousel_slider_container' 
                    onTouchStart={this.touch_down} 
                    onTouchEnd={this.touch_up} 
                    
                    onMouseDown={this.mouse_down} 
                    onMouseUp={this.mouse_up}
                    
                    onDragStart={this.mouse_down}
                    onDragEnd={this.mouse_up}
                    onDrag={this.handleDrag}
                    draggable={false}
                    >
        */}
                <div className='carousel_slider_container'>

                    <div className='carousel_slider_content' 
                    style={{    'transform': `translateX(${this.state.translate}px)`, 
                                'transition': `transform ease-out ${this.state.transition}s`,
                                //'width': `${(this.getWidth()+16)*this.props.slides.length}px`
                                'width': `fit-content`

                                }}>
                    
                        {this.props.slides.map(slide => 
                            <div key={slide.key} className='carousel_slide'  style={{'width': `${this.props.slides.length > 1 ? this.getWidth() * 0.8 : this.getWidth() -3}px`}}>
                                {slide}
                            </div>
                        )}
                        
                    </div>

                    
                   
                    
                </div>
                {/* this.state.arrow_back && this.state.arrow_forward ?
                        <div className='carousel_arrows_container'>    
                            <i onClick={this.prevSlide} className='material-icons carousel_arrow'>arrow_back_ios</i>  
                            <i onClick={this.nextSlide} className='material-icons carousel_arrow'>arrow_forward_ios</i>  
                        </div> 
                        :
                        (this.state.arrow_forward && !this.state.arrow_back ?
                            <div className='carousel_arrows_container'>    
                                <i onClick={this.nextSlide} className='material-icons carousel_arrow'>arrow_forward_ios</i>  
                            </div> 
                            :
                            (!this.state.arrow_forward && this.state.arrow_back ?
                                <div className='carousel_arrows_container'>    
                                    <i onClick={this.prevSlide} className='material-icons carousel_arrow'>arrow_back_ios</i>  
                                </div> 
                                : null
                                )
                            )
                            */ }

                

            </div>
            
        );
    }
}

export default Carousel;