import React from 'react';
import Ask_for_gift from '../../../Landing_page/Ask_for_gift/Ask_for_gift';
import './Article_page.css';

class Article_body extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        if(this.props.article_name === 'How to Ask for Money as Gifts for a Baby Shower') {
            return (
                <div className='article_body'>
                    <div>
                        <p className='bold'>Most parents-to-be prefer money for their new baby instead of traditional gifts. Here are some facts and tips to help remove any awkwardness from asking for money for your baby shower.</p>
                        <p>TL;DR: money gifts have become more widely accepted, and with the right gifting tools, they can even be personal and fun. Try Greatest Gift’s share a request tool now and let your guests know that you are saving for something great.</p>
                        <Ask_for_gift />
                    </div>

                    <div>
                        <p>Congratulations! You’re having a baby! Soon you will hold the little one in your arms, and you’ll be filled with feelings of love and happiness. Sleepless nights and endless diaper changes are on the horizon, but nothing the newborn’s first smile can’t fix.</p>
                        <p>Everyone is so excited – grandparents, uncles and aunts, the whole family and your friends too. They can't wait for the baby shower to spoil you with gifts.</p>
                        <p>Unfortunately, some of the gifts they’ll buy are things you already have, and many are things you don’t need. In all honesty, you would just rather get money as gifts instead. You’re in luck because we’ve put together some tips and facts on how to ask your friends and family to give cash gifts for your baby shower.</p>
                    </div>

                    <div>
                        <h2>Whats Ahead:</h2>
                        <ul className='bold'> 
                            <li><a href={'#'+'The Benefits of Monetary Gifts for a Baby'} className='green_text'>
                            The Benefits of Monetary Gifts for a Baby
                            </a></li>
                            <li><a href={'#'+'Why it’s ok to Ask for Financial Gifts for a Baby Shower'} className='green_text'>
                            Why it’s ok to Ask for Financial Gifts for a Baby Shower
                            </a></li>
                            <li><a href={'#'+'Different Ways to Ask for Cash Gifts for a Baby Shower'} className='green_text'>
                            Different Ways to Ask for Cash Gifts for a Baby Shower
                            </a></li>
                            <li><a href={'#'+'3 Examples of How to Ask for Cash Gifts in the Baby Shower Invitation'} className='green_text'>
                            3 Examples of How to Ask for Cash Gifts in the Baby Shower Invitation
                            </a></li>
                            <li><a href={'#'+'Ask for Money Gifts for Your Baby Shower with Greatest Gift’s Help '} className='green_text'>
                            Ask for Money Gifts for Your Baby Shower with Greatest Gift’s Help 
                            </a></li>
                            <li><a href={'#'+'How to Write Thank You Notes for Money Gifts'} className='green_text'>
                            How to Write Thank You Notes for Money Gifts
                            </a></li>
                        </ul>
                    </div>

                    <div>
                        <h2 className='article_section_header' 
                            id='The Benefits of Monetary Gifts for a Baby'>The Benefits of Monetary Gifts for a Baby</h2>
                        <p>As the parents, you know what your baby needs best. Cash gifts provide endless possibilities and allow you to use the funds in the best way possible. </p>
                        <p>Gift cards can also be nice, but even they miss the mark. Gift cards are often not utilized, with <a className='green_text' href='https://www.cbsnews.com/news/unused-gift-cards-add-up-to-3-billion-annually/' target='_blank'>$3 billion left on the table</a> in unredeemed gift cards per year that goes to waste. Like all new parents, you want to make sure you are doing everything right for your newborn, and that includes looking out for their financial well-being.</p>
                        <p>The gifts that come from a baby shower can be used as a steppingstone for your child's future. Cash and investment gifts for your baby can kickstart their journey to college and set them on the path to financial security (away from their parent’s basement!). Remember, every dollar invested when your child is born can grow by as much as three and a half times by the time they go to college.</p>
                        <img alt='Greatest gift: gift growth potential over 18 years' className='article_section_cover_img' src='https://gg-app-images.s3.us-east-2.amazonaws.com/articles/greatest_gift_app_gift_growth_potential.png'></img>
                    </div>

                    <div>
                        <h2 className='article_section_header' 
                            id='Why it’s ok to Ask for Financial Gifts for a Baby Shower'>Why it’s ok to Ask for Financial Gifts for a Baby Shower</h2>
                        <p>Most young parents already prefer to receive cash as gifts and understand why you would like to receive them as well. In fact, in a 2015 survey by Upromise 7 out of 10 parents said <a className='green_text' target='_blank' href='https://www.businesswire.com/news/home/20151105006646/en/Upromise-by-Sallie-Mae-Survey-Finds-Parents-Prefer-Money-for-College-over-Traditional-Gifts-This-Holiday-Season'>money was their preferred gift.</a>  Online wedding registries and honeymoon funds provide cash registries for couples, and make cash gifting the new norm. Young couples follow this trend when planning their baby shower, and open cash registries for their baby funds. </p>
                        <p>In addition, it is still a major priority for people to invest in a child’s future. Until not too long ago, <a className='green_text' target='_blank' href='https://www.bankrate.com/banking/savings/when-giving-a-savings-bond-to-a-child-still-makes-sense/'> US Savings Bonds were still popular gifts for babies</a>, but this form of monetary gifting is very outdated. Many people still have one or two of them in paper form lying around somewhere in their attic. </p>
                        <p>Taking all of this into account, cash gifting is not a taboo topic like you might think. Also, with the current global events people are trying to be more careful by skipping events and shopping less in stores. The solution of sending a monetary gift is more desirable.</p>
                        <p>Some new technologies, like Greatest Gift, even <a target='_blank' className='green_text' href='https://www.greatestgiftapp.com'>make cash gifts more personal</a> by adding customized greetings with pictures and videos and allow receivers to invest the funds. This turns money gifts into thoughtful gestures.</p>
                        <p>If you still feel awkward at first to ask your guests for cash gifts, below are some ways to politely suggest it.</p>
                    </div>

                    <div>
                        <h2 className='article_section_header' 
                            id='Different Ways to Ask for Cash Gifts for a Baby Shower'>Different Ways to Ask for Cash Gifts for a Baby Shower</h2>
                        
                        <p className='bold'>Set goals and share them – </p>
                        <p>Asking and receiving money for a baby shower is the perfect opportunity to kickstart savings for bigger goals. Giving monetary gifts might feel impersonal or unthoughtful but contributing towards a savings goal can be considerate and generous. When gifting for a goal, gifts gain greater purpose, and a <a target='_blank' className='green_text' href='https://www.greatestgiftapp.com'>$100 dollar gift can turn into a gift of college savings.</a> </p>
                        <p>Socialize your goals when discussing the shower with guests and let them know how they can contribute if they ask. If you have a baby-shower planner, make sure they know about your savings goals so they can share them with your guests by including it on the invite for example.</p>
                        <p>After learning about your savings goals, even your Great-Aunt Rose will send you cash gifts instead of the crochet blanket she usually sends everyone.</p>
                        
                        <p className='bold'>Ask for cash gifts in the baby shower invitation –</p>
                        <p>If you are sending out printed invitations, make sure to let people know your preference. You can even add a QR code that links directly to Greatest Gift or your online cash registry, where they can send cash gifts to go towards long term savings. </p>
                        <p>If you are sending out evites, you can simply add a link to a cash gifting website.</p>
                        <Ask_for_gift text='Create Cash Gifting Link'/>
                        <p>Either way, make sure to write a few words to let people know how they can help support your goals and what you need most for your baby. This can help guests who are on the fence about what gift to get you. </p>
                        <p>We’ve provided a few example in this article.</p>
                        
                        <p className='bold'>Ask for cash gift for a savings account -</p>
                        <p>One way to ask for financial gifts is to actually open up a fund for the baby, and let your guest know how they can contribute directly to that fund. Gift givers may feel more comfortable knowing they’re sending a financial gift towards an existing savings fund for a specific purpose.</p>
                        <p>There are great options for setting up saving and investing funds for babies, including <a target='_blank' className='green_text' href='/discover/529plans'>529 plans</a> for college savings, custodial accounts, and even regular savings accounts, to name a few. </p>
                        <p>Until recently, sending gifts to these accounts was difficult and a tedious process, but <a target='_blank' className='green_text' href='https://www.greatestgiftapp.com'>Greatest Gift’s gifting process</a> makes it easy and exciting </p>
                        
                        <p className='bold'>Baby shower registry cash gifts -</p>
                        <p>If you use a traditional baby shower registry, only add items that you actually need. This will ensure that you get exactly what you need, and you don’t choose any unnecessary gift options just to fill out the registry. In addition to the registry, refer guests to a financial gifting website like <a target='_blank' className='green_text' href='https://www.greatestgiftapp.com'>Greatest Gift</a>, so that they have both options of cash gifts and traditional gifts. </p>
                    
                    </div>

                    <div>
                        <h2 className='article_section_header' 
                            id='3 Examples of How to Ask for Cash Gifts in the Baby Shower Invitation'>3 Examples of How to Ask for Cash Gifts in the Baby Shower Invitation</h2>
                        <ol>
                            <li>We are thrilled and excited for the new edition to our Family! We’ve been nesting for a while and already have everything we need, but a cash gift would be great – we want to start a saving account for the baby for college in the future!</li>
                            <li>Join us for a Baby Shower to celebrate our new addition!<br></br>
                                We already got the stroller, baby car seat and the crib.<br></br>
                                Burp cloths, bottles and bibs too!<br></br>
                                So if you want a part in our baby's future,<br></br>
                                A cash gift would be just great<br></br>
                                Promise to invite you for the graduation too…
                            </li>
                            <li>Wish we didn’t have to celebrate our newest addition to our family with you from a distance! Please feel free to help us start our savings account for the baby if you would like. Hope we can celebrate in person together soon!</li>
                        </ol>
                    </div>

                    <div>
                        <h2 className='article_section_header' 
                            id='Ask for Money Gifts for Your Baby Shower with Greatest Gift’s Help'>Ask for Money Gifts for Your Baby Shower with Greatest Gift’s Help</h2>
                        <p>Greatest Gift is an online cash <a target='_blank' className='green_text' href='https://www.greatestgiftapp.com'>gifting platform</a> for children’s long-term savings. You can use Greatest Gift to share with your friends and family that you are saving for your baby and introduce them to an easy and exciting way of investing in your baby’s future. </p>
                        <Ask_for_gift/>
                    </div>


                    <div>
                        <h2 className='article_section_header' 
                            id='How to Write Thank You Notes for Money Gifts'>How to Write Thank You Notes for Money Gifts</h2>
                        <p>After the party is over, and the baby is born, we’d like to remind you not to forget the “Thank You” notes. Give your gift-givers the credit they deserve in helping you start your child on the right path to saving!</p>
                        <p>Make it personal by adding a picture or cute video of the newborn.</p>
                        <p>Specifically, with cash gifts, you can show your gratitude by sharing a bit of how you will use the money. If you open a savings account or fund for the baby, let them know their money was used to help start that account! If you buy that stroller you always wanted, share it with them and let them know they made it happen.</p>
                        <div className='article_section_img_container'>
                            <img className='article_section_cover_img' src='https://gg-app-images.s3.us-east-2.amazonaws.com/email_pictures/personalized_gift(small).png'></img>
                        </div>
                    </div>

                    
                    <div>
                        <Ask_for_gift />
                    </div>

                    <div>
                        <p>The greatest wonder of life is about to happen. From all of us at Greatest Gift, good luck, and happy gifting!</p>
                    </div>
                    
                </div>
            )
        } else if (this.props.article_name === 'Introducing Greatest Gift Inc: Gifts for Kids\' Long Term Savings') {
            return (
                <div>

                </div>
            )

        } else if(this.props.article_name === 'How to Start Saving for Your Child’s Future – An Actionable Guide') {
            return (
                <div className='article_body'>
                    {/* Intro */}
                    <div>
                        <p className='bold'>If you’re looking for ways to save for your child’s financial future, and you made it this far, then congratulations! You’re on the right track! Learning the best ways to save for your kids is the first step in creating a better future for them.</p>
                        <p>TL;DR: Starting to save for your child's future can be broken down to 3 steps: start saving, start investing, and grow your investments. The most important thing is to just start.</p>
                    </div>

                    <div>
                        <p>With so many options out there, you might be asking yourself – where do I start?</p>
                        <p>Don’t beat yourself up. Rome wasn’t built in a day and saving for your child’s future is a journey with many steps.</p>
                        <p>We put together this guide to help you with actionable steps for building your kid’s savings for the future. Start with the level that fits your current status and check back in on a yearly basis to see if you are ready to move to the next level.</p>
                    </div>

                    {/* Contents */}
                    <div>
                        <h4 className='article_contents_header'>Jump Ahead:</h4>
                        <ul className='bold'> 
                            <li><a href={'#'+'Saving for your child: Beginner level – Start Saving'} className='green_text'>
                            Beginner level – Start Saving
                            </a></li>
                            <li><a href={'#'+'Saving for your child: Advanced level – Start Investing'} className='green_text'>
                            Advanced level – Start Investing
                            </a></li>
                            <li><a href={'#'+'Saving for your child: Expert level – Grow Your Investments'} className='green_text'>
                            Expert level – Grow Your Investments
                            </a></li>
                        </ul>
                    </div>
                    
                    {/* Chapter */}
                    <div className='article_section'>
                        <h2 className='article_section_header' 
                            id='Saving for your child: Beginner level – Start Saving'>Saving for your child: Beginner level – Start Saving</h2>
                        <p>If you think you don’t have enough money to start, you’re not sure of the best savings option, or just haven’t gotten to it yet, this is the place to begin. Saving as little as <a className='green_text' href='https://csd.wustl.edu/13-06/' target='_blank'>$500 can increase a kid’s chances of graduating from college </a>by as much as 5 times.</p>
                        
                        <h4>Set aside funds for your child</h4>
                            <p>The best way to start is to start. Put money aside for your child in a different savings account, checking account, separate digital savings bucket, or even cash in an envelope. It doesn’t matter where, as long as you put it aside and know that the funds are for your child’s savings.</p>
                            <p>The goal here is to build a starter fund, even if it’s $500, and turn saving for your child into a habit.</p>
                            <h2 className='article_quote'>The goal here is to build a starter fund, even if it's $500</h2>
                            
                            <p>If you’re not sure how to afford putting money aside, start with unexpected income such as gifts, your tax return, a bonus from work, or other unexpected money that comes your way.</p>
                            <p>Greatest Gift’s <a className='green_text' href='https://www.greatestgiftapp.com' target='_blank'>gifting platform</a> provides a great way to kickstart your savings by <a className='green_text' href='/discover/articles/How-to-Ask-for-Money-as-Gifts-for-a-Baby-Shower' target='_blank'>asking your friends and family to send cash gifts</a> for a baby shower, graduation, or any other event.</p>
                            <Ask_for_gift text='Invite Friends & Family to Contribute'/>

                        <h4>Use automated tools to start saving for your child</h4>
                            <p>It’s been proven time and time again; automation is the best way to save money. We can all become forgetful (or even lazy) about constantly managing our finances, so it’s best to use tools that help save effortlessly.</p>
                            <p>Start by automatically moving money to a savings account every month. Look at your budget and see if you can start with $50 per month (or more if you can). If not, start with any amount, and check back in every year, to see if you can increase it. Starting with $10 per month can get you to that $500 goal, and you can reach it even faster with more tools.</p>
                            <p>Use additional technology to boost your savings even more, like rounding up spare change from purchases, or setting up automatic “surprise savings”. Smart saving features are the new saver’s best friend. <a className='green_text' href='/discover/kidssavings' target='_blank'>Find a list of our favorite saving tools here.</a></p>
                        
                        <h4>Use a high yield savings account to start saving for your child</h4>
                            <p>Some banks offer savings accounts that pay you interest on your account balance. A high yield savings account means the bank pays more interest than the average bank, translating to more dollars per year for you. Discover <a className='green_text' href='/discover/kidssavings' target='_blank'>great high yield savings accounts</a> that take a few minutes to open online right here.</p>
                            
                        <h4>Beginner Level Summary - Your Next Steps</h4>
                            <ol>
                                <li>Open a high yield savings account for your child</li>
                                <li>Set aside gifts and unexpected income in the new account</li>
                                <li>Set automatic transfers to the account of $50 per month (or start even with $10!)</li>
                                <li>Save $500</li>
                            </ol>
                    </div>
                    
                    {/* Chapter */}
                    <div className='article_section'>
                        <h2 className='article_section_header' 
                            id='Saving for your child: Advanced level – Start Investing'>Saving for your child: Advanced level – Start Investing</h2>
                        <p>By now, you should have about $500 in a starter fund set aside for your child’s future, and that’s more than enough to take your savings game to the next level. It’s time to put your money to work for you.</p>
                        
                        {/*
                        <div className='article_quote_container'>
                            <p className='article_quote_open'>"</p>
                            <h2 className='article_quote'>It's time to put your money to work.</h2>    
                            <p className='article_quote_close'>"</p>
                        </div>
                        */}
                        
                        <h2 className='article_quote'>It's time to put your money to work</h2>

                        <h4>Start investing for your child’s future with managed investments</h4>
                            <p>While high yield savings are good, investing the savings can provide a much higher return, and has the potential to double your child’s savings over the course of 18 years.</p>
                            <img alt='Greatest gift: Investment Vs Savings Account' className='article_section_cover_img' src="https://gg-app-images.s3.us-east-2.amazonaws.com/articles/greatest_gift_potential_to_double_your_child's_savings.png"></img>
                            <p>Starting to invest can seem complicated or intimidating, but managed investment accounts are beginner friendly, and provide a way to start earning money quickly with no prior knowledge required.</p>
                            <p>With a managed account (some call it a “robo advisor” account) you get to invest in the stock market without having to pick and choose individual stocks or bonds for the price of a small management fee (think 0.3% of your account balance per year in return for an average annual 7% in profits).</p>
                            <p>Don’t forget to keep investing any financial gifts you receive for your child. Gifts can provide a great an additional boost to your overall investment portfolio, and family and friends love to <a className='green_text' href='https://www.greatestgiftapp.com' target='_blank'>give gifts with purpose.</a></p>
                        
                        <h4>Invest for your child’s future with a 529 plan or custodial investment account</h4>
                            <p>Taxes are the worst. Luckily, saving for your child’s future can have some tax benefits with tax-advantaged investment accounts.</p>
                            <p>A 529 plan allows you invest for K-12 tuition (tuition from kindergarten to the 12th grade) and college in a tax-free way, similar to retirement investment accounts. Specifically, you won’t have to pay capital gains taxes on your investment’s earnings, as long as you use the investments to pay for school expenses. Some plans also offer deductions for state taxes on contributions to the account. This can mean thousands of dollars in savings.
                            <br></br><a className='green_text' href='/discover/529plans' target='_blank'>Direct 529 plans</a> (plans you can enroll to without an advisor) offer different investment plans that don’t require your active management, making it even easier to invest with them.</p>
                            <p>Custodial investment accounts are investment accounts that are officially owned by your child and managed by you. This means that the earnings for these accounts are taxed at your child’s income level, not yours, and can potentially save you money on taxes.</p>
                            
                        <h4>Invest for your child’s future with an aggressive portfolio</h4>
                            <p>Most managed investment accounts will ask you for your investment strategy, goals, timeline, and risk appetite in order to recommend an investment portfolio or asset allocation. This determines the type of investments that will be made in the account (percent of investment in stocks vs bonds, mutual funds, and ETFs).</p>
                            <p>When you’re investing for your child, time is on your side. Consider choosing an aggressive investment portfolio that has more stocks, and potential for high returns. As your child ages and nears adulthood, switch the portfolio to a more balanced portfolio with lower risk and less volatility.</p>
                            <p>Most <a className='green_text' href='/discover/529plans' target='_blank'>direct 529 plans</a> offer investment plans that provide exactly this – they change the investment allocation based on the child’s age automatically.</p>
                        
                        <h4>Keep saving for your child’s future with a savings account</h4>
                            <p>Use the money you have in the savings account to start investing and earning more money for your child’s future, but don’t completely abandon your high yield savings account just yet.</p>
                            <p>You should start diverting your auto savings from the savings account into your new investment account, while maintaining a small amount in the savings account. You can replenish the account when you do use the funds from there.</p>
                            <p>Your child’s savings account can be used as an emergency fund for any unexpected expenses immediately, without needing to sell any investments.</p>
                            <p>It can also be used to teach your child about savings, and to encourage your child to deposit savings into the account when the child is older and starts working.</p>
                            <p>The savings account can also be used as the starter funds for your child’s adult life, providing peace of mind and avoiding going into debt. This starter fund will motivate your child to keep saving and encourage them to work towards future savings goals of their own, like putting a down payment on a house, or saving for a wedding.</p>

                        <h4>Advanced Summary – Your Next Steps</h4>
                            <ol>
                                <li>Start investing with a 529 plan, or automated investment plan</li>
                                <li>Choose an investment strategy based on your child’s age – aggressive for younger kids, and more balanced closer to adulthood</li>
                                <li>Maintain the savings account as your child’s digital savings and starter fund for adulthood</li>
                            </ol>
                    </div>
                    
                    {/* Chapter */}
                    <div className='article_section'>
                        <h2 className='article_section_header' 
                            id='Saving for your child: Expert level – Grow Your Investments'>Saving for your child: Expert level – Grow Your Investments</h2>
                            <p>At this point, you are participating in the stock market through managed investment.
                            <br></br>You may be looking to get more control of your investments, or maybe you started listening more carefully to financial news and have your own investment strategy ideas.</p>
                            <p>Most people can gain enough by participating in the stock market through managed funds, but if you still want to get active, let’s look at how you can take your investments to the next level.</p>

                        <h4>Expand your investment knowledge through companies you know first</h4>
                            <p>The first step when starting to trade on your own should be to learn more. Start with one company that you are familiar with, a company that works in an industry that you understand, and start tracking news about the company.
                            <br></br>Listen in on board meetings and try to read about their strategy. Look for a company with a business that you can understand, so you can make educated decisions on whether the company will do well in the years to come.</p>
                            
                            <h2 className='article_quote'>Most people can gain enough by participating in the stock market through managed funds</h2>

                        <h4>Learn about investing from others in your community</h4>
                            <p>Learning from others and sharing experiences are great ways to learn more. People love talking about stocks and investments. Try talking with your friends about their favorite trading platforms, tools, investment strategies, and favorite stock picks. Join online blogs and groups, or even an <a className='green_text' href='/discover/investments/#Public' target='_blank'>investing social network</a> that allows trading and sharing of investment ideas.</p>
                            <p>Advice from experienced traders and brokers can be invaluable. Try to find a mentor in the space, such as someone you have a good relationship with that has a little more experience with investing. Another option is to <a className='green_text' href='/discover/investments/#ZACKS%20Trade' target='_blank'>make trades with live support from seasoned brokers,</a> an option most brokerages offer at different prices (and one even offers this for free).</p>
                        
                        <h4>Invest for your child’s future with fractional shares, ETFs, and mutual funds</h4>
                            <p>When you are just starting active trading and investing, we suggest starting with a small percentage of your total investments for active trades, say 5% or 10% (so if you have $10,000 in the stock market in managed funds, start with $500 or $1,000).</p>
                            <p>Stock prices can seem pricey (Amazon stock is priced at more than $3,000), but many trading platforms offer a solution for this –<a className='green_text' href='/discover/investments/#Fidelity' target='_blank'>fractional shares trading.</a> Fractional shares are fractions of a share, and some trading platforms allow you to buy and sell these smaller pieces of stocks. That means you can choose how much to invest in any stock, and buy a slice of the stock instead of the whole thing.</p>
                            <p>Start your active trading with fractional shares, or buy ETFs, for a lower risk and practice, until you feel more confident.</p>
                            
                            <h2 className='article_quote'>We suggest starting with a small percentage of your total investments for active trades, say 5% or 10%</h2>
                        
                        <h4>Expert Level Summary – Your Next Steps</h4>
                            <ol>
                                <li>Join investing forums or social media</li>
                                <li>Dedicate 5%-10% to active trading</li>
                                <li>Start trading with fractional shares, ETFs, and Mutual Funds</li>
                            </ol>
                    </div>

                    
                    
                    {/* summary */}
                    <h2>Summary and Next Steps</h2>
                    <div className='article_section'>
                        <p>If you follow the steps in this guide, you should be on the right track to saving for your child’s future and setting up your child for success. Savings should feel more attainable now, whether you are saving for college, wedding expenses, a down payment for a house, or starter funds for your child’s adult life.</p>
                        <p>Your children will know they have savings for them, and that feeling alone can change their lives.</p>
                        <p>Remember to celebrate the milestones along the way and enjoy the journey. Have a positive mindset. Time is your best resource when saving for your child, and it’s never too late to start.</p>
                    </div>
                    
                </div>
            )
        } else if(this.props.article_name === '18 Great Ways to Start Saving for Your Child (Before Your Child Turns 18)') {
            return (
                <div className='article_body'>
                    {/* Intro */}
                    <div>
                        <p className='bold'>If you’re looking for ideas and inspiration on how to save for your child’s future, or if you don’t think you have enough money to start saving, check out these 18 ways to start saving for your child.</p>
                        <p>TL;DR: Use unexpected income to start saving for your child effortlessly. Two or three hours a week spent on earning extra income can have a big impact on your child’s savings, and finding saving opportunities in your budget can free up more money for your child’s savings.</p>
                    </div>

                    <div>
                        <p>Whether you’re saving for your child’s future education, a wedding fund, a down payment on a house, or just some starter money for adulthood, getting started can be difficult. Remember though, time is on your side and best time to start saving for your child is now.</p>
                        <p>We did some research and found the best ways to save for your child. Use the ways in this guide to jumpstart your child’s savings and try to build up to an initial $500. A starter fund of at least $500 can set your child on the path to a brighter financial future. If you’re wondering where to put all these savings, check our guide on <a className='green_text' href='/discover/articles/How-to-Start-Saving-for-Your-Child’s-Future-–-An-Actionable-Guide' target='_blank'>how to start saving for your child.</a></p>
                    </div>

                    {/* Contents */}
                    <div>
                        <h4 className='article_contents_header'>Jump Ahead:</h4>
                        <ul className='bold'> 
                            <li><h2><a href={'#'+'Save Your Unexpected Income'} className='green_text'>
                            Save Your Unexpected Income</a></h2></li>
                            <ol>
                                <li>Save a portion of your tax return</li>
                                <li>Save your bonuses from work</li>
                                <li>Save Financial Gifts</li>
                                <li>Save with credit card rewards</li>
                                <li>Sell gift cards for cash savings</li>
                            </ol>

                            <li><h2><a href={'#'+'Ways to Earn Extra Income for Your Child’s Savings'} className='green_text'>
                            Ways to Earn Extra Income for Your Child’s Savings</a></h2></li>
                            <ol start='6'>
                                <li>Employer sponsored 529 plans</li>
                                <li>Earn money by performing tasks and errands</li>
                                <li>Sell photos online</li>
                                <li>Test websites and apps </li>
                                <li>Participate in research studies</li>
                            </ol>

                            <li><a href={'#'+'Reduce Expenses and Put Extra Money Towards Savings'} className='green_text'>
                            <h2>Reduce Expenses and Put Extra Money Towards Savings</h2></a></li>
                            <ol start='11'>
                                <li>Track your budget and spending</li>
                                <li>Save your spare change</li>
                                <li>Save on lunch and coffee</li>
                                <li>Lower your dining-out spending</li>
                                <li>Consolidate your subscriptions</li>
                                <li>Compare insurance costs </li>
                                <li>Lower your interest on credit cards</li>
                                <li>Explore refinancing your mortgage</li>
                            </ol>

                        </ul>
                    </div>

                    {/* Chapter */}
                    <div className='article_section'>
                        <h2 className='article_section_header' 
                            id='Save Your Unexpected Income'>Save Your Unexpected Income</h2>
                        <p>Putting unexpected income aside for savings is the best way to start when you’re not quite sure how to get started in saving for your child. Usually, your budget doesn’t rely on this additional money, and it won't affect you if you put a portion of it towards your child’s savings. If you’re not quite sure what unexpected income is, simply read the examples ahead and discover opportunities to turn found money into booster funds for your child’s savings.</p>
                        
                        <h4>1. Save a portion of your tax return</h4>
                            <p>Your yearly tax return is a perfect opportunity to put money away for your child’s savings. If you don’t rely on your tax return for your monthly living budget, putting aside a portion of it towards savings is a great choice without affecting your lifestyle.</p>
                            <p className='bold'>Savings Potential: $$$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Open a <a className='green_text' href='/discover/articles/How-to-Start-Saving-for-Your-Child’s-Future-–-An-Actionable-Guide' target='_blank'>savings account for your child’s savings</a></li>
                                <li>Use the tax return direct deposit feature to put a portion of your refund directly into your child’s savings account (or ask your tax advisor to set this up for you)</li>
                                <li>If you don’t want to directly deposit the money into your child’s savings, transfer a portion of your refund to your child’s savings account as soon as you get paid </li>
                            </ol>
                        
                        <h2 className='article_quote'>Discover opportunities to turn found money into booster funds for your child’s savings</h2>
                        
                        <h4>2. Save your bonuses from work</h4>
                            <p>If you receive a yearly or a holiday bonus at work, try to save a portion of it. If you usually use this to buy holiday gifts, remember that the <a className='green_text' href='https://www.greatestgiftapp.com' target='_blank'>gift of a brighter future</a> can have a huge impact on your child’s life.</p>
                            <p className='bold'>Savings Potential: $$$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Transfer a portion of your bonus into your child’s saving account as soon as you get paid</li>
                            </ol>

                        <h2 className='article_quote'>Invite your friends and family to contribute to your child’s future with Greatest Gift</h2>

                        <h4>3. Save Financial Gifts</h4>
                            <p>When you receive money or cash gifts from friends and family, save as much as you can from it. If you find that you always get gifts that you don’t need, <a className='green_text' href='/How-to-Ask-for-Money-as-Gifts-for-a-Baby-Shower' target='_blank'>invite your friends and family to contribute</a> to your child’s future with <a className='green_text' href='https://www.greatestgiftapp.com' target='_blank'>Greatest Gift</a>. If they still want to get you stuff, suggest the 50-50 gift rule – spend half the gift budget on stuff, and give the rest in cash gifts.</p>
                            <p className='bold'>Savings Potential: $$$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Invite friends and family to contribute to your child’s future through Greatest Gift</li>
                                <li>Add a gifting platform link to birthday invites and tell family members that <a className='green_text' href='https://www.greatestgiftapp.com' target='_blank'>cash gifts for long term savings</a> are your preferred choice of gifts</li>
                                <li>Pro tip: match any gift you receive and double your child’s savings</li>
                            </ol>
                            <Ask_for_gift text='Invite Friends & Family to Contribute'/>

                        <h4>4. Save with credit card rewards</h4>
                            <p>Using a rewards credit card with cash back is a great way to earn more unexpected income that you can then put towards your child’s savings. Typical cash back cards offer somewhere between 1-3% cash back on your purchases, so if you have an average of $2,000 in expenses per month, you could be earning $480 every year!</p>
                            <p>Another way to earn is to create a <a className='green_text' href='https://www.upromise.com/' target='_blank'>Upromise</a> account. Upromise helps you earn more bonuses when you shop online or dine out, and even provides the option to directly deposit your rewards into a <a className='green_text' href='/discover/529plans' target='_blank'>529 plan</a> (if you choose to link one). </p>
                            <p className='bold'>Savings Potential: $$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Apply to a credit card with a high cash back rate</li>
                                <li>Create a <a className='green_text' href='https://www.upromise.com/' target='_blank'>Upromise account</a> and link your credit card to the Upromise Dining program and earn more rewards</li>
                                <li>Deposit your cash back rewards into your child’s savings whenever you pay your credit card bill, or once a year</li>
                            </ol>

                        <h4>5. Sell gift cards for cash savings</h4>
                            <p>Although people love giving gift cards, gift cards are often not fully used, with <a className='green_text' href='https://www.cbsnews.com/news/unused-gift-cards-add-up-to-3-billion-annually/' target='_blank'>$3 billion left on the table</a> in unredeemed gift cards per year that go to waste.</p>
                            <p>Don’t let those gift cards you received go to waste. Sell your unwanted and unused gift cards and put that money into your child’s savings. A $25 gift card for Target or Starbucks may not mean much to you, but that’s an extra $25 you can put towards your child’s savings.</p>
                            <p className='bold'>Savings Potential: $</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Round up your unused and unwanted gift cards</li>
                                <li>Go to <a className='green_text' href='/https://www.cardcash.com/sell-gift-cards/' target='_blank'>CardCash.com</a> and sell your gift cards</li>
                                <li>Put the earnings into your child’s savings account</li>
                            </ol>
                    </div>

                    {/* Chapter */}
                    <div className='article_section'>
                        <h2 className='article_section_header' 
                            id='Ways to Earn Extra Income for Your Child’s Savings'>Ways to Earn Extra Income for Your Child’s Savings</h2>
                        <p>In this section, we have some ways for you to make a few extra dollars for your child's savings account with as little time and effort as possible. Finding two or three hours per week to earn an extra $25 per week can quickly become a total of $100 per month and can help you save enough for two years of college in a public school! </p>
                        <p>Find the way that works best for you from an option of online or offline ways and turn it into a habit. Your kids will thank you in the future, and so will your future self.</p>

                        <h4>6. Employer sponsored 529 plans</h4>
                            <p>Similar to 401(k) contributions and matching by employers, some employers sponsor and match contributions to <a className='green_text' href='/discover/529plans' target='_blank'>529 plans</a> (tax-advantaged college saving & investment accounts). This can mean earning more for your child’s future savings, with no effort at all!</p>
                            <p>On top of that, some <a className='green_text' href='https://www.savingforcollege.com/article/the-new-401-k-employer-sponsored-529-plans-shift-into-high-gear' target='_blank'>employers even get a tax benefit for contributing to 529 plans.</a> If you work for yourself, ask your tax advisor if there are any benefits you can get for making direct payroll deductions to your 529 plan.</p>
                            <p className='bold'>Savings Potential: $$$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Ask your employer or HR department if there is a program for contributions to 529 plans. Alternatively, look up your employee benefits on your company website and search for 529 plans</li>
                                <li>If the employer matches contributions, start depositing what you can, and earn extra from your employer</li>
                            </ol>
                        
                        <h2 className='article_quote'>We have some ways for you to make a few extra dollars for your child's savings account</h2>

                        <h4>7. Earn money by performing tasks and errands</h4>
                            <p>If you have a few extra hours to dedicate for performing tasks, check out <a className='green_text' href='https://www.taskrabbit.com/become-a-tasker' target='_blank'>TaskRabbit</a>. TaskRabbit (partnered with Ikea) is a platform where you earn money by performing tasks for other people. As a new TaskRabbit, you can start earning quickly for tasks like running errands, assembling furniture, hanging TVs, or help moving. You can even get paid for waiting in line for someone else.</p>
                            <p className='bold'>Savings Potential: $$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Sign up as a Tasker on the <a className='green_text' href='https://www.taskrabbit.com/become-a-tasker' target='_blank'>TaskRabbit</a> site (there is a $25 registration fee, but you can earn that back with your first task)</li>
                                <li>Start with offering a task you feel proficient in</li>
                                <li>Dedicate a two or three hour window for performing a task. Start with one task, then try to do at least a task per month, or even a task per week if you can</li>
                                <li>Put all your extra earnings from performing tasks into your child’s savings</li>
                            </ol>
                        
                        <h4>8. Sell stock photos, images and illustrations</h4>
                            <p>If you own a smartphone, that means you own a camera. Demand for photos keeps growing. Whether it is for a small business or an online blogger, there are opportunities to sell pictures for websites and digital marketing efforts. Even <a className='green_text' href='https://www.greatestgiftapp.com' target='_blank'>Greatest Gift</a> buys illustrations from websites like Shutterstock every now and then.</p>
                            <p>Stock photo websites will often let you know what type of images are in demand. Demand exists for many different photo categories, including family photos, finance, nature, city views, landscapes, and even food.</p>
                            <p className='bold'>Savings Potential: $</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Open a contributor account on <a className='green_text' href='https://submit.shutterstock.com/?language=en' target='_blank'>Shutterstock</a> or <a className='green_text' href='https://contributor.stock.adobe.com/' target='_blank'>Adobe</a></li>
                                <li>Read a few articles on their website to <a className='green_text' href='https://www.shutterstock.com/explore/the-shot-list-archive' target='_blank'>learn what’s in demand</a> and how to get the most downloads</li>
                                <li>Take a day to capture some pictures and upload your pictures. Repeat on a monthly basis until you see results</li>
                            </ol>
                        
                        <h4>9. Test websites and apps</h4>
                            <p>Companies are constantly looking to understand how customers interact with their websites and apps and are willing to pay money for people to participate in product testing. The good news are, prior knowledge and skills are not required. All you need is a computer, access to the internet, and the ability to share your thoughts on a product aloud.</p>
                            <p>You can sign up and get paid to share your opinions and experiences on <a className='green_text' href='https://www.usertesting.com/get-paid-to-test' target='_blank'>Usertesting</a>.</p>
                            <p className='bold'>Savings Potential: $$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Sign up to be a <a className='green_text' href='https://www.usertesting.com/get-paid-to-test' target='_blank'>Usertesting tester</a> and download the testing extension</li>
                                <li>Take one practice test, and then get invited to take tests for cash</li>
                                <li>Take 1-3 tests per week and you can get to that $100 per month in savings for your child</li>
                            </ol>
                        
                        <h4>10. Participate in research studies</h4>
                            <p>If you live next to a university or college, check out their website to see if they pay for participation in research studies. University researchers often need participants for their studies and have a budget to pay for your participation. </p>
                            <p>The social sciences field is more likely to look for research participants. Studies typically don’t require any physical exertion or medical procedures. Studies in the psychology field typically include visual perception and attention, decision-making, language acquisition, learning, and memory and social cognitive processes. Studies in economics can include decision-making, consumer behavior, or opinions on social issues.</p>
                            <p className='bold'>Savings Potential: $</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Do a simple google search with the university name and “social sciences paid research study”. For example – “NYU social sciences paid research study”</li>
                                <li>Find the website for the psychology department or the economics / business department and look for the paid study section</li>
                                <li>Sign up, and schedule tests every now and then. Pro tip: try to schedule multiple tests back-to-back to save on travel time and expenses</li>
                            </ol>
                            
                    </div>

                    {/* Chapter */}
                    <div className='article_section'>
                        <h2 className='article_section_header' 
                            id='Reduce Expenses and Put Extra Money Towards Savings'>Reduce Expenses and Put Extra Money Towards Savings</h2>
                        <p>Sometimes you have opportunities to save that you weren’t aware of. The following will help you think of ways to significantly save money from your existing expenses and will provide you with tips and links to tools that will help you save with ease. </p>
                        <p>Free up your money from unnecessary expenses and put more money towards your child’s savings. </p>
                        
                        <h4>11. Track your budget and spending</h4>
                            <p>It’s hard to save money when you don’t really know where and how you spend your money. The first step in saving more money is seeing where your money is going. Only then can you start to build a spending plan. When you have a budget and understand where your money is being spent, you can find opportunities to save money and you gain confidence in managing your finances. </p>
                            <p>Luckily, apps and websites like <a className='green_text' href='https://mint.intuit.com/' target='_blank'>Mint</a> exist for this purpose, and help you get control over your money and spending. </p>
                            <p>The closer you stay to your budget, the less likely it will be that you exceed it.</p>
                            <p className='bold'>Savings Potential: $$$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li><a className='green_text' href='https://mint.intuit.com/' target='_blank'>Open a mint account</a> and link your credit cards to start tracking your spending</li>
                                <li>Check back in once a month and start understanding your spending habits after a few short months</li>
                                <li>Create a budget for different categories in Mint</li>
                                <li>Find saving opportunities and put the money towards your child’s savings</li>
                            </ol>

                        <h4>12. Save your spare change</h4>
                            <p>Spare change can add up, and while it may not have a big impact on your life, the spare change you save up can help kickstart your child’s savings. If you use credit cards more often, try using a digital spare change saving tool.</p>
                            <p>Some banks offer spare change roundups on your credit card purchases, and some fintech apps like <a className='green_text' href='/discover/kidssavings/#Acorns' target='_blank'>Acorns</a> even invest these roundups for you automatically for an extra boost on your savings. </p>
                            <p>For example, if you buy a snack for $1.55, Acorns will round up the cost to an even $2 and put the additional 45 cents into an investment account for you.The average person can have anywhere between 20-30 transactions per month, which could translate to $10-15 per month! Every dollar counts when you are trying to save money.</p>
                            <p className='bold'>Savings Potential: $$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>If you use a lot of cash, get a jar and put your coins in it. Otherwise, open an account on <a className='green_text' href='/discover/kidssavings/#Acorns' target='_blank'>Acorns</a>, and link your credit card for a digital option</li>
                                <li>Once a year, transfer your savings to your child’s savings, or deposit the contents of your change jar at the bank (take your child with you for a complete experience)</li>
                                <li>Pro tip: try doubling or tripling your spare change roundups on Acorns to put even more savings away!</li>
                            </ol>

                        <h4>13. Save on lunch and coffee</h4>
                            <p>Expenses on lunch and coffee for your workday can add up quickly and have a big cost saving opportunity. </p>
                            <p>Buying lunch at work can easily cost $7-12, while bringing your own lunch can cost you half as much. If you cook routinely, try cooking larger portions and take leftovers the next day to work. If you’re not a great cook, sandwiches and veggies make a satisfying lunch.</p>
                            <p>If you have to start your day with a caffeine fix, try making a cup at home and taking it to go. Buying a $4 cup of coffee every day quickly adds up, and that’s before buying a croissant or cookie to go with it. Instead, get a coffee machine at home and buy to-go cups. This will slash your daily coffee expense in half. By the way, you can get bonus savings if you switch to tea!</p>
                            <p className='bold'>Savings Potential: $$$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Buy a set of <a className='green_text' href='https://www.amazon.com/Airtight-Containers-Microwave-Dishwasher-Container/dp/B078S3W6KQ/ref=sr_1_9?dchild=1&keywords=glass+tupperware&qid=1619814669&sr=8-9' target='_blank'>reusable containers</a> for your lunch (you won’t have to wash them every day)</li>
                                <li>Get a coffee machine – <a className='green_text' href='https://www.keurig.com/brewer-starter-kit?cm_sp=ksk+banner+sut-_-home+page-_-get+started' target='_blank'>Keurig</a> or <a className='green_text' href='https://www.nespresso.com/us/en/' target='_blank'>nespresso</a> will work nicely for this. Buy <a className='green_text' href="https://www.amazon.com/Dixie-Beverage-Assorted-Designs-Disposable/dp/B008R6LOBS/ref=sr_1_4?dchild=1&keywords=togo%2Bcoffee%2Bcups&qid=1619815409&s=home-garden&sr=1-4&th=1" target='_blank'>to-go cups in bulk</a> or get two <a className='green_text' href='https://www.amazon.com/Jura-Tumbler-Double-Stainless-Installation/dp/B07MJR3P1H/ref=sxin_10?asc_contentid=amzn1.osa.25d60a01-7fa3-4030-a3ed-ff299d397527.ATVPDKIKX0DER.en_US&asc_contenttype=article&ascsubtag=amzn1.osa.25d60a01-7fa3-4030-a3ed-ff299d397527.ATVPDKIKX0DER.en_US&creativeASIN=B07MJR3P1H&cv_ct_cx=togo+coffee+cups&cv_ct_id=amzn1.osa.25d60a01-7fa3-4030-a3ed-ff299d397527.ATVPDKIKX0DER.en_US&cv_ct_pg=search&cv_ct_we=asin&cv_ct_wn=osp-single-source-earns-comm&dchild=1&keywords=to+go+coffee+cups&linkCode=oas&pd_rd_i=B07MJR3P1H&pd_rd_r=7bb36330-2cfd-4c85-a403-e2d306a76e85&pd_rd_w=YiXLJ&pd_rd_wg=CoAvT&pf_rd_p=c5aa77b7-f0ac-4b95-b207-5ef230c60c9b&pf_rd_r=5HZZF2KPTYRXFCXWR8GK&qid=1619815409&s=home-garden&sr=1-2-64f3a41a-73ca-403a-923c-8152c45485fe&tag=one37pmb-20' target='_blank'>reusable cups</a> (in case you forget to wash one!). Making sure you have supplies readily at home will increase your chances of following through with this</li>
                                <li>Set an auto deposit of $10 per week (or however much you think you are saving with this) into your child’s savings</li>
                                <li>Pro tip: If you absolutely can’t let go of your Starbucks, start ordering a smaller size and save about $1 a day (or over $200 a year!)</li>
                            </ol>

                        <h2 className='article_quote'>Free up your money from unnecessary expenses and put more money towards your child’s savings</h2>
                        
                        <h4>14. Lower your dining-out spending</h4>
                            <p>We all love going out to a good restaurant, but it can get pricey, and the costs add up quickly. </p>
                            <p>Cutting back on alcohol when eating at restaurants can save you 30% of your dining out budget, and your liver may thank you as well. </p>
                            <p>When you go out to a restaurant, have a rough plan of how many items you can order. Choosing to share some dishes can lower your bill too. </p>
                            <p>Commit to eating out fewer times per month. Instead, try to elevate your home cooking for a more personal date night in, or a fun dinner in with friends. </p>
                            <p className='bold'>Savings Potential: $$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>When going out to restaurants, opt for sharing dishes and try to order fewer alcoholic beverages</li>
                                <li>Go out one less time per month and try cooking at home (even ordering pizza can save a few dollars)</li>
                            </ol>

                        <h4>15. Consolidate your subscriptions</h4>
                            <p>It feels like every company offers a subscription for something nowadays. Netflix, Amazon Prime, Spotify, Apple Music, Dropbox, Blue Apron, Prose and so many others can take up a recurring chunk of your monthly expenses. Look at your monthly credit and debit card statements and find subscriptions that you no longer need. </p>
                            <p>If you have a partner or close friend, see if you can set up a family account. For example, Spotify offers a family account for $16 for up to five accounts, while each individual account can cost you $10 per month. If you never compared your subscriptions with your partner, this is the time to do it. </p>
                            <p>If you prefer a more hands-free method, check out <a className='green_text' href='https://www.truebill.com/feature/manage-subscriptions' target='_blank'>Truebill</a>, an online service and app that helps get control over your subscriptions for you. One cancelled subscription can mean $10 per month that go towards your child’s savings. </p>
                            <p className='bold'>Savings Potential: $$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Scan your monthly statements for all your recurring subscriptions</li>
                                <li>Compare your subscriptions with your partner’s and see if there are any family plans you can switch to</li>
                                <li>Create a truebill account and <a className='green_text' href='https://www.truebill.com/feature/manage-subscriptions' target='_blank'>let truebill do the work for you</a></li>
                                <li>As soon as you cancel a subscription, set a auto-deposit for your child’s savings account for the same amount</li>
                            </ol>

                        <h4>16. Compare insurance costs </h4>
                            <p>There’s insurance for your home (whether you own or rent), your car, your life (yikes) and even for your pet. Hopefully you compared and shopped around before purchasing the right insurance for your needs, but that doesn’t mean your work is done. Every year when it’s time to renew is an opportunity to compare prices again and look for the best price out there.  </p>
                            <p className='bold'>Savings Potential: $$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Whenever you get a notification that your insurance is about to renew, get quotes from two competitor insurance companies</li>
                                <li>Set an auto-deposit with the difference into your child’s savings</li>
                            </ol>

                        <h2 className='article_quote'>Use any of the ways in this guide to pay down your credit card debt and avoid paying interest</h2>
                        <h4>17. Lower your interest on credit cards</h4>
                            <p>Credit cards and their rewards are good only as long as you avoid paying interest on them. Paying your credit card bills on time and for the full statement amount can help you avoid paying unnecessary monstrous fees. </p>
                            <p>If you do you have credit card debt, use any of the ways in this article to first crush your credit card debt. Credit card interest rates can be well over 14% and can ruin all of your saving attempts without noticing. </p>
                            <p>With an average spend of $2,000 per month, you could be looking at hundreds if not thousands of dollars every year on interest payments alone!</p>
                            <p className='bold'>Savings Potential: $$$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Pay your statement in whole and on time every month</li>
                                <li>Use any of the ways in this guide to pay down your credit card debt and avoid paying interest</li>
                            </ol>


                        <h4>18. Explore refinancing your mortgage</h4>
                            <p>Refinancing means replacing your existing mortgage loan with a new one, with the hope of getting better terms on the new loan. There are a few ways refinancing can help you save money, both in the short term and the long term. </p>
                            <p>Here are a few ways refinancing your mortgage can help you save money:</p>
                            <ul>
                                <li>If the market interest rates are down, you can try to refinance and get a lower interest rate on your loan. This means a lower monthly payment, as long as you don’t shorten the length of your mortgage with your new loan. Lowering your debt by half a percent can save your hundreds of dollars every year. </li>
                                <li>If your credit score significantly improved since you initially took out your mortgage, you may have a big saving opportunity through refinancing. Even a <a className='green_text' href='https://www.mortgageloan.com/a-better-credit-score-could-make-mortgage-refinancing-worthwhile#Credit-a-bigger-factor-in-conventional-loans' target='_blank'>twenty-point difference in your credit score</a> can translate to significant monthly savings and thousands of dollars over the lifetime of a loan. Paying mortgage payments on time for a year or two can already have a great impact on your credit score. </li>
                                <li>Refinancing and getting a longer loan with smaller monthly payments can help today at the expense of tomorrow. With this method you will pay more over the lifetime of your loan, but you will be able to free up some cash on a monthly basis. This method is only good if you can <a className='green_text' href='/discover/articles/How-to-Start-Saving-for-Your-Child’s-Future-–-An-Actionable-Guide' target='_blank'>invest your child’s savings in investment accounts</a> and earn a higher percentage from the investment than your mortgage interest. </li>
                                <li>If your household income has increased since you took the mortgage you can refinance with a shorter mortgage that has a higher monthly payment. With a shorter loan, you have the potential to save more on interest payments, so that your overall lifetime payments will be smaller. This may not help you save today, but it could help you save in the long run. </li>
                            </ul>
                            <p className='bold'>Savings Potential: $$$</p>
                            <p className='article_section_list_header bold'>Implementation Tips:</p>
                            <ol>
                                <li>Work on improving your credit score</li>
                                <li>When you feel the time is right (at least once every five years), collect quotes from at three (or more) different lenders. Try to diversify the type of lenders you reach out to and include both big banks, local banks and credit unions</li>
                                <li>If you get a good quote, refinance your mortgage</li>
                                <li>Set a new auto-deposit into your child’s savings account with the new saved money</li>
                            </ol>




                    </div>
                    
                    
                    {/* summary */}
                    <h2>Final Thoughts</h2>
                    <div className='article_section'>
                        <p>If you found one of the ways mentioned here useful, try it and start saving right away. Come back to this guide once a year and see if there are more ways you can save.</p>
                        <p>Remember, $500 can <a className='green_text' href='https://csd.wustl.edu/13-06/' target='_blank'>increase your child’s chances of graduating from college</a> by up to five times.</p>
                        <p>To make sure your money is being saved in the best tools and earning you more money, check out our guide on <a className='green_text' href='/discover/articles/How-to-Start-Saving-for-Your-Child’s-Future-–-An-Actionable-Guide' target='_blank'>how to start saving for your child.</a></p>
                    </div>
                    
                    
                    
                </div>
            )
        } else {
            return null;
        }
    }
}

export default Article_body;
