import React from 'react';


import { Route, Redirect } from 'react-router-dom';
import GGApi from '../../../utils/GGApi';


function ProtectedRoute ({component: Component, ...rest}) {
    
    let user = JSON.parse(localStorage.getItem('user'));
    let validToken = true; 
    
    if (!user ) {
        validToken = false;
    
    } else if (user) {
        GGApi.checkToken().then(response => {
            validToken = response;
            if (validToken === false) {
                window.location.reload();
                alert('You have been logged out due to inactivity. Please log back in to continue using the Greatest Gift website.');
                /* 
                this is only a temporary solution that should be improved - it relies on window refresh!
                Right now, it renders the Component, until the response arrives, 
                then refreshes if it was a bad token.
                Potential solution - loading screen!
                */
                
            };
        });
    };

    

    return (
        <Route
            {...rest} 
            render={(props) =>
            (validToken === true) ? 
            (<Component {...rest} {...props} />) : 
            (<Redirect to="/login" />)}
            />
    );
}

export default ProtectedRoute;
