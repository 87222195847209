import React from 'react';
import './Selector_field.css';


/*

    options             = {[list_of_options_to_select_from]}

    label               = "label"
    field_name          = "name"
    field_placeholder   = "text"
    field_value         = {some function that determines the value}
    max_length          = {250}
    randomize           = false

    image_selector      = false

    handleChange        = {this.handleChange}

    select_item         = {(selected_item) => this.setState({item_value: selected_item})}
*/

class Selector_field extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: -1,
            options: {
            }
        }

        this.nextItem       = this.nextItem.bind(this);
        this.previousItem   = this.previousItem.bind(this);
        this.random   = this.random.bind(this);

        this.textAreaRef = React.createRef();

    }

    nextItem(){
        let i;
        if(this.state.index === this.props.options.length - 1) {
            i = 0;
        } else {
            i = this.state.index + 1;
        };
        
        let new_value = this.props.options[i];
        if(!this.props.image_selector) {
            document.getElementById('selector_field_input').value = new_value;
        }
        this.props.select_item(new_value);
        this.setState({index: i});

        if(this.textAreaRef.current){
            this.textAreaRef.current.scrollTop = 0;
            this.textAreaRef.current.scrollLeft = 0;
        }

    }

    previousItem(){
        let i;
        if(this.state.index === 0 || this.state.index === -1 ) {
            i = this.props.options.length - 1;
        } else {
            i = this.state.index - 1;
        };

        let new_value = this.props.options[i];
        if(!this.props.image_selector) {
            document.getElementById('selector_field_input').value = new_value;
        }
        this.props.select_item(new_value);
        this.setState({index: i});

        // scroll the text area:
        if(this.textAreaRef.current){
            this.textAreaRef.current.scrollTop = 0;
            this.textAreaRef.current.scrollLeft = 0;
        }
    }

    random(){
        let i = this.state.index;
        while ( i === this.state.index){
            i = Math.floor( Math.random() * this.props.options.length);
            console.log(i)
        }

        let new_value = this.props.options[i];
        document.getElementById('selector_field_input').value = new_value;
        this.props.select_item(new_value);
        this.setState({index: i});
    }

    render () {

        return (
                <div>
                    
                    <p className='form_label' style={{ 'margin': `0`}}>{this.props.label}</p>
                    
                    <div className='selector_field_master_container'>
                        
                        <div className='selector_field_container'>

                            <div><i className={'material-icons-outlined back_arrow'} onClick={this.previousItem}>arrow_back_ios</i></div> 
                            
                            {this.props.image_selector 
                            
                            ?

                                <div className='selector_field_img_container'>
                                    <img className='selector_field_img'
                                        src={this.props.field_value.url}
                                    />
                                </div>
                            : 
                            
                                <textarea 
                                ref={this.textAreaRef}
                                id='selector_field_input' 
                                className='selector_field_text_area'
                                onChange={this.props.handleChange}
                                name={this.props.field_name} 
                                placeholder={this.props.field_placeholder}
                                value={this.props.field_value}
                                maxLength={this.props.max_length}
                                autoComplete='off'
                                />
                            }

                            <div><i className={'material-icons-outlined forward_arrow'} onClick={this.nextItem}>arrow_forward_ios</i></div>
            
                        </div>

                        <div className='selector_field_container_tracker'>
                            {`${this.state.index + 1 }/${this.props.options.length}`}
                        </div>
                    </div>

                    {this.props.randomize ? 
                    <div className='selector_field_randomizer_container'>
                        <p  style={{ 'width': `42px`}} ></p>
                        <p className='green_text cursor' onClick={this.random}>Choose Random</p>
                        <p className='tiny_text selector_field_char_count'>{this.props.field_value.length}/{this.props.max_length}</p></div>
                    :
                    <div><p className='tiny_text selector_field_char_count'>{this.props.field_value.length}/{this.props.max_length}</p></div> }
                    

                </div>

                                    
        );
    }
}

export default Selector_field;