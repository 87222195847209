const calculator = {
    compound(amount, Y, I) {
        if(amount[0] === '$') {
            amount = amount.slice(1);
        };
        
        let compoundedFrequencyPerYear = 12;
        let result = Number(amount);
        let y = '';
        let n = '';
        for (y = 1 ; y < Y + 1 ; y++) {
            for ( n = 1 ; n <= compoundedFrequencyPerYear ; n++) {
                result = result * ( 1 + (I / compoundedFrequencyPerYear ));
            }
        };
        return result.toFixed(0);
    },

    getYearFromDate(date){
        let year = Number(date.slice(6));
        return year;
    },

    todaysYear() {
        let d = new Date();
        let year = d.getFullYear();
        return year;
    },

    monthlyAddition(targetValue, initialPrincipal, Y, I) {
        let P = 0;
        let Prin = initialPrincipal;
        // remaining value needs to be targetValue less future value of initial Principal
        
        let TV = Number(targetValue);
        let RM = TV - calculator.compound(Prin, Y, I);
        let n = 12; // compounded frequency per year
        P = ( ( RM * (I/n) ) /  ((1+(I/n))  *   ((Math.pow((1+(I/n)),(n*Y)) -1))  )  );
        P = P.toFixed(0);

        return P;
    }


};

export default calculator;
