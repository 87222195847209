import React from 'react';
import './Landing_page.css';

import { Link } from 'react-router-dom';

import GG_balloons from '../general_components/GG_confetti/GG_balloons';



import cover_picture from '../../images/illustrations/personalized_cash_gift_app.png';
import how_it_works_1 from '../../images/illustrations/personalized_gift.png';
import how_it_works_2 from '../../images/illustrations/gift_sent.png';
import how_it_works_3 from '../../images/illustrations/redeem_gift.png';
import security from '../../images/illustrations/blue_shield.png';
import gifts from '../../images/illustrations/gifts_w_confetti.png';
import Pic_selector from '../general_components/Pic_selector/Pic_selector';
import Media_selector from '../general_components/Pic_selector/Media/Media_selector';
import Greeting_box from './Greeting_box/Greeting_box';
import Home_Page_Footer from './Home_Page_Footer';
import Home_Page_Header from './Home_Page_Header';
import Accordion from '../general_components/Accordion/Accordion';

// import and calculate pricing info
import gg_fees from '../../utils/gg_pricing';
let lowest_fee_variable     =  `${String(((Math.min(gg_fees.send_fees.ach_variable, gg_fees.send_fees.cc_variable) + Math.min(gg_fees.redeem_fees.checking_variable, gg_fees.redeem_fees.other_variable))*100).toFixed(1))}%`;
let lowest_fee_fixed        =  (Math.min(gg_fees.send_fees.ach_fixed, gg_fees.send_fees.cc_fixed) + Math.min(gg_fees.redeem_fees.other_fixed)) === 0 ? '' : `${String((Math.min(gg_fees.send_fees.ach_fixed, gg_fees.send_fees.cc_fixed) + Math.min(gg_fees.redeem_fees.other_fixed)))}¢`;

let checking_redeem_fee     =  `${String((gg_fees.redeem_fees.checking_variable   *100).toFixed(1))}% ${gg_fees.redeem_fees.checking_fixed    === 0 ? '': '+ '+gg_fees.redeem_fees.checking_fixed+'¢' }`;
let other_redeem_fee        =  `${String((gg_fees.redeem_fees.other_variable      *100).toFixed(1))}% ${gg_fees.redeem_fees.other_fixed       === 0 ? '': '+ '+gg_fees.redeem_fees.other_fixed+'¢' }`;
let ach_send_fee            =  `${String((gg_fees.send_fees.ach_variable*100).toFixed(1))}% ${gg_fees.send_fees.ach_fixed === 0 ? '': '+'+gg_fees.send_fees.ach_fixed+'¢' }`;
let cc_send_fee             =  `${String((gg_fees.send_fees.cc_variable*100).toFixed(1))}% ${gg_fees.send_fees.cc_fixed === 0 ? '': '+'+gg_fees.send_fees.cc_fixed+'¢' }`;   

let variable_fees_for_hundred        =   100 * (Math.min(gg_fees.send_fees.ach_variable, gg_fees.send_fees.cc_variable) + Math.min(gg_fees.redeem_fees.checking_variable, gg_fees.redeem_fees.other_variable));
let fixed_fees_for_hundred          = (Math.min(gg_fees.send_fees.ach_fixed, gg_fees.send_fees.cc_fixed) + Math.min(gg_fees.redeem_fees.other_fixed));

class Landing_page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_balloons: false,
            occasion: 'Just because'
        }

        this.updateOccasion = this.updateOccasion.bind(this);
        this.showBalloons = this.showBalloons.bind(this);
    }
    
    componentDidMount() {
        this.props.removeNavBar();
        //this.props.removeHeader();
    }

    componentWillUnmount() {
        this.props.restoreNavBar();
        //this.props.restoreHeader();
    }

    updateOccasion(event){
        this.setState({occasion: event.target.value})
    }
    
    showBalloons(){
        if(window.pageYOffset < 200 && window.pageYOffset > 0 && !this.state.show_balloons){
            this.setState({show_balloons: true});
        }
    }

    render () {

        window.onscroll = this.showBalloons;

        return (
            <div className='landing_body'>

                <Home_Page_Header />
                
                <div className='landing_main'>

                    <div className='section_1 cover landing_main_section'>
                        {this.state.show_balloons ? <GG_balloons landing={true}/> : null}
                        <div className='two_column_flex_content'>
                            <div className='left_side'>

                                <div className='landing_page_tagline'>
                                    <h1>Personalized cash gifts with purpose</h1>
                                    <h3>Send and receive monetary gifts for children's long term savings. Set them on the path to higher education, and a great financial future.</h3>
                                    <h3>Discover great ways to save and invest for children, and your new go-to for family finance knowledge.</h3>
                                    {/*<h3>Receive gifts, plan savings goals, and keep track of savings.</h3>*/}
                                </div>

                                <div className='section_1_buttons_container'>
                                    <Link to='/give-a-gift'>
                                        <button className='landing_page_button'>
                                            Give a gift now
                                        </button>
                                    </Link>

                                    <Link to='/receive'>
                                        <button className='landing_page_button'>
                                            Redeem a gift now
                                        </button>
                                    </Link>
                                </div>
                                    
                            </div>
                            
                            <div className='right_side'>
                                <img src={cover_picture} />
                            </div>
                        </div>

                    </div>
                    

                    <div id='send_gifts' className='section_3 landing_main_section'>
                        <div className='section_header'>
                            <h2>{'Send Monetary Gifts, Invest in their Future'}</h2>
                        </div>

                        <div className='two_column_flex_content two_column_screenshot'>
                            <div className='left_side'>
                                <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/landing_page/benefits_gifter_1.svg'}/>
                            </div>

                            <div className='right_side'>
                                <div className='two_column_screenshot_textbox'>
                                    <h3>Personal Greetings</h3>
                                    <p>Turn a money gift into a thoughtful gesture by adding a personal greeting with pictures and videos. Don't settle for a gift emoji.</p>
                                </div>
                            </div>
                        </div>

                        <div className='two_column_flex_content two_column_screenshot two_column_reverse'>
                            <div className='left_side'>
                                <div className='two_column_screenshot_textbox'>
                                    <h3>Gift With Purpose</h3>
                                    <p>Gift receivers are encouraged to put cash gifts to work for them, and get to discover fantastic savings and investment options for their family.</p>
                                </div>
                            </div>

                            <div className='right_side'>
                                <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/landing_page/benefits_gifter_2.svg'}/>
                            </div>
                        </div>

                        <div className='two_column_flex_content two_column_screenshot'>
                            <div className='left_side'>
                                <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/landing_page/benefits_gifter_3.svg'}/>
                            </div>

                            <div className='right_side'>
                                <div className='two_column_screenshot_textbox'>
                                    <h3>Easily Send To Anyone</h3>
                                    <p>All you need is the receiver's phone number or email. No need to know the receiver's account numbers. Anyone can receive gifts, whether they have a Greatest Gift account or not.</p>
                                </div>
                            </div>
                        </div>

                        <div className='section_button_container'>
                            <Link to='/give-a-gift'>
                                <button className='landing_page_button'>
                                    Start a gift
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div id='receive_gifts' className='section_3 landing_main_section'>
                        <div className='section_header'>
                            <h2>{'Receive & Redeem Gifts, Discover Ways to Save & Invest'}</h2>
                        </div>

                        <div className='two_column_flex_content two_column_screenshot two_column_reverse'>
                            <div className='left_side'>
                                <div className='two_column_screenshot_textbox'>
                                    <h3>Receivers' Choice</h3>
                                    <p>Gift receivers get to choose how to use the money, and are encouraged to save in 529 plans and long term savings accounts.</p>
                                </div>
                            </div>

                            <div className='right_side'>
                                <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/landing_page/benefits_receivers_1.svg'}/>
                            </div>
                        </div>

                        <div className='two_column_flex_content two_column_screenshot'>
                            <div className='left_side'>
                                <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/landing_page/benefits_receivers_2.svg'}/>
                            </div>

                            <div className='right_side'>
                                <div className='two_column_screenshot_textbox'>
                                    <h3>Easy to Redeem</h3>
                                    <p>Skip the trip to the bank. Transfer cash gift funds from Greatest Gift directly to your favorite long-term savings or investment accounts.</p>
                                </div>
                            </div>
                        </div>

                        <div className='two_column_flex_content two_column_screenshot two_column_reverse'>
                            <div className='left_side'>
                                <div className='two_column_screenshot_textbox'>
                                    <h3>Discover Ways to Save & Invest</h3>
                                    <p>We help parents answer financial questions, and provide a curated collection of financial products, guides and life-hacks for creating a brighter future for their family.</p>
                                </div> 
                            </div>

                            <div className='right_side'>
                                <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/landing_page/benefits_receivers_3.svg'}/>
                            </div>
                        </div>

                        <div className='section_button_container'>
                            <Link to='/give-a-gift'>
                                <button className='landing_page_button'>
                                    Give a gift now
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div id='gift_of_financial_knowledge' className='section_4 landing_main_section'>
                        <div className='section_header'>
                            <h2>The Gift of Financial Knowledge</h2>
                        </div>

                        <div className='two_column_flex_content'>
                            
                            <div className='left_side'>
                                <div className='discover_questions_container'>
                                    
                                    <div className='discover_question'>
                                        <p>How do we prepare financially for a baby?</p>
                                    </div>
                                    <div className='discover_question discover_question_indented'>
                                        <p>How should I start saving for my child?</p>
                                    </div>
                                    <div className='discover_question'>
                                        <p>How do we teach our kids about money?</p>
                                    </div>
                                    <div className='discover_question discover_question_indented'>
                                        <p>When should we start building credit for our kids?</p>
                                    </div>

                                </div>
                                
                            </div>

                            <div className='right_side'>
                                <div className='gift_of_financial_knowledge_text'>
                                    <h3>Our gifts go a long way. </h3>
                                    <p>We help parents answer financial questions and provide gift receivers with a curated collection of financial products, guides and life-hacks for creating a bright financial future for their children.</p>
                                    <div className='section_button_container'>
                                        <Link to='/discover'>
                                            <button className='landing_page_button'>
                                                Discover More
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className='sub_section'>
                            <h2 className='sub_section_header'>Our Featured Partners</h2>
                            
                            <div className='trust_icons_container'>
                                <a className='trust_icon_item' href='/discover/kidssavings/#Acorns' target='_blank'>
                                    <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_kidssavings_acorns.png'}/>
                                </a>
                                <a className='trust_icon_item' href='/discover/kidssavings/#Greenlight' target='_blank'>
                                    <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_kidssavings_greenlight.png'}/>
                                </a>
                                <a className='trust_icon_item' href='/discover/investments/#Public' target='_blank'>
                                    <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_brokerage_public.png'}/>
                                </a>
                                <a className='trust_icon_item' href='/discover/kidssavings/#Axos' target='_blank'>
                                    <img src={'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_kidssavings_axos_bank.png'}/>
                                </a>
                            </div>
                        </div>

                    </div>


                    <div id='how it works' className='section_2 how_it_works landing_main_section'>
                        <div className='section_header'>
                            <h2>How It Works</h2>
                        </div>

                        <div className='blocks_container'>
                            
                            <div className='block'>
                                <img src={how_it_works_1}></img>
                                <h3>1. Personalize</h3>
                                <p>Choose a greeting (or write your own), select a delivery date, and choose a gift amount.</p>
                            </div>

                            <div className='block'>
                                {/*
                                <div className='icon_enlarge'><i className='material-icons-outlined landing_page_icon'>double_arrow</i></div>
                                 */}
                                
                                {/*
                                <div className='gift_with_arrows'>
                                    <i className='material-icons-outlined landing_page_icon with_gift'>double_arrow</i>
                                    <img src={gift}></img>
                                    <i className='material-icons-outlined landing_page_icon with_gift'>double_arrow</i>
                                </div>
                                */}
                                <img src={how_it_works_2}></img>
                                <h3>2. Send</h3>
                                <p>Send the gift through text message or email to a friend or loved one. Recipients don't need to have an account in order to receive gifts!</p>
                            </div>

                            <div className='block'>
                                <img src={how_it_works_3}></img>
                                <h3>{'3. Receive & Redeem'}</h3>
                                {/*
                                <p>Recipients redeem gifts at a financial destination for their child, and are encouraged to put the gifts in long term savings options </p>
                                <p>Receive gifts, and transfer funds to long term savings accounts for children.</p>
                                <p>1. receive 2. redeem at financial institutions 3. </p>
                                */}
                                <p>Receive gifts, and transfer funds to financial institutions, such as 529 plans, bank accounts, and custodial investment accounts.</p>
                                
                            </div>

                        </div>

                        <div className='section_button_container'>
                            <Link to='/give-a-gift'>
                                <button className='landing_page_button'>
                                    Give a gift now
                                </button>
                            </Link>
                        </div>
                    </div>

                    
                    
                    
                    <div id='security' className='section_6 landing_main_section gg_security'>
                        <div className='section_header'>
                            <div className='section_6_img_container'>
                                    <img className='section_6_img' src={security}/>
                            </div>
                            <div className='section_6_headers_text'>
                                <h2>{'Give Gifts Securely'}</h2>
                                <h3>Your peace of mind is our top priority. </h3>
                                <h3>Greatest Gift uses bank-level security safeguards to make sure your personal data and gifts are secure.</h3>
                            </div>
                        </div>

                        <div className='blocks_container'>
                            
                            <div className='block'>
                                <div>
                                    <i className='material-icons-outlined landing_page_icon landing_page_icon_blue'>lock</i>
                                </div>
                                
                                <h3>Data Encryption</h3>
                                <p>We encrypt data transfers to our website and app with 256-bit SSL/TSL. We also encrypt any sensitive data that we keep (like user passwords) with industry best practice BCrypt hashing algorithm. We don’t keep any of your bank account or payment information (see our Privacy Policy).</p>
                            </div>      

                            
                            <div className='block'>
                                <div>
                                    <i className='material-icons-outlined landing_page_icon landing_page_icon_blue'>verified_user</i>
                                </div>
                                
                                <h3>Account Management</h3>
                                <p>We use multi-factor authentication, automatic loggouts after inactivity, and account activity notifications to protect your account from fruad.</p>
                            </div>    

                            
                            <div className='block'>
                                <div>
                                    <i className='material-icons-outlined landing_page_icon landing_page_icon_blue'>supervisor_account</i>
                                </div>
                                
                                <h3>Trusted Partners</h3>
                                <p>We use trusted third-party integrations to provide payment capabilities. Our payment and cloud partners are PCI DSS compliant. Data transfers to our partners and data they hold is always encrypted.</p>
                            </div>                        

                        </div>


                    </div>

                    <div id='faq' className='section_7 landing_main_section faq_section'>
                        <div className='section_header'>
                            <h2>Frequently Asked Questions (FAQ)</h2>
                        </div> 
                        <Accordion list={[
                            {
                                header: 'What makes gifts from Greatest Gift fun and meaningful?',
                                content: 
                                <div>
                                    <p>Our gifts are digitally gift wrapped, and always put a smile on people's faces.</p>
                                    <p>Greetings and pictures add a personal touch to a money gift. We make it easy to save for the future, and our gifts encourage recipients to start saving and investing. <br></br>Recipients benefit from our curated collection of financial products, guides and life-hacks for creating a bright future for their children.</p>
                                    <p>Send a gift now and see for yourself!</p>
                                </div> 
                            },
                            {
                                header: 'How do I send a gift?',
                                content: 
                                    <div>
                                        <p className='bold'>Sending a gift is easy and fun!</p>
                                        <ol>
                                            <li>First, write a greeting and add a picture (we like to add a picture of us and the gift recipient, or just the gift recipient).</li>
                                            <li>Choose a gift amount and delivery date.</li>
                                            <li>Add the gift reciever's contact info - either email or a US phone number will work. </li>
                                            <li>Finally, choose your payment method, and confirm the gift.</li>
                                        </ol>
                                        <p>Happy gifting!</p>
                                    </div>
                            },
                            {
                                header: 'Can I send a gift to someone who doesn\'t have a Greatest Gift account?',
                                content: 
                                    <div>
                                        <p className='bold'>Absolutely!</p>
                                        <p>You can send a gift through Greatest Gift to anyone in the USA that has a valid US phone number and email, even if they don't have an active Greatest Gift account. Gifts that go unclaimed for 60 days will be returned to the sender.</p>
                                    </div> 
                            },
                            {
                                header: 'I accidentally sent the gift to the wrong person, can I cancel the gift?',
                                content: 
                                <div>
                                    <p>Unfortunately no. All gifts are final. The moment you send a gift through Greatest Gift, we send a request to your bank/card to debit the funds, and make them available to the gift recipient. Please make sure to only send gifts to people you know.</p>
                                </div> 
                            },
                            {
                                header: 'I just gave a gift. What will the gift receiver see?',
                                content: 
                                <div>
                                    <p>Congrats on sending a gift!</p>
                                    <p>On the gift delivery date, the gift receiver will receive a text message or email with the greeting and picture, the gift sender, and a link to redeem the gift. </p>
                                    <p>If the receiver already has a Greatest Gift account, the gift can be redeemed from the platform. Otherwise, the gift receiver can now sign up for a Greatest Gift account.</p>
                                    <p>On the Greatest Gift platform, the gift receiver can see additional details and initiate a transfer to a financial institution. </p>
                                    <p>To make sure the gift funds are put to work, we encourage the receiver to transfer the funds to a long term savings option, and we suggest great options for savings and investment accounts.</p>    
                                </div> 
                            },
                            {
                                header: 'What do I need in order to redeem a gift?',
                                content: 
                                <div>
                                    <p>In order to redeem a gift, you need to sign up for a Greatest Gift account, and verify your contact information. You will also need a US based financial account to transfer the gift funds into. </p>
                                    <p>We have a list of supported 529 plans and investment accounts, and we support most savings and checkings accounts. </p>
                                    <p>If you don't have a financial account, check out our <a className='secondary_dark bold' href='/discover'>discover</a> section for great ways to save and invest!</p>
                                    <p>In order to comply with laws and regulations imposed by the United States Treasury Department and under legislation like the US Patriot Act, our payments partner Stripe may require additional identification information. We do not hold this information in our databases, and all of your information is completely secured. For more information, see the <a href='#security' className='bold secondary_dark'>security section.</a></p>
                                </div>
                            },
                            {
                                header: 'How do I receive my gift money?',
                                content: 
                                <div>
                                    <p>Receiving your gift money is easy with Greatest Gift.</p>
                                    <ol>
                                        <li>On the Greatest Gift platform, create a recipient account (for yourself, your child, or anyone else you are receiving gifts for).</li>
                                        <li>Link a financial account to the recipient account. We recommend choosing long term savings destinations, like savings accounts or our supported 529 plans and investment accounts, but we also support checking accounts.</li>
                                        <li>On the gift page, choose the recipient account, transfer destination, and redeem the gift. </li>
                                    </ol>
                                    <p>The funds will appear directly in your transfer destination in 2-14 business days (no need to transfer money to a checking account first and then transfer from there to your savings or investment accounts).</p>
                                </div> 
                            },
                            {
                                id: 'pricing',
                                header: 'What does Greatest Gift charge for its service? What are the fees?',
                                content: 
                                <div>
                                    <p className='bold'>We're proud to offer the lowest fees on cash gifts and crowdfunding services for long term savings, college savings or baby shower registries.</p>
                                    <p>Opening a Greatest Gift account is free, and there are <span className='bold'>no monthly fees.</span> <br></br>Also, <span className='bold'>we don't ask the sender or receiver for any tips </span>for our services.</p>
                                    <p>Our lowest fees are {lowest_fee_variable + ' + ' + lowest_fee_fixed}. When sending a $100 gift, that means the gift receiver keeps ${100 - (variable_fees_for_hundred + fixed_fees_for_hundred/100)} of the gift, and we only take ${variable_fees_for_hundred + fixed_fees_for_hundred/100} in fees.</p>
                                    <p className='bold'>Gift Sending Convenience Fees- </p>
                                        <ul>
                                            <li>Paying for gifts directly from your bank: {ach_send_fee} <span className='italics green_text tiny_text'>Recommended</span></li>
                                            <li>Paying for gifts with credit cards: {cc_send_fee}</li>
                                        </ul>
                                    <p className='bold'>Gift Redeeming Fees-</p>
                                        <ul>
                                            <li>Redeeming to long term savings accounts*: {other_redeem_fee} <span className='italics green_text tiny_text'>Recommended</span></li>
                                            <li>Redeeming to regular checking account: {checking_redeem_fee}</li>
                                        </ul>
                                        <p>In comparison, gift registries charge anywhere from 2.4% to 2.9%, college gift platforms either charge 5% to 10%, or "ask" for tips of 5% to even 15%, and crowdfunding platforms typically charge a minimum of 5% (or "ask" for tips of 5% and upwards!). </p>
                                        <p className='tiny_text'>*Long term savings accounts include supported 529 plans, supported investment accounts, and savings accounts.</p>
                                </div> 
                            },
                            {
                                header: 'Is Greatest Gift safe and secure to use?',
                                content: 
                                <div>
                                    <p className='bold'>Your peace of mind is our top priority.</p>
                                    <p>Greatest Gift uses bank-level security safeguards to make sure your personal data and gifts are secure.</p>
                                    <p>To learn more, see our <a href='#security' className='bold secondary_dark'>security</a> section.</p>
                                </div> 
                            },

                            {
                                header: 'How is Greatest Gift better than giving cash, a treasury bond, or sending a gift with a peer to peer payment app?',
                                content: 
                                <div>
                                    <p>Giving gifts with Greatest Gift is a great way to send money as a gift in a personal and meaningful way, and set a young one on the right financial path.</p>
                                    <p>We transform money gifts into thoughtful gestures by adding greetings and pictures, setting delivery dates, and using digital gift wrap.</p>
                                    <p>Gift receivers can transfer gift funds from Greatest Gift directly to a long term savings account, without depositing in a checking account first.</p>
                                    <p>We consistently encourage gift receivers to save and invest for the future. We curate a collection of financial products, guides and life-hacks for saving and investing, and remind gift receivers to save at the right time.</p>
                                    <p>We hope you enjoy sending gifts with Greatest Gift, we sure do!</p>
                                </div> 
                            },
                        ]}/> 

                        <div className='section_button_container'>
                            <Link to='/give-a-gift'>
                                <button className='landing_page_button'>
                                    Give a gift now
                                </button>
                            </Link>
                        </div>

                    </div>

                    <div id='start with a greeting' className='section_5 landing_main_section greeting'>
                        <div className='section_header'>
                            <h2>Let's Start with the Greeting</h2>
                        </div>

                        <div className='two_column_flex_content'>
                            
                            
                            <div className='left_side'>

                                <form className='landing_form'>
                                    <p className='form_label'>1. Who is the gift for?</p>
                                    <input 
                                        type='text' 
                                        id='gift_for_child_name' 
                                        onChange={this.props.handleChange}
                                        name='Gift_for' 
                                        placeholder='Baby Jones' 
                                        value={this.props.Gift_for}
                                        maxLength='50'
                                        autoComplete='on'
                                        />


                                    <p className='form_label'>2. Gift from:</p>
                                    <input 
                                        type='text' 
                                        id='gift_from' 
                                        onChange={this.props.handleChange}
                                        name='from' 
                                        placeholder='Your name' 
                                        value={this.props.from}
                                        maxLength='50'
                                        autoComplete='on'
                                        /> 

                                    

                                    {/*<p className='form_label'>3. How would you like the gift to be used?</p>*/}
                                    {/*
                                        <p className='form_label'>3. What would you like the gift to be used for?</p>
                                        
                                        <input className='dropdown_input' list='gift_uses' name='gift_use' id='gift_use'></input>
                                        <datalist id='gift_uses'>
                                            <option value='College savings'>    College savings</option>
                                            <option value='Long term savings'>  Long term savings</option>
                                            <option value='Car fund'>           Car fund</option>
                                            <option value='Emergency Savings'>  Emergency Savings</option>
                                            <option value='Other'>              Other</option>
                                        </datalist>
                                    */}
                                    
                                    <p className='form_label'>3. What is the occasion? (We’ll offer some greetings!) </p> {/*() */}

                                    <div className='landing_select_container'>
                                        <select id='occassions' 
                                                name='occassion' 
                                                className='dropdown_input dropdown_with_arrow' 
                                                onChange={this.updateOccasion} 
                                                placeholder='Birthday / just because / other...' >

                                            <option value='Just because'>       Just because</option>
                                            <option value='New baby'>           New baby</option>
                                            <option value='Holidays'>           Holidays</option>
                                            <option value='Birthday'>           Birthday</option>
                                            <option value='School Graduation'>  School Graduation</option>
                                            <option value='Other'>              Other</option>
                                        </select>

                                        <div className='arrow_container_select'><i className={'material-icons-outlined rotate_90'} onClick={this.nextGreeting}>arrow_forward_ios</i></div>

                                    </div>
                                    
                                    <Greeting_box   occasion={this.state.occasion}
                                                    label={'4. Choose a greeting (or type your own):'}
                                                    handleChange={this.props.handleChange}
                                                    greeting={this.props.greeting}
                                                    select_greeting={this.props.select_greeting}/>
                                    
                                    <Media_selector 
                                        label={'5. Add a picture or video (max 8MB):'}
                                        handlePicChange={this.props.handlePicChange}
                                        handleRemoveMedia={this.props.handleRemoveMedia}
                                        file={this.props.file}/>
                                    
                                    
                                </form>

                                <div className='section_button_container'>
                                    <Link to='/give-a-gift'>
                                        <button className='landing_page_button'>
                                            Continue
                                        </button>
                                    </Link>
                                </div>
                                    
                            </div>
                            

                            <div className='right_side'>
                                <img src={gifts} />
                            </div>
                            
                        </div>

                    </div>


                </div>
            
                <Home_Page_Footer />

            </div>
        );
    }
}

export default Landing_page;

/*
                    <div className='section_4'>
                        <div className='section_header'>
                            <h2>Join a community of Great Gifters!</h2>
                        </div>

                        <div className='blocks_container'>
                            
                            <div className='block'>
                                <img src={gift}></img>
                                <p>Caption</p>
                            </div>

                            <div className='block'>
                                <img src={gift}></img>
                                <p>Caption</p>
                            </div>

                            <div className='block'>
                                <img src={gift}></img>
                                <p>Caption</p>
                            </div>

                            

                        </div>

                        <div className='section_button_container'>
                            <Link to='/give-a-gift'>
                                <button className='landing_page_button'>
                                    Give a gift now
                                </button>
                            </Link>
                        </div>
                    </div>
                    */

/*


                                    <p className='form_label'>3. What is the occasion? </p> {/*(We’ll offer some greetings)
                                    
                                    <input  className='dropdown_input' 
                                            list='occassions' 
                                            name='occassion' 
                                            id='occassion' 
                                            onChange={this.updateOccasion}
                                            placeholder='Birthday / just because / other...'>
                                    </input>

                                    <datalist id='occassions'>
                                        <option value='Just because'>       Just because</option>
                                        <option value='New baby'>           New baby</option>
                                        <option value='Holidays'>           Holidays</option>
                                        <option value='Birthday'>           Birthday</option>
                                        <option value='School Graduation'>  School Graduation</option>
                                        <option value='Other'>              Other</option>
                                    </datalist>

                                    <p className='form_label'>4. Choose a greeting (or type your own):</p>
                                    <textarea 
                                        id='greeting_input' 
                                        
                                        onChange={this.props.handleChange}
                                        name='greeting' 
                                        placeholder='Example: We’re so excited to celebrate with you, and hope this helps you achieve all of your dreams!'
                                        value={this.props.greeting}
                                        maxLength='150'
                                        autoComplete='off'
                                        />
*/

/*

<div id='features and benefits' className='section_3 landing_main_section benefits_and_features'>
                        <div className='section_header'>
                            <h2>{'Additional Benefits & Features'}</h2>
                        </div>

                        <div className='blocks_container'>
                            
                            <div className='block'>
                                <div>
                                    <i className='material-icons-outlined landing_page_icon'>send</i>
                                </div>
                                
                                <h3>Easily Send To Anyone</h3>
                                <p>All you need is the receiver's phone number or email. No need to know the receiver's account numbers. Anyone can receive gifts, whether they have a Greatest Gift account or not.</p>
                            </div>

                            <div className='block'>
                                <div>
                                    <i className='material-icons-outlined landing_page_icon'>spa</i>
                                </div>
                                
                                <h3>Gift With Purpose</h3>
                                <p>Gift receivers are encouraged to put the gift to work for them, and get to discover fantastic savings and investment options for their family.</p>
                            </div>

                            <div className='block'>
                                <div>
                                    <i className='material-icons-outlined landing_page_icon'>rate_review</i>
                                </div>

                                <h3>Personal Greetings</h3>
                                <p>Turn a money gift into a thoughtful gesture by adding a personal greeting with pictures and videos. Don't settle for a gift emoji.</p>
                            </div>

                            <div className='block'>
                                <div>
                                    <i className='material-icons-outlined landing_page_icon'>money_off</i>
                                </div>
                                
                                <h3>Low Fees</h3>
                                <p>When paying directly from your bank account, or pay with a credit card and incurr a standard Convenience Fee.</p>
                            </div>


                            <div className='block'>
                                <div>
                                    <i className='material-icons-outlined landing_page_icon'>all_inclusive</i>
                                </div>
                                
                                <h3>Receivers' Choice</h3>
                                <p>Gift receivers get to choose how to use the money, and are encouraged to save in 529 plans and long term savings accounts.</p>
                            </div>

                            <div className='block'>
                                <div>
                                    <i className='material-icons-outlined landing_page_icon'>emoji_events</i>
                                </div>
                                
                                <h3>Thank the Gifters</h3>
                                <p>Gift receivers can easily send Thank You Notes, and share their gratitude. (coming soon)</p>
                            </div>

                            

                        </div>

                        <div className='section_button_container'>
                            <Link to='/give-a-gift'>
                                <button className='landing_page_button'>
                                    Give a gift now
                                </button>
                            </Link>
                        </div>
                    </div>
*/