import React from 'react';

class ItemList extends React.Component {
    render() {

        return this.props.items.map(item => (
            <li className='pm_item' key={item.id}>
                <div className='pm_item_image_container'>
                    <img src={item.image} alt={item.main_text}></img>
                    <div className='pm_item_image_placeholder'></div>
                </div>

                <div className='pm_item_right_side'>
                    <div className='pm_item_text'>
                        <p className='small_text'>{item.main_text}<br></br>{item.sub_text}</p>
                    </div>

                    <div className='pm_item_icon'> 
                        {!this.props.button_active ? null : (
                            <p className={'pm_verify_button tiny_text bold'} onClick={() => this.props.onButtonClick(item)}>
                                {this.props.button_text}
                            </p>
                        )}   
                    </div>
                </div>
            </li>
        ));
    }
}

export default ItemList;
