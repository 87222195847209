import React from 'react';
import './Goal_details.css';

import money from '../../../utils/money.js';

/*              
    saved_to_date={this.props.saved_to_date}
    target_savings={this.props.target_savings}
    target_date={this.props.target_date}
    potential_profit={this.props.potential_profit}
    linked_acount={this.props.linked_acount}
    plan_id={this.props.plan_id} */

class Goal_details extends React.Component {
    render () {
        return (
            <div className='goal_details_container'>
                
                <div className='goal_details_line first_details_line'>
                    <div className='goal_details_heading'><p className='small_text'>Redeemed Gifts:</p></div>
                    <div className='goal_details_data'><p className='small_text'>{this.props.redeemed_gifts}</p></div>
                </div>

                <div className='goal_details_line '>
                    <div className='goal_details_heading'><p className='small_text'>Saved to date:</p></div>
                    <div className='goal_details_data'><p className='small_text'>${money.addCommas(this.props.saved_to_date)}</p></div>
                </div>

                {/*
                <div className='goal_details_line'>
                    <div className='goal_details_heading'><p className='small_text'>Target savings:</p></div>
                    <div className='goal_details_data'><p className='small_text'>${money.addCommas(this.props.target_savings)}</p></div>
                </div>

                <div className='goal_details_line'>
                    <div className='goal_details_heading'><p className='small_text'>Target date:</p></div>
                    <div className='goal_details_data'><p className='small_text'>{this.props.target_date}</p></div>
                </div>
                */}

                {/*
                <div className='goal_details_line last_details_line'>
                    <div className='goal_details_heading'><p className='small_text'>Invested tax-free 
                        potential growth:</p></div>
                    <div className='goal_details_data'><p className='small_text'>${money.addCommas(this.props.potential_profit)} by target date</p></div>
                </div>
                */}
            </div>
        );
    }
}

export default Goal_details;