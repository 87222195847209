import React from 'react';

import GG_loader from '../../../general_components/GG_loader/GG_loader.js';

import { Redirect } from 'react-router-dom';


class Callback_oauth extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            last_seen_gift: null,
            redirect_to: ''
        }
    }

    componentDidMount(){
        // get the plaid link session from window.location.href
        let plaid_session = window.location.href;
        // get the plaid link token from local storage
        let plaid_link_token = JSON.parse(localStorage.getItem('plaid_link_token'));
        // get the user id from local storage
        let user = JSON.parse(localStorage.getItem('user'));
        
        // set plaid info in App level component
        this.props.update_oauth(plaid_link_token, plaid_session);

        // flow specific:
        let flow_type = JSON.parse(localStorage.getItem('oauth_flow'));
        if(flow_type === 'destination'){
            // get the child account from local storage
            let child_account_name = JSON.parse(localStorage.getItem('child_account_name'));
            let last_seen_gift = JSON.parse(localStorage.getItem('last_seen_gift'));
            
            if(!user || ! child_account_name || !plaid_link_token || !plaid_session){
                window.location.href = '/home';
            } else {
                this.props.updateActiveRecipient(child_account_name);
                this.setState({
                    loading: false, 
                    redirect_to: last_seen_gift ? '/link_destination?source='+last_seen_gift : '/link_destination'
                });
                // set redirect to:
            }

        } else if (flow_type === 'pm'){

            let gift = JSON.parse(localStorage.getItem('gift'));
            let gifting_url = JSON.parse(localStorage.getItem('gifting_url'));

            const url = new URL(gifting_url);
            const urlParams = new URLSearchParams(url.search);
            //console.log(urlParams.toString());

            if(!user || !gift || !gifting_url){
                window.location.href = '/home';
            } else {
                // redirect to?
                this.setState({
                    loading: false, 
                    redirect_to: '/give-a-gift?' + urlParams.toString()});
            }

        }

    }

    render () {
        if(this.state.loading) {
            return (
                <main>
                    <GG_loader />
                </main>
            )
        }

        return (
            <main>

                <Redirect push to={this.state.redirect_to} />
                
            </main> 
        );
    }
}

export default Callback_oauth;