import React from 'react';
const kidssavings = [
    {
        id: 201,
        affiliate_company: 'Ally Bank',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_kidssavings_ally_bank.png',
        offer_summary: 'Set it and forget it savings at its best. Ally bank\'s smart saving features and boosters help you automate savings, driving your savings up with little effort. ',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>Easy to open accounts online</li>
                <li>Offers custodial savings accounts, truely owned by your child</li>
                <li>High yield savings account</li>
                <li>Smart features to optimize savings</li>
                <li>No minimum to open or hold account</li>
                <li>No monthly fees</li>
            </ul>
        </div>,
        offer_link: 'https://www.ally.com/bank/online-savings-account/'
    },

    
    {
        id: 202,
        affiliate_company: 'PNC',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/pnc.png', 
        offer_summary: 'PNC\'s \'S\' is for Savings offers a kid-friendly platform with educational resources to teach children about money management through saving, spending, and sharing.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>Integrated educational content from Sesame Street.</li>
                <li>Best for parents focused on financial education for their kids.</li>
                <li>Interactive jars concept for saving, spending, and sharing.</li>
                <li>0.01% interest rate is low, one of the lowest offered.</li>
                <li>Established bank history of over 170 years with 2,600+ branches.</li>
                <li>No fees for account holders under 18.</li>
            </ul>
        </div>,
        offer_link: 'https://www.pnc.com/en/personal-banking/banking/savings/s-is-for-savings.html'  
    },

    {
        id: 203,
        affiliate_company: 'Acorns',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_kidssavings_acorns.png',
        offer_summary: 'If you\'re not sure how to afford starting to save, use Acorns\' roundups and roundup multiplier to automatically save & invest your "spare change" from credit card purchases. Great for building that initial few hundred dollars in savings quickly, without effort, and without noticing.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>$3 per month for an investment account the rounds up your spare change automatically</li>
                <li>Save & invest roundups automatically, with option to apply roundup multiplier for faster savings</li>
                <li>Your balance is automatically invested in ETFs for extra earnings</li>
                <li>Plans can be expensive for investment purposes, but provide a great way to jump start some savings</li>
                <li>No minimum required to open an account</li>
                <li>Since this is an investment account, earnings will be higher than most high-yield savings accounts</li>
            </ul>
        </div>,
        offer_link: 'https://track.flexlinkspro.com/g.ashx?foid=156074.5136.481458&trid=1241967.201227&foc=16&fot=9999&fos=5'
    },
    {
        id: 204,
        affiliate_company: 'Greenlight',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_kidssavings_greenlight.png',
        offer_summary: 'Built for parents, Greenlight offers tools for kids to save, spend, and earn money. Greenlight is all about financial education for kids.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>$4.99 per monthly for a family with up to 5 kids</li>
                <li>No minimum balances</li>
                <li>Parents can pay interest on kids' savings</li>
                <li>Pay kids a weekly allowance, and reward chores with perks</li>
                <li>Parental controls in place for ATMs and spending in stores</li>
                <li>Raise financially-smart kids</li>
            </ul>
        </div>,
        offer_link: 'https://track.flexlinkspro.com/g.ashx?foid=156074.11976.886464&trid=1241967.216129&foc=16&fot=9999&fos=5'
    },
    {
        id: 205,
        affiliate_company: 'Aspiration',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_kidssavings_aspiration.jpeg',
        offer_summary: 'Save for your children while saving the planet for them. Aspiration plants trees when you purchase, offsets carbon dioxide from gas you purchase, and doesn\'t lend money to the fossil fuels industry.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>Pay what you feel is fair for basic Aspiration, or $7.99 per month for Aspiration Plus  </li>
                <li>Up to 10% cash back on debit card purchases at socially conscious businesses</li>
                <li>0.25% APY on savings, or 1% when you spend $1000 monthly</li>
                <li>Impact features to build a better world</li>
                <li>No minimum to open or hold account</li>
            </ul>
        </div>,
        offer_link: 'https://www.aspiration.com/get-account'
    }
]

export default kidssavings;

/*
{
        id: 202,
        affiliate_company: 'Axos Bank',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/offers/logo_kidssavings_axos_bank.png',
        offer_summary: 'One of the highest interest rates on savings accounts, at an online bank. Great if you\'re just looking for that high return.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <p>Take your money further, faster. No monthly maintenance fees. No minimum balance requirements. Interest compounded daily. This is high-yield savings, evolved.</p>
            <ul>
                <li>Easy to open accounts online</li>
                <li>Offers custodial savings accounts, truely owned by your child</li>
                <li>High yield savings account</li>
                <li>Interest compounded daily</li>
                <li>Requires initial $250 deposit to open an account</li>
                <li>No minimum to balance to hold account</li>
                <li>No monthly fees</li>
            </ul>
        </div>,
        offer_link: 'https://track.flexlinkspro.com/g.ashx?foid=1.44016.1000000049&trid=1241967.206645&foc=16&fot=9999&fos=5'
    },
 */