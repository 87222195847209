import React from 'react';
import './My_pms.css';

import GGApi from '../../../utils/GGApi.js';

import GG_loader from '../../general_components/GG_loader/GG_loader.js';
import Overlay_popup from '../../general_components/Overlay_popup/Overlay_popup';
import { Helmet } from 'react-helmet';
import List_w_Category from '../../general_components/List_w_Category/List_w_Category.js';

/* Need to pull gift data from the database here, for inputs into the fields */

class My_pms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            pm_list: [],

            open_overlay: false,
            pm_to_verify: {},
            deposit_1: '',
            deposit_2: ''
        }

        this.toggle_loading = this.toggle_loading.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handleOverlayButton = this.handleOverlayButton.bind(this);


    }

    componentDidMount() {        
        // load pm list
        GGApi.pm_get().then(list => {
            this.setState({
                loading: false,
                pm_list: list
            })
        });
    }
    
    // toggles

    toggle_loading(){
        this.setState({loading: !this.state.loading})
    }

    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }
    // button handlers

    handleOverlayButton(){
        if(!this.state.pm_to_verify){
            return
        };
        if(!this.state.deposit_1 || !this.state.deposit_2){
            alert('Please enter both deposit amounts from your bank\'s statement.')
        }
        this.setState({loading: true});

        GGApi.verify_manual_bank(this.state.deposit_1, this.state.deposit_2, this.state.pm_to_verify.id)
        .then(response => {
            console.log(response);
            if(response.success){
                GGApi.pm_get().then(list => {
                    this.setState({
                        loading: false,
                        pm_list: list,
                        open_overlay: false,
                        pm_to_verify: {},
                        deposit_1: '',
                        deposit_2: ''
                    })
                    alert('Successfully verified your bank account.')
                });
            } else {
                this.setState({
                    loading: false
                })
                alert('An error has occurred. Please try again.')
            }
        });
    }

    // render methods



    render () {
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        return (
        <main>
                <Helmet>
                    <title>{`Payment Methods | Greatest Gift`}</title>
                    <meta name="description"
                        content='See and manage your payment methods.' 
                    />
                </Helmet>
            <h2>My Payment Methods</h2>
            <ul className='pm_category_list'>
                <List_w_Category
                    list={this.state.pm_list}
                    onVerifyClick={(item) => this.setState({pm_to_verify: item, open_overlay: true})}
                    button_text={'Verify'}
                />
            </ul>

            
            <Overlay_popup 
                open={this.state.open_overlay}
                closer={true}
                handleClose={() => this.setState({open_overlay: false, deposit_1: '', deposit_2: '', pm_to_verify: {}})}
                overlay_title='Verify Account'
                body_2='Please note, the deposits may take 1-3 days to appear in your account’s balance'
                body_1='Please enter the two deposits found in your account’s balance in whole cents with the statement descriptors of AMTS.'
                form={<form>

                    <p className='form_label'>Deposit #1:</p>
                    <input 
                                type='text' 
                                id='deposit_1' 
                                onChange={this.handleChange}
                                name='deposit_1' 
                                placeholder='32' 
                                value={this.state.deposit_1}
                                autoFocus
                                maxLength='3'
                                autoComplete='off'
                                />
                    <p className='form_label'>Deposit #2:</p>
                    <input 
                                type='text' 
                                id='deposit_2' 
                                onChange={this.handleChange}
                                name='deposit_2' 
                                placeholder='45' 
                                value={this.state.deposit_2}
                                maxLength='3'
                                autoComplete='off'
                                />
                </form>}
                button_1={{text: 'Verify', handler: this.handleOverlayButton}}
            />

            <div className='buffer'></div>
    
        </main> 
        );
    }
}

export default My_pms;