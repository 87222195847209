import React from 'react';
import './Notifications.css';

import GGApi from '../../../utils/GGApi.js';

import GG_loader from '../../general_components/GG_loader/GG_loader.js';
import Overlay_popup from '../../general_components/Overlay_popup/Overlay_popup.js';
import { Helmet } from 'react-helmet';
import List_w_Category from '../../general_components/List_w_Category/List_w_Category.js';
import MUI_Switch from '../../general_components/material_ui/mui_Switch.js';

/* Need to pull gift data from the database here, for inputs into the fields */

class Notifications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            notifications: {
                redeemGiftReminders: true,
                birthdayOccasionReminders: true,
                productGuides: true,
                ageBasedTips: true,
                personalizedContent: true,
                partnerOffers: true,
                announcements: true,
            },
        };

        this.toggle_loading = this.toggle_loading.bind(this);
        this.handleToggle = this.handleToggle.bind(this);

    }

    componentDidMount() {
        // Load notification settings from the API
        
        GGApi.getNotificationSettings().then(notifications => {
            console.log(notifications)
            this.setState({
                loading: false,
                notifications: notifications,
            });
        });
    }
    
    // toggles

    toggle_loading(){
        this.setState({loading: !this.state.loading})
    }

    handleToggle(setting) {
        this.setState(prevState => ({
            notifications: {
                ...prevState.notifications,
                [setting]: !prevState.notifications[setting],
            },
        }));
        // Update the notification setting in the database
        //console.log('update in db:', setting, !this.state.notifications[setting])
        GGApi.updateNotificationSetting(setting, !this.state.notifications[setting]);
    }

    // render methods



    render () {
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        const { notifications } = this.state;


        return (
            <main>
                <Helmet>
                    <title>{`Notifications | Greatest Gift`}</title>
                    <meta name="description" content="Manage your Greatest Gift notifications." />
                </Helmet>

            <h2 className=''>Notifications</h2>
            <p className='no_margin'>Get emails to find out what’s going on when you’re not on the platform.</p>
            
            <div className="notifications-section">
                
                <div className="notification-item">
                    <div className="notification-item-headline-and-button">
                        <p className='bold'>Redeem gift reminders</p>
                        <MUI_Switch checked={notifications.redeemGiftReminders}  changeHandler={() => this.handleToggle('redeemGiftReminders')} />
                    </div>
                    <p className='no_margin'>We want your gift funds to go to work for you. Get regular reminders about unredeemed gifts.</p>
                </div>

                
                <div className="notification-item">
                    <div className="notification-item-headline-and-button">
                        <p className='bold'>Birthday and occasion reminders</p>
                        <MUI_Switch checked={notifications.birthdayOccasionReminders} changeHandler={() => this.handleToggle('birthdayOccasionReminders')} />
                    </div>
                    <p className='no_margin'>Get reminded to update your gifting pages ahead of upcoming birthdays. Get yearly reminders to send another gift to your gift recipients.</p>
                </div>
            </div>

            <h2>Communication Preferences</h2>
            <p className='no_margin'>Tips and guides for your financial parenting journey and for using Greatest Gift.</p>

            <div className="notifications-section">

                <div className="notification-item">
                    <div className="notification-item-headline-and-button">
                        <p className='bold'>Greatest Gift product guides</p>
                        <MUI_Switch checked={notifications.productGuides} changeHandler={() => this.handleToggle('productGuides')} />
                    </div>
                    <p className='no_margin'>Expert tips on setting up your Greatest Gift account, explanations, reminders, and other information related to your Greatest Gift account.</p>
                </div>

                <div className="notification-item">
                    <div className="notification-item-headline-and-button">
                        <p className='bold'>Age-based financial parenting tips</p>
                        <MUI_Switch checked={notifications.ageBasedTips} changeHandler={() => this.handleToggle('ageBasedTips')} />
                    </div>
                    <p className='no_margin'>Get financial parenting tips that are just-right for your children’s ages. Know what to do every step of the way to build a great financial future for them.</p>
                </div>

                <div className="notification-item">
                    <div className="notification-item-headline-and-button">
                        <p className='bold'>Personalized content</p>
                        <MUI_Switch checked={notifications.personalizedContent} changeHandler={() => this.handleToggle('personalizedContent')} />
                    </div>
                    <p className='no_margin'>Get financial parenting guides that matter most to you directly to your inbox.</p>
                </div>

                <div className="notification-item">
                    <div className="notification-item-headline-and-button">
                        <p className='bold'>Partner offers</p>
                        <MUI_Switch checked={notifications.partnerOffers} changeHandler={() => this.handleToggle('partnerOffers')} />
                    </div>
                    <p className='no_margin'>Exclusive deals from brands we love and think you’ll be interested in, sent from Greatest Gift on behalf of our partners.</p>
                </div>

                <div className="notification-item">
                    <div className="notification-item-headline-and-button">
                        <p className='bold'>Announcements and news</p>
                        <MUI_Switch checked={notifications.announcements} changeHandler={() => this.handleToggle('announcements')} />
                    </div>
                    <p className='no_margin'>Our roundup of news, noteworthy stories, and company updates.</p>
                </div>

            </div>
            
            <div className="buffer"></div>
        </main>
        );
    }
}

export default Notifications;