import React from 'react';
import './Add_step_3.css';
import '../../../plan_components/Gifting_page/Gifting_page_setup.css';

import MasterButton from '../../../general_components/MasterButton/MasterButton.js';
import Selector_field from '../../../general_components/Selector_field/Selector_field';
import Pic_selector from '../../../general_components/Pic_selector/Pic_selector';

import GGApi from '../../../../utils/GGApi';

import config from '../../../../utils/config';
import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);


class Add_step_3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        // Mechanics:
            button_active: true,
        
        // Inputs:
            page_active: true,
            gifting_page_welcome_message: '',
            media_file: '',

            original_media_file: ''
        }

        this.handleButtonClick  = this.handleButtonClick.bind(this);

        this.handleChange       = this.handleChange.bind(this);
    }

    // Button handler:

    handleButtonClick(){
        if(!this.state.button_active){
            return;
        }

        // start gg loader
        this.setState({loading: true, button_active: false})
        
        // if new media was selected - upload media
        // otherwise - don't change it
        if(this.state.media_file && this.state.media_file !== this.state.original_media_file){
            GGApi.upload_media(this.state.media_file, 'gifting-page-media').then(response => {
                // handle media upload error
                let media;
                if(response.errors){
                    alert(response.errors[0].detail + ' \nThere was an issue when attempting to upload the image. Please try again.')
                } else if(response.mediaUrl){
                    media = response.mediaUrl;
                };

                // update gifting page - 
                    // active y/n
                    // welcome message
                    // media
                    // goals
                
                // temporary - set page active as true. doesn't allow the option to toggle the page active
                //let page_active = this.state.page_active
                let page_active = true; 

                GGApi.update_gifting_page(this.props.match.params.plan_id, page_active,  this.state.gifting_page_welcome_message, media, this.state.goals)
                .then(response => {
                    if(!response.success){
                        this.setState({loading: false, button_active: true})
                        alert('There was an issue when trying to update the gifting page. Please try again, or contact our support team for further assistance.')
                    } else if (response.success){
                        // get the slug from the back-end
                        // end gg loader
                        // open a success pop up? 
                        this.setState({loading: false, open_overlay: true, button_active: false})
                        // amplitude
                        amplitude.getInstance().logEvent('gifting page: confirm gifting page', {'active_page': this.state.page_active})
                    }
                })
            });

        } else { 
            // temporary - set page active as true. doesn't allow the option to toggle the page active
            //let page_active = this.state.page_active
            let page_active = true; 
            GGApi.update_gifting_page(this.props.match.params.plan_id, page_active,  this.state.gifting_page_welcome_message, this.state.media_file, this.state.goals)
            .then(response => {
                if(!response.success){
                    this.setState({loading: false, button_active: true})
                    alert('There was an issue when trying update the gifting page. Please try again, or contact our support team for further assistance.')
                } else if (response.success){
                    // get the slug from the back-end
                    // end gg loader
                    // open a success pop up? 
                    this.setState({loading: false, open_overlay: true, button_active: false})
                    // amplitude
                    amplitude.getInstance().logEvent('gifting page: confirm gifting page', {'active_page': this.state.page_active})
                }
            })
        }
        
    }

    
    // Event handlers:

    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }

    

    // Render methods:
    
    render () {
        if (this.props.currentStep !== 3) {
            return null;
        }
        
        
        return (
           <div>
                <h2 className='center'>Let's personalize {this.props.account_name}'s gifting page</h2>    
            
                {/* picture */}
                {
                    <Pic_selector 
                    label={'1. Add a Cover Picture: (optional)'}
                    handlePicChange={this.props.handlePicChange}
                    file={this.props.media_file}
                    uploaded_file={this.props.original_media_file === this.props.media_file}
                    custom_height={"200-420"}/>
                }

                
                {/* welcome message */}
                <form style={{ 'marginTop': `16px`}}>

                <Selector_field 
                    options             = {
                        [
                        
                        // saving & investing for future
                        `${this.props.user_first} is saving for ${this.props.account_name}’s future! Send a gift with Greatest Gift, and invest in ${this.props.account_name}’s future today.\nHappy Gifting,\nYour friends at Greatest Gift.`, 
                        
                        // diaper fund
                        `We’re so excited to welcome our little one soon!\n\nInstead of traditional gifts, we’re asking for contributions to our diaper fund to help us keep our baby clean and comfortable.\n\nYour support will help us cover diaper expenses and also start a savings fund for baby’s future needs.\n\nThank you for your love and support!`,
                        `Guess what? We’re having a baby!\n\nInstead of showering us with clothes and toys, help us stock up on diapers by contributing to our diaper fund.\n\nLet’s keep those diaper changes flowing smoothly!`,

                        // future adventures
                        `We're so excited to celebrate with you!\n\nIf you're thinking of a gift, we’ve set up a registry on Greatest Gift for ${this.props.account_name}’s future adventures`,
                        
                        // thinking ahead
                        `As we celebrate ${this.props.account_name}’s big day, we're also thinking ahead!\n\nWe've set up a this registry to help build ${this.props.account_name}’s future`,

                        // long term savings
                        `Thank you for visiting ${this.props.account_name}'s gifting page.\n\nThrough this page, you can give cash gifts for ${this.props.account_name}'s long term savings.`,
                        
                        // diaper and future
                        `Our hearts are full as we prepare for our new baby!\n\nWe’re setting up a diaper fund and would appreciate your support.\n\nContributions will go towards both diaper expenses and a savings account for our little one’s future. Thank you for being part of our journey!`,
                        
                        // goal
                        `I am saving for [goal], and a cash gift from you would be incredibly helpful. Any amount would mean the world!`,
                        
                        // graduation
                        `Join in on my 'Graduation to Greatness' fund - your cash gift will help launch my exciting next chapter!`,
                        `As I prepare for college, any cash gifts will go directly towards textbooks and other essential supplies.\n\nYour support would make a big difference!`

                        ]
                                    }

                    label               = "2. Gifting Page Welcome Message:"
                    field_name          = "gifting_page_welcome_message"
                    field_placeholder   = {`Type your welcome message here, or customize one of our pre-written messages!`}
                    field_value         = {this.props.gifting_page_welcome_message}
                    max_length          = {350}
                    randomize           = {false}
                
                    handleChange        = {this.props.handleChange}
                
                    select_item         = {this.props.select_item}

                />

                </form>
           </div>
        );
    }
}

export default Add_step_3;
