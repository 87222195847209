import React from 'react';
import './Gifting_page.css';

import {Helmet} from "react-helmet";
import GGApi from '../../../utils/GGApi.js';

// images
import logo from '../../../images/logos/logo.png';
import stripe_logo from '../../../images/stripe_imgs/stripe_black.png';
import plaid_logo from '../../../images/stripe_imgs/plaid.svg';

import amazon_logo  from '../../../images/logos/other_registries/amazon_registry_logo.png'
import other_registry_logo  from '../../../images/logos/other_registries/other_registry.png'

import gg_main_img from '../../../images/illustrations/personalized_cash_gift_app.png'

import College from '../../../images/goals/college_fund.png'
import Future from '../../../images/goals/future_savings.png'
import Puppy from '../../../images/goals/puppy_fund.png'
import House from '../../../images/goals/home.png'
import First from '../../../images/goals/car_fund.png'
import Emergency from '../../../images/goals/emergency_fund.png'
import Financial from '../../../images/goals/dollar_signs.png'
import Disney from '../../../images/goals/disney_trip.png'
import Crypto from '../../../images/goals/goal_crypto_fund.png'
import Diaper from '../../../images/goals/diaper_fund.png'
import Adulting from '../../../images/goals/adulting_fund.png'
import Wedding from '../../../images/goals/goal_wedding_fund.png'
import Vacation from '../../../images/goals/vacation_fund.png'
import Adventure from '../../../images/goals/adventure_fund.png'


// comps
import GG_loader from '../../general_components/GG_loader/GG_loader.js';
import MasterButton from '../../general_components/MasterButton/MasterButton.js';
import GG_confetti from '../../general_components/GG_confetti/GG_confetti.js';

import config from '../../../utils/config.js';

// other
import amplitude from 'amplitude-js';
import Goals_list from '../../plan_components/Gifting_page/Goals/Goals_list.js';
import Page_footer from '../../layout_components/Page_footer/Page_footer.js';
import Timeline from './Gifting_page_comps/Timeline.js';
import timeline_milestones from '../../../utils/timeline_milestones.js';
amplitude.getInstance().init(config.amp_key);


class Gifting_page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            gifting_page: {},
            gifting_link: '/give-a-gift',
            sent_gifts: 2,
            timeline_milestones: [],
            gifts_activity: [],
            other_registries: [],
            goals: [
                { goal_name:'College Fund',
                  goal_img: College},
                { goal_name:'Future Savings',
                  goal_img: Future},
                { goal_name:'Diaper Fund',
                  goal_img: Diaper},
                { goal_name:'Puppy Fund',
                  goal_img: Puppy},
                { goal_name:'House Fund',
                  goal_img: House},
                { goal_name:'First Car Fund',
                  goal_img: First},
                { goal_name:'Adulting Fund',
                  goal_img: Adulting},
                { goal_name:'Emergency Fund',
                  goal_img: Emergency},
                { goal_name:'Financial Independence',
                  goal_img: Financial},
                { goal_name:'Disney Trip Fund',
                  goal_img: Disney},
                { goal_name:'Crypto Fund',
                  goal_img: Crypto},
                { goal_name:'Wedding Fund',
                  goal_img: Wedding},
                { goal_name:'Vacation Fund',
                  goal_img: Vacation},
                { goal_name:'Adventure Fund',
                    goal_img: Adventure},
                ],
            selected_goals: []
        }
        
        this.toggle_goal        = this.toggle_goal.bind(this);
    }
      
    componentDidMount() {
        
        // get the registry details
        
        GGApi.get_gifting_page(this.props.match.params.slug).then(result => {
            if(result.access === 'No-access'){
                window.location.href = 'https://www.GreatestGiftApp.com';
            } else {

                let goals = result.gifting_page.goals.split('; ');
                let options = [
                    { goal_name:'College Fund',
                      goal_img: College},
                    { goal_name:'Future Savings',
                      goal_img: Future},
                    { goal_name:'Diaper Fund',
                      goal_img: Diaper},
                    { goal_name:'Puppy Fund',
                      goal_img: Puppy},
                    { goal_name:'House Fund',
                      goal_img: House},
                    { goal_name:'First Car Fund',
                      goal_img: First},
                    { goal_name:'Adulting Fund',
                      goal_img: Adulting},
                    { goal_name:'Emergency Fund',
                      goal_img: Emergency},
                    { goal_name:'Financial Independence',
                      goal_img: Financial},
                    { goal_name:'Disney Trip Fund',
                      goal_img: Disney},
                    { goal_name:'Crypto Fund',
                      goal_img: Crypto},
                    { goal_name:'Wedding Fund',
                      goal_img: Wedding},
                    { goal_name:'Vacation Fund',
                      goal_img: Vacation},
                    { goal_name:'Adventure Fund',
                        goal_img: Adventure},
                    ];

                let filteredOptions = options.filter(option => goals.includes(option.goal_name.toLowerCase()));             

                let milestones = timeline_milestones(result.gifting_page.birthday)

                let split_gifts_activity = result.gifts_activity ? result.gifts_activity.split(',') : [];
                let new_gifts_activity = [];
                let other_registries = result.other_registries;
                
                if(split_gifts_activity.length > 0){
                    split_gifts_activity.forEach((date) => {
                        new_gifts_activity.push({
                            milestone: 'Gift',
                            date: date,
                            type: 'gift'
                        });
                    })
                }

                this.setState({
                    loading: false,
                    gifting_page: result.gifting_page,
                    sent_gifts: result.sent_gifts,
                    goals: filteredOptions,
                    gifting_link: result.gifting_link,
                    timeline_milestones: milestones,
                    gifts_activity: new_gifts_activity,
                    other_registries: other_registries
                });
            }
        }); 
    }


    toggle_goal(goal){
        let goals = this.state.selected_goals;
        let new_goals;
        let lower_case_goal = goal.toLocaleLowerCase();
        if(goals.includes(goal.toLocaleLowerCase())){
            let index = goals.findIndex( item => 
                item === lower_case_goal
            );
            new_goals = goals.slice(0, index).concat(goals.slice(index + 1));
            this.setState({selected_goals: new_goals})
        } else {
            goals.push(goal.toLocaleLowerCase());
            this.setState({selected_goals: goals})
        }
    }

    render () {
        
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        return (
            <div className='open_body'>
                
                <Helmet>
                    {/* UPDATE THE HELMET FOR SEO */}
                    <title>{`${this.state.gifting_page.account_name}'s Financial Gifting Registry - Greatest Gift`}</title>
                    <meta property="og:title" content={`${this.state.gifting_page.account_name}'s Financial Gifting Registry - Greatest Gift`}/>

                    <link rel="icon" href="/static/media/logo_var_2.3a7e8fdf.png" />
                    
                    <meta name="description"
                        content={`Welcome to ${this.state.gifting_page.account_name}'s financial gifting registry! ${this.state.gifting_page.welcome_message}`}
                    />
                    <meta property="og:description" 
                        content={`Welcome to ${this.state.gifting_page.account_name}'s financial gifting registry! ${this.state.gifting_page.welcome_message}`} 
                    />
                    
                    <meta property="og:image" content={this.state.gifting_page.media_url ? this.state.gifting_page.media_url : gg_main_img}/>

                    
                </Helmet>

                
                <GG_confetti />

                {/* header */}
                <header className='gifting_page_header'>

                    <div className='gifting_page_header_container'>
                        
                        <div>
                            <a href='https://www.greatestgiftapp.com'>
                            <img src={logo} className='open_header_logo' alt='gg_logo'/>
                            </a>
                        </div>

                        
                        
                        <MasterButton 
                            buttonText={'Give A Gift'} 
                            notSticky={true}
                            links={true}
                            linkTo={this.state.gifting_link}
                        />
                    </div>
                </header>

                <div className='header_buffer'></div>

                <div className='gifting_page_main'>

                    {/* registry section */}

                    <h1 className='gifting_page_h1'>Welcome to <span className='green_text'>{this.state.gifting_page.account_name}'s</span> Financial Gifting Registry!</h1>

                    <div className='gifting_page_columns_wrapper  square_bottom_bg'>

                        <div className='left_column margin_bottom_40'>
                            
                            {/* pic, msg */}
                            <div> 

                                {this.state.gifting_page.media_url ? 
                                <img 
                                    className='gifting_page_banner' 
                                    src={this.state.gifting_page.media_url}
                                    alt={'Main registry photo ' + this.state.gifting_page.account_name}
                                />
                                : null}
                                

                                <p className='welcome_message'>{this.state.gifting_page.welcome_message}</p>

                                {!this.state.gifting_page.media_url ? 
                                
                                    <div className='align_center'>
                                        <img 
                                        className='gifting_page_banner_placeholder align_center' 
                                        src={gg_main_img}
                                        alt='alternate main registry photo'
                                        />
                                    </div>

                                : null}

                            </div>

                            {/* button */}
                            <div className='gifting_page_main_button_wrapper'>
                                <div className='gifting_page_main_button_wrapper_child'>
                                    <MasterButton
                                        buttonText={`Give ${this.state.gifting_page.account_name} a gift`} 
                                        notSticky={true}
                                        links={true}
                                        linkTo={this.state.gifting_link}
                                        icon=''
                                        />
                                </div>
                            
                                

                            </div>
                            <p className='green_text bold gifting_page_main_button_wrapper_child no_margin align_center'>
                            {this.state.sent_gifts === 0 ?
                                `Be the first to send ${this.state.gifting_page.account_name} a gift!`
                                : 
                                `${this.state.sent_gifts} Gifts already sent by ${this.state.gifting_page.account_name}'s family members and friends!`    
                            }
                            </p>
                                


                            {/* timeline on desktop */}
                            {this.state.gifting_page.show_activity ? 
                            <Timeline 
                            mobile={false}
                            account_name={this.state.gifting_page.account_name ? this.state.gifting_page.account_name : ''}
                            milestones={this.state.timeline_milestones}
                            gifts_activity={this.state.gifts_activity}
                            />
                            : null
                            }

                            {/* calculator on desktop? */}
                        </div>
                        
                        
                        <div className='right_column'>

                                <div className='gifting_page_card yellow_card'>
                                    
                                    <h2>{this.state.gifting_page.account_name}'s Financial Goals</h2>
                                    
                                    <p className='desktop_only'>Invest in {this.state.gifting_page.account_name}'s goals by sending a gift today!         
                                        </p>
                                        <p>
                                        Choose the goals you'd like to give towards:
                                    </p>

                                    <Goals_list
                                        goals={this.state.selected_goals}
                                        goals_list={this.state.goals}
                                        toggle_goal={(goal) => this.toggle_goal(goal)}
                                        goals_per_line={3}
                                    />

                                    <MasterButton
                                        buttonText={`Give ${this.state.gifting_page.account_name} a gift`} 
                                        notSticky={true}
                                        links={true}
                                        linkTo={this.state.gifting_link}
                                        icon=''
                                    />

                                </div>

                                {/*

                                <div className='gifting_page_card green_card'>
                                    matching card
                                </div>

                                <div className='gifting_page_card red_card'>
                                    causes card
                                </div>

                                <div className='gifting_page_card blue_card'>
                                    registries card
                                </div>
                                */}


    
                                {/* other registries */}

                                {this.state.gifting_page.display_other_registries && this.state.other_registries.length > 0 ? 
                                
                                <div className='gifting_page_card green_card'>
                                    
                                    <h2>{this.state.gifting_page.account_name}'s Other Registries</h2>
                                    
                                    <p>See {this.state.gifting_page.account_name}'s other gift registries
                                    </p>

                                    <div className={`goals_list_container goals_per_line_3`}>

                                        {this.state.other_registries.map( (registry, i) => {
                                            return(
                                            <a 
                                                className={ 'input_button_w_img'} 
                                                href={registry.registry_provider === 'Amazon' ?
                                                    registry.registry_link.split('?')[0] + '?&linkCode=ll2&tag=greatestgif09-20&linkId=df51e0244b3078b7746f5bf6c81dccf1&language=en_US&ref_=as_li_ss_tl'
                                                    : registry.registry_link} target='_blank'
                                            >
                                                <img src={registry.registry_provider === 'Amazon' ? amazon_logo : other_registry_logo} alt={'registry.registry_provider registry'}/>
                                                <p className='small_text '>{registry.registry_provider}</p>
                                            </a>
                                            )
                                        })}

                                    </div>


                                    <MasterButton
                                        buttonText={`Give ${this.state.gifting_page.account_name} a gift`} 
                                        notSticky={true}
                                        links={true}
                                        linkTo={this.state.gifting_link}
                                        icon=''
                                    />

                                </div>

                                : null }


                                {/* timeline on mobile */}

                                {this.state.gifting_page.show_activity ? 
                                <Timeline 
                                mobile={true}
                                account_name={this.state.gifting_page.account_name ? this.state.gifting_page.account_name : ''}
                                milestones={this.state.timeline_milestones}
                                gifts_activity={this.state.gifts_activity}
                                />
                                : null
                                }
                                
                                {/* calculator on mobile 
                                <div className='gifting_page_card green_card'>
                                    calculator card
                                </div>
                                    */}



                        </div>

                    </div>

                    
                    
                    {/* about GG section */}

                    <section className='about_gg_section'>

                        <div className='section_3 landing_main_section'>

                            <div className='section_header margin_bottom_40'><h2>Invest in {this.state.gifting_page.account_name}’s Future with Great Gifts</h2></div>

                            <div className='two_column_flex_content two_column_screenshot'>
                                <div className='left_side'>
                                    <img src={'https://cdn.prod.website-files.com/60cca303af56dec963670957/60d1eb2bcadb5e0bf78e3adc_benefits_gifter_1.svg'}
                                        alt='Greatest Gift monetary gifts screenshot'/>
                                </div>

                                <div className='right_side'>
                                    <div className='two_column_screenshot_textbox'>
                                        <h3 className='primary_dark'>Personalized Monetary Gifts</h3>
                                        <p>Turn a money gift into a thoughtful gesture by adding a personal greeting with pictures and videos. Don't settle for a gift emoji.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='two_column_flex_content two_column_screenshot two_column_reverse'>
                                <div className='left_side'>
                                    <div className='two_column_screenshot_textbox'>
                                        <h3 className='primary_dark'>Cash Gift With Purpose</h3>
                                        <p>Gift receivers put cash gifts to work for them, and get to discover fantastic savings and investment options for their family.</p>
                                    </div> 
                                </div>

                                <div className='right_side'>
                                    <img src={'https://cdn.prod.website-files.com/60cca303af56dec963670957/616320e3d300e856467a1b34_Benefits%20-%20Gifter%20-%202%20(1).svg'}
                                        alt='Greatest Gift financial gifts - gift details screenshot'
                                    />
                                </div>
                            </div>

                            <div className='two_column_flex_content two_column_screenshot'>
                                <div className='left_side'>
                                    <img src={'https://cdn.prod.website-files.com/60cca303af56dec963670957/6160b36ebe657338164edcee_Secure%20Gifting.webp'}
                                    alt='Gift securely'/>
                                </div>

                                <div className='right_side'>
                                    <div className='two_column_screenshot_textbox'>
                                        <h3 className='primary_dark'>Give Financial Gifts Securely</h3>
                                        <p>Your peace of mind is our top priority.
                                            <br></br>
                                            <br></br>
                                            Greatest Gift uses bank-level security safeguards to make sure your personal data and gifts are secure.</p>
                                            <div className='stripe_and_plaid_logos'>
                                                <img src={stripe_logo} alt='powered by Stripe'/>
                                                <img src={plaid_logo} alt='plaid logo'/>
                                            </div>
                                    </div>
                                </div>
                            </div>

                            <div className='button_wrapper'>
                                <MasterButton
                                buttonText={`Give ${this.state.gifting_page.account_name} a gift`} 
                                notSticky={true}
                                links={true}
                                linkTo={this.state.gifting_link}
                                icon=''
                                />
                            </div>

                        </div>


                        <div className='section_header'><h2>Join a Community of Great Gifters</h2></div>

                        <div className='reviews_wrapper margin_bottom_40'>
                            
                            <div className='review_container'>
                                <div className='review'>
                                    <h3 className='align_center'>Love The Discover Section</h3>
                                    <p>We have a 2-year-old and another baby on the way, and we love Greatest Gift’s discover section. I look forward to learning about the right financial tools to help build their future and set them up for success financially.</p>
                                </div>
                                <p className='align_center'>Hillan K.</p>
                            </div>

                            <div className='review_container'>
                                <div className='review'>
                                    <h3 className='align_center'>Thoughtful + Impactful Gifting</h3>
                                    <p>Convenient, practical, and ideal for a busy parent with a lot of little friends to gift to throughout the year! ... Nothing but positive feedback as well from our friends who have received through Greatest Gift. Looking forward to continued gifting!</p>
                                </div>
                                <p className='align_center'>Judy</p>
                            </div>


                            <div className='review_container'>
                                <div className='review'>
                                    <h3 className='align_center'>{'Easy & Meaningful Gifting Experience'}</h3>
                                    <p>I love how easy it is to give a meaningful gift to the kids and tots in my life!It means a lot to me that I can contribute to their future, and Greatest Gift makes the experience seamless and fun.</p>
                                </div>
                                <p className='align_center'>Alana</p>
                            </div>

                           
                        </div>


                        <h3 className='align_center'>As Featured In</h3> 
                        <div className="featured_wrapper">
                            
                            <a href="https://www.parents.com/parenting/money/teach-kids-exchange-rates/" target="_blank" className="featured_logo_link">
                                <img src="https://uploads-ssl.webflow.com/60cca303af56dec963670957/6196c37cde2f8602d54e6126_parents.com%20logo.png" loading="lazy" alt="parents.com logo" className="featured_logo" />
                            </a>

                            <a href="https://finance.yahoo.com/news/much-kids-could-save-investing-204619531.html" target="_blank" className="featured_logo_link">
                                <img src="https://uploads-ssl.webflow.com/60cca303af56dec963670957/6151eebcaa7eec2084f26f10_Logo_yahoofinance_color.png" loading="lazy" alt="yahoo finance logo " className="featured_logo"/>
                            </a>
                            
                            <a href="https://www.gobankingrates.com/credit/credit-score/parents-best-way-to-build-your-gen-z-kids-credit/" target="_blank" className="featured_logo_link">
                                <img src="https://uploads-ssl.webflow.com/60cca303af56dec963670957/6151ecee24c6884ffd4fb487_Logo_gobankingrates.svg" loading="lazy" alt="go banking rates logo" className="featured_logo"/>
                            </a>

                        </div>

                    
                    <div className='buffer'></div>

                    </section>
                    
                </div>

                <Page_footer />

            </div>
        );
    }
}

export default Gifting_page;