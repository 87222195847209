import React from 'react';
import './MasterButton.css';

import { Link } from 'react-router-dom';

/*
    MasterButton Properties:
                    buttonText={this.props.buttonText} 
                    handleButtonClick={this.props.handleButtonClick}
                    notSticky={true}
                    inactive_button={!this.state.button_active}
                    links={true}
                    linkTo={'/'}
                    icon=''
*/

class MasterButton extends React.Component {
    constructor(props) {
        super(props);

        this.toggleStickyContainer = this.toggleStickyContainer.bind(this);
        this.toggleStickyButton = this.toggleStickyButton.bind(this);
    }

    toggleStickyContainer() {
        if (this.props.notSticky === true) {
            return (
                {position: 'static', margin: '24px 0'}
            )
        } else {
            return null;
        }
    }

    toggleStickyButton() {
        if (this.props.notSticky === true) {
            return (
                {width: '100%'}
            )
        } else {
            return null;
        }
    }



    render () {
        if(this.props.links === true) {
            return (
                <div className='button_container' style={this.toggleStickyContainer()}>
                    <Link to={this.props.linkTo} className='button_link'>
                    <button 
                        onClick={this.props.handleButtonClick} 
                        style={this.toggleStickyButton()} 
                        disabled={this.props.disabled}
                        className={`${this.props.icon ? 'button_w_icon' : null} ${this.props.inactive_button ? 'inactive_button' : ''}`}>
                            
                            {this.props.buttonText}

                            {this.props.icon ? <i className='material-icons'>{this.props.icon}</i> : null}
                    </button>
                    </Link>
                </div>
            
            );
        } else {
            return (
                <div className='button_container' style={this.toggleStickyContainer()} >
                    <button 
                        onClick={this.props.handleButtonClick} 
                        style={this.toggleStickyButton()} 
                        disabled={this.props.disabled}
                        className={`${this.props.icon ? 'button_w_icon' : null} ${this.props.inactive_button ? 'inactive_button' : ''}`}>
                            {this.props.buttonText}
                            {this.props.icon ? <i className='material-icons'>{this.props.icon}</i> : null}
                    </button>
                </div>
            );
        }
    }
}

export default MasterButton;