/*
    {   id: 1, 
        plan_name: '',
        routing: '',
        account_format: '',
        plan_logo: '',
        link: 
    }
*/

const plans = [
    {   id: 1, 
        title: 'NY 529 Direct Plan',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_ny529.png',
        routing: '011001234',
        account_format: '531-xxxxxxxxx', // 9 digit with 3 digit prefix
        account_prefix: '531',
        account_length: 9,
        link: 'https://www.nysaves.org/',
        account_input_instructions: 'Please enter the first 9 digits of your account:'
    },
    {   id: 2, 
        title: 'ScholarShare 529',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_scholarshare_529.png',
        routing: '011001234',
        account_format: '581-xxxxxxxxx', // 9 digit with 3 digit prefix
        account_prefix: '581',
        account_length: 9,
        link: 'https://www.cascholarshare529.com/cadtpl/auth/ll.cs',
        account_input_instructions: 'Please enter the first 9 digits of your account:'
    },
    {   id: 3, 
        title: 'Ohio\'s 529 Plan, CollegeAdvantage',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_ohio_collegeadvantage.png',
        routing: '011001234', // mellon bank
        account_format: '546-xxxxxxxxx', // 9 digit with 3 digit prefix
        account_prefix: '546',
        account_length: 9,
        link: 'https://www.collegeadvantage.com/', 
        account_input_instructions: 'Please enter the first 9 digits of your account:'
    },
    {   id: 4, 
        title: 'Texas College Savings Plan',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_texas_college_savings_plan.png',
        routing: '104000016', // First National Bank
        account_format: '9108369-xxxxxxxxxx', // 10 digit with 7 digit prefix
        account_prefix: '9108369',
        account_length: 10,
        link: 'https://texas529paweb.geminifund.com/PAWebTexas529Plan/Logins/Login.aspx?planname=texas529plan&user=', 
        account_input_instructions: 'Please enter your 10 digit account number:'
    },
    {   id: 5, 
        title: 'Schwab 529 Plan',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_charlesschwab.svg',
        routing: '011001234', // mellon bank
        account_format: '926-Xxxxxxxxxxx', // 11 digit with 3 digit prefix
        account_prefix: '926',
        account_length: 11,
        link: 'https://www.schwab529plan.com/swatpl/auth/ll.cs', 
        account_input_instructions: 'Please enter the first 11 digits of your account:'
    },
    {   id: 6, 
        title: 'Bright Start 529 Plan',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_brightstart.svg',
        routing: '104910795', // Union Bank & Trust
        account_format: '989-xxxxxxxxx', // 9 digit with 3 digit prefix
        account_prefix: '989',
        account_length: 9,
        link: 'https://portal.ubtrust.com/Portal/paport/brightstart/#login', 
        account_input_instructions: 'Please enter your 9 digit account number:'
    },

    {   id: 7, 
        title: 'The Education Plan',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_theeducationplan.png',
        routing: '011001234', // mellon bank
        account_format: '580-xxxxxxxxx', // 9 digit with 3 digit prefix
        account_prefix: '580',
        account_length: 9,
        link: 'https://myaccount.theeducationplan.com/nmdtpl/auth/ll.cs', 
        account_input_instructions: 'Please enter your 9 digit account number:'
    },

]


;

export default plans;

/*

   

    {   id: 6, 
        title: 'NJ Best 529 College Savings Plan',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_nj_best.png',
        routing: '021409169', // JP Morgan Chase
        account_format: '529-Xxxxxxxxx-99999', // 9 digit SSN with 3 digit prefix and 5 digit ending
        account_prefix: '529',
        account_ending: '99999',
        account_length: 9,
        link: 'https://www.schwab529plan.com/swatpl/auth/ll.cs', 
        account_input_instructions: 'Please enter the 9 digit Social Security Number of the NJ Best account holder to set up an ACH transfer'
    },    

    {   id: 5, 
        title: 'my529',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_my529.svg',
        routing: 'unknown', // user needs to fill out
        account_format: '', // 
        account_prefix: '',
        link: 'https://login.my529.org/login?_ga=2.92654814.254825456.1617826388-626731596.1617826388', 
        account_input_instructions: 'Please enter your my529 PAYROLL BANK ACCOUNT NUMBER and PAYROLL ROUTING NUMBER found in the Payroll Contribution section of your my529 dashboard:'
    },
,
    
    {   id: 5, 
        title: 'CollegeAmerica by Capital Group',
        image: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_collegeamerica.jpeg',
        routing: '121000248', // Wells Fargo ?
        account_format: '253-xxxx-xxxxxxxxxx', // 3 digit prefix, with 4 digit fund number, and 10 digit account number
        account_prefix: '253',
        link: 'https://www.capitalgroup.com/individual/service-and-support/contact-us.html', 
        account_input_instructions: 'Please enter your 4 digit fund number, followed by your 10 digit account number: (if you don\'t know your fund and account numbers, please reach out to your financial advisor)'
    }
*/