import React from 'react';
import './Add_step_2.css';

import MasterButton from '../../../general_components/MasterButton/MasterButton.js';
import dates from '../../../../utils/dates';
import Goals_list from '../../../plan_components/Gifting_page/Goals/Goals_list.js';
import Overlay_popup from '../../../general_components/Overlay_popup/Overlay_popup.js';
import Selector_field from '../../../general_components/Selector_field/Selector_field.js';

import College from '../../../../images/goals/college_fund.png'
import Future from '../../../../images/goals/future_savings.png'
import Emergency from '../../../../images/goals/emergency_fund.png'
import Financial from '../../../../images/goals/dollar_signs.png'
import Crypto from '../../../../images/goals/goal_crypto_fund.png'
import Diaper from '../../../../images/goals/diaper_fund.png'
import Vacation from '../../../../images/goals/vacation_fund.png'

class Add_step_2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            goals: [],
            open_overlay_1: false,
        }

        this.calculateCollegeStart = this.calculateCollegeStart.bind(this);
    }

    calculateCollegeStart(){
        let yearsTillCollege = '';
        let birthdayYear = dates.getYearFromDate(this.props.birthday);
        let birthdayMonth = dates.getMonthFromDate(this.props.birthday);
        if (birthdayMonth <= 9 ) {
            yearsTillCollege = 18;
        } else {
            yearsTillCollege = 19;
        }
        let expectedCollegeYear = birthdayYear + yearsTillCollege;

        let expectedCollegeDate = '08/01/'+expectedCollegeYear;

        return expectedCollegeDate;
    }

    

    render () {   
        if (this.props.currentStep !== 2) {
            return null;
        };
        return (
           <div>
                <h2 className='center'>Choose savings goals</h2>

                <div className='headline_with_icon'>
                        <p className='form_label'>Select {this.props.account_name}'s goals:</p>
                        <i onClick={() => this.setState({open_overlay_1: true})} className='material-icons-outlined inline_icon'>help</i>
                    </div>

                <Goals_list
                    goals={this.props.goals}
                    toggle_goal={this.props.toggle_goal}
                />

                {/* CUSTOM GOALS - WAITING FOR GIFTING PAGE REVAMP
                !this.props.custom_goal_active 
                ? 
                
                <div className='add_custom_goal margin_bottom_40' onClick={this.props.active_custom}>
                    <i className='material-icons-outlined inline_icon'>add</i>
                    <p>Add a custom goal</p>
                </div>
                
                :

                <div>
                    <h2 className='center'>Add a custom goal</h2>


                    <form className='margin_bottom'>
                        <p className='form_label'>Goal name:</p>

                        <input 
                            type='text' 
                            id='goal_name' 
                            onChange={this.props.handleChange}
                            name='goal_name' 
                            placeholder='Name your custom goal' 
                            value={this.props.goal_name}
                            maxLength='100'
                            autoComplete='off'
                            />
                    </form>

                    <Selector_field 
                            options             = {[
                                {image: 'Financial', url: Financial},
                                {image: 'Future', url: Future},
                                {image: 'Emergency', url: Emergency},
                                {image: 'Diaper', url: Diaper},
                                {image: 'College', url: College},
                                {image: 'Crypto', url: Crypto},
                                {image: 'Vacation', url: Vacation}
                            ]}

                            label               = "Goal Image:"
                            field_name          = "goal_image"
                            field_value         = {this.props.custom_goal}
                        
                            select_item         = {(selected_item) => this.props.changeGoalImg(selected_item)}

                            image_selector      = {true}

                        />

                        <p className='gg_red align_center cursor margin_bottom' onClick={()=> {this.props.active_goal_false()}}
                            >Remove custom goal</p>
                </div>
                */}

               


                <Overlay_popup 
                    open            = {this.state.open_overlay_1}
                    closer          = {true}
                    handleClose     = {() => {
                        this.setState({open_overlay_1: false})
                    }
                    }
                    overlay_title   = 'Quick Tips'
                    body_1          = {`The goals you pick will show up on ${this.props.account_name}'s gifting page. Goal target amounts are never shown to gift givers.`}
                />

           </div>
        );
    }
}

export default Add_step_2;


/*

                <form>
                    
                    <p className='form_label'>How much would you like to save?</p>
                    <input 
                        className='dropdown_input' 
                        list='target_saving_options' 
                        id='target_savings' 
                        onChange={this.props.handleChange}
                        onFocus={this.props.target_savings[0] === '$' ? null : this.props.handleDollarInput }
                        onBlur={this.props.handleDollarInput}
                        name='target_savings' 
                        placeholder='$83,080 (4 years of in-state college tuition)' 
                        value={this.props.target_savings}
                        
                        autoComplete='off'
                        maxLength={this.props.target_savings[0] === '$' ? 9 : 8}
                        />
                    <datalist id='target_saving_options'>
                        <option value='$50,000'>    $50,000</option>
                        <option value='$83,080'>    $83,080</option>
                        <option value='$100,000'>    $100,000</option>
                    </datalist>


                    <p className='form_label'>When will {this.props.account_name} need it by? Pick a target date</p>
                    <input 
                        className='dropdown_input' 
                        list='target_dates' 
                        name='target_date' 
                        id='target_date'
                        onChange={this.props.handleTargetDateChange}
                        value={this.props.target_date}
                        maxLength='10'
                        minLength='10'
                        placeholder={`${this.calculateCollegeStart()} (${this.props.account_name}'s expected college start)`}
                        autoComplete='off'
                        >
                    </input>

                    <datalist id='target_dates'>
                        <option value={this.calculateCollegeStart()}>       Expected college start year</option>
                        <option value={dates.next5Years()}>       In 5 years</option>
                        <option value={dates.nextYear()}>       Next year</option>
                    </datalist>

                </form>
*/

/*
                    <input 
                        type='text' 
                        id='target_savings' 
                        onChange={this.props.handleChange}
                        onBlur={this.props.handleDollarInput}
                        name='target_savings' 
                        placeholder='$83,080 (4 years of in-state college tuition)' 
                        value={this.props.target_savings}
                        autoFocus
                        autoComplete='off'
                        maxLength={this.props.target_savings[0] === '$' ? 9 : 8}
                        />                    

                    <input 
                        type='text' 
                        id='target_date' 
                        onChange={this.props.handleTargetDateChange}
                        value={this.props.target_date}
                        name='target_date' 
                        maxLength='10'
                        minLength='10'
                        placeholder={`${this.calculateCollegeStart()} (${this.props.account_name}'s expected college start)`}
                        autoComplete='off'
                         />
*/