import React from 'react';
import './Goals.css';

import choose_goal from '../../../../images/icons/choose_goal.png';

import config from '../../../../utils/config';
import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);

class Goal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
 
    render () {
        return  (
          <div 
            onClick={() => this.props.toggle_goal(this.props.goal_name)}
            className={(this.props.selected ? 'input_button_w_img input_button_w_img_selected' : 'input_button_w_img') 
              +  (this.props.goal_name.length > 20 ? ' padding_4_mobile' : '') }
          >
            <img src={this.props.selected ? choose_goal : this.props.goal_img } alt={this.props.goal_name}/>
            <p className='small_text '>{this.props.goal_name}</p>
          </div>
        )
    }
}

export default Goal;