import React from 'react';
import './Gifting_step_4.css';

import {Helmet} from "react-helmet";
import MasterButton from '../../../general_components/MasterButton/MasterButton.js';
import Dropdown_w_category from '../../../general_components/Dropdown_w_category/Dropdown_w_category';
import gg_fees from '../../../../utils/gg_pricing.js';

class Gifting_step_4 extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
            selected_fund_active: false,
            buttonActive: false,

            highlight_fields: false
        };

        this.handleDDSelection = this.handleDDSelection.bind(this);
        this.calculateFinalGift = this.calculateFinalGift.bind(this);
        this.calculateFees = this.calculateFees.bind(this);

        this.render_gift_media = this.render_gift_media.bind(this);

    }

    handleDDSelection(item, dd_name) {
        if (dd_name === 'payment_method') {
            // set the newly selected payment method
            this.setState(
                {   payment_method: item, 
                    buttonActive: true});
        }
    }

    calculateFinalGift(){
        if(this.props.accept_additional_charges) {
            return this.props.Gift_amount;
        } else {
            // reduce the fee from the gift amount!
            let fee;
            let amount = Number(this.props.Gift_amount.slice(1));
            let centAmount = amount * 100;
            if(this.props.selected_payment_method.type === 'CC') {
                //fee = centAmount * 0.035;
                fee = ( centAmount * gg_fees.send_fees.cc_variable ) + gg_fees.send_fees.cc_fixed;
            } else if (this.props.selected_payment_method.type === 'ACH') {
                //fee = centAmount * 0.01;
                fee = ( centAmount * gg_fees.send_fees.ach_variable ) + gg_fees.send_fees.ach_fixed;
            } else {
                fee = 0;
            };
            fee = Math.floor(fee);

            let new_centAmount = centAmount - fee;
            let new_amount = new_centAmount / 100;
            //fee = fee / 100 ;
            return '$' + String(new_amount);
        }
    }

    calculateFees(){
            let fee;
            let amount = Number(this.props.Gift_amount.slice(1));
            let centAmount = amount * 100;
            if(this.props.selected_payment_method.type === 'CC') {
                //fee = centAmount * 0.035;
                fee = ( centAmount * gg_fees.send_fees.cc_variable ) + gg_fees.send_fees.cc_fixed;
            } else if (this.props.selected_payment_method.type === 'ACH') {
                //fee = centAmount * 0.01;
                fee = ( centAmount * gg_fees.send_fees.ach_variable ) + gg_fees.send_fees.ach_fixed;
            } else {
                fee = 0;
            };
            fee = Math.floor(fee);
            fee = fee / 100 ;
            return '$' + String(fee);
    }
    

    render_gift_media(){
        if(!typeof this.props.gift_media === 'string'){
            return null;
        } else {
            let split = this.props.gift_media.split(".");
            let extension = this.props.gift_media.split(".")[split.length - 1];
            if (extension === 'jpeg' || 
                extension === 'jpg' || 
                extension === 'png' ){
                    return (
                        <img 
                            src={this.props.gift_media} 
                            className={'greeting_image'}/>
                    )
            } else if ( 
                extension === 'quicktime' ||
                extension === 'mp4' ||
                extension === 'mov' ||
                extension === 'qt'){
                    return (
                        <video className='uploaded_media_preview' controls>
                            <source src={this.props.gift_media}/>
                        </video>
                    )
            }
        }
    }

    // render

    render () {
        if (this.props.currentStep !== 4) {
            return null;
        }
        
        return (
            <div>
                
                <h2>{'Payment & Confirmation'}</h2>
    
                <p style={{'marginBottom': '8px'}}>Payment</p>
                <Dropdown_w_category dd_name='payment_method'

                    header='Select Payment Method:' 
                    list={this.props.payment_methods}

                    handleItemClick={this.props.handleDDSelection}
                    handleLastItemClick={this.props.goToPayments}

                    selected={this.props.selected_payment_method}

                    lastItem={true}
                    last_item_add_button={true}
                    lastItemText='Add a payment method'
                    active={true}
                />

                <p>Confirm Gift Details:</p>
                <div className='two_column_text_container'>
                
                    <div className='two_column_line'>
                        <div className='left_column_text'><p className='small_text'>Send to:</p></div>
                        <div className='right_column_text'><p className='small_text'>{this.props.Send_to}</p></div>
                    </div>

                    <div className='two_column_line'>
                        <div className='left_column_text'><p className='small_text'>Gift for:</p></div>
                        <div className='right_column_text'><p className='small_text'>{this.props.Gift_for}</p></div>
                    </div>

                    <div className='two_column_line'>
                        <div className='left_column_text'><p className='small_text'>From:</p></div>
                        <div className='right_column_text'><p className='small_text'>{this.props.from}</p></div>
                    </div>

                    <div className='two_column_line'>
                        <div className='left_column_text'><p className='small_text'>Delivery date:</p></div>
                        <div className='right_column_text'><p className='small_text'>{this.props.schedule_delivery === 'now' ? 'Today' : this.props.Gift_delivery_shown_date}</p></div>
                    </div>

                    <div className='two_column_line'>
                        <div className='left_column_text'><p className='small_text'>Delivery time:</p></div>
                        <div className='right_column_text'><p className='small_text'>{this.props.schedule_delivery === 'now' ? 'Now' : `${this.props.Gift_delivery_shown_time.title} ${this.props.timezone.title}`}</p></div>
                    </div>

                    <div className='two_column_line two_column_line_new_section'>
                        <div className='left_column_text'><p className='small_text'>Gift amount:</p></div>
                        <div className='right_column_text'><p className='small_text'>{this.calculateFinalGift()}</p></div>
                    </div>

                    <div className='two_column_line'>
                        <div className='left_column_text'><p className='small_text'>Convenience fee:</p></div>
                        <div className='right_column_text'><p className='small_text'>{this.calculateFees()}</p></div>
                    </div>

                    <div className='two_column_line'>
                        <div className='left_column_text'><p className='small_text bold'>Total due:</p></div>
                        <div className='right_column_text'><p className='small_text bold'>{'$'+String(Number(this.calculateFees().slice(1)) + Number(this.calculateFinalGift().slice(1)))}</p></div>
                    </div>

                </div>

                <p className='header_for_greeting'>Greeting Preview:</p>
                <div className='greeting_box'>
                    <p>"{this.props.greeting}"</p>
                </div>
                
                <div className='greeting_image_container'>
                    {   this.props.gift_media ?

                        typeof this.props.gift_media === 'string' ? 
                        
                        this.render_gift_media() 
                        
                        :

                        ((this.props.gift_media.type === 'image/jpeg' ||this.props.gift_media.type === 'image/jpg' || this.props.gift_media.type === 'image/png') ? 
                            <img 
                                src={URL.createObjectURL(this.props.gift_media)}
                                className={'greeting_image'}/>
                        :
                            <video className='uploaded_media_preview' controls>
                                <source src={URL.createObjectURL(this.props.gift_media)}/>
                            </video>
                        ) :
                        <img 
                        src={'https://gg-app-images.s3.us-east-2.amazonaws.com/email_pictures/personalized_gift(small).png'}
                        className={'greeting_image no_border'} />
                    }
                </div>
                
        
                
                <MasterButton 
                    buttonText={this.props.buttonText} 
                    handleButtonClick={this.props.handleButtonClick}
                    notSticky={true}
                    
                />

                <div className='disclaimer_checkbox_container'>
                    {this.props.accept_additional_charges ?
                     <i onClick={this.props.toggle} className='material-icons-outlined disclaimer_checkbox'>check_box</i> :
                     <i onClick={this.props.toggle} className='material-icons-outlined disclaimer_checkbox'>check_box_outline_blank</i>}
                    <p className='disclaimer_checkbox_text tiny_text'>I agree to cover the conveniece fees so that {this.props.Gift_for} receives the full gift amount</p>
                </div>

                {this.props.selected_payment_method.type === 'ACH' ? 
                    <div className='disclaimer_checkbox_container'>
                        <i className='material-icons-outlined disclaimer_checkbox'>info</i>
                        <p className='disclaimer_checkbox_text tiny_text'>By confirming, you authorize Greatest Gift Inc. to electronically debit your account and, if necessary, electronically credit your account to correct erroneous debits.</p>
                    </div>
                :null}

                { this.props.guest ?
                <div className='disclaimer_checkbox_container'>
                     <i onClick={this.props.opt_in_out} className='material-icons-outlined disclaimer_checkbox'> {this.props.opt_in ? "check_box" : "check_box_outline_blank" }</i>
                    <p className='disclaimer_checkbox_text tiny_text'>Keep me up to date with Greatest Gift's newsletter</p>
                </div>
                : null }

           </div>
        );
    }
}

export default Gifting_step_4;

/*
<div className='linked_account_headline'>
                    <p>Payment</p>
                </div>                

<Financial_account 
                    handleAccountClick={()=> {return null}}
                    account_id='id_from_html_get_request'
                />
*/