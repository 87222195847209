import React from 'react';
import './Pic_selector.css';

import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';

import { cropped_images } from '../../../utils/cropped_images.js';
import dates from '../../../utils/dates.js';

/*
        this.props.imageSrc
        this.props.onCropComplete
*/

class Image_cropper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          crop: { x: 0, y: 0 },
          zoom: 1,
          croppedAreaPixels: null,
        };
      }
    

    onCropChange = (crop) => {
        this.setState({ crop });
    };

    onZoomChange = (zoom) => {
        this.setState({ zoom });
    };

    onCropComplete = (_, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels });
    };

    handleCrop = async () => {
        //const croppedImage = await cropped_images(this.props.imageSrc, this.state.croppedAreaPixels);
        const croppedBlob = await cropped_images(this.props.imageSrc, this.state.croppedAreaPixels);
        console.log(croppedBlob);
        let croppedFile = new File([croppedBlob], dates.current_time(), { type: croppedBlob.type });
        this.props.onCropComplete(croppedFile);
    };
    

    render () {
        return (
        <div>
            <div className='cropper_wrapper'>
                <Cropper
                image={this.props.imageSrc}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={720 / 420}  // Set aspect ratio for banner image
                onCropChange={this.onCropChange}
                onZoomChange={this.onZoomChange}
                onCropComplete={this.onCropComplete}
                showGrid={false}
                />
            </div>
            
            <div className='cropper_button_wrapper'>
                <button className='' onClick={this.handleCrop}>Crop</button>
            </div>

            <div className='slider_wrapper'>
                <Slider
                    value={this.state.zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    onChange={(e, value) => this.onZoomChange(value)}
                    color={'warning'}
                />
            </div>

            

            <div className=''></div>

        </div>
        );
    }
}

export default Image_cropper;