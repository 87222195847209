import React from 'react';
import './Article_page.css';

class Article_section extends React.Component {
    constructor(props) {
        super(props);

        this.renderParagraphs = this.renderParagraphs.bind(this);
        this.renderOl = this.renderOl.bind(this);
        this.renderUl = this.renderUl.bind(this);
    }

    renderParagraphs(){
        if(!this.props.paragraphs) {
            return null;
        }
        return this.props.paragraphs.map(text => {
            return <p>{text}</p>
            });
    }

    renderOl(){
        if(!this.props.Ol) {
            return null;
        }
        let i = 0;
        let ordered_list = this.props.Ol.map(li => {
            i++;
            return <li key={i}>{li}</li>
        });
        return (
            <ol>
                {ordered_list}
            </ol>
        )
    }

    renderUl(){
        if(!this.props.Ul) {
            return null;
        }
        let j = 0;
        let unordered_list = this.props.Ul.map(li => {
            j++;
            return <li key={j}>{li}</li>
        });
        return (
            <ul>
                {unordered_list}
            </ul>
        )
    }

    render () {
        return (
                <div id={this.props.section_header}>
                    <h2 className='article_section_header'>{this.props.section_header}</h2>
                    {this.renderParagraphs()}
                    {this.renderOl()}
                    {this.renderUl()}
                    {this.props.image ? <img className='article_section_cover_img' src={this.props.image}></img> : null }
                    
                </div>
        );
    }
}

export default Article_section;