import React from 'react';
import './Cards.css';

import { Link } from 'react-router-dom';


/*
    props:
    {
        header = {
            link: '',
            text: '',
            icon: ''
        }

        list = [
            {
                link: '',
                text: '',
                icon: '',
                img: '',
                clickHandler: this.handler_function
            },
            {
                link: '',
                text: '',
                icon: '',
                img: '',
                clickHandler: this.handler_function
            }
        ]

    }

*/


class List_card_w_header extends React.Component {
    
    render_items(){
        return this.props.list.map(item => {
            if(item.links) {
                return (
                    <a className='List_card_w_header_item' href={item.link}  onClick={() => item.clickHandler ? item.clickHandler() : null}>
                        <div className='List_card_w_header_item_left'>
                            <img src={item.img}/>
                            <p className='small_text'>{item.text}</p>
                        </div>
                        <i className='material-icons small_text'>{item.icon}</i>  
                    </a>
                )
            } else {
                return (
                    <div className={item.clickHandler ? 'cursor List_card_w_header_item' : 'List_card_w_header_item'} onClick={() => item.clickHandler ? item.clickHandler() : null}>
                        <div className='List_card_w_header_item_left'>
                            <img src={item.img}/>
                            <p className='small_text'>{item.text}</p>
                        </div>
                        <i className='material-icons small_text'>{item.icon}</i>  
                    </div>
                )
            }
            
        })
    }

    //
    render () {
        return (
            <div className='card List_card_w_header_container'>
                {   this.props.header.links 
                    ?
                    <Link className='List_card_w_header_header' to={this.props.header.link}>
                    <p className='bold'>{this.props.header.text}</p>
                    <i className='material-icons small_text'>{this.props.header.icon}</i>  
                    </Link>
                    :
                    <div className='List_card_w_header_header'>
                    <p className='bold'>{this.props.header.text}</p>
                    <i className='material-icons small_text'>{this.props.header.icon}</i>  
                    </div>
                }

                {this.render_items()}
            </div>
        );
    }
}

export default List_card_w_header;