import React from 'react';
import './Accordion.css';
import { Link } from 'react-router-dom';

class Accordion_item extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }


    render () {
        
        return (
            <div id={this.props.id} onClick={() => this.setState({open: !this.state.open})}
                className={this.state.open ? 'accordion_item_container accordion_item_container_open' : 'accordion_item_container accordion_item_container_closed'}>
                <div className='accordion_item_header'>
                    <h2>{this.props.header}</h2>
                    <i className={'material-icons accordion_icon'}>add</i>
                </div>
                <div className='accordion_item_content'>
                    <div className='accordion_content_container small_text'>{this.props.content}</div>
                    <div className='accordion_item_content_placeholder'> </div>
                </div>
            </div>
        );
    }
}

export default Accordion_item;
