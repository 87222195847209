import React from 'react';
import './Home_Page_Footer.css';
import { Link } from 'react-router-dom';

// import images:
import logo_var_2 from '../../images/logos/logo_var_2.png';
import fb from '../../images/icons/FB_Gray.png';
import ig from '../../images/icons/IG_Gray.png';


class Home_Page_Footer extends React.Component { 

    render () {
        let now = new Date();
        let year = now.getFullYear();
        return (
            
                <footer className='landing_footer'>

                    <div className='landing_footer_container'>

                        <div className='footer_item_container'>
                            <a href='https://www.greatestgiftapp.com'>
                                <img src={logo_var_2} className='logo logo_var_2'/>    
                            </a>
                            
                            <div className='footer_social_container'>
                                <a target='_blank' href='https://facebook.com/greatestgiftapp'>
                                    <img className='footer_social_icon' src={fb}/>
                                </a>
                                
                                <a target='_blank' href='https://instagram.com/greatestgiftapp'>
                                    <img className='footer_social_icon' src={ig}/>
                                </a>
                            </div>
                           
                        </div>

                        <div className='landing_footer_menu'>
                            

                            <div className='landing_footer_menu_item'>
                                
                                <h4 className='bold landing_footer_menu__item_header'>Who We Are</h4>
                                
                                <a href='https://www.greatestgiftapp.com/about'>
                                    <h4 className='small_text'>About Greatest Gift</h4>
                                </a>
                                
                                <a href='https://www.greatestgiftapp.com/#how_it_works'>
                                    <h4 className='small_text'>How it Works</h4>
                                </a>
                                
                                
                                <a href='https://www.greatestgiftapp.com/#pricing'>
                                    <h4 className='small_text'>Pricing</h4>
                                </a>
                            </div>

                            <div className='landing_footer_menu_item'>
                                <h4 className='bold landing_footer_menu__item_header'>Help</h4>
                                
                                <a href='https://www.greatestgiftapp.com/contact'>
                                    <h4 className='small_text'>Contact Us</h4>
                                </a>

                                <a href='https://www.greatestgiftapp.com/#security'>
                                    <h4 className='small_text'>Our Security</h4>
                                </a>

                                <a href='https://www.greatestgiftapp.com/#faq'>
                                    <h4 className='small_text'>FAQ</h4>
                                </a>
                            </div>
                            
                            <div className='landing_footer_menu_item'>
                                
                                <h4 className='bold landing_footer_menu__item_header'>Legal</h4>
                                
                                <Link to='/legal'>
                                    <h4 className='small_text'>Terms</h4>
                                </Link>
                                <Link to='/legal?active=privacy'>
                                    <h4 className='small_text'>Privacy</h4>
                                </Link>
                            </div>

                            <div className='landing_footer_menu_item'>
                                <h4 className='bold landing_footer_menu__item_header'>Discover</h4>

                                <Link to='/discover'>
                                    <h4 className='small_text'>Ways to Save & Invest</h4>
                                </Link>

                                <a href='https://www.greatestgiftapp.com/#featured_partners'>
                                    <h4 className='small_text'>Featured Partners</h4>
                                </a>
                                
                                <a href='https://www.greatestgiftapp.com/blog'>
                                    <h4 className='small_text'>Our Blog</h4>
                                </a>                                
                            </div>

                            {/*
                            <div className='landing_footer_menu_item'>
                                <h4 className='small_text'>Affiliates</h4>
                            </div>
                            */}
                            
                            {/*
                            <div className='landing_footer_menu_item'>
                                <h4 className='small_text'>Blog</h4>
                            </div>
                            */}
                            
                        </div>

                        <div className='landing_footer_buttons footer_item_container'>
                            <Link to='/new_account'>
                                <button className='landing_page_button_sign_in'>
                                    Sign Up
                                </button>
                            </Link>
                            <Link to='/home'>
                                <button className='landing_page_button_sign_in'>
                                    Log In
                                </button>
                            </Link>
                        </div>
                    
                    </div>
                    <div className='landing_footer_copyright'>
                        <p className='small_text'>The entirety of this site is protected by copyright © 2020–{year} Greatest Gift Inc. All rights reserved.</p>
                    </div>

                </footer>
        );
    }
}

export default Home_Page_Footer;