import React from 'react';
import './Main_receive.css';

import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import Received_gift_list from '../Received_gift_list/Received_gift_list';
import GGApi from '../../../utils/GGApi.js';

//import open_gift_illustration from '../../../images/illustrations/gift_open.png';
import open_gift_illustration from '../../../images/illustrations/gift_open_money_w_b.svg';
import GG_loader from '../../general_components/GG_loader/GG_loader';

class Main_receive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            receivedGifts: [],
            email_confirmed: 0
        }
        
        
    }
      
    componentDidMount() {
           GGApi.received_gifts().then(jsonResponse => {
                this.setState({email_confirmed: jsonResponse.email_confirmed});
                if (jsonResponse.receivedGifts) {
                    return jsonResponse.receivedGifts;
                } else { 
                    return [];
                };
           }).then( gifts => {
            this.setState({receivedGifts: gifts, loading: false});
            
            function check_new_receiver(gift) {
                if(gift.redeem_status !== 'new') {
                    return true;
                } else {
                    return false;
                }
            }
            let index = gifts.findIndex(check_new_receiver);
            console.log(index);
            if(index === -1){
                this.props.newReceiver();
            };
        });
    }


    render () {
        
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        return (
            <main>
                <Helmet>
                    <title>{`Receive Gifts | Greatest Gift`}</title>
               </Helmet>
                
                <h2>Your received gifts</h2>
                
                {this.state.receivedGifts.length !== 0 ? null : <p>You haven't received any gifts yet. Send a gift to others, or share your account and ask friends and family to support your recipients</p>}
                
                <Received_gift_list gifts={this.state.receivedGifts}/>
                
                <img className='main_receive_illustration' src={open_gift_illustration} />
                
                {this.state.email_confirmed ? null : <p><Link to='/settings/account' className='secondary_dark'>Verify your email</Link> to make sure you see all of your gifts!</p>}
                
                <p>In order to receive gifts sent to a different email, visit your <Link to='/settings/account' className='secondary_dark'>account settings</Link> and add an additional email to your account.</p>
                
                <div className='buffer'></div>
        
            </main> 
        );
    }
}

export default Main_receive;