import React from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Header from '../layout_components/Header/Header.js';
import Nav from '../layout_components/Nav_bar/Nav.js';
import Main_gift from '../pages/Main_gift_page/Main_gift/Main_gift';
import Main_receive from '../receive_components/Main_receive/Main_receive';
import Main_plan from '../plan_components/Main_plan/Main_plan.js';
import Main_gift_flow from '../pages/gifting_flow/Main_gift_flow/Main_gift_flow.js';
import Page_received_gift from '../receive_components/Page_received_gift/Page_received_gift.js';
import Detailed_plan_page from '../plan_components/Detailed_plan_page/Detailed_plan_page.js';
import Offers_page from '../discover_components/Offers/Offers_page/Offers_page.js';

import Main_account_creation from '../pages/Signup_page/Main_account_creation/Main_account_creation.js';
import Login_page from '../pages/Login_page/Login_page.js';
import Main_add_account from '../pages/New_recipient_page/Main_add_account/Main_add_account.js';
import Landing_page from '../Landing_page/Landing_page';
import ProtectedRoute from '../util_components/ProtectedRoute/ProtectedRoute';
import Discover_header_and_main from '../discover_components/Discover_header_and_main/Discover_header_and_main';
import Profile_overlay from '../layout_components/Header/Profile_overlay/Profile_overlay';
import Main_link_destination from '../Financial_account_components/Link_destination/Main_link_destination/Main_link_destination';

import My_account from '../pages/My_account/My_account';
import Forgot_password from '../Settings/Forgot_password/Forgot_password';
import Forgot_password_reset from '../Settings/Forgot_password_reset/Forgot_password_reset';
import My_pms from '../Settings/My_pms/My_pms';
import Notifications from '../Settings/Notifications/Notifications';

// Stripe 
import { Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../utils/config.js';
import About from '../Landing_page/About/About';
import ScrollToTop from './ScrollToTop';
import Legal from '../Landing_page/Legal/Legal';
import Article_page from '../discover_components/Articles/Article_page/Article_page';
import GG_intro from '../pages/Signup_page/GG_intro/GG_intro';
import Multi_factor_auth from '../pages/Login_page/Multi_factor_auth/Multi_factor_auth';
import Home from '../Home/Home';
import Secondary_emails from '../Settings/Secondary_emails/Secondary_emails';
import Thank_you from '../receive_components/Thank_you/Thank_you';
import Gifting_page_setup from '../plan_components/Gifting_page/Gifting_page_setup';
import Open_gift from '../pages/Open_gift_page/Open_gift';
import Callback_oauth from '../Financial_account_components/Link_destination/callback_oauth/Callback_oauth';
import Main_discover from '../pages/Main_discover/Main_discover.js';
import Rule_of_72 from '../pages/Rule_of_72/Rule_of_72';
import Gifting_page from '../pages/Gifting_page/Gifting_page.js';

const stripe = loadStripe(config.stripe.public_key);

class App extends React.Component{
  constructor(props){
    super(props);
    
    this.state={
      header_visible: true,
      discover_secondary_header: 'offers',
      nav_visible: true,
      display_menu: 'none',

      greeting: '',
      from: '',
      Gift_for: '',
      file: null,
      gift: null,

      gift_link: '',

      active_recipient: '',
      new_receiver: false,

      plaid_link_token: null,
      plaid_session: null
    }
    
    this.handleOffersClick = this.handleOffersClick.bind(this);
    this.handleArticlesClick = this.handleArticlesClick.bind(this);
    
    this.removeNavBar = this.removeNavBar.bind(this);
    this.restoreNavBar = this.restoreNavBar.bind(this);
    this.removeHeader = this.removeHeader.bind(this);
    this.restoreHeader = this.restoreHeader.bind(this);
    
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu  = this.closeMenu.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.select_greeting = this.select_greeting.bind(this);
    this.handlePicChange = this.handlePicChange.bind(this);
    this.handleRemoveMedia = this.handleRemoveMedia.bind(this);

    this.updateActiveRecipient = this.updateActiveRecipient.bind(this);

    this.update_oauth = this.update_oauth.bind(this);
    this.cleanupOauth = this.cleanupOauth.bind(this);
  }


  // Navigation related methods
  handleOffersClick() {
      this.setState({discover_secondary_header: 'offers'});
  }

  handleArticlesClick(){
    this.setState({discover_secondary_header: 'articles'});
  }

  // Remove and restore the nav when needed
  removeNavBar() {
    this.setState({nav_visible: false});
  }

  restoreNavBar() {
    this.setState({nav_visible: true});
  }

  // Remove and restore the header when needed
  removeHeader() {
    this.setState({header_visible: false});
  }
  restoreHeader() {
    this.setState({header_visible: true});
  }
  
  // toggle profile pic menu
  toggleMenu() {
    if (this.state.display_menu === 'none') {
        this.setState({display_menu: 'block'})
    } else {
        this.setState({display_menu: 'none'})
    }
  }

  closeMenu(){
    if (this.state.display_menu === 'block'){
      this.setState({display_menu: 'none'})
    }
  }

  //methods for greeting to gift flow - landing page to gift flow from greeting
  handleChange(event) {
    const { name, value} = event.target;
    this.setState({[name]: value});
  }

  select_greeting(greeting){
    this.setState({greeting: greeting});
  }

  handlePicChange(event) {
    let file = event.target.files[0];
    // try to convert here to jpeg?
    if(!file){
      return;
    }
    if(file.size > 8388608) {
        alert('Please select a photo or video with a max size of 8MBs.');
        return;
    }
    this.setState(
        {file: event.target.files[0]}
    );
  }
 
  handleRemoveMedia(){
      this.setState({file: null});
  }

  // pull the active recipient
  updateActiveRecipient(recipient){
    this.setState({active_recipient: recipient});
  }

  update_oauth(plaid_link_token, plaid_session){
    this.setState({plaid_link_token: plaid_link_token, plaid_session: plaid_session});
  }

  cleanupOauth(){
    this.setState({plaid_link_token: null, plaid_session: null})
  }

  // Render function
  
  render() {
    return (
      <Router>
        <ScrollToTop />
        <Elements stripe={stripe}>
        <div className="App" onClick={this.closeMenu}>
          
          <Switch>
            <Route path={['/gifts', '/receive', '/plan', '/discover', '/settings/reset/:t', '/oauth_return']} exact render={props => 
              <Header 
                text={this.state.header_text}
                backButton={false}
                toggleMenu={this.toggleMenu}
                {...props}
                />
              }/>
            
            <Route path={['/receive/:id', '/receive/:id/thank_you', '/plan/:plan_id', '/plan/:plan_id/gifting_page', '/discover/*', '/settings/*', '/login/verify']} exact render={props =>
              <Header 
                text={this.state.header_text}
                backButton={true}
                toggleMenu={this.toggleMenu}
                {...props}
                />
              }/>
          </Switch>
            
            
          <Route path={['/home', '/gifts', '/receive', '/plan', '/discover', '/receive/:id', '/plan/:plan_id', '/discover/*', '/settings/account','/settings/payment_methods' ]} exact render={props =>
              <Profile_overlay display={this.state.display_menu} toggleMenu={this.toggleMenu}/>
          }/>

          <ElementsConsumer>{({stripe, elements}) => (
            <Switch>

{/* 
              <Route path='/' exact render={props =>
                  <Landing_page 
                  removeNavBar={this.removeNavBar} 
                  restoreNavBar={this.restoreNavBar}
                  handleChange={this.handleChange}
                  select_greeting={this.select_greeting}
                  handlePicChange={this.handlePicChange}
                  handleRemoveMedia={this.handleRemoveMedia}
                  file={this.state.file}
                  greeting={this.state.greeting}
                  {...props}
                  />
                }/>

              <Route path='/about' exact render={props =>
                  <About
                  {...props}
                  />
                }/>

*/}
              <Route path='/legal' exact render={props =>
                  <Legal
                  {...props}
                  />
                }/>

              {/* directing / path to login page  */}
              <Route path={['/', '/login']} exact render={props => 
                <Login_page 
                  removeNavBar={this.removeNavBar} 
                  restoreNavBar={this.restoreNavBar}
                  removeHeader={this.removeHeader}
                  restoreHeader={this.restoreHeader}
                  gift_link={this.state.gift_link}
                  {...props}
                  />
              }/>

              <Route path={'/login/verify'} exact render={props => 
                <Multi_factor_auth 
                gift_link={this.state.gift_link}
                {...props}
                />
              }/>

              <Route path={['/settings/recover']} exact render={props => 
                <Forgot_password 
                  {...props}
                  />
              }/>

              <Route path={['/settings/reset/:t']} exact render={props => 
                 <Forgot_password_reset 
                  {...props}
                  />
              }/>

              {/* */}
              <Route path='/new_account' exact render={props => 
                  <Main_account_creation 
                    removeNavBar={this.removeNavBar} 
                    restoreNavBar={this.restoreNavBar}
                    handleSignIn={this.handleSignIn}
                    updateLoginInfo={this.updateLoginInfo}
                    {...props}
                    />
              }/>

              <Route path='/greatest_gift_intro' exact render={props => 
                <GG_intro
                  {...props}
                  />
              }/>
            {/* */}
            

              <ProtectedRoute path='/home' exact
                component={Home}
                toggleMenu={this.toggleMenu}
                />

              <ProtectedRoute path='/gifts' 
                component={Main_gift} />
              
              <Route path='/give-a-gift' exact render={props => 
                <Main_gift_flow 
                  removeNavBar={this.removeNavBar} 
                  restoreNavBar={this.restoreNavBar}
                  remember_gift={(gift) => this.setState({gift: gift})}
                  forget_gift={() => this.setState({gift: null})}
                  remember_gift_link={(link) => this.setState({gift_link: link})}
                  gift={this.state.gift}
                  Gift_for={this.state.Gift_for}
                  from={this.state.from}
                  greeting={this.state.greeting}
                  file={this.state.file}
                  stripe={stripe}
                  elements={elements}
                  plaid_link_token={this.state.plaid_link_token}
                  plaid_session={this.state.plaid_session}
                  cleanupOauth={this.cleanupOauth}
                  {...props} />
                }/>
                
              <ProtectedRoute path='/receive' exact
                component={Main_receive} 
                newReceiver={() => this.setState({new_receiver: true})}
                />

              <Route path='/opengift/:gid' exact render={props => 
                <Open_gift 
                  {...props} />
                }/>

              <Route path='/registry/:slug' exact render={props => 
                <Gifting_page 
                  {...props} />
                }/>

              <ProtectedRoute path='/receive/:id' exact
                component={Page_received_gift}
                updateActiveRecipient={this.updateActiveRecipient}
                new_receiver={this.state.new_receiver}
                />

              <ProtectedRoute path='/receive/:id/thank_you' exact
                component={Thank_you}
                />

              <ProtectedRoute path='/add_account' exact
                component={Main_add_account}
                updateActiveRecipient={this.updateActiveRecipient}
                />
              
              

              <ProtectedRoute path='/plan' exact
                component={Main_plan} />
              
              <ProtectedRoute path='/plan/:plan_id' exact
                component={Detailed_plan_page} 
                updateActiveRecipient={this.updateActiveRecipient}
                />

              <ProtectedRoute path='/plan/:plan_id/gifting_page' exact
                component={Gifting_page_setup}
                />
              
              <Route path='/discover' exact render={props => 
                <Main_discover 
                  removeNavBar={this.removeNavBar} 
                  restoreNavBar={this.restoreNavBar}
                  {...props}
                  />
              }/>
          
              <Route path='/discover/529plans' exact render={props => 
                <Offers_page 
                  category='529plans'
                  removeNavBar={this.removeNavBar} 
                  restoreNavBar={this.restoreNavBar}
                  {...props}
                  />
              }/>
              <Route path='/discover/kidssavings' exact render={props => 
                <Offers_page 
                  category='kidssavings'
                  removeNavBar={this.removeNavBar} 
                  restoreNavBar={this.restoreNavBar}
                  {...props}
                  />
              }/>
              <Route path='/discover/investments' exact render={props => 
                <Offers_page 
                  category='investments'
                  removeNavBar={this.removeNavBar} 
                  restoreNavBar={this.restoreNavBar}
                  {...props}
                  />
              }/>
              <Route path='/discover/kidstech' exact render={props => 
                <Offers_page 
                  category='kidstech'
                  removeNavBar={this.removeNavBar} 
                  restoreNavBar={this.restoreNavBar}
                  {...props}
                  />
              }/>
              
              {/* 
              <Route path='/discover/articles/:article_name' exact render={props => 
                <Article_page 
                  removeNavBar={this.removeNavBar} 
                  restoreNavBar={this.restoreNavBar}
                  {...props}
                />
              }/>
              */}

            
              <ProtectedRoute path='/link_destination' exact
                component={Main_link_destination}
                recipient={this.state.active_recipient}
                stripe={stripe}
                plaid_link_token={this.state.plaid_link_token}
                plaid_session={this.state.plaid_session}
                cleanupOauth={this.cleanupOauth}
                />

              <ProtectedRoute path='/oauth_return' exact
                component={Callback_oauth}
                updateActiveRecipient={this.updateActiveRecipient}
                update_oauth={this.update_oauth}
                />

              <ProtectedRoute path='/settings/account' exact
                component={My_account}
                />
              
              <ProtectedRoute path='/settings/secondary_emails' exact
                component={Secondary_emails}
                />

              <ProtectedRoute path='/settings/payment_methods' exact
                component={My_pms}
                />

              <ProtectedRoute path='/settings/notifications' exact
                component={Notifications}
                />


            </Switch>
          )}
          </ElementsConsumer>

          <Route path={['/gifts', '/receive', '/plan', '/discover', '/receive/:id', '/plan/:plan_id', '/discover/*', '/settings/account', '/settings/payment_methods']} exact render={props =>
              <Nav 
                visible={this.state.nav_visible}
                {...props}
              />
          }/>


          {/* other */}
          <Switch>
            <Route path={['/rule_of_72']} exact render={props => 
              <Rule_of_72
              {...props}
              />
            }/>
          </Switch>
          
        
        </div>
        </Elements>
      </Router>
    );
  }
}


export default App;