import React from 'react';
import './Page_footer.css';
import MasterButton from '../../general_components/MasterButton/MasterButton';

class Page_footer extends React.Component {
    
    render () {
        return (             

                <footer className='page_footer'>

                            {/*<!-- Main footer area -->*/}
                            <div class="div-block-34">
                                <div class="footer_columns">
                                    
                                    
                                    {/*<!-- Logo Section -->*/}
                                    <div class="w-col w-col-2">
                                        <a href="https://www.GreatestGiftApp.com" aria-current="page" class="footer-logo w-inline-block">
                                            <img src="https://cdn.prod.website-files.com/60cca303af56dec963670957/60d087549253b7513cd4f684_GG%20Logo%20Var%202_small.webp" loading="lazy" alt="Greatest Gift Logo"/>
                                        </a>
                                    </div>


                                    {/*<!-- Links Section -->*/}
                                    <div class="footer-links w-col w-col-7">
                                        {/*<!-- Discover Section -->*/}
                                        <div class="landing_footer_menu_item">
                                        
                                        <div className='div-block-17'>
                                            <h2 class="footer-heading">Discover</h2>
                                        </div>
                                            <ul role="list" class="w-list-unstyled">
                                                <li><a href="https://www.GreatestGiftApp.com/#financial-parenting-journey" class="footer-link-2">Ways to Save & Invest</a></li>
                                                <li><a href="https://www.GreatestGiftApp.com/personal-finance-for-parents-explained" class="footer-link-2">Financial Parenting Dictionary</a></li>
                                                <li><a href="https://www.greatestgiftapp.com/blog/investing-for-kids" class="footer-link-2">Investing for Kids</a></li>
                                                <li><a href="https://www.GreatestGiftApp.com/blog" class="footer-link-2">All Articles</a></li>
                                            </ul>
                                        </div>

                                        {/*<!-- Give Gifts Section -->*/}
                                        <div class="landing_footer_menu_item">
                                            <div className='div-block-17'><h2 class="footer-heading">Give Gifts</h2></div>
                                            <ul role="list" class="w-list-unstyled">
                                                <li><a href="https://www.greatestgiftapp.com/blog/best-financial-gifts-for-kids-of-every-age" class="footer-link-2">Financial Gifts for Kids</a></li>
                                                <li><a href="https://www.GreatestGiftApp.com/baby-shower-gift-card" class="footer-link-2">Baby Shower Gift Card</a></li>
                                                <li><a href="https://www.greatestgiftapp.com/blog/best-gifts-for-a-second-baby" class="footer-link-2">Gifts for a Second Baby</a></li>
                                                <li><a href="https://www.greatestgiftapp.com/blog/baby-shower-gift-ideas" class="footer-link-2">Baby Shower Gift Ideas</a></li>
                                            </ul>
                                        </div>
                                        {/*<!-- Receive Gifts Section -->*/}
                                        <div class="landing_footer_menu_item">
                                           <div className='div-block-17'> <h2 class="footer-heading">Receive Gifts</h2></div>
                                            <ul role="list" class="w-list-unstyled">
                                                <li><a href="https://www.GreatestGiftApp.com/baby-registry" class="footer-link-2">Baby Registry</a></li>
                                                <li><a href="https://www.GreatestGiftApp.com/create-a-baby-fund-registry" class="footer-link-2">Baby Fund Registry</a></li>
                                                <li><a href="https://www.GreatestGiftApp.com/diaper-fund" class="footer-link-2">Diaper Fund</a></li>
                                                <li><a href="https://www.greatestgiftapp.com/blog/best-baby-registries" class="footer-link-2">Best Baby Registries</a></li>
                                            </ul>
                                        </div>

                                        {/*<!-- Who We Are Section -->*/}
                                        <div class="landing_footer_menu_item">
                                          <div className='div-block-17'>  <h2 class="footer-heading">Who We Are</h2></div>
                                            <ul role="list" class="w-list-unstyled">
                                                <li><a href="https://www.GreatestGiftApp.com/about" class="footer-link-2">About Greatest Gift</a></li>
                                                <li><a href="https://www.GreatestGiftApp.com/#how_it_works" class="footer-link-2">How it Works</a></li>
                                                <li><a href="https://www.GreatestGiftApp.com/#featured_partners" class="footer-link-2">Featured Partners</a></li>
                                                <li><a href="https://www.GreatestGiftApp.com/#pricing" class="footer-link-2">Pricing</a></li>
                                            </ul>
                                        </div>

                                        {/*<!-- Help Section -->*/}
                                        <div class="landing_footer_menu_item">
                                         <div className='div-block-17'>   <h2 class="footer-heading">Help</h2></div>
                                            <ul role="list" class="w-list-unstyled">
                                                <li><a href="https://www.GreatestGiftApp.com/contact" class="footer-link-2">Contact Us</a></li>
                                                <li><a href="https://www.GreatestGiftApp.com/#security" class="footer-link-2">Our Security</a></li>
                                                <li><a href="https://www.GreatestGiftApp.com/#faq" class="footer-link-2">FAQ</a></li>
                                            </ul>
                                        </div>

                                        {/*<!-- Legal Section -->*/}
                                        <div class="landing_footer_menu_item">
                                        <div className='div-block-17'>    <h2 class="footer-heading">Legal</h2></div>
                                            <ul role="list" class="w-list-unstyled">
                                                <li><a href="https://app.GreatestGiftApp.com/legal" class="footer-link-2">Terms</a></li>
                                                <li><a href="https://app.GreatestGiftApp.com/legal?active=privacy" class="footer-link-2">Privacy</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                   {/* <!-- Sign Up and Social Media Section -->*/}
                                    <div class="footer_column w-col w-col-3">
                                        
                                        <MasterButton
                                        buttonText='Sign up' 
                                        notSticky={true}
                                        links={true}
                                        linkTo={'/new_account'}
                                        />

                                        <MasterButton
                                        buttonText='Log In' 
                                        notSticky={true}
                                        links={true}
                                        linkTo={'/home'}
                                        />
                                        
                                        
                                        <div class="centered-div">
                                            <a href="https://Facebook.com/GreatestGiftApp" class="footer-social w-inline-block">
                                                <img src="https://cdn.prod.website-files.com/60cca303af56dec963670957/60d08b5b53b47d8c330d4f3c_FB_Gray.webp" loading="lazy" width="22" height="22" alt="Facebook" class="image-2"/>
                                            </a>
                                            <a href="https://Instagram.com/GreatestGiftApp" class="footer-social w-inline-block">
                                                <img src="https://cdn.prod.website-files.com/60cca303af56dec963670957/60d08b5bd5633686d4735fd7_IG_Gray.webp" loading="lazy" width="22" height="22" alt="Instagram" class="image-2"/>
                                            </a>
                                            <a href="https://twitter.com/GreatestGiftApp" class="footer-social w-inline-block">
                                                <img src="https://cdn.prod.website-files.com/60cca303af56dec963670957/62059dae4a0c4184c87e5592_TW%20Gray.webp" loading="lazy" width="22" height="22" alt="Twitter" class="image-2"/>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* <!-- Copyright Section -->*/}
                            <div class="w-container">
                                <div class="div-block-3">
                                    <p className='small_text no_margin'>The entirety of this site is protected by copyright &copy; 2020-2024 Greatest Gift Inc. All rights reserved.</p>
                                </div>
                            </div>

                </footer>
        );
    }
}

export default Page_footer;