import React from 'react';
import './Category.css';
import { Link } from 'react-router-dom';

class Category extends React.Component {
    render () {
        return (
            <div className='category_container'>
                <Link to={this.props.linksTo}>
                    <div>

                    <div className={'category_picture_container' + ' ' + 'category_picture_container_' + this.props.image} >
                    </div>

                    <div className='category_headline_container'>
                        <p className='category_headline bold green_text'>{this.props.category}</p>
                    </div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default Category;

/*
            <div className='category_container'>
                <Link to={this.props.linksTo}>
                    <div>
                    <div className='category_picture_container'>
                         -- placeholder for picture --
                    </div>

                    <div className='category_headline_container'>
                        <h2 className='green_text'>{this.props.category}</h2>
                    </div>
                    </div>
                </Link>
            </div>

*/