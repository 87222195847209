import React from 'react';

const plans = [
    {
        id: 101,
        affiliate_company: 'NY 529',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_ny529.png',
        offer_summary: 'Offers low fees and diverse investment options and available to residents of any state. New York residents enjoy a state tax deduction for contributions to the plan.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>No minimum to open an account or to contribute</li>
                <li>Low fees - 0.13% total annual asset-based fee</li>
                <li>No advisor fees, commissions or account maintenance fees</li>
                <li>State tax deduction of $5,000/yr for single filers, $10,000/yr for joint filers</li>
                <li>Offers Age-Based investment plans, reducing portfolio's risk as child nears college years</li>
                <li>Top 10 performing 529 plans by SavingForCollege.com</li>
            </ul>
        </div>,
        offer_link: 'https://www.nysaves.org/home.html'
    },
    {
        id: 102,
        affiliate_company: 'Texas College Savings Plan',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_texas_college_savings_plan.png',
        offer_summary: 'This plan offers two age-based investment portfolios for you to choose from, or seven static options to match your investment preferences. If you don\'t have any preferences, the age-based portfolios are a great way to start.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>$25 minimum to open an account</li>
                <li>$25 minimum contributions</li>
                <li>Total annual asset-based fee range from 0.5902% - 0.9887% based on account owner choices</li>
                <li>Offers Age-Based investment plans, reducing portfolio's risk as child nears college years</li>
                <li>Top 10 performing 529 plans by SavingForCollege.com</li>
            </ul>
        </div>,
        offer_link: 'https://www.texascollegesavings.com/enroll/'
    },
    {
        id: 103,
        affiliate_company: 'Ohio\'s 529 Plan, CollegeAdvantage',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_ohio_collegeadvantage.png',
        offer_summary: 'With great reviews by SavingForCollege.com and a good tax deduction for instate residents, Ohio\'s CollegeAdvantage is a great option.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>No minimum to open an account or to contribute</li>
                <li>Low fees - total annual asset-based fee range from 0.155% - 0.445%  based on account owner choices</li>
                <li>No advisor fees, commissions or account maintenance fees</li>
                <li>State tax deduction of $4,000/yr for single filers and for joint filers</li>
                <li>Offers Age-Based investment plans, reducing portfolio's risk as child nears college years</li>
                <li>Top 10 performing 529 plans by SavingForCollege.com</li>
            </ul>
        </div>,
        offer_link: 'https://www.collegeadvantage.com/theplanthatcan'
    },
    {
        id: 104,
        affiliate_company: 'my529',
        affiliate_logo: 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_529_my529.svg',
        offer_summary: 'We love the modern user experience on their website, and we also like the low fees that let us keep more of our earnings! The my529 plan is officially Utah\'s plan but residency is not required.',
        expanded_info: 
        <div className='offer_expanded_info'>
            <ul>
                <li>User friendly website</li>
                <li>No minimum to open an account or to contribute</li>
                <li>Low fees - total annual asset-based fee range from 0.12% - 0.48%  based on account owner choices</li>
                <li>No advisor fees, commissions or account maintenance fees</li>
                <li>State tax deduction of 5% on up to $2,040/yr for single filers, or up to $4,080/yr for joint filers</li>
                <li>Offers Age-Based investment plans, reducing portfolio's risk as child nears college years</li>
                <li>Earned highest rating by Morningstar for the last 10 years</li>
            </ul>
        </div>,
        offer_link: 'https://my529.org/'
    },
    
]

export default plans;