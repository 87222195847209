import React from 'react';
import './Main_discover.css';

import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import GGApi from '../../../utils/GGApi.js';
import config from '../../../utils/config';

// import react components
import Dictionary_term from '../../discover_components/Dictionary_term/Dictionary_term';
import Carousel from '../../general_components/Carousel/Carousel';

// import dictionary terms
import terms from '../../discover_components/Dictionary_term/Terms';

// import images
import expecting_parents from '../../../images/illustrations/expecting_parents_100.png';
import new_parents from '../../../images/illustrations/new_parents_100.png';
import young_kids from '../../../images/illustrations/young_kids_100.png';
import teens from '../../../images/illustrations/teens_100.png';
import young_adults from '../../../images/illustrations/young_adults_100.png';

import plan_529 from '../../../images/icons/529_plan_50.png';
import investment_account from '../../../images/icons/investment_account_50.png';
import savings_account from '../../../images/icons/savings_account_50.png';
import piggy_banks from '../../../images/icons/piggy_bank_icon_50.png';
import allowance_and_chores from '../../../images/icons/allowance_and_chores_apps_50.png';

import trust_and_will from '../../../images/financial_institution_logos/Trust&Will_stacked.png';


// amplitude
import amplitude from 'amplitude-js';
import Articles_list from '../../discover_components/Articles/Articles_list/Articles_list';
import Offer_card from '../../general_components/Cards/Offer_card.js';
amplitude.getInstance().init(config.amp_key);

class Main_discover extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tokenIsValid: true,
            guest: '',
            
            // loading status for different items on page
            loading_1: false,

            // plans
            plans_carousel: [],
            plans: [],
            child: '',
            open_overlay_1: false,

            '529plans': [],
            'kidssavings': [],
            'investments': [],
            active: '',
            discover_slides: [],

        };
    }
    
     
    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        let validToken = '';
        // if there's a token, check that it's valid
        if (user) {
            GGApi.checkToken().then(response => {
                validToken = response;
                // if token is not valid, log out
                if (validToken === false) {
                    this.setState({tokenIsValid: false})

                } else if (validToken === true) {
                    // token is valid. set flow as signed in user
                    console.log('user validated. Discover as a user:');
                    this.setState({guest: false})
                };
            });
        
            // if there is no token, continue as a guest
        } else if (!user) {
            console.log('Discover as guest');
            this.setState({guest: true});
            this.props.removeNavBar();
        };
        

    }

    componentWillUnmount() {
        this.props.restoreNavBar();
    }

    //
    render () {
        return (
            <div className='home_background'>
                <Helmet>
                    <title>Discover Great Ways to Save and Invest for Kids | Greatest Gift</title>
                    <meta property="og:title" content={`Discover Great Ways to Save and Invest for Kids | Greatest Gift`}/>
                    <link rel="icon" href="/static/media/logo_var_2.3a7e8fdf.png" />
                    <meta name="description"
                        content='Discover great ways to save and invest for children and find answers to family finance questions. Discover our curated collection of financial products, guides and life-hacks.' 
                    />
                    <meta property="og:description" 
                        content='Discover great ways to save and invest for children and find answers to family finance questions. Discover our curated collection of financial products, guides and life-hacks.' 
                    />
                </Helmet>
                
                <div className='discover_header_buffer'></div>

                <div className='discover_banner'>
                    <h2 className='align_center'>Discover Family Finance Guides, Tools and Tips</h2>
                </div>
                
                <main className='discover_main'>

                {/* journey */}
                    <div className=''>
                        <Carousel 
                            //main_width_factor={window.innerWidth > 600 ? 0.1 : 0.33}

                            slides={
                                [
                                    {title: 'Expecting Parents',    image: expecting_parents, href: 'https://www.greatestgiftapp.com/financial-guides/expecting-parents'},
                                    {title: 'New Parents',          image: new_parents,       href: 'https://www.greatestgiftapp.com/financial-guides/new-parents'},
                                    {title: 'Young Kids',           image: young_kids,        href: 'https://www.greatestgiftapp.com/financial-guides/young-kids'},
                                    {title: 'Teens',                image: teens,             href: 'https://www.greatestgiftapp.com/financial-guides/teenagers'},
                                    {title: 'Young Adults',         image: young_adults,      href: 'https://www.greatestgiftapp.com/blog/how-to-make-money-as-a-kid'},

                                ].map(item => {
                                    return <a className='card small_card_in_carousel align_center' target='_blank' href={item.href}>
                                                <img src={item.image}/>
                                                <div>
                                                    <p className='bold no_margin'>{item.title}</p>
                                                </div>
                                            </a>
                                }).concat([<div></div>])
                               
                            }/>
                    </div>

                {/* product categories */}
                    <div className='discover_margin_wrapper'>
                        <h2 className='xl_text underline_full no_bottom_margin'>Tools & Products</h2>
                    </div>
                    <div className='margin_bottom_40'>
                        <Carousel 
                            //main_width_factor={window.innerWidth > 600 ? 0.1 : 0.33}

                            slides={
                                [
                                    {   title: 'Investment Accounts',
                                        image: investment_account, 
                                        href: 'https://www.greatestgiftapp.com/blog/best-utma-accounts'
                                    },
                                    {   title: '529 Plans: College Savings',
                                        image: plan_529, 
                                        href: '/discover/529plans'
                                        
                                    },
                                    {   title: 'Savings Accounts',
                                        image: savings_account, 
                                        href: 'https://www.greatestgiftapp.com/blog/best-savings-account-for-kids'
                                    },
                                    
                                    {   title: 'Piggy Banks',
                                        image: piggy_banks, 
                                        href: 'https://www.greatestgiftapp.com/blog/piggy-banks-for-kids'
                                    },

                                    {   title: 'Allowance & Chores Apps',
                                        image: allowance_and_chores, 
                                        href: 'https://www.greatestgiftapp.com/blog/best-chores-and-allowance-apps'
                                    }
                                ].map(item => {
                                    return <a className='card small_card_in_carousel align_center' href={item.href}  
                                    target="_blank" 
                                    rel="noopener noreferrer">
                                                <img src={item.image}/>
                                                <div>
                                                    <p className='bold no_margin'>{item.title}</p>
                                                </div>
                                            </a>
                                }).concat([<div></div>])
                               
                            }/>
                    </div>
                    
                    
                {/* top guides */}
                    <div className='discover_margin_wrapper'>
                        <h2 className='xl_text underline_full no_bottom_margin'>Top Guides</h2>
                    </div>
                    <div className='discover_margin_wrapper margin_bottom_40'>
                    <Articles_list />
                    </div>


                {/* dictionary */}
                    <div className='discover_margin_wrapper underline_full discover_double_header_wrapper'>
                        <h2 className='xl_text no_bottom_margin'>Financial Terms, Explained</h2>
                        <a className='no_bottom_margin gray' target='_blank' href='https://www.greatestgiftapp.com/personal-finance-for-parents-explained'>View All</a>
                    </div>
                    <div className=''>
                        <Carousel 
                            //main_width_factor={window.innerWidth > 600 ? 0.1 : 0.33}

                            slides={
                                [
                                    {   title: terms[6].term,
                                        image: terms[6].image, 
                                        href: 'https://www.greatestgiftapp.com/dictionary/' + terms[6].slug
                                    },
                                    {   title: terms[14].term,
                                        image: terms[14].image, 
                                        href: 'https://www.greatestgiftapp.com/dictionary/' + terms[14].slug
                                    },
                                    {   title: terms[5].term,
                                        image: terms[5].image, 
                                        href: 'https://www.greatestgiftapp.com/dictionary/' + terms[5].slug
                                    },
                                    {   title: terms[12].term,
                                        image: terms[12].image, 
                                        href: 'https://www.greatestgiftapp.com/dictionary/' + terms[12].slug
                                    },
                                    {   title: terms[0].term,
                                        image: terms[0].image, 
                                        href: 'https://www.greatestgiftapp.com/dictionary/' + terms[0].slug
                                    },
                                    {   title: terms[3].term,
                                        image: terms[3].image, 
                                        href: 'https://www.greatestgiftapp.com/dictionary/' + terms[3].slug
                                    }
                                ].map(item => {
                                    return <a className='card small_card_in_carousel align_center' target='_blank' href={item.href}>
                                                <img src={item.image}/>
                                                <div>
                                                    <p className='bold no_margin'>{item.title}</p>
                                                </div>
                                            </a>
                                }).concat([<div></div>])
                               
                            }/>
                    </div>
                    



                    <div className='discover_margin_wrapper'>
                        <Dictionary_term />
                    </div>

                {/* wills offer */}
                <Offer_card 
                    imageSrc = {trust_and_will}
                    promoCode = "EXCLUSIVE10"
                    offers = {[
                        {
                            url: 'https://trustandwill.sjv.io/c/2698189/959162/11883',
                            text1: 'Make a Will online in minutes.',
                            text2: 'Make sure your children and loved ones are taken care of.'
                        },
                        {
                            url: 'https://trustandwill.sjv.io/c/2698189/959162/11883',
                            text1: 'Make a Will online in minutes.',
                            text2: 'Name a guardian for your kids, organize your assets.'
                        },
                        {
                            url: 'https://trustandwill.sjv.io/c/2698189/959164/11883',
                            text1: 'Make a Will online in minutes.',
                            text2: 'Not sure where to start? Take the quiz and determine which estate plan is best for you.'
                        }
                    ]}
                />

                <br></br>
                <br></br>
                <br></br>
                <br></br>


                </main> 
                
            </div>
        );
    }
}

export default Main_discover;