import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';

import logo_var_2 from '../../../images/logos/gg_logo_icon no_margin.svg';

class Header extends React.Component {
    constructor(props) {
        super(props);
        
        this.getHeaderText = this.getHeaderText.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
    };

    getHeaderText() {
        let flow_type = JSON.parse(localStorage.getItem('oauth_flow'));

        if (this.props.match.path === '/gifts') {
            return 'Gift'
        } else if (this.props.match.path === '/receive' ) {
            return 'Receive'
        } else if (this.props.match.path === '/plan') {
            return 'Plan'
        } else if (this.props.match.path === '/discover') {
            return 'Discover'
        } else if (this.props.match.path === '/receive/:id') {
            return 'Redeem Gift'
        } else if (this.props.match.path === '/receive/:id/thank_you') {
            return 'Thank You'
        } else if (this.props.match.path === '/plan/:plan_id') {
            return 'Plan'
        } else if (this.props.match.path === '/plan/:plan_id/gifting_page') {
            return 'Gifting Page'
        } else if (this.props.match.path === '/discover/*') {
            return 'Discover'
        } else if (this.props.match.path === '/settings/*' || this.props.match.path === '/settings/reset/:t' ) {
            return 'Settings'
        } else if (this.props.match.path === '/user_id/linked-accounts') {
            return 'Linked Accounts'
        } else if (this.props.match.path === '/login/verify') {
            return 'Login Verification'
        } else if (this.props.match.path === '/oauth_return' && flow_type === 'destination') {
            return 'Link Account'
        } ;
    }


    handleBackButton(){
        if (this.props.match.path === '/receive/:id/thank_you') {
            window.location.href = `/receive/${this.props.match.params.id}`; 
            return
        } else if (this.props.match.path === '/receive/:id') {
            window.location.href = `/receive`; 
            return
        } else if (this.props.match.path === '/discover/*') {
            window.location.href = `/discover`; 
            return
        } else if (this.props.match.path === '/plan/:plan_id/gifting_page') {
            window.location.href = `/plan/${this.props.match.params.plan_id}`; 
            return
        } else if (this.props.match.path === '/plan/:plan_id') {
            window.location.href = `/plan`; 
            return
        } else {
            this.props.history.goBack()
            return;
        }
    }

    render () {
        let user = JSON.parse(localStorage.getItem('user'));
        return (
            <header>
                <div className='header_container'>
                    
                    {this.props.backButton === true ?
                    <i  className='material-icons' 
                    onClick={this.handleBackButton} >arrow_back_ios</i>  
                    :
                    <Link to={user ? '/home' : '/'}>
                        <img src={logo_var_2} className='header_logo'/>
                    </Link>
                    }
                    
                    <h1>{this.getHeaderText()}</h1>
                    
                    {user ? 
                    <div className='background_for_icon' onClick={this.props.toggleMenu}>
                        <i className='material-icons-outlined person_icon'>person</i>  
                    </div> 
                    : <div className=''> </div>
                    }
                </div>
            </header>
            );
    }
}

export default Header;