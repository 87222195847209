import React from 'react';
import './Gifting_step_2.css';

import {Helmet} from "react-helmet";
import MasterButton from '../../../general_components/MasterButton/MasterButton.js';

import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
//import {  utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { toZonedTime, toDate } from 'date-fns-tz';


import calculator from '../../../../utils/calculator.js';
import dates from '../../../../utils/dates';


import image_1 from '../../../../images/illustrations/growing_gift.png';
import Dropdown_input from '../../../general_components/Dropdown_input/Dropdown_input';

import config from '../../../../utils/config.js'
import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);
const oneYearFromToday = new Date();
oneYearFromToday.setFullYear(oneYearFromToday.getFullYear() + 1);

class Gifting_step_2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Gift_delivery_time: '',
            timezone: {id: 1, title: "Eastern Time", tz: "America/New_York" },
            time_options: [
                {id: 8001, title: '08:00 AM'},
                {id: 8301, title: '08:30 AM'},
                {id: 9001, title: '09:00 AM'},
                {id: 9301, title: '09:30 AM'},
                {id: 10001, title: '10:00 AM'},

                {id: 10301, title: '10:30 AM'},
                {id: 11001, title: '11:00 AM'},
                {id: 11301, title: '11:30 AM'},
                {id: 12002, title: '12:00 PM'},
                {id: 12302, title: '12:30 PM'},

                {id: 1002, title: '01:00 PM'},
                {id: 1302, title: '01:30 PM'},
                {id: 2002, title: '02:00 PM'},
                {id: 2302, title: '02:30 PM'},
                {id: 3002, title: '03:00 PM'},

                {id: 3302, title: '03:30 PM'},
                {id: 4002, title: '04:00 PM'},
                {id: 4302, title: '04:30 PM'},
                {id: 5002, title: '05:00 PM'},
                {id: 5302, title: '05:30 PM'},
                
                {id: 6002, title: '06:00 PM'},
                {id: 6302, title: '06:30 PM'},
                {id: 7002, title: '07:00 PM'},
                {id: 7302, title: '07:30 PM'},
                {id: 8002, title: '08:00 PM'},

                {id: 8302, title: '08:30 PM'},
                {id: 9002, title: '09:00 PM'},
                {id: 9302, title: '09:30 PM'},
                {id: 10002, title: '10:00 PM'},
                {id: 10302, title: '10:30 PM'},

                {id: 11002, title: '11:00 PM'},
                {id: 11302, title: '11:30 PM'},
                {id: 12001, title: '12:00 AM'},
                {id: 12301, title: '12:30 AM'},
                {id: 1001, title: '01:00 AM'},

                {id: 1301, title: '01:30 AM'},
                {id: 2001, title: '02:00 AM'},
                {id: 2301, title: '02:30 AM'},
                {id: 3001, title: '03:00 AM'},
                {id: 3301, title: '03:30 AM'},

                {id: 4001, title: '04:00 AM'},
                {id: 4301, title: '04:30 AM'},
                {id: 5001, title: '05:00 AM'},
                {id: 5301, title: '05:30 AM'},
                {id: 6001, title: '06:00 AM'},

                {id: 6301, title: '06:30 AM'},
                {id: 7001, title: '07:00 AM'},
                {id: 7301, title: '07:30 AM'},
            ],
            todays_times: [
                {id: 8001, title: '08:00 AM'},
                {id: 8301, title: '08:30 AM'},
                {id: 9001, title: '09:00 AM'},
                {id: 9301, title: '09:30 AM'},
                {id: 10001, title: '10:00 AM'},

                {id: 10301, title: '10:30 AM'},
                {id: 11001, title: '11:00 AM'},
                {id: 11301, title: '11:30 AM'},
                {id: 12002, title: '12:00 PM'},
                {id: 12302, title: '12:30 PM'},

                {id: 1002, title: '01:00 PM'},
                {id: 1302, title: '01:30 PM'},
                {id: 2002, title: '02:00 PM'},
                {id: 2302, title: '02:30 PM'},
                {id: 3002, title: '03:00 PM'},

                {id: 3302, title: '03:30 PM'},
                {id: 4002, title: '04:00 PM'},
                {id: 4302, title: '04:30 PM'},
                {id: 5002, title: '05:00 PM'},
                {id: 5302, title: '05:30 PM'},
                
                {id: 6002, title: '06:00 PM'},
                {id: 6302, title: '06:30 PM'},
                {id: 7002, title: '07:00 PM'},
                {id: 7302, title: '07:30 PM'},
                {id: 8002, title: '08:00 PM'},

                {id: 8302, title: '08:30 PM'},
                {id: 9002, title: '09:00 PM'},
                {id: 9302, title: '09:30 PM'},
                {id: 10002, title: '10:00 PM'},
                {id: 10302, title: '10:30 PM'},

                {id: 11002, title: '11:00 PM'},
                {id: 11302, title: '11:30 PM'},
                {id: 12001, title: '12:00 AM'},
                {id: 12301, title: '12:30 AM'},
                {id: 1001, title: '01:00 AM'},

                {id: 1301, title: '01:30 AM'},
                {id: 2001, title: '02:00 AM'},
                {id: 2301, title: '02:30 AM'},
                {id: 3001, title: '03:00 AM'},
                {id: 3301, title: '03:30 AM'},

                {id: 4001, title: '04:00 AM'},
                {id: 4301, title: '04:30 AM'},
                {id: 5001, title: '05:00 AM'},
                {id: 5301, title: '05:30 AM'},
                {id: 6001, title: '06:00 AM'},

                {id: 6301, title: '06:30 AM'},
                {id: 7001, title: '07:00 AM'},
                {id: 7301, title: '07:30 AM'},
            ],
            date: '',
            formatted_date: '',
            no_options: false
        }


        this.handleDateChange = this.handleDateChange.bind(this);
        this.getTodaysTimes = this.getTodaysTimes.bind(this);
        this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
        this.handleTimeClick = this.handleTimeClick.bind(this);
        this.checkTimezoneNextDay = this.checkTimezoneNextDay.bind(this);

    }

    componentDidMount(){
        if (dates.current_time() >= '23:30:00') {
            this.setState({no_options: true})
        } else {
            this.setState({no_options: false})
        }
        this.setState({todays_times: this.getTodaysTimes()});
    }

    handleDateChange(date) {
        let check_next_day = this.checkTimezoneNextDay(this.props.Gift_delivery_time, this.props.Gift_delivery_shown_time);
        if(check_next_day === 1){
            // set dates differently
            this.props.set_date_plus_one(date);
        } else {
            // set dates regularly
            this.props.set_date(date);
        }
    }

    getTodaysTimes(){        
        let todaysTimes;
        const currentESTTime = toDate(new Date(), 'America/New_York');

        // Convert current time to selected timezone
        const currentTimeInSelectedTZ = toZonedTime(currentESTTime, this.state.timezone.tz);
        const currentTimeStr = format(currentTimeInSelectedTZ, 'HH:mm:ss');
        if (currentTimeStr >= '08:00:00' && currentTimeStr < '23:30:00') {
            let index = this.state.time_options.findIndex(item =>
                dates.militarizeTime(item.title) > currentTimeStr
            );
            todaysTimes = this.state.time_options.slice(index + 1, 32);
            //console.log('1', todaysTimes, index);
            return todaysTimes;

        } else if (currentTimeStr < '08:00:00') {
            //todaysTimes = this.state.time_options.slice(0, 32);
            let index = this.state.time_options.findIndex(item => 
                    (dates.militarizeTime(item.title) > currentTimeStr) 
                    &&
                    dates.militarizeTime(item.title) < '08:00:00'
                )
            todaysTimes = this.state.time_options.slice(index).concat(this.state.time_options.slice(0, 32));
            //console.log('2', todaysTimes);
            return todaysTimes;

        } else if (currentTimeStr >= '23:30:00'){
            todaysTimes = this.state.time_options.slice(32).concat(this.state.time_options.slice(0, 32));
            return todaysTimes;
        }
    }

    handleTimezoneChange(selection) {
        //console.log('new timezone selected: ', selection.title)
        this.setState({ timezone: selection }, () => {
          //  console.log('updated state ')
            this.setState({todays_times: this.getTodaysTimes()});
            if(this.props.Gift_delivery_shown_date === dates.today()){
                //console.log('date is today, reset selected hour ')
                this.props.handleHourSelection('', '');
            } else if(this.props.Gift_delivery_shown_time){
                //console.log('update the time in parent component')
                this.handleTimeClick(this.props.Gift_delivery_shown_time)
            }
            this.props.update_timezone(selection);
        });
    }

    handleTimeClick(selectedTime){
        
        //console.log('update selected time to: ', selectedTime)
        // Find the index of the selected time in the time_options array
        const selectedIndex = this.state.time_options.findIndex(item => item.title === selectedTime.title);

        //console.log('found index: ', selectedIndex)
        // Adjust the index based on the selected timezone
        let adjustedIndex = selectedIndex;
        if (this.state.timezone.title === "Central Time") {
            adjustedIndex += 2;
        } else if (this.state.timezone.title === "Mountain Time") {
            adjustedIndex += 4;
        } else if (this.state.timezone.title === "Pacific Time") {
            adjustedIndex += 6;
        }
        //console.log('new index', adjustedIndex);

        // Wrap around if the index is negative
        if (adjustedIndex < 0) {
            adjustedIndex = this.state.time_options.length + adjustedIndex;
        }

        if (adjustedIndex > this.state.time_options.length) {
            adjustedIndex =  adjustedIndex - this.state.time_options.length;
        }

        // Find the corresponding time in Eastern Time
        const matchingESTItem = this.state.time_options[adjustedIndex];

        // Send the matching EST item to the parent component
        if (matchingESTItem) {
            this.props.handleHourSelection(matchingESTItem, selectedTime);
        }

        let check_next_day = this.checkTimezoneNextDay(matchingESTItem, selectedTime)
        if(this.props.Gift_delivery_date && this.props.Gift_delivery_shown_date){
            if(check_next_day === 1){
                this.props.move_date_by_one();
            } else if (check_next_day === 0){
                this.props.set_dates_same();
            }
        }
        
    }

    checkTimezoneNextDay(eastern, timezone){
        // console.log('check next day adjustment');

        if(eastern && timezone){

            let timezone_time = dates.militarizeTime(timezone.title)
            let eastern_time = dates.militarizeTime(eastern.title);
            
            // console.log('checking times: ', timezone_time, eastern_time)
            // console.log(timezone_time > "20:00:00")
            // console.log(eastern_time < "04:00:00")

            if (timezone_time > "20:00:00" && eastern_time < "04:00:00"){
                console.log('we need to move the date by 1');
                // move the real delivery date up by 1, if it is set...
                //this.props.move_date_by_one();
                return 1;
            } else if (// else... see if the props date fields match, if not need to set them to be the same!
                this.props.Gift_delivery_date && 
                this.props.Gift_delivery_shown_date && 
                this.props.Gift_delivery_date !== this.props.Gift_delivery_shown_date){
                    
                    //console.log('we need to set the dates as same');
                    //this.props.set_dates_same();
                    return 0;
            } else {
                return 2;
            }
        }

    }

    render () {   
        if (this.props.currentStep !== 2) {
            return null;
        };
        return (
           <div>
               
                <h2>Gift Details</h2>
        
                <form className='margin_bottom_40'>
                    <p className='form_label'>Gift amount:</p>
                    
                    <div className='input_buttons_container amount_input_button_container'>
                        <p  onClick={() => {this.props.set_amount('$20'); amplitude.getInstance().logEvent('gifting: select gift amount', {'gift amount': '20'});}}
                            className={this.props.Gift_amount === '$20' ? 'input_button input_button_selected large_text amount_input_button' : 'input_button  large_text amount_input_button'}>
                                $20
                        </p>
                        <p  onClick={() => {this.props.set_amount('$50'); amplitude.getInstance().logEvent('gifting: select gift amount', {'gift amount': '50'});}}
                            className={this.props.Gift_amount === '$50' ? 'input_button input_button_selected large_text amount_input_button' : 'input_button  large_text amount_input_button'}>
                                $50
                        </p>
                        <p  onClick={() => {this.props.set_amount('$100'); amplitude.getInstance().logEvent('gifting: select gift amount', {'gift amount': '100'});}}
                            className={this.props.Gift_amount === '$100' ? 'input_button input_button_selected large_text amount_input_button' : 'input_button  large_text amount_input_button'}>
                                $100
                        </p>
                        <p  onClick={() => {this.props.set_amount('$180'); amplitude.getInstance().logEvent('gifting: select gift amount', {'gift amount': '180'});}}
                            className={this.props.Gift_amount === '$180' ? 'input_button input_button_selected large_text amount_input_button' : 'input_button  large_text amount_input_button'}>
                                $180
                        </p>
                        <p  onClick={() => {this.props.set_amount('$250'); amplitude.getInstance().logEvent('gifting: select gift amount', {'gift amount': '250'});}}
                            className={this.props.Gift_amount === '$250' ? 'input_button input_button_selected large_text amount_input_button' : 'input_button  large_text amount_input_button'}>
                                $250
                        </p>
                        {/*
                        <p  onClick={() => this.props.set_amount('$360')}
                            className={this.props.Gift_amount === '$360' ? 'input_button input_button_selected large_text amount_input_button' : 'input_button  large_text amount_input_button'}>
                                $360
                        </p>
                        */}
                    <input 
                        type='text' 
                        id='other_gift_amount' 
                        onChange={this.props.handleChange}
                        onBlur={this.props.handleDollarInput}
                        onClick={this.props.handleChange}
                        name='other_gift_amount' 
                        placeholder='Other' 
                        value={this.props.other_gift_amount}
                        autoComplete='off'
                        maxLength={this.props.other_gift_amount[0] === '$' ? 5 : 4}
                        className={ this.props.other_gift_amount && this.props.Gift_amount === this.props.other_gift_amount ? 'input_button input_button_selected large_text amount_input_button' : 'input_button  large_text amount_input_button'}
                        />

                    <input 
                        style={{display: 'none'}}
                        type='text' 
                        id='gift_amount' 
                        onChange={this.props.handleChange}
                        onBlur={this.props.handleDollarInput}
                        name='Gift_amount' 
                        placeholder='Value' 
                        value={this.props.Gift_amount}
                        autoFocus
                        autoComplete='off'
                        maxLength={this.props.Gift_amount[0] === '$' ? 5 : 4}
                        className={ this.props.Gift_amount ? 'input_button input_button_selected large_text amount_input_button' : 'input_button  large_text amount_input_button'}
                        />

                    </div>
                    

                    <p className='form_label margin_top_24'>Schedule gift delivery:</p>
                    
                    <div className='horizontal_radio_container'>
                        
                        <div className='pointer' onClick={() => this.props.handleRadioClick('later')}>
                            <input 
                                type='radio' 
                                id='deliver_later' 
                                name='schedule_delivery' 
                                value='later' 
                                onChange={this.props.handleChange}
                                checked={this.props.schedule_delivery === 'later'}
                                className='pointer'
                                /> 
                            <p className='form_radio_label pointer'>Later</p>
                        </div>

                        <div className='pointer' onClick={() => this.props.handleRadioClick('now')}>
                            <input 
                                type='radio' 
                                id='deliver_now' 
                                name='schedule_delivery' 
                                value='now' 
                                onChange={this.props.handleChange}
                                checked={this.props.schedule_delivery === 'now'}/> 
                            <p className='form_radio_label'>Now</p>
                        </div>

                       


                    </div>
                    
                    {this.props.schedule_delivery === 'later' ? 

                    <div>
                        <p className='form_label'>Delivery date:</p>

                        <DatePicker 
                            selected={this.props.Gift_delivery_shown_date} 
                            onChange={(date) => {
                                this.setState({date: date});
                                if(date){
                                        this.props.handleDatePicker(format(date, 'MM/dd/yyyy'), this.checkTimezoneNextDay(this.props.Gift_delivery_time, this.props.Gift_delivery_shown_time));
                                    } else {
                                        this.setState({formatted_date: ""})
                                    }
                                }
                            } 
                            showIcon
                            toggleCalendarOnIconClick

                            minDate={dates.today()}
                            maxDate={oneYearFromToday}


                            placeholderText="MM / DD / YYYY"
                        />

                        <div className='input_buttons_container'>
                            
                            <p  onClick={() => {this.handleDateChange(dates.today());}}
                                className={this.props.Gift_delivery_shown_date === dates.today() ? 'input_button input_button_selected small_text' : 'input_button  small_text'}>
                                    Today
                            </p>

                            <p  onClick={() => this.handleDateChange(dates.tomorrow())}
                                className={this.props.Gift_delivery_shown_date === dates.tomorrow() ? 'input_button input_button_selected small_text' : 'input_button  small_text'}>
                                    Tomorrow 
                            </p>
                            
                            <p  onClick={() => this.handleDateChange(dates.nextWeek())}
                                className={this.props.Gift_delivery_shown_date === dates.nextWeek() ? 'input_button input_button_selected small_text' : 'input_button  small_text'}>
                                    {dates.dayOfWeek(dates.nextWeek())} {dates.nextWeek()}
                            </p>


                        </div>

                        <p className='form_label'>Gift delivery time:</p> 

                        <div className='multi_dropdown_container'>
                        
                        <div className='multi_dropdown_single'>

                        
                        <Dropdown_input
                            dd_name='gift_delivery_time'

                            header={this.state.no_options && this.props.Gift_delivery_shown_date === dates.today() ? 'Please select a different date' : 'Select time' }
                            list={
                                !(this.props.Gift_delivery_shown_date === dates.today()) ? 
                                this.state.time_options :
                                
                                this.state.todays_times
                                }

                            handleItemClick={item => this.handleTimeClick(item)}

                            selected={this.props.Gift_delivery_shown_time}
                            
                            highlight={false}
                            
                            lastItem={false}
                            active={this.state.no_options && this.props.Gift_delivery_shown_date === dates.today() ? false : true}
                        />
                    </div>
                    <div className='multi_dropdown_single'>
                    <Dropdown_input
                            dd_name='delivery_timezone'

                            header={'Select timezone'}
                            
                            list={
                                [
                                    {id: 1, title: "Eastern Time", tz: "America/New_York" },
                                    { id: 2, title: "Central Time", tz: "America/Chicago" },
                                    { id: 3, title: "Mountain Time", tz: "America/Denver" },
                                    { id: 4, title: "Pacific Time", tz: "America/Los_Angeles" }
                                ]
                                }

                            handleItemClick={this.handleTimezoneChange}

                            selected={this.state.timezone}
                            
                            highlight={false}
                            
                            lastItem={false}
                            active={true}
                        />
                    </div>


</div>

                    </div>

                    : null}

                    
                </form>
                




                <div className='gifting_flow_image_container'>
                    <img src={image_1}/>
                <p>When invested tax free, your gift could grow into <span className='money'>${calculator.compound(this.props.Gift_amount, 18, 0.07)}</span> in 18 years</p>
                </div>

                <MasterButton 
                    buttonText={this.props.buttonText} 
                    handleButtonClick={this.props.handleButtonClick}
                    notSticky={true}
                />

                


           </div>
        );
    }
}

export default Gifting_step_2;