import React from 'react';
import './Articles_list.css';

import Article_list_item from './Articles_list_item/Articles_list_item.js';

import articles from '../Articles.js';

class Articles_list extends React.Component {
    constructor(props) {
        super(props);

        this.renderArticlesList = this.renderArticlesList.bind(this);

        this.state = {
            articles: []
        }
    }
    
    componentDidMount() {
        //api to load articles from db
        this.setState({articles: articles});
    }

    renderArticlesList(){
        return this.state.articles.map(article => {
            return <Article_list_item 
                        headline={article.article_name}
                        preview={article.preview}
                        picture_url={article.preview_image}
                        link={article.url}
                        key={article.id}
                        /> ;
            });
    }
    
    render () {
        if(this.props.active_header === 'offers') {
            //return null;
        };
        
        return (
            <div className='articles_wrapper'>
                {this.renderArticlesList()}
            </div>
        );
    }



    
}

export default Articles_list;