import { addYears, format, isBefore, isAfter, setMonth, setDate, subMonths } from 'date-fns';

const timeline_milestones = (birthday) => {

  const currentYear = new Date().getFullYear();  
  const currentDate = new Date();
  const birthDate = new Date(birthday);
  const oneYearFromNow = addYears(currentDate, 1);
  const sixMonthsAgo = subMonths(currentDate, 6);
  const sixMonthsFromNow = addYears(currentDate, 0.5);

  const milestones = [];

  if (isAfter(currentDate, birthDate)) {
    // The birthday is in the past or today, so we calculate based on an actual birthdate.

    // Handle the most recent birthday
    let lastBirthday = new Date(currentDate.getFullYear(), birthDate.getMonth(), birthDate.getDate());

    // If the birthday this year hasn't happened yet, subtract a year
    if (isBefore(currentDate, lastBirthday)) {
      lastBirthday.setFullYear(lastBirthday.getFullYear() - 1);
    }


    // Last birthday
    const lastBirthdayNumber = lastBirthday.getFullYear() - birthDate.getFullYear();

    if (isAfter(lastBirthday, sixMonthsAgo) && isBefore(lastBirthday, sixMonthsFromNow)) {
      milestones.push({
        milestone: lastBirthdayNumber === 0 ? 'Birthdate' : 'Last Birthday',
        date: format(lastBirthday, 'yyyy-MM-dd'),
        type: 'birthday',
        birthdayNumber: lastBirthdayNumber,
      });
    }
    
    // Next birthday
    const nextBirthday = addYears(lastBirthday, 1);
    const nextBirthdayNumber = lastBirthdayNumber + 1;

    if (isAfter(nextBirthday, sixMonthsAgo) && isBefore(nextBirthday, sixMonthsFromNow)) {
      milestones.push({
        milestone: 'Next Birthday',
        date: format(nextBirthday, 'yyyy-MM-dd'),
        type: 'birthday',
        birthdayNumber: nextBirthdayNumber,
      });
    }


  } else {
    // The birthday is in the future (due date scenario)
    milestones.push({
      milestone: 'Expected Birthdate',
      date: format(birthDate, 'yyyy-MM-dd'),
      type: 'birthday',
      birthdayNumber: 0,
    });

    /*
    // First birthday after the expected birthdate
    const firstBirthday = addYears(birthDate, 1);
    milestones.push({
      milestone: 'First Birthday',
      date: format(firstBirthday, 'yyyy-MM-dd'),
      type: 'birthday',
      birthdayNumber: 1,
    });

    */
  }

  // School milestones
  const schoolMilestones = [
    { milestone: 'First Day of Kindergarten', age: 5, type: 'school' },
    { milestone: 'First Day of 1st Grade', age: 6, type: 'school' },
    { milestone: 'First Day of 5th Grade', age: 10, type: 'school' },
    { milestone: 'First Day of 9th Grade', age: 14, type: 'school' },
    { milestone: 'High School Graduation', age: 18, type: 'school' },
  ];

  const graduationMilestones = [
    { milestone: 'Kindergarten Graduation', age: 5, type: 'school', graduationAge: 6 },
    { milestone: 'Elementary School Graduation', age: 10, type: 'school', graduationAge: 11 },
    { milestone: 'Middle School Graduation', age: 14, type: 'school', graduationAge: 15 },
  ];

  schoolMilestones.forEach(({ milestone, age, type }) => {
    
    let milestoneDate = addYears(birthDate, age);

    // Set school start dates to September 1st
    milestoneDate = setMonth(milestoneDate, 8); // September (0-indexed, so 8 = September)
    milestoneDate = setDate(milestoneDate, 1);  // 1st day of September

    const milestoneYear = milestoneDate.getFullYear();

    // Check the child's age as of August 31st of the milestone year
    const augustBefore = subMonths(milestoneDate, 1);
    const ageAsOfAugust31 = augustBefore.getFullYear() - birthDate.getFullYear();

    if (ageAsOfAugust31 === age) {
      
      // Only include school milestones within the current or next year
      if (isAfter(milestoneDate, currentDate) && isBefore(milestoneDate, sixMonthsFromNow)) {
        milestones.push({ milestone, date: format(milestoneDate, 'yyyy-MM-dd'), type });
      }    
    }
  });

  graduationMilestones.forEach(({ milestone, age, type, graduationAge }) => {
    let graduationDate = addYears(birthDate, graduationAge);

    // Set graduation dates to June 30th
    graduationDate = setMonth(graduationDate, 5); // June (0-indexed, so 5 = June)
    graduationDate = setDate(graduationDate, 30);  // 30th of June

    const graduationYear = graduationDate.getFullYear();

    // Check the child's age as of August 31st of the graduation year
    const augustBefore = subMonths(graduationDate, 1);
    const ageAsOfAugust31 = augustBefore.getFullYear() - birthDate.getFullYear();

    if (ageAsOfAugust31 === age) {
      // Only include graduation milestones within the next year
      if (isAfter(graduationDate, currentDate) && isBefore(graduationDate, sixMonthsFromNow)) {
        milestones.push({ milestone, date: format(graduationDate, 'yyyy-MM-dd'), type });
      }  
    }
  });


  return milestones;
};

export default timeline_milestones;