import React from 'react';
import GGApi from '../../../utils/GGApi';
import './Pic_selector.css';

import camera from '../../../images/icons/camera.png';

class Pic_selector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null
        };

        this.fileUploadHandler = this.fileUploadHandler.bind(this);
    }

    fileUploadHandler(){
        let file = this.state.file;
        console.log(file);
        GGApi.upload_media(file, 'greetings-media').
        then(res => {
            console.log(res);
        })
    }

    render () {
        return (
        <div className='margin_bottom'>
            <p className='form_label'>{this.props.label}</p>
            <div className='picture_upload_wrapper'
                style={this.props.file ? {border: 'none', cursor: 'auto'} : null }
                onClick={() => this.props.file ? null : this.fileInput.click()}>
                
                <img 
                    src={this.props.file ? this.props.uploaded_file ? this.props.file  :  URL.createObjectURL(this.props.file) : camera}
                    className={
                        this.props.file ? 
                        this.props.custom_height === "200-420" ?
                        'uploaded_picture_preview uploaded_picture_preview_200-420' 
                        :
                        'uploaded_picture_preview' 
                        : 
                        'upload_picture_button'}
                    style={this.props.file ? {border: '1px solid rgba(0,0,0,0.12)', cursor: 'pointer'} : null }
                    onClick={() => this.props.file ?  this.fileInput.click() : null}
                    />

            </div>
            
            { this.props.uploaded_file ?
                <p style={{margin: '0'}  } className='green_text align_center cursor' onClick={() => this.fileInput.click()}>Change Picture</p> 
                : null
            }

            <input type="file" 
                onChange={this.props.handlePicChange} 
                accept='image/png, image/jpeg, image/jpg'
                style={{display: 'none'}}
                ref={fileInput => this.fileInput = fileInput}
                //capture='camera'
                />

            {/*<button onClick={this.fileUploadHandler}>Upload media</button>*/}
        </div>
        );
    }
}

export default Pic_selector;