import React from 'react';
import './Article_page.css';

import {Helmet} from "react-helmet";

import GGApi from '../../../../utils/GGApi.js';
import Article_section from './Article_section';

import articles from '../Articles.js';

import image_1 from '../../../../images/offer_category/529.jpg'
import GG_loader from '../../../general_components/GG_loader/GG_loader';
import Articles_list_item from '../Articles_list/Articles_list_item/Articles_list_item';
import Article_body from './Article_body';
import logo_var_2 from '../../../../images/logos/logo_var_2.png';
import Home_Page_Footer from '../../../Landing_page/Home_Page_Footer';


import config from '../../../../utils/config';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);

class Article_page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            article: {},
            next_article: {}
        }

        this.renderSections = this.renderSections.bind(this);
        this.renderNextArticle = this.renderNextArticle.bind(this);
        this.renderArticleMenu = this.renderArticleMenu.bind(this);
    }

    componentDidMount() {
        console.log('mounted');
        // check if user or guest:
        let user = JSON.parse(localStorage.getItem('user'));
        let validToken = '';
        // if there's a token, check that it's valid
        if (user) {
            GGApi.checkToken().then(response => {
                validToken = response;
                // if token is not valid, log out
                if (validToken === false) {
                    this.setState({tokenIsValid: false})

                } else if (validToken === true) {
                    // token is valid. set flow as signed in user
                    console.log('user validated. Discover as a user:');
                    this.setState({guest: false})
                };
            });
        
            // if there is no token, continue as a guest
        } else if (!user) {
            console.log('Discover as guest');
            this.setState({guest: true});
            this.props.removeNavBar();
        };

        // api to load article from db:
        // in the gg db, query and search for the article with the name that matches the article name in this.props.match.params.article_name
        let index = articles.findIndex(article => article.article_name === this.props.match.params.article_name.split('-').join(' '));
        if(index < 0 ){
            this.setState({article: null, loading: false})
        } else {
            let next_index = articles.findIndex(article => article.article_name === articles[index].next_article);
            this.setState({article: articles[index], next_article: articles[next_index], loading: false})
            //amplitude
            amplitude.getInstance().logEvent('discover: view article', {'article': articles[index].article_name});
        };
        
    }
    
    renderArticleMenu(){
        if(!this.state.article) {
            return null;
        }
        let j = 0;
        let unordered_list = this.state.article.body.map(section => {
            j++;
            return <li key={j}><a href={'#'+section.header}>{section.header}</a></li>
        });
        return (
            <div>
                <p>Contents:</p>
                <ul> 
                    {unordered_list}
                </ul>
            </div>
            
        )
    }

    renderSections(){
        if(!this.state.article) {
            return null;
        }
        let i = 0;
        return this.state.article.body.map(section => {
            i++;
            return (
                <Article_section 
                    section_header={section.header}
                    paragraphs={section.paragraphs}
                    Ol={section.ol}
                    Ul={section.ul}
                    image={section.imageUrl}
                    joker={section.joker}
                    key={i}
                />
            )    
        });
    }

    renderNextArticle(){
        return (
            <Articles_list_item 
                headline={this.state.next_article.article_name}
                preview={this.state.next_article.preview}
                picture_url={this.state.next_article.preview_image}
                link={'/discover/articles/'+this.state.next_article.article_name.split(' ').join('-')}
                key={this.state.next_article.id}
            /> 
        );
    }

    render () {
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        } 
        if(!this.state.article) {
            return (
                <main>
                    <h1>We're sorry, but the page you are trying to locate is unavailable.</h1>
                </main>
            )
        }

        return (
            <div>
            <main>
                <Helmet>
                    <title>{this.state.article.article_name} | Greatest Gift</title>
                    <meta name="description"
                        content={this.state.article.article_name + ' | ' + this.state.article.preview} 
                    />
                    <meta property="og:description" 
                        content={this.state.article.article_name + ' | ' + this.state.article.preview}  
                    />
                    <meta property="og:title" content={this.state.article.article_name +' | Greatest Gift'}/>
                </Helmet>
                {this.state.article.cover_image ? 
                    <div className='article_header_cover_img_container'>
                        <img className='article_header_cover_img' src={this.state.article.cover_image}></img>
                    </div>
                : null}
                
                
                <div>
                    <h1>{this.state.article.article_name}</h1>
                    <h5 className='article_publish_date'>Published {this.state.article.publish_date}</h5>
                </div>
                
                <Article_body article_name={this.state.article.article_name}/>
                    

                <div className='article_bio_container'>
                    <p className='small_text'>By {this.state.article.author}</p>
                    <p className='small_text italics'>{this.state.article.author_bio}</p>
                </div>
            
                {   this.state.next_article ? 
                    <div className='article_footer_container'>
                        <div>
                            <p className='small_text bold article_up_next'>Up Next...</p>
                            {this.renderNextArticle()}
                        </div>
                    </div>
                : null }

                <div className='article_footer_logo_container'>
                    <a href='https://www.greatestgiftapp.com'>
                        <img className='article_footer_logo' src={logo_var_2}></img>
                    </a>
                </div>
                

                <div className='buffer'></div>
            </main>

            {this.state.guest ? 
            <Home_Page_Footer /> : null}
            </div>
        );
    }
}

export default Article_page;