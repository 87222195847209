import React from 'react';
import './Forgot_password.css';

import GGApi from '../../../utils/GGApi.js';

import GG_loader from '../../general_components/GG_loader/GG_loader.js';
import MasterButton from '../../general_components/MasterButton/MasterButton.js';
import { Helmet } from 'react-helmet';


function delay(time){
    return new Promise(resolve => setTimeout(resolve, time));
};

class Forgot_password extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            buttonActive: true,
            email: '',
            server_message: '',
            show_message: false,
            show_null_error: false
        }

        this.sendPwReset = this.sendPwReset.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
    }

    sendPwReset(){
        if(!this.state.buttonActive) {
            return;
        }
        if(this.state.email === '') {
            this.setState({show_null_error: true});
        } else {
            this.setState({loading: true});
            GGApi.reset_password_email(this.state.email)
            .then(response => {
                if(response.message === 'email not in db') {
                    this.setState({
                        loading: false, 
                        buttonActive: false,
                        show_message: true, 
                        server_message: 'The email address isn\'t recognized. Please try again or register for a new account'
                    })
                } else if(response.message === 'recovery email sent') {
                    this.setState({
                        loading: false,
                        buttonActive: false,
                        show_message: true, 
                        server_message: 'Password reset email successfully sent!'
                    })
                } else if(response.message === 'Email was not provided') {
                    this.setState({
                        loading: false,
                        buttonActive: false,
                        show_message: true, 
                        server_message: 'Email was not provided!'
                    })
                }
            }).then(() => {

                delay(15*1000).then(() => {
                    console.log('counted 3 seconds.')
                    this.setState({buttonActive: true})
                })
            })
        }
        
    }

    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }

    handleEnter(event) {
        if(event.keyCode == 13 ) {
            this.sendPwReset();
            event.preventDefault();
        };
    }

    render () {
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        return (
        <main>

            <Helmet>
                <title>{`Password Reset | Greatest Gift`}</title>
                <meta name="description"
                        content='Forgot your password? Receive a password reset email.' 
                    />
            </Helmet>

            <h2>Reset Password:</h2>
            
            <form>
                <p className='form_label'>Please enter your account's email </p>
                <input 
                    type='email' 
                    id='email' 
                    name='email' 
                    placeholder='Example@email.com' 
                    onChange={this.handleChange} 
                    value={this.state.email}
                    autoFocus 
                    autoComplete='off'
                    onKeyDown={this.handleEnter}
                    />

            </form>

            <MasterButton   buttonText='Send password reset email'
                                handleButtonClick={this.sendPwReset}
                                notSticky={true}
                                inactive_button={!this.state.buttonActive}
                                />

            {this.state.show_null_error && (
                <div>
                    <p>Please enter a valid email address</p>
                </div>
            )}
            {this.state.show_message && (
                <div>
                    <p>{this.state.server_message}</p>
                </div>
            )}
    
        </main> 
        );
    }
}

export default Forgot_password;