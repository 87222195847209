import React from 'react';
import './Sent_gift_list.css';

import Sent_gift from '../Sent_gift/Sent_gift.js';

import dates from '../../../../utils/dates.js';

class Sent_gift_list extends React.Component {
    constructor(props) {
        super(props);

        this.renderGiftList = this.renderGiftList.bind(this);
    }

    renderGiftList(){
        return this.props.gifts.map(gift => {
            return <Sent_gift 
                        giftTo={gift.gift_for} 
                        giftAmount={gift.gift_amount - (1 - gift.accepted_fees) * gift.gift_send_fee} 
                        giftDate={dates.jsDate(gift.delivery_date)} 
                        key={gift.id}
                        /> ;
            });
    }

    render () {
        return (
            <div className='list'>
                {this.renderGiftList()}
            </div>
        );
    }
}

export default Sent_gift_list;
