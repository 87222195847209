import React from 'react';
import './Home_header.css';

import { Link } from 'react-router-dom';

//import logo_var_2 from '../../../images/logos/logo_var_2.png';
import logo_var_2 from '../../../images/logos/gg_logo_icon no_margin.svg';

class Home_header extends React.Component {
    constructor(props) {
        super(props);
    };



    render () {
        return (
            <header className='home_header'>
                <div className='home_header_container'>
                    
                    <div>
                        {/*<Link to='/'>*/}
                        <div>
                        <img src={logo_var_2} className='home_header_logo'/>
                        {/*</Link>*/}
                        </div>
                    </div>

                    <h2>Home</h2>
                    
                    <div className='background_for_icon' onClick={this.props.toggleMenu}>
                        <i className='material-icons-outlined person_icon'>person</i>  
                    </div>
                </div>
            </header>
            );
    }
}

export default Home_header;